








import { Component, Vue } from "vue-property-decorator";
import TopToolbarLogo from "@/components/navigation/TopToolbarLogo.vue";
import MachineSelectionCard from "@/components/docphant/MachineSelectionCard.vue";
import DocphantFooter from "@/components/docphant/DocphantFooter.vue";
import { DocphantModule } from "@/store/modules/docphant";

@Component({
  components: {
    TopToolbarLogo,
    MachineSelectionCard,
    DocphantFooter
  }
})
export default class MachineSelection extends Vue {
  mounted() {
    const favIcon: any = document.querySelector("link[rel~='icon']");
    favIcon.href = this.partner.settings.favIconUrl;
  }

  /*
   * information on cooperation partner content
   */
  get coop() {
    return DocphantModule.cooperatingPartners;
  }
  get partner() {
    return DocphantModule.ibLeimkuehler;
  }
}
