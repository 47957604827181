







import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class TopToolbar extends Vue {
  @Prop({ default: "" })
  src!: string;

  @Prop({ default: "4" })
  elevation?: string;
}
