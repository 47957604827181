













import CooperationPartners from "@/components/docphant/CooperationPartners.vue";
import ManufacturerCreationCard from "@/components/docphant/dashboard/ManufacturerCreationCard.vue";
import ManufacturerDetailCard from "@/components/docphant/dashboard/ManufacturerDetailCard.vue";
import ManufacturerListCard from "@/components/docphant/dashboard/ManufacturerListCard.vue";
import TopToolbarLogo from "@/components/navigation/TopToolbarLogo.vue";
import PartnerFooter from "@/components/partner/PartnerFooter.vue";
import { DocphantModule } from "@/store/modules/docphant";
import { Component, Vue } from "vue-property-decorator";
import DocphantFooter from "@/components/docphant/DocphantFooter.vue";
import TheLayoutDocphant from "@/layouts/TheLayoutDocphant.vue";

@Component({
  components: {
    TheLayoutDocphant,
    PartnerFooter,
    TopToolbarLogo,
    ManufacturerListCard,
    ManufacturerDetailCard,
    CooperationPartners,
    ManufacturerCreationCard,
    DocphantFooter
  }
})
export default class ManufacturerCreationPage extends Vue {
  mounted() {
    const favIcon: any = document.querySelector("link[rel~='icon']");
    favIcon.href = this.partner.settings.favIconUrl;
  }

  /*
   * information on cooperation partner content
   */
  get coop() {
    return DocphantModule.cooperatingPartners;
  }
  get partner() {
    return DocphantModule.ibLeimkuehler;
  }
}
