var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      attrs: {
        flat: ""
      }
    },
    [
      _c(
        "v-card-text",
        [
          _c("v-combobox", {
            attrs: {
              items: _vm.baseUrlList,
              label: "Basis-Url",
              outlined: "",
              dense: ""
            },
            on: {
              change: _vm.updateBaseUrl
            },
            model: {
              value: _vm.selectedBaseUrl,
              callback: function callback($$v) {
                _vm.selectedBaseUrl = $$v
              },
              expression: "selectedBaseUrl"
            }
          }),
          _c("p", [_vm._v("Url zur Maschine:")]),
          _c(
            "a",
            {
              attrs: {
                target: "_blank",
                href: _vm.urlToSelectedMachineForQrCOde
              }
            },
            [_vm._v(_vm._s(_vm.urlToSelectedMachineForQrCOde))]
          ),
          _c("br"),
          _c("br"),
          _c("qrcode-vue", {
            attrs: {
              value: _vm.urlToSelectedMachineForQrCOde,
              size: "300",
              level: "L"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }