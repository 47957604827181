































import { simpleDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { MrfiktivFaqViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ThgFaqViewModelGen } from "@/services/thg/v1/data-contracts";
import { FaqModule } from "@/store/modules/faq.store";
import { Component, Prop } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import Card from "../utility/Card.vue";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog.vue";
import PartnerFaqEditDialog from "./PartnerFaqEditDialog.vue";
import PartnerFaqToggleActive from "./PartnerFaqToggleActive.vue";

@Component({
  components: {
    Card,
    ConfirmDeleteDialog: ConfirmDeleteDialog,
    PartnerFaqEditDialog,
    PartnerFaqToggleActive,
    LatestEntriesCardEmpty
  }
})
export default class PartnerFaqDetailCard extends DarkModeHighlightMixin {
  @Prop()
  isDetailLoading!: boolean;

  @Prop()
  faq!: ThgFaqViewModelGen | MrfiktivFaqViewModelGen;

  isDeleteDialog = false;

  isDeleteLoading = false;

  get timestamp() {
    if (!this.faq?.timestamp.created) {
      return "";
    }
    return simpleDate(this.faq?.timestamp.created);
  }

  onUpdate(faq: ThgFaqViewModelGen) {
    this.$emit("update", faq);
  }

  async deleteFaq() {
    try {
      this.isDeleteLoading = true;
      await FaqModule.delete({ faqId: this.faq.id, partnerId: this.faq.partnerId });
      this.$emit("delete", this.faq);
    } catch (e) {
      handleError(e);
    } finally {
      this.isDeleteDialog = false;
      this.isDeleteLoading = false;
    }
  }
}
