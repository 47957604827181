/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivCompanyControllerGetParamsGen,
  MrfiktivCompanyViewModelGen,
  MrfiktivCreateCompanyDtoGen,
  MrfiktivPageViewModelGen,
  MrfiktivUpdateCompanyDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Company<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags company
   * @name CompanyControllerCreate
   * @summary (Company - CREATE) Create a Company
   * @request POST:/partner/{partnerId}/company
   * @secure
   * @response `201` `MrfiktivCompanyViewModelGen` The created company
   */
  companyControllerCreate = (partnerId: string, data: MrfiktivCreateCompanyDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivCompanyViewModelGen, any>({
      path: `/partner/${partnerId}/company`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags company
   * @name CompanyControllerGet
   * @summary (Company - READ) Get all companies
   * @request GET:/partner/{partnerId}/company
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivCompanyViewModelGen)[] })`
   */
  companyControllerGet = ({ partnerId, ...query }: MrfiktivCompanyControllerGetParamsGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivPageViewModelGen & { data?: MrfiktivCompanyViewModelGen[] }, any>({
      path: `/partner/${partnerId}/company`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags company
   * @name CompanyControllerGetOne
   * @summary (Company - READ) Get a company
   * @request GET:/partner/{partnerId}/company/{companyId}
   * @secure
   * @response `200` `MrfiktivCompanyViewModelGen` The company
   */
  companyControllerGetOne = (partnerId: string, companyId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivCompanyViewModelGen, any>({
      path: `/partner/${partnerId}/company/${companyId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags company
   * @name CompanyControllerUpdate
   * @summary (Company - UPDATE) Updates a company
   * @request PATCH:/partner/{partnerId}/company/{companyId}
   * @secure
   * @response `201` `MrfiktivCompanyViewModelGen` The updated company
   */
  companyControllerUpdate = (
    partnerId: string,
    companyId: string,
    data: MrfiktivUpdateCompanyDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivCompanyViewModelGen, any>({
      path: `/partner/${partnerId}/company/${companyId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags company
   * @name CompanyControllerDelete
   * @summary (Company - DELETE) Deletes a company
   * @request DELETE:/partner/{partnerId}/company/{companyId}
   * @secure
   * @response `200` `MrfiktivCompanyViewModelGen` The deleted company
   */
  companyControllerDelete = (partnerId: string, companyId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivCompanyViewModelGen, any>({
      path: `/partner/${partnerId}/company/${companyId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
}
