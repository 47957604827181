import { ThgHttpClientProvider } from "@/services/thg/thg-http-client.provider";
import { BillingInformation } from "../v1/BillingInformation";
import { ThgBillingInformationViewmodelGen, ThgCreateBillingInformationDtoGen } from "../v1/data-contracts";
import { HttpClient } from "../v1/http-client";

/**
 * Communicates with the thg module (implementet in thg api)
 */
class BillingInformationService {
  /**
   * The HttpClient containing the Axios Instance
   */
  client: HttpClient;

  /**
   * The proxy.
   */
  proxy: BillingInformation;

  /**
   * @class Initialize BillingInformationService
   */
  constructor(clientProvider: ThgHttpClientProvider) {
    this.client = clientProvider.client();
    this.proxy = new BillingInformation(this.client);
  }

  /**
   *
   * Gets the Bankinginformation of a User by AuthInfo
   * (BILLING_INFORMATION Read Permission)
   *
   * @returns
   */
  async getBillingInformationForPartner(partnerId: string): Promise<ThgBillingInformationViewmodelGen> {
    const response = await this.proxy.billingInformationControllerGetForPartner(partnerId);
    return response.data;
  }

  /**
   *
   * Gets the Bankinginformation of a User by AuthInfo
   * (BILLING_INFORMATION Update Permission)
   *
   * @returns
   */
  async createOrUpdateBillingInformationForPartner(
    partnerId: string,
    dto: ThgCreateBillingInformationDtoGen
  ): Promise<ThgBillingInformationViewmodelGen> {
    const response = await this.proxy.billingInformationControllerCreateOrUpdateForPartner(partnerId, dto);
    return response.data;
  }
}

export default new BillingInformationService(new ThgHttpClientProvider());
