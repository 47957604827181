/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * removes whitespace before and after value
 * removes capitalization
 * replaces groups of whitespace with minus
 *
 * @param value
 */
export function convertToKey(value: string) {
  let processedValue = value;

  processedValue = processedValue.trim();
  processedValue = processedValue.toLowerCase();
  processedValue = processedValue.replace(/\s\s+/g, " ");
  processedValue = processedValue.replace(/\s/g, "-");

  return processedValue;
}
