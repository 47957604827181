var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("the-layout-portal-list", {
    attrs: {
      title: "User",
      loading: _vm.isLoading,
      isLoadingMore: _vm.isLoadingMore,
      getModule: _vm.getModule
    },
    on: {
      loadMore: _vm.loadMore,
      refresh: _vm.refresh
    },
    scopedSlots: _vm._u([
      {
        key: "options",
        fn: function fn() {
          return [
            _c("user-administration-detail-create-dialog"),
            _c(
              "v-tooltip",
              {
                attrs: {
                  bottom: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function fn(_ref) {
                      var on = _ref.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              attrs: {
                                to: {
                                  name: "UserTable"
                                },
                                icon: ""
                              }
                            },
                            on
                          ),
                          [_c("v-icon", [_vm._v("mdi-table")])],
                          1
                        )
                      ]
                    }
                  }
                ])
              },
              [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("components.partner.UserTable.title")))
                ])
              ]
            )
          ]
        },
        proxy: true
      },
      {
        key: "listItem",
        fn: function fn(_ref2) {
          var listItem = _ref2.listItem
          return [
            _c(
              "v-list-item",
              {
                key: listItem ? listItem._id : "",
                staticClass: "listItem",
                class: _vm.isActive(listItem),
                attrs: {
                  disabled: _vm.loadingUser,
                  "two-line": ""
                },
                on: {
                  click: function click($event) {
                    return _vm.set(listItem)
                  }
                }
              },
              [
                _c(
                  "v-list-item-content",
                  [
                    _c("v-list-item-title", [
                      _vm._v(
                        " " +
                          _vm._s(listItem ? listItem.firstName : "") +
                          " " +
                          _vm._s(listItem ? listItem.lastName : "") +
                          " "
                      )
                    ]),
                    _c("v-list-item-subtitle", [
                      _vm._v(_vm._s(listItem ? listItem.userName : ""))
                    ])
                  ],
                  1
                ),
                _c(
                  "v-list-item-action",
                  [
                    _c("v-list-item-action-text", [
                      _vm._v(_vm._s(_vm.date(listItem)))
                    ]),
                    _c("v-icon", {
                      attrs: {
                        color: "grey lighten-1"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }