var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return !_vm.isLoading
    ? _c(
        "v-card",
        {
          attrs: {
            flat: ""
          }
        },
        [
          _c("v-card-title", [
            _c("h3", [_vm._v(_vm._s(_vm.partner.companyName))])
          ]),
          _c("v-card-subtitle", [
            _vm._v(
              " " +
                _vm._s(_vm.partner.id) +
                " erstellt am " +
                _vm._s(_vm._f("detailedDate")(_vm.partner.timestamps.created)) +
                " "
            )
          ]),
          _c(
            "v-tabs",
            {
              attrs: {
                "background-color": "transparent",
                color: "basil",
                grow: "",
                "show-arrows": ""
              },
              model: {
                value: _vm.tab,
                callback: function callback($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _c("v-tab", [_vm._v("Infos")]),
              _c("v-tab", [_vm._v("Addresse")]),
              _c("v-tab", [_vm._v("Kontakt")]),
              _c("v-tab", [_vm._v("Settings")]),
              _vm.isPartnerBankingAvailable
                ? _c("v-tab", [_vm._v("Bankdaten")])
                : _vm._e()
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card",
            {
              directives: [
                {
                  name: "resize",
                  rawName: "v-resize",
                  value: _vm.onResize,
                  expression: "onResize"
                }
              ],
              staticClass: "scrollable",
              attrs: {
                height: _vm.virtualScrollerHeight,
                flat: ""
              }
            },
            [
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function callback($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                [
                  _c(
                    "v-tab-item",
                    {
                      key: "0"
                    },
                    [
                      _c("partner-form", {
                        attrs: {
                          partner: _vm.partner
                        },
                        on: {
                          save: _vm.updatePartner
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    {
                      key: "1"
                    },
                    [
                      _c("address-form", {
                        attrs: {
                          address: _vm.partner.address
                        },
                        on: {
                          save: _vm.updatePartnerAddress
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    {
                      key: "2"
                    },
                    [
                      _c("contact-form", {
                        attrs: {
                          contact: _vm.partner.contact
                        },
                        on: {
                          save: _vm.updatePartnerContact
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    {
                      key: "3"
                    },
                    [
                      _c("partner-settings-form", {
                        attrs: {
                          settings: _vm.partner.settings
                        },
                        on: {
                          save: _vm.updatePartnerSettings
                        }
                      })
                    ],
                    1
                  ),
                  _vm.isPartnerBankingAvailable
                    ? _c(
                        "v-tab-item",
                        {
                          key: "6"
                        },
                        [_c("PartnerDetailBillingInformationCard")],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _c("v-skeleton-loader", {
        attrs: {
          type:
            "article,\n              list-item-two-line,\n              list-item-two-line,\n              list-item-two-line,\n              list-item-two-line,\n              list-item-two-line,\n              list-item-two-line,\n              list-item-two-line,\n              list-item-two-line"
        }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }