var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      staticClass: "mx-0",
      attrs: {
        flat: ""
      }
    },
    [
      _c(
        "v-form",
        {
          ref: "form",
          model: {
            value: _vm.valid,
            callback: function callback($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c("v-autocomplete", {
            ref: "startTextField",
            attrs: {
              "auto-select-first": "",
              rules: _vm.requiredRules,
              label: _vm.$t("components.thg.ThgRegistrationForm.driveTyp"),
              outlined: "",
              "cache-items": "",
              items: _vm.driveTypes,
              "item-text": "text",
              "item-value": "value"
            },
            on: {
              blur: function blur($event) {
                _vm.registration.driveTyp = $event.target.value
              }
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function fn(_ref) {
                  var item = _ref.item
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.getDriveType(item)) +
                        " (" +
                        _vm._s(item) +
                        ") "
                    )
                  ]
                }
              }
            ]),
            model: {
              value: _vm.registration.driveTyp,
              callback: function callback($$v) {
                _vm.$set(_vm.registration, "driveTyp", $$v)
              },
              expression: "registration.driveTyp"
            }
          }),
          _c("v-autocomplete", {
            attrs: {
              items: _vm.legitChecksums,
              rules: _vm.requiredRules,
              label: _vm.$t("components.thg.ThgRegistrationForm.checksum"),
              "cache-items": "",
              outlined: "",
              "auto-select-first": ""
            },
            on: {
              blur: _vm.blurChecksum,
              keyup: _vm.validate
            },
            model: {
              value: _vm._checksum,
              callback: function callback($$v) {
                _vm._checksum = $$v
              },
              expression: "_checksum"
            }
          }),
          _c("v-text-field", {
            ref: "fin",
            staticClass: "code",
            attrs: {
              label: _vm.$t(
                "components.thg.ThgRegistrationForm.identificationnumber"
              ),
              counter: "17",
              rules: _vm.vinRules,
              suffix: _vm.getChecksumText,
              outlined: ""
            },
            on: {
              keyup: _vm.uppercase,
              blur: _vm.checkfin
            },
            model: {
              value: _vm.registration.identificationnumber,
              callback: function callback($$v) {
                _vm.$set(_vm.registration, "identificationnumber", $$v)
              },
              expression: "registration.identificationnumber"
            }
          }),
          _c("v-autocomplete", {
            attrs: {
              "auto-select-first": "",
              label: _vm.$t("components.thg.ThgRegistrationForm.vehicleClass"),
              rules: _vm.requiredRules,
              outlined: "",
              "cache-items": "",
              items: _vm.vehicleClasses,
              "item-text": "showText",
              "item-value": "value"
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function fn(_ref2) {
                  var item = _ref2.item
                  return [_vm._v(" " + _vm._s(_vm.getVehicleClass(item)) + " ")]
                }
              }
            ]),
            model: {
              value: _vm.registration.vehicleClass,
              callback: function callback($$v) {
                _vm.$set(_vm.registration, "vehicleClass", $$v)
              },
              expression: "registration.vehicleClass"
            }
          }),
          _c("v-text-field", {
            attrs: {
              label: _vm.$t("components.thg.ThgRegistrationForm.name"),
              rules: _vm.requiredRules,
              outlined: ""
            },
            model: {
              value: _vm.registration.name,
              callback: function callback($$v) {
                _vm.$set(_vm.registration, "name", $$v)
              },
              expression: "registration.name"
            }
          }),
          _c("v-text-field", {
            attrs: {
              rules: _vm.noNumberRules,
              label: _vm.$t("components.thg.ThgRegistrationForm.firstname"),
              outlined: ""
            },
            model: {
              value: _vm.registration.firstname,
              callback: function callback($$v) {
                _vm.$set(_vm.registration, "firstname", $$v)
              },
              expression: "registration.firstname"
            }
          }),
          _c("debug", {
            attrs: {
              debug: "Given: "
                .concat(_vm.selectedThg.registration.firstregistrationYear, "-")
                .concat(
                  _vm.selectedThg.registration.firstregistrationMonth,
                  "-"
                )
                .concat(_vm.selectedThg.registration.firstregistrationDay)
            }
          }),
          _c("debug", {
            attrs: {
              debug: "Current: "
                .concat(_vm.registration.firstregistrationYear, "-")
                .concat(_vm.registration.firstregistrationMonth, "-")
                .concat(_vm.registration.firstregistrationDay)
            }
          }),
          _c("v-text-field", {
            attrs: {
              type: "date",
              rules: _vm.requiredRules,
              label: _vm.$t(
                "components.thg.ThgRegistrationForm.firstregistration"
              ),
              outlined: ""
            },
            on: {
              blur: function blur($event) {
                _vm.registrationDate = $event.target.value
              }
            },
            model: {
              value: _vm.registrationDate,
              callback: function callback($$v) {
                _vm.registrationDate = $$v
              },
              expression: "registrationDate"
            }
          }),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: !_vm.valid,
                    block: "",
                    "x-large": "",
                    loading: _vm.loading,
                    color: "success"
                  },
                  on: {
                    click: _vm.submit
                  }
                },
                [_vm._v(" Fahrzeugdaten bestätigen ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }