






import { Component, Vue } from "vue-property-decorator";
import LoginCard from "@/components/login/LoginCard.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { AssetRepository, AssetEnum } from "@/lib/AssetRepository";
import { PartnerModule } from "../store/modules/partner";

@Component({
  components: { TheLayoutPortal, LoginCard }
})
export default class Login extends Vue {
  logo = AssetRepository.getAsset(false, AssetEnum.damageReportLogo);

  get title() {
    return this.$t("views.PartnerLogin.title");
  }

  get text() {
    return this.$t("views.PartnerLogin.text");
  }

  onLoginFunction = async () => {
    await PartnerModule.getPartners();
    await PartnerModule.setPartner(PartnerModule.partners[0]);
    this.$router.push({ path: "/" });
  };
}
