var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      staticClass: "mx-4",
      attrs: {
        outlined: "",
        "max-width": "344"
      }
    },
    [
      _c("v-img", {
        attrs: {
          src: _vm.manufacturer.logoUrl,
          "max-height": "200px"
        }
      }),
      _c(
        "v-card-title",
        {
          staticClass: "title"
        },
        [_c("h3", [_vm._v("Hersteller")])]
      ),
      _c(
        "v-card-text",
        {
          staticClass: "content"
        },
        [
          _vm._v(" " + _vm._s(_vm.manufacturer.name)),
          _c("br"),
          _vm._v(" " + _vm._s(_vm.manufacturer.address.street)),
          _c("br"),
          _vm._v(
            " " +
              _vm._s(_vm.manufacturer.address.zip) +
              " " +
              _vm._s(_vm.manufacturer.address.city)
          ),
          _c("br"),
          _c(
            "v-icon",
            {
              staticClass: "mr-2",
              attrs: {
                small: ""
              }
            },
            [_vm._v("mdi-email-outline")]
          ),
          _c(
            "a",
            {
              attrs: {
                href: "mailto:".concat(_vm.manufacturer.contact.email)
              }
            },
            [_vm._v(_vm._s(_vm.manufacturer.contact.email))]
          ),
          _vm._v(" "),
          _c("br"),
          _c(
            "v-icon",
            {
              staticClass: "mr-2",
              attrs: {
                small: ""
              }
            },
            [_vm._v("mdi-phone-outline")]
          ),
          _c(
            "a",
            {
              attrs: {
                href: "tel:".concat(_vm.manufacturer.contact.phone)
              }
            },
            [_vm._v(_vm._s(_vm.manufacturer.contact.phone))]
          ),
          _c("br")
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c("ManufacturerDeleteDialog", {
            attrs: {
              manufacturer: _vm.manufacturer
            }
          }),
          _c("ManufacturerUpdateDialog", {
            attrs: {
              manufacturer: _vm.manufacturer
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }