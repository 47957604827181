import { defineAbilitiesFor } from "@/lib/ability";
import eventBus from "@/lib/eventBus";
import { getPartnersFromUserPermissions } from "@/lib/getPartnersFromUserPermissions";
import { IStoredAuthAccessToken } from "@/lib/interfaces/User/storedAuthAccessToken.interface";
import { UpdateUserDtoFactory } from "@/lib/utility/updateUserDtoFactory";
import { IUser } from "@/models/user.entity";
import { updateApplicationInsightsUserId } from "@/plugins/AppInsightsPlugin";
import { default as AuthService } from "@/services/mrfiktiv/services/authService";
import {
  MrfiktivChangePasswordIfRequiredDtoGen as ChangePasswordIfRequiredDto,
  MrfiktivConfirmPasswordDtoGen as ConfirmPasswordDto,
  MrfiktivForgotPasswordDtoGen as ForgotPasswordDto,
  MrfiktivGetTokenByRefreshTokenDtoGen as GetTokenByRefreshTokenDto,
  MrfiktivUpdateUserDtoGen,
  MrfiktivPermissionDtoGen as PermissionDto,
  MrfiktivUserAuthDtoGen as UserAuthDto,
  MrfiktivVerifyAuthDtoGen as VerifyAuthDto
} from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { AuthRolesEnum } from "@/store/enum/authRolesEnum";
import { Ability } from "@casl/ability";
import Vue from "vue";
import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";
import adminUserService from "../../services/shared/adminUserService";
import { ResourceEnum } from "../enum/authResourceEnum";
import { ErrorLogModule } from "./error-log";

@Module({
  dynamic: true,
  namespaced: true,
  name: "userStore",
  store
})
export class UserMeStore extends VuexModule {
  /**
   * Access/refresh/id token, expiration time
   */
  authAccessTokens: IStoredAuthAccessToken =
    JSON.parse(window.localStorage.getItem("vuex") || "{}").state?.authAccessTokens || "";

  userId: string = JSON.parse(window.localStorage.getItem("vuex") || "{}").state?.userId || "";

  user: IUser = JSON.parse(window.localStorage.getItem("vuex") || "{}").state?.user || "";

  userAnalyticsPartnerIds: string[] =
    JSON.parse(window.localStorage.getItem("vuex") || "{}").state?.userAnalyticsPartnerIds || [];

  /**
   * list of partnerids that user is assigned to
   */
  partnerIds: string[] = JSON.parse(window.localStorage.getItem("vuex") || "{}").state?.partnerIds || [];

  /**
   * permissions of user
   */
  abilities: Ability = new Ability(
    JSON.parse(window.localStorage.getItem("vuex") || "{}").state?.abilities?.$ || defineAbilitiesFor()
  );

  @Mutation
  setAuthAccessToken(authAccessTokens: any) {
    this.authAccessTokens = authAccessTokens;
  }

  @Mutation
  setRefreshAccessTokens(authAccessTokens: any) {
    this.authAccessTokens = {
      expiresAt: authAccessTokens.expiresAt,
      refreshToken: this.authAccessTokens.refreshToken,
      idToken: authAccessTokens.idToken,
      accessToken: authAccessTokens.accessToken
    };
  }

  @Mutation
  setUser(user: IUser) {
    this.user = user;
  }

  @Mutation
  setUserAnaliticsPartnerIds(permissions: PermissionDto[]) {
    const partnerIds = permissions.filter(p => p.type === ResourceEnum.ANALYTICS).map(p => p.id);
    this.userAnalyticsPartnerIds = partnerIds;
  }

  @Mutation
  setUserId(userId: string) {
    this.userId = userId;
    updateApplicationInsightsUserId(Vue, userId);
  }

  @Mutation
  setPartners(partnerIds: string[]) {
    this.partnerIds = partnerIds;
  }

  @Mutation
  setPartnersByPermissions(permissions: PermissionDto[]) {
    const res = getPartnersFromUserPermissions(permissions);
    this.partnerIds = res;
  }

  @Mutation
  setAbilities(user?: any) {
    const abilities = defineAbilitiesFor(user);
    this.abilities.update(abilities);
  }

  @Action
  async logout() {
    Vue.$log.info("logging out");
    try {
      await AuthService.signOut();
    } catch (error) {
      Vue.$log.error(error);
    }
    this.removeUserTokens();

    eventBus.$emit("logout");
  }

  @Action
  async deactivateTokens() {
    await AuthService.signOut();
  }

  @Action
  forceLogout() {
    Vue.$log.error("force logout");
    this.removeUserTokens();
    eventBus.$emit("sessionExpired");
  }

  @Action
  removeUserTokens() {
    this.context.commit("setUser", {} as IUser);
    this.context.commit("setAuthAccessToken", { expiresAt: "", refreshToken: "", idToken: "", accessToken: "" });
    this.context.commit("setAbilities");
  }

  get isAdmin() {
    return this.user.roles?.includes(AuthRolesEnum.ADMIN);
  }

  /** Computed property - not part of the store */
  get userHasAnalyticsPartnerIds() {
    return this.userAnalyticsPartnerIds && this.userAnalyticsPartnerIds.length > 0;
  }

  /**
   * Assuming a user is "authenticated" if a given access token is present.
   * The first call to the api will determine if the user is `actually` authenticated.
   * If a user is not authenticated the access token will be removed via @see UserMeStore.removeUserTokens() action.
   */
  get isAuthenticated() {
    return Boolean(this.authAccessTokens.accessToken);
  }

  @Action
  async getUserInfo() {
    if (!this.authAccessTokens.accessToken) {
      throw new Error("notLoggedIn");
    }
    const authRequestUser = await adminUserService.getByAuthInfo();

    const id = authRequestUser._id;

    this.context.commit("setUserId", id);
    this.context.commit("setPartnersByPermissions", authRequestUser.permission);

    // Set analytics partner ids
    this.context.commit("setUserAnaliticsPartnerIds", authRequestUser.permission);

    this.context.commit("setAbilities", authRequestUser);
    this.context.commit("setUser", authRequestUser);
  }

  @Action
  async setIsMarketingOptin() {
    const updateUserDto = new UpdateUserDtoFactory().createFromIUser(this.user);
    updateUserDto.isMarketingOptIn = true;
    await UserModule.updateUser({ id: this.user._id, updateUserDto: updateUserDto });
  }

  @Action
  async setPhone(phone: string) {
    const updateUserDto = new UpdateUserDtoFactory().createFromIUser(this.user);
    updateUserDto.contact.phone = phone;
    await UserModule.updateUser({ id: this.user._id, updateUserDto: updateUserDto });
  }

  @Action
  async refresh(getTokenByRefreshTokenDto: GetTokenByRefreshTokenDto) {
    try {
      const authAccessTokens = await AuthService.refresh(getTokenByRefreshTokenDto);

      const authInfo = {
        expiresAt: Date.now() + authAccessTokens.expiresIn * 1000 - 1000,
        refreshToken: authAccessTokens.refreshToken,
        idToken: authAccessTokens.idToken,
        accessToken: authAccessTokens.access_token
      };

      this.context.commit("setRefreshAccessTokens", authInfo);
    } catch (error) {
      Vue.$log.error(error);
      throw error;
    }
  }

  @Action
  async getCurrentUser() {
    try {
      const user = await adminUserService.getByAuthInfo();
      this.context.commit("setUser", user);
    } catch (error) {
      ErrorLogModule.addErrorLog({
        name: "User Error",
        message: "Could not fetch current User"
      });
      // FIXME:
      // useAuth().logout();
    }
  }

  @Action
  async updateUser(userDto: { id: string; updateUserDto: MrfiktivUpdateUserDtoGen }) {
    const user = await adminUserService.update(userDto.id, userDto.updateUserDto);
    this.context.commit("setUser", user);

    eventBus.$emit("userUpdated");
  }

  @Action
  async logIn(userAuthDto: UserAuthDto) {
    const authAccessTokens = await AuthService.signIn(userAuthDto);

    const authInfo = {
      expiresAt: Date.now() + authAccessTokens.expiresIn * 1000 - 1000,
      refreshToken: authAccessTokens.refreshToken,
      idToken: authAccessTokens.idToken,
      accessToken: authAccessTokens.access_token
    };
    this.context.commit("setAuthAccessToken", authInfo);

    eventBus.$emit("login");
  }

  @Action
  async changePasswordIfRequired(changePasswordIfRequiredDto: ChangePasswordIfRequiredDto) {
    const authAccessTokens = await AuthService.changePasswordIfRequired(changePasswordIfRequiredDto);
    const authInfo = {
      expiresAt: Date.now() + authAccessTokens.expiresIn * 1000 - 1000,
      refreshToken: authAccessTokens.refreshToken,
      idToken: authAccessTokens.idToken,
      accessToken: authAccessTokens.access_token
    };
    this.context.commit("setAuthAccessToken", authInfo);
  }

  @Action
  async confirmPassword(confirmPasswordDto: ConfirmPasswordDto) {
    await AuthService.confirmPassword(confirmPasswordDto);
  }

  @Action
  async forgotPassword(mail: string) {
    await AuthService.forgotPassword({ username: mail });
  }

  @Action
  async confirmMail(verifyAuthDto: VerifyAuthDto) {
    await AuthService.confirmMail(verifyAuthDto);
  }

  @Action
  async requestNewConfirmMailCode(username: string) {
    const requestNewVerification: ForgotPasswordDto = {
      username: username
    };
    await AuthService.requestNewConfirmMailCode(requestNewVerification);
  }
}

export const UserModule = getModule(UserMeStore);
