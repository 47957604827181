import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { IEntity } from "@/lib/utility/data/entity.interface";
import costService from "@/services/mrfiktiv/services/costService";
import { MrfiktivCostViewModelGen, MrfiktivUpdateCostDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { CostDataAccessLayer } from "@/store/modules/access-layers/cost.access-layer";
import { CostGroupDataAccessLayer } from "@/store/modules/access-layers/cost-group.access-layer";
import { IReference, Reference } from "./reference.entity";
import { ITimestamp, Timestamp } from "./timestamp.entity";
import { CustomFieldValue, ICustomFieldValue } from "./custom-field-value.entity";
import { ICustomViewableEntity } from "@/lib/interfaces/custom-viewable-entity.interface";
import { VehicleReference } from "./vehicle-reference.entity";
import { CostTypeEnum } from "@/lib/enum/cost-type.enum";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";

@IsFilterable
class CostBase
  implements
    IEntity<ICost, MrfiktivUpdateCostDtoGen>,
    ICustomViewableEntity<ICost, MrfiktivUpdateCostDtoGen>,
    Omit<MrfiktivCostViewModelGen, "values"> {
  /** Id of the cost object */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.cost.id"
  })
  id: string;

  /** The partnerId of the cost */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.cost.partnerId"
  })
  partnerId: string;

  /** The type of the cost object */
  @FilterConfig({
    type: FilterTypes.ENUM,
    displayName: "objects.cost.type",
    config: {
      items: Object.values(CostTypeEnum).map(e => {
        return {
          text: `enums.CostTypeEnum.${e}`,
          value: e
        } as IVSelectItem;
      }),
      itemValue: "value"
    }
  })
  get expenseOrIncome() {
    return this.total <= 0 ? CostTypeEnum.EXPENSE : CostTypeEnum.INCOME;
  }

  set expenseOrIncome(expenseOrIncome: CostTypeEnum) {
    if (expenseOrIncome === CostTypeEnum.EXPENSE) {
      this.total = -this.absoluteTotal;
    } else if (expenseOrIncome === CostTypeEnum.INCOME) {
      this.total = this.absoluteTotal;
    }
  }

  /** The total value of the cost object as absolute number without minus */
  get absoluteTotal() {
    return Math.abs(this.total);
  }

  set absoluteTotal(value) {
    this.total = this.expenseOrIncome === CostTypeEnum.EXPENSE ? -value : value;
  }

  /**
   * use absoluteTotal and expenseOrIncome instead
   * @deprecated
   */
  @FilterConfig({
    type: FilterTypes.NUMBER,
    displayName: "objects.cost.total"
  })
  total: number;

  /** Title of the cost object */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.cost.title"
  })
  title: string;

  /** Internal description of the cost object */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.cost.description"
  })
  description: string;

  /** tags */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.cost.tags"
  })
  tags: string[];

  /** values of the custom fields */
  values: ICustomFieldValue[] = [];

  /** file ids */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.cost.files"
  })
  files: string[];

  /** refs */
  @FilterConfig({
    type: VehicleReference
  })
  @FilterConfig({
    type: Reference
  })
  refs: IReference[];

  /** cost group */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    config: {
      itemCallback: () => CostGroupDataAccessLayer.entities,
      mapItemToComponent: (item: any) => {
        return { item };
      },
      itemValue: "id",
      component: "refs-cost-group"
    },
    displayName: "objects.cost.group"
  })
  group?: string;

  /**
   * The invoice date
   * @format date-time
   */
  @FilterConfig({
    type: FilterTypes.DATE,
    displayName: "objects.cost.date"
  })
  date: string;

  /** The creator */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.cost.userId",
    config: {
      itemCallback: () => PartnerUserModule.paginationList,
      mapItemToComponent: (item: any) => {
        return { item };
      },
      itemValue: "id",
      component: "refs-user"
    }
  })
  userId?: string;

  /** timestamp */
  @FilterConfig({
    type: Timestamp
  })
  timestamp: ITimestamp;

  get totalReadable() {
    return this.total.toLocaleString("de-DE", { style: "currency", currency: "EUR" });
  }

  get projectId() {
    return this.group;
  }

  get titleReadable() {
    return `${this.title}`;
  }

  loading = false;

  constructor(data: Partial<MrfiktivCostViewModelGen> | ICost) {
    this.id = data.id ?? "";
    this.partnerId = data.partnerId ?? "";
    this.total = data.total ?? 0;
    this.title = data.title ?? "";
    this.description = data.description ?? "";
    this.tags = data.tags ?? [];
    this.files = data.files ?? [];
    this.refs = (data.refs ?? []).map(ref => new Reference(ref));
    this.group = data.group;
    this.timestamp = new Timestamp(data.timestamp);
    this.date = data.date ?? "";
    if (!this.date && this.timestamp.created) {
      this.date = this.timestamp.createdNoTime;
    }
    this.userId = data.userId;
    this.values = CustomFieldValue.buildCustomFieldValues(data?.values || []);
  }

  map(data: MrfiktivCostViewModelGen): void {
    this.id = data.id;
    this.partnerId = data.partnerId;
    this.total = data.total;
    this.title = data.title;
    this.description = data.description;
    this.tags = data.tags;
    this.files = data.files;
    this.refs = (data.refs ?? []).map(ref => new Reference(ref));
    this.group = data.group;
    this.timestamp = new Timestamp(data.timestamp);
    this.date = data.date ?? "";
    if (!this.date && this.timestamp.created) {
      this.date = this.timestamp.createdNoTime;
    }
    this.userId = data.userId;
    this.values = CustomFieldValue.buildCustomFieldValues(data?.values || []);
  }

  async create(): Promise<this> {
    const res = await costService.create(this.partnerId, {
      title: this.title,
      description: this.description,
      tags: this.tags,
      files: this.files,
      refs: this.refs,
      total: this.total,
      group: this.group || undefined,
      values: CustomFieldValue.buildCustomFieldValuesDto(this.values || []),
      date: this.date || undefined
    });

    this.map(res);
    CostDataAccessLayer.set(this);

    return this;
  }

  async fetch(): Promise<this> {
    this.loading = true;
    try {
      const res = await costService.getOne(this.partnerId, this.id);
      this.map(res);
      CostDataAccessLayer.set(this);
    } catch (e) {
      this.loading = false;
      CostDataAccessLayer.delete(this);
      throw e;
    }
    this.loading = false;

    return this;
  }

  async updatePartial(dto: Partial<MrfiktivUpdateCostDtoGen>): Promise<this> {
    if (dto.values) {
      dto.values = CustomFieldValue.buildCustomFieldValuesDto((dto.values as ICustomFieldValue[]) || []);
    }

    const res = await costService.update(this.partnerId, this.id, dto);

    this.map(res);
    CostDataAccessLayer.set(this);

    return this;
  }

  async update(): Promise<this> {
    this.loading = true;
    try {
      await this.updatePartial({
        title: this.title,
        description: this.description,
        tags: this.tags,
        values: CustomFieldValue.buildCustomFieldValuesDto(this.values || []),
        files: this.files,
        refs: this.refs,
        total: this.total,
        group: this.group || null,
        date: this.date
      });
    } catch (e) {
      this.loading = false;
      throw e;
    }
    this.loading = false;

    return this;
  }

  async delete(): Promise<void> {
    const res = await costService.delete(this.partnerId, this.id);

    this.map(res);
    CostDataAccessLayer.delete(this);
  }
}

type ICost = CostBase;
const Cost = Filter.createForClass(CostBase);

export { Cost, ICost };
