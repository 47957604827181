import { render, staticRenderFns } from "./ThgChargingStationProof.vue?vue&type=template&id=37ca0210&scoped=true&"
import script from "./ThgChargingStationProof.vue?vue&type=script&lang=ts&"
export * from "./ThgChargingStationProof.vue?vue&type=script&lang=ts&"
import style0 from "./ThgChargingStationProof.vue?vue&type=style&index=0&id=37ca0210&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37ca0210",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFileInput } from 'vuetify/lib/components/VFileInput';
installComponents(component, {VFileInput})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('37ca0210')) {
      api.createRecord('37ca0210', component.options)
    } else {
      api.reload('37ca0210', component.options)
    }
    module.hot.accept("./ThgChargingStationProof.vue?vue&type=template&id=37ca0210&scoped=true&", function () {
      api.rerender('37ca0210', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/thg/ThgChargingStationProof.vue"
export default component.exports