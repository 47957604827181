var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "card",
    {
      attrs: {
        margin: 0,
        title: _vm.$t(
          "components.partner.PartnerReportInitializeCard.empty.createTicket"
        ),
        loading: _vm.loading
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function fn() {
            return [
              _c(
                "v-btn",
                {
                  attrs: {
                    icon: ""
                  },
                  on: {
                    click: function click($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v(" mdi-close ")])],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-card-text",
        [
          _c("ticket-create-card", {
            attrs: {
              ticket: _vm.ticket,
              partnerId: _vm.partnerId
            }
          })
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c("v-btn", {
            attrs: {
              text: "",
              loading: _vm.loading || _vm.isCreateLoading
            },
            domProps: {
              textContent: _vm._s(_vm.$t("back"))
            },
            on: {
              click: _vm.back
            }
          }),
          _c("v-spacer"),
          _c("v-btn", {
            attrs: {
              color: "info",
              disabled: !_vm.ticket.title,
              loading: _vm.loading || _vm.isCreateLoading,
              elevation: 0
            },
            domProps: {
              textContent: _vm._s(_vm.$t("create"))
            },
            on: {
              click: _vm.onCreateTicket
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }