var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          attrs: {
            flat: "",
            "max-width": "4000"
          }
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    md: "6"
                  }
                },
                [
                  _c("partner-billing-information-data-card", {
                    attrs: {
                      partnerId: _vm.partnerId,
                      hasPermissions:
                        _vm.$can(
                          _vm.ActionEnum.READ,
                          _vm.ResourceEnum.BILLING_INFORMATION
                        ) &&
                        _vm.$can(_vm.ActionEnum.READ, _vm.ResourceEnum.PARTNER)
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    md: "6"
                  }
                },
                [
                  _c("portal-detail-banking", {
                    attrs: {
                      partnerId: _vm.partnerId
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }