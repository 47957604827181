











import { Component, Vue, Prop } from "vue-property-decorator";

import ErrorAlert from "@/components/utility/ErrorAlert.vue";
import TopToolbarLogo from "@/components/navigation/TopToolbarLogo.vue";

@Component({ components: { ErrorAlert, TopToolbarLogo } })
export default class LayoutLogo extends Vue {
  @Prop({ default: "" })
  src!: string;
}
