

































































































































































































import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop, Vue } from "vue-property-decorator";
import * as XLSX from "xlsx";
import { downloadAsXlsx } from "@/lib/download-as-xlsx";
import { IExcelImportConfig, DuplicateHandlingEnum } from "@/lib/interfaces/excel-import.interface";

type ImportDtoEntry = Record<string, number | boolean | string | undefined>;

/**
 * For excample usage see ExcelImportPlayground
 */
@Component({ components: { ConfirmActionDialog } })
export default class ExcelImport extends Vue {
  @Prop()
  config!: { [key: string]: IExcelImportConfig };

  @Prop({ default: "partner.excelimport.btnText" })
  btnText!: string;

  @Prop({ default: false })
  outlined!: boolean;

  @Prop({ default: false })
  small!: boolean;

  @Prop({ default: false })
  loading!: boolean;

  /**
   * Import config as array
   */
  importConfig: IExcelImportConfig[] = [];

  dialog = false;
  activeWindow = 1;
  e1 = 1;
  inputFile: File | undefined | "" = "";
  importWorkBook: any = undefined;
  isFileLoaded = false;
  content: ArrayBuffer | undefined | string | null = undefined;
  worksheetAsJson: { [key: string]: string | number | boolean }[] = [];
  importResult: any[] = [];
  validationErrors: any[] = [];
  progressCounter = 0;
  isDialogLoading = false;
  isColumnMapValid = false;
  duplicateHandling = [
    {
      value: DuplicateHandlingEnum.SKIP,
      text: "Doppelte Werte nicht importieren"
    },
    {
      value: DuplicateHandlingEnum.IGNORE,
      text: "Doppelte Werte importieren"
    }
  ];

  get importDisabled(): boolean {
    if (!this.isFileLoaded) {
      return true;
    }
    if (!this.importResult.length) {
      return true;
    }
    return false;
  }

  get isNonRequiredColumns() {
    for (const config of this.importConfig) {
      if (!config.required) {
        return true;
      }
    }

    return false;
  }

  get isAllNonRequiredSelected() {
    for (const config of this.importConfig) {
      if (!config.import && !config.required) {
        return false;
      }
    }

    return true;
  }

  /**
   * Headers of the import
   */
  get importResultPrevHeaders() {
    const headers: { text: string; value: string }[] = [];
    for (const [key, config] of Object.entries(this.config)) {
      if (!config.import) {
        continue;
      }
      headers.push({ text: key, value: key });
    }
    return headers;
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get headers() {
    return Object.values(this.config).map(c => c.label);
  }

  beforeMount() {
    const config = Object.values(this.config);
    config.sort((a, b) => Number(b.required) - Number(a.required));
    this.importConfig.splice(0, this.importConfig.length, ...config);
  }

  selectAllNonRequired() {
    for (const config of this.importConfig) {
      config.import = true;
    }
  }

  unselectAllNonRequired() {
    for (const config of this.importConfig) {
      if (!config.required) {
        config.import = false;
      }
    }
  }

  downloadHeaderFile() {
    const headerFile: Record<string, any> = {};
    for (const header of this.headers) {
      headerFile[header] = "";
    }

    downloadAsXlsx([headerFile]);
  }

  /**
   * Preview of selected data
   */
  dataPreview(config: IExcelImportConfig) {
    const preview = [];
    let escape = 0;
    for (const dataEntryRow of this.worksheetAsJson) {
      escape = escape + 1;
      if (escape > 3) {
        break;
      }

      const entry = dataEntryRow[config.originColumnNameInExcelFile];
      if (entry) {
        try {
          preview.push(config.transform(entry));
        } catch (error) {
          const errorMessage = (error as Error).message;
          preview.push(errorMessage);
        }
      } else if (entry === false) {
        preview.push(false);
      } else {
        preview.push("-");
      }
    }

    return preview;
  }

  emitDto(): { data: any; headers: any } {
    this.activeWindow = 4;
    this.$emit("uploadData", { data: this.importResult, headers: this.importResultPrevHeaders });

    return { data: this.importResult, headers: this.importResultPrevHeaders };
  }

  getConfigByLabel(label: string) {
    return Object.entries(this.config).find(c => c[1].label === label);
  }

  createDto() {
    this.importResult = [];
    this.validationErrors = [];
    /**
     * Iterate over each row of the excel file
     */
    for (const dataEntryRow of this.worksheetAsJson) {
      const importDtoEntry: ImportDtoEntry = {};
      let validationError = false;

      /**
       * Iterate over each column of the row
       * If an validation error occurs during transformation, the row is pushed to validationErrors
       * Else, the array is pushed to importResult
       */
      for (const importEntryKey of this.headers) {
        const [key, config] = this.getConfigByLabel(importEntryKey) ?? ["", undefined];

        /**
         * if import is set to false skip importing
         */
        if (!config?.import) {
          continue;
        }

        const entry = dataEntryRow[config.originColumnNameInExcelFile];

        /**
         * Check if entry exists or is of type boolean "false"
         * Check if its a duplicate
         */
        if (entry || entry === false) {
          try {
            importDtoEntry[key] = config.transform(entry);
            this.duplicateCheck(key, importDtoEntry[key]);
          } catch (error) {
            validationError = true;
            const errorMessage = (error as Error).message;
            importDtoEntry[key] = errorMessage;
          }
        } else {
          if (config.required) {
            validationError = true;
            importDtoEntry[key] = "Pflichtfeld darf nicht leer sein";
            continue;
          }
          importDtoEntry[key] = undefined;
          continue;
        }
      }

      /**
       * checks if validation error is occured during parsing the colums of a specific row
       */
      if (validationError) {
        this.validationErrors.push(importDtoEntry);
        continue;
      }
      this.importResult.push(importDtoEntry);
    }
    this.activeWindow = 3;
  }

  duplicateCheck(importEntryKey: string, value: string | number | boolean | undefined) {
    /**
     * Duplicate Handling
     */
    if (this.config[importEntryKey].duplicateHandling) {
      if (this.config[importEntryKey].duplicateHandling?.method === DuplicateHandlingEnum.SKIP) {
        if (this.config[importEntryKey].duplicateHandling?.existingValues?.includes(value)) {
          throw new Error("Entry already exists: " + value);
        }

        // Check if the value is in importResult for the specific key
        const isDuplicateInImportResult = this.importResult.some(result => result[importEntryKey] === value);

        if (isDuplicateInImportResult) {
          throw new Error("Duplicate in Import File: " + value);
        }
      }
    }
  }

  closeAndReset() {
    this.dialog = false;
    this.reset();
  }

  finish() {
    this.dialog = false;
    this.reset();
    this.$emit("finish", true);
  }

  /**
   * Resets the dialog to default
   */
  reset() {
    this.progressCounter = 0;
    this.activeWindow = 1;
    this.inputFile = undefined;
    this.importWorkBook = undefined;
    this.isFileLoaded = false;
    this.content = undefined;
    this.worksheetAsJson = [];
    this.importResult = [];
  }

  /**
   * Returns the keys of the excel file
   */
  get keys() {
    if (this.worksheetAsJson.length) {
      return Object.getOwnPropertyNames(this.worksheetAsJson[0]);
    } else {
      return [];
    }
  }

  sanitize(text: string | number | boolean) {
    if (!(typeof text === "string")) return text;

    text = text.replace(/\r?\n/g, ""); // Remove newline characters
    text = text.replace(/\s+/g, " ").trim(); // Replace consecutive space characters (including tabs \t ⊆ \s) with a single tab

    return text;
  }

  /**
   * Import Excel file from file system
   */
  async importFileAsExcel() {
    if (this.inputFile) {
      await this.readFile();
      try {
        this.importWorkBook = XLSX.read(this.content);
      } catch (error) {
        this.$log.error(error);
        this.$toast.error("Could not load Excel File.");
        return;
      }

      if (this.importWorkBook.SheetNames.length >= 2) {
        this.$toast.warning("Bitte Datei mit einem Reiter auswählen");
        return;
      }

      if (this.importWorkBook) {
        const wsname = this.importWorkBook.SheetNames[0];
        this.worksheetAsJson = XLSX.utils.sheet_to_json<{ [key: string]: string | number | boolean }>(
          this.importWorkBook.Sheets[wsname],
          { defval: null }
        );

        for (const item of this.worksheetAsJson) {
          for (const key of Object.keys(item)) {
            if (item[key]) {
              item[key] = this.sanitize(item[key]);
            }
          }
        }

        this.checkPredefinedOriginColumns();
        this.activeWindow = 2;
        this.$log.info("Workbook imported succesfully.");
      }
    }
  }

  checkPredefinedOriginColumns() {
    for (const config of this.importConfig) {
      if (!this.keys.includes(config.originColumnNameInExcelFile)) {
        config.originColumnNameInExcelFile = "";
      }
    }
  }

  /**
   * reads an excel file into array buffer
   */
  async readFile() {
    const reader = new FileReader();
    if (this.inputFile) {
      reader.onload = res => {
        this.content = res?.target?.result;
        this.isFileLoaded = true;
      };
      reader.onerror = err => this.$log.error(err);
      reader.readAsArrayBuffer(this.inputFile);
      this.$log.info("File loaded successfully.");
    }
  }

  getPartner(partnerId: string) {
    return PartnerModule.partnersMap.get(partnerId);
  }
}
