export enum NavigationCategoryEnum {
  ADMIN = "admin",
  ANALYTICS = "analytics",
  HOME = "home",
  SIGN = "sign",
  SETTINGS = "settings",
  SEARCH = "search",
  HELP = "help",
  BOOKING = "booking",
  FLEET = "fleet",
  PROJECT = "project",
  COST = "cost"
}
