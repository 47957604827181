













































































































































































































import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import TemplateCard from "@/components/template/TemplateCard.vue";
import TemplateDialog from "@/components/template/TemplateDialog.vue";
import TemplateEditor from "@/components/template/TemplateEditor.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import MDetailViewGrid from "@/components/utility/mmmint/MDetailViewGrid.vue";
import MHeader, { IAction, IBreadcrumb } from "@/components/utility/mmmint/MHeader.vue";
import RefsSelect from "@/components/utility/RefsSelect.vue";
import SelectAssignees from "@/components/utility/SelectAssignees.vue";
import TimelineCard from "@/components/utility/TimelineItem.vue";
import ViewedPreview from "@/components/utility/ViewedPreview.vue";
import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";
import { MessageFolderEnum } from "@/lib/enum/message-folder.enum";
import { detailedDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import { isMobile } from "@/lib/utility/isMobile";
import { avatar } from "@/lib/utility/mail-helper";
import { $t } from "@/lib/utility/t";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IPartnerMessage, messageFolderIconMap } from "@/models/partner-message.entity";
import { IReference } from "@/models/reference.entity";
import { TimeStampEntity } from "@/models/timestampEntity";
import { ResourceEnum, BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { PartnerModule } from "@/store/modules/partner";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { debounce } from "debounce";
import { Component, Prop } from "vue-property-decorator";
import PartnerMessageDetailAttachDialog from "@/components/partner/PartnerMessageDetailAttachDialog.vue";
import PartnerMessageDetailAttachmentRow from "@/components/partner/PartnerMessageDetailAttachmentRow.vue";
import PartnerMessageDetailReportRefs from "@/components/partner/PartnerMessageDetailReportRefs.vue";
import CostCreateDialog from "@/components/cost/CostCreateDialog.vue";
import { ISignDocument } from "@/models/sign-document.entity";
import { ICost, Cost } from "@/models/cost.entity";
import TicketCreateDialog from "@/components/project/TicketCreateDialog.vue";
import { ITicket, Ticket } from "@/models/ticket.entity";
import CostSideCard from "@/components/cost/CostSideCard.vue";
import TicketSideCard from "@/components/fleet/FleetHomeVehicleTableTicketSideCard.vue";
import { TicketModule } from "@/store/modules/ticket.store";
import { CostModule } from "@/store/modules/cost.store";

@Component({
  name: "PartnerMessageDetail",
  components: {
    MHeader,
    TemplateEditor,
    TimelineCard,
    TemplateCard,
    TemplateDialog,
    LatestEntriesCardEmpty,
    ConfirmActionDialog,
    MDetailViewGrid,
    SelectAssignees,
    ViewedPreview,
    RefsSelect,
    PartnerMessageDetailReportRefs,
    PartnerMessageDetailAttachmentRow,
    PartnerMessageDetailAttachDialog,
    CostCreateDialog,
    TicketCreateDialog,
    CostSideCard,
    TicketSideCard
  }
})
export default class PartnerMessageDetail extends PermissionMixin {
  @Prop({ default: false })
  loading!: boolean;

  @Prop()
  value?: IPartnerMessage;

  isMounting = false;

  isArchiveDialogOpen = false;

  isArchiveLoading = false;

  isMarkUnreadLoading = false;

  unMarkMenu = false;

  isLoadingRefs = false;

  isMoveLoading = false;

  isMoveDialogOpen = false;

  isCostCreateDialogActive = false;

  isTicketCreateDialogActive = false;

  selectedTicket: ITicket | null = null;

  selectedCost: ICost | null = null;

  get ticketCreateRefs(): IReference[] {
    const refs: IReference[] = [];

    if (!this.value) {
      return refs;
    }

    if (this.value?.attachments) {
      for (const attachment of this.value?.attachments ?? []) {
        if (attachment.ref?.refType === ResourceEnum.DOCUMENT) {
          refs.push(attachment.ref);
        }
      }
    }
    refs.push({ refType: ResourceEnum.MESSAGE, refId: this.value?.id });
    if (this.value?.refs) {
      for (const ref of this.value.refs) {
        if (ref.refType === ResourceEnum.REPORT) {
          refs.push(ref);
        }
      }
    }

    return refs;
  }

  get reportReferences(): IReference[] {
    return this.value?.refs?.filter(r => r.refType === ResourceEnum.REPORT) ?? [];
  }

  get editNonReportReferences(): IReference[] {
    return this.value?.refs?.filter(r => r.refType !== ResourceEnum.REPORT) ?? [];
  }

  set editNonReportReferences(v: IReference[]) {
    if (!this.value) return;
    this.value.refs = [...v, ...this.reportReferences];
  }

  get isTouch() {
    return isMobile();
  }

  get folderOptions() {
    return Object.values(MessageFolderEnum);
  }

  get messageFolderIconMap() {
    return messageFolderIconMap;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    if (!this.$route.params.messageId) {
      actions.push({
        text: $t("common.nouns.detail"),
        key: "detail",
        icon: "mdi-open-in-new",
        exec: () => this.value?.goToDetail(this.$router)
      });
    }

    actions.push({
      text: $t("common.verbs.reply"),
      key: "Reply",
      icon: "mdi-reply-outline",
      exec: () => (this.$refs.templateDialog as TemplateDialog)?.show()
    });

    actions.push({
      text: $t("message.attachToReport"),
      key: "Attach",
      icon: "mdi-reply-outline",
      exec: () => (this.$refs.attachDialog as PartnerMessageDetailAttachDialog)?.open()
    });

    actions.push({
      key: "createCost",
      icon: "mdi-cash-multiple",
      text: this.$t("cost.createCost").toString(),
      color: "",
      exec: () => this.openCreateCostDialog()
    });

    actions.push({
      key: "createTicket",
      icon: "mdi-ticket-outline",
      text: this.$t("project.ticket.createTicket").toString(),
      color: "",
      exec: () => (this.$refs.ticketCreateDialog as TicketCreateDialog).startDialog()
    });

    if (this.value?.url) {
      actions.push({
        text: $t("common.verbs.download"),
        key: "Download",
        icon: "mdi-download",
        exec: () => this.value?.download()
      });
    }

    if (this.value?.folder !== MessageFolderEnum.ARCHIVE) {
      actions.push({
        text: $t("common.verbs.archive"),
        key: "Archive",
        icon: "mdi-inbox",
        exec: () => (this.isArchiveDialogOpen = true)
      });
    }

    actions.push({
      text: $t("common.verbs.move"),
      key: "move",
      icon: "mdi-inbox-multiple",
      exec: () => (this.isMoveDialogOpen = true)
    });

    if (this.value?.viewed.length) {
      actions.push({
        text: $t("components.assignees.markUnread"),
        key: "unread",
        icon: "mdi-check-all",
        exec: () => this.markUnread()
      });
    }

    return actions;
  }

  get breadCrumbs(): IBreadcrumb[] {
    const breadCrumbs: IBreadcrumb[] = [];
    const isDetailView = this.$route.params.messageId;

    breadCrumbs.push({
      text: $t("common.nouns.inboxes"),
      to: { name: "PartnerMessageInboxList", params: { partnerId: this.partner.id } },
      exact: true
    });

    const inbox = PartnerModule.partner.settings?.inboxes?.find(inbox => inbox.identifier === this.value?.to);
    if (inbox && isDetailView) {
      breadCrumbs.push({
        text: inbox.name,
        to: {
          name: "PartnerMessageInbox",
          params: { partnerId: this.value?.partnerId ?? "", inboxId: this.value?.to ?? "" },
          query: { folder: this.value?.folder.toString() ?? "" }
        },
        exact: true
      });
    }

    if (this.value?.folder) {
      breadCrumbs.push({
        text: $t("MessageFolderEnum." + this.value?.folder),
        to: { name: "PartnerMessageDetail" },
        exact: true,
        disabled: true
      });
    } else {
      breadCrumbs.push({
        text: $t("common.nouns.detail"),
        to: { name: "PartnerMessageDetail" },
        exact: true,
        disabled: true
      });
    }
    return breadCrumbs;
  }

  get chips(): IAction[] | undefined {
    return undefined;
  }

  /**
   * E.g. "user xy from mrfiktiv"
   */
  get fromMail() {
    let fromMail = PartnerModule.partner.companyName;
    let fromUser = "";

    if (this.value?.folder === MessageFolderEnum.OUTBOX) {
      return this.value?.to;
    }

    if (this.value?.from) {
      fromMail = this.value.from;
    }

    if (this.userNameForId) {
      fromUser = this.$t("components.PartnerMessageDetail.toAlt", { mail: this.userNameForId }).toString();
    }

    return fromUser + fromMail;
  }

  get date() {
    if ((this.value?.timestamp as TimeStampEntity).created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";

      return detailedDate((this.value?.timestamp as TimeStampEntity).created, locale);
    }
    return "";
  }

  get partner() {
    return PartnerModule.partner;
  }

  get from() {
    return this.partner;
  }

  get to(): string[] {
    if (!this.value?.to) {
      return [];
    }
    return [this.value?.to];
  }

  get userNameForId() {
    if (this.value?.userId === undefined) {
      return "";
    }

    const user = PartnerUserModule.maps.id.get(this.value.userId)[0];
    if (!user) {
      return "";
    }

    return user.firstName + " " + user.lastName;
  }

  async openCreateCostDialog() {
    const message = this.value;
    if (!message) {
      return;
    }
    const costCreateDialog = this.$refs.costCreateDialog as CostCreateDialog;
    if (!costCreateDialog) {
      throw new Error("No CostCreateDialog");
    }
    costCreateDialog.isLoading = true;
    const documents = ((
      await Promise.all(
        this.value?.attachments.map(async attachment => {
          if (!attachment.isPdf) return undefined;
          return attachment.getReference(message);
        }) ?? []
      )
    ).filter(document => !!document) ?? []) as ISignDocument[];
    costCreateDialog.show();
    costCreateDialog.cost.files.push(...documents.map(document => document.id));
    await costCreateDialog.onCreatedDocument(...documents);
    costCreateDialog.isLoading = false;
  }

  getAvatar(mail: string) {
    return avatar(mail);
  }

  abortArchive() {
    this.isArchiveDialogOpen = false;
  }

  async archive() {
    try {
      if (!this.value) {
        return;
      }
      this.isArchiveLoading = true;
      await this.value.archive();
    } catch (e) {
      handleError(e);
    } finally {
      this.isArchiveDialogOpen = false;
      this.isArchiveLoading = false;
    }
  }

  async markUnread() {
    if (!this.value || this.isMarkUnreadLoading) {
      return;
    }

    this.isMarkUnreadLoading = true;
    await this.value.markUnread().catch(handleError);
    this.isMarkUnreadLoading = false;
    this.unMarkMenu = false;
  }

  async onAssigneesUpdate(assignees: []) {
    if (!this.value) return;

    await this.value.updatePartial({ assignees });
  }

  async onAssigneesAdded(assignees: string[]) {
    if (!this.value) return;

    await this.value.createAssigneeActivity(ActivityTypeEnum.CREATE_ASSIGNEE, assignees);
  }

  async onAssigneesRemoved(assignees: string[]) {
    if (!this.value) return;

    await this.value.createAssigneeActivity(ActivityTypeEnum.DELETE_ASSIGNEE, assignees);
  }

  async updateNonReportRefs(refs?: IReference[]) {
    if (!this.value) return;
    if (refs) {
      this.value.refs?.splice(0);
      this.value.refs?.push(...(refs ?? []));
    }

    await this.debounceSaveRefs();
  }

  debounceSaveRefs = debounce(this.saveRefs, 500);
  private async saveRefs() {
    if (!this.value) return;
    this.isLoadingRefs = true;
    await this.value
      .updatePartial({
        refs: this.value?.refs
      })
      .catch(handleError);
    this.isLoadingRefs = false;
  }

  async setNewFolder(folder: MessageFolderEnum) {
    if (!this.value) return;

    this.isMoveLoading = true;
    await this.value
      .updatePartial({
        folder: folder
      })
      .catch(handleError);
    this.isMoveLoading = false;
  }

  async onCostCreated(cost: ICost) {
    if (!this.value) return;
    this.isLoadingRefs = true;
    await this.value
      .updatePartial({
        refs: [
          ...(this.value.refs ?? []),
          {
            refId: cost.id,
            refType: ResourceEnum.COST
          }
        ]
      })
      .catch(e => this.$log.error(e));
    this.isLoadingRefs = false;
  }

  async onTicketCreated(ticket: ITicket) {
    if (!this.value) return;
    this.isLoadingRefs = true;
    await this.value
      .updatePartial({
        refs: [
          ...(this.value.refs ?? []),
          {
            refId: ticket.id,
            refType: ResourceEnum.TICKET
          }
        ]
      })
      .catch(e => this.$log.error(e));
    this.isLoadingRefs = false;
  }

  async openSideCard(ref: IReference) {
    if (ref.refType === BackendResourceEnum.TICKET) {
      this.selectedTicket = null;
      const ticket =
        TicketModule.maps.id.get(ref.refId)[0] ??
        (await new Ticket({ id: ref.refId, partnerId: this.value?.partnerId }).fetch().catch(handleError));
      this.$nextTick(() => {
        this.selectedTicket = ticket;
      });
    } else if (ref.refType === BackendResourceEnum.COST) {
      this.selectedCost = null;
      const cost =
        CostModule.maps.id.get(ref.refId)[0] ??
        (await new Cost({ id: ref.refId, partnerId: this.value?.partnerId }).fetch().catch(handleError));
      this.$nextTick(() => {
        this.selectedCost = cost;
      });
    }
  }
}
