































































































import { extendetPhoneRule } from "@/lib/rules/contactRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { MrfiktivContactDocumentGen, MrfiktivContactDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { CreateContactDto } from "@/store/models/mrfiktiv/contact";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class ContactForm extends DarkModeHighlightMixin {
  @Prop()
  contact!: MrfiktivContactDocumentGen;

  @Prop({ default: false })
  isLoading!: boolean;

  disabled = true;

  valid = false;

  update: MrfiktivContactDtoGen = new CreateContactDto(this.contact);

  initialize() {
    this.update = new CreateContactDto(this.contact);
  }

  mounted() {
    this.initialize();
  }

  save(): MrfiktivContactDtoGen {
    this.$emit("save", this.update);
    this.disabled = true;

    return this.update;
  }

  abort() {
    this.initialize();
    this.disabled = true;
  }

  get emailRules() {
    const rules = [];

    rules.push(requiredRule());

    return rules;
  }

  get phoneNumberRules() {
    const rules = [];

    rules.push(extendetPhoneRule());

    return rules;
  }
}
