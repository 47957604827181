




























import PaginatedTable from "@/components/utility/PaginatedTable.vue";
import TableWrapper, { IControlElements } from "@/components/utility/TableWrapper.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { MrfiktivActivityLogViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { ActivityLogModule } from "@/store/modules/activity-log.store";
import { PartnerModule } from "@/store/modules/partner";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortal,
    TableWrapper,
    PaginatedTable
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class ActivityLogTable extends PartnerFallbackMixin {
  isLoadingAll = false;
  isLoading = false;

  search = "";

  itemsPerPage = 25;

  expanded = [];
  selected: MrfiktivActivityLogViewModelGen[] = [];

  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;

  headers = [
    { text: this.$t("partner.ActivityLogTable.timestamp"), align: "start", value: "timestamp.created", width: 200 },
    { text: this.$t("partner.ActivityLogTable.userName"), align: "start", value: "user.userName" },
    { text: this.$t("partner.ActivityLogTable.refType"), align: "start", value: "source.refType", width: 200 },
    { text: this.$t("partner.ActivityLogTable.refId"), align: "start", value: "source.refId", width: 200 },
    { text: this.$t("partner.ActivityLogTable.activity"), align: "start", value: "actionType", width: 200 },
    { text: this.$t("partner.ActivityLogTable.activity"), align: "start", value: "activity" },
    { text: this.$t("partner.ActivityLogTable.comment"), align: "start", value: "comment" },
    { text: "", align: "end", value: "controls", width: 200, sortable: false }
  ];

  async mounted() {
    await this.load();
  }

  async load() {
    this.isLoadingAll = true;
    try {
      await this.trySetByRouteOrDefault();

      await ActivityLogModule.getAll({ partnerId: PartnerModule.partner._id });
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.isLoadingAll = false;
    }
  }

  async refresh(partnerId?: string) {
    if (!partnerId) {
      partnerId = PartnerModule.partner._id;
    }

    try {
      this.isLoadingAll = true;
      await ActivityLogModule.fetchAllFromBeginning({
        partnerId
      });
    } catch (e) {
      this.$log.error(e);
    } finally {
      this.isLoadingAll = false;
    }
  }

  get activityLogs(): MrfiktivActivityLogViewModelGen[] {
    return ActivityLogModule.activityLogs;
  }

  getModule() {
    return ActivityLogModule;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get controlElements(): IControlElements[] {
    return [
      {
        icon: "mdi-delete-outline",
        text: this.$t("sign.DocumentTable.delete").toString(),
        action: this.delete
      }
    ];
  }

  async open(activityLog: MrfiktivActivityLogViewModelGen) {
    // await new GoToHelper(this.$router).goToDocumentDetail(request.id, request.partnerId);
    this.$log.debug(activityLog);
  }

  async delete(activityLog: MrfiktivActivityLogViewModelGen) {
    try {
      await ActivityLogModule.delete({ partnerId: this.partner._id, activityId: activityLog.id });
    } catch (error) {
      this.$log.error(error);
      this.$toast.error("sign.Sign.error.network");
    }
  }
}
