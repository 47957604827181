var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("paginated-table", {
        ref: "paginationTable",
        attrs: {
          store: _vm.pagination,
          title: _vm.hideTitle
            ? _vm.$t("components.operation.OperationTable.title")
            : "",
          predefinedFilter: _vm.predefinedFilter,
          headers: _vm.headers,
          baseQuery: _vm.baseQuery
        },
        on: {
          "click:row": _vm.openOperationSideCard
        },
        scopedSlots: _vm._u([
          {
            key: "bodyActions",
            fn: function fn() {
              return [
                _c(
                  "btn",
                  {
                    on: {
                      click: function click() {
                        return _vm.pagination.fetchFirstPage(_vm.baseQuery)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("common.verbs.refresh")) + " ")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "item.timestamp.created",
            fn: function fn(_ref) {
              var item = _ref.item
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(new Date(item.timestamp.created).toLocaleString())
                  )
                ])
              ]
            }
          },
          {
            key: "item.id",
            fn: function fn(_ref2) {
              var item = _ref2.item
              return [
                _c(
                  "a",
                  {
                    on: {
                      click: function click($event) {
                        return _vm.goToOperationDetail(item.id, item.partnerId)
                      }
                    }
                  },
                  [_vm._v(_vm._s(item.id))]
                )
              ]
            }
          },
          {
            key: "item.type",
            fn: function fn(_ref3) {
              var item = _ref3.item
              return [
                !_vm.hideTypeHeader
                  ? _c("b", [_vm._v(_vm._s(item.type)), _c("br")])
                  : _vm._e(),
                item.operationRefs.length
                  ? _c("small", [
                      _vm._v(
                        " " +
                          _vm._s(
                            ""
                              .concat(_vm.$t("common.nouns.attempt"), " #")
                              .concat(item.operationRefs.length + 1)
                          ) +
                          " "
                      )
                    ])
                  : _vm._e()
              ]
            }
          }
        ])
      }),
      _vm.selectedOperation
        ? _c("operation-side-card", {
            attrs: {
              operation: _vm.selectedOperation
            },
            on: {
              close: function close($event) {
                _vm.selectedOperation = null
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }