










import PartnerMessageDetail from "@/components/partner/PartnerMessageDetail.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { PartnerModule } from "@/store/modules/partner";
import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IPartnerMessage, PartnerMessage } from "@/models/partner-message.entity";
import { MessageModule } from "@/store/modules/message.store";

@Component({
  components: {
    TheLayoutPortal,
    PartnerMessageDetail
  }
})
export default class PartnerMessagesDetailView extends mixins(PartnerFallbackMixin, PermissionMixin) {
  isDetailLoading = false;

  loading = true;
  isGoHomeOnBack = false;

  message: IPartnerMessage | null = null;

  setIsLoadingDetail(value: boolean) {
    this.isDetailLoading = value;
  }

  async mounted() {
    await this.trySetByRouteOrDefault();
    await this.load();
  }

  async refresh() {
    await this.load();
  }

  get partner() {
    return PartnerModule.partner;
  }

  get to(): string[] {
    if (!this.message?.to) {
      return [];
    }
    return [this.message?.to];
  }

  get newMessageTo() {
    if (this.message?.from) {
      return [this.message.from];
    }

    return this.to;
  }

  async load() {
    this.loading = true;
    this.isDetailLoading = true;
    try {
      const message = await new PartnerMessage({
        partnerId: this.$route.params.partnerId,
        id: this.$route.params.messageId
      }).fetch();

      MessageModule.mutateMessage(message);
      this.message = message;
    } catch (error) {
      this.$log.error(error);
      this.$toast.error("Error loading Message");
      this.$router.push({ name: "PartnerMessages" });
    }
    this.loading = false;
    this.isDetailLoading = false;
  }
}
