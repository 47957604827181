





































import { Component, Vue } from "vue-property-decorator";
import { DocphantModule } from "@/store/modules/docphant";
import MachineCard from "@/components/docphant/MachineCard.vue";
import DocphantFooter from "@/components/docphant/DocphantFooter.vue";
import TopToolbarLogo from "@/components/navigation/TopToolbarLogo.vue";
import AttachmentsCard from "@/components/docphant/AttachmentsCard.vue";
import Loader from "@/components/utility/Loader.vue";
import ManufacturerCard from "@/components/docphant/ManufacturerCard.vue";
@Component({
  components: {
    DocphantFooter,
    TopToolbarLogo,
    MachineCard,
    AttachmentsCard,
    Loader,
    ManufacturerCard
  }
})
export default class MachinePage extends Vue {
  /**
   * checks if machine is available in store
   */
  get loading() {
    return DocphantModule.loading;
  }
  /**
   * gets machine from store
   */
  get machineInfo() {
    return DocphantModule.machine;
  }

  /**
   * requests machine document from api and sets it in store
   */
  async mounted() {
    //change favicon
    const el: any = document.querySelector("link[rel~='icon']");
    el.href = this.partner.settings.favIconUrl;

    const manufacturerId = this.$route.params.manufacturerId;
    const machineId = this.$route.params.machineId;
    try {
      await DocphantModule.getMachine({ manufacturerId: manufacturerId, machineId: machineId });
    } catch {
      this.$router.push({ path: `/manufacturer/${manufacturerId}` });
      Vue.$toast.error(
        "Es wurde keine Maschine mit diesen Daten gefunden. Bitte prüfen Sie die ID des Herstellers und der Maschine."
      );
    }
  }

  get partner() {
    return DocphantModule.ibLeimkuehler;
  }

  goHome() {
    this.$router.push({ path: "/" });
  }
}
