import { render, staticRenderFns } from "./PortalBankingDialog.vue?vue&type=template&id=817d7e8a&scoped=true&"
import script from "./PortalBankingDialog.vue?vue&type=script&lang=ts&"
export * from "./PortalBankingDialog.vue?vue&type=script&lang=ts&"
import style0 from "./PortalBankingDialog.vue?vue&type=style&index=0&id=817d7e8a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "817d7e8a",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCardActions,VCardText,VDialog,VForm,VIcon,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('817d7e8a')) {
      api.createRecord('817d7e8a', component.options)
    } else {
      api.reload('817d7e8a', component.options)
    }
    module.hot.accept("./PortalBankingDialog.vue?vue&type=template&id=817d7e8a&scoped=true&", function () {
      api.rerender('817d7e8a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/partner/PortalBankingDialog.vue"
export default component.exports