/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivExceptionViewmodelGen,
  MrfiktivOperationPartnerControllerFindAllByPartnerIdParamsGen,
  MrfiktivPageViewModelGen,
  MrfiktivPartnerOperationViewModelGen
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class PartnerOperation<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags partner-operation
   * @name OperationPartnerControllerFindAllByPartnerId
   * @summary Get operation by partner id
   * @request GET:/partner/{partnerId}/operation
   * @secure
   * @response `200` `((MrfiktivPageViewModelGen & { data?: (MrfiktivPartnerOperationViewModelGen)[] }) | MrfiktivPartnerOperationViewModelGen)`
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` No partner found.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  operationPartnerControllerFindAllByPartnerId = (
    { partnerId, ...query }: MrfiktivOperationPartnerControllerFindAllByPartnerIdParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<
      | (MrfiktivPageViewModelGen & { data?: MrfiktivPartnerOperationViewModelGen[] })
      | MrfiktivPartnerOperationViewModelGen,
      MrfiktivExceptionViewmodelGen
    >({
      path: `/partner/${partnerId}/operation`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags partner-operation
   * @name OperationPartnerControllerFindOneByPartnerId
   * @summary Get operation by id
   * @request GET:/partner/{partnerId}/operation/{id}
   * @secure
   * @response `200` `MrfiktivPartnerOperationViewModelGen` The found operations
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` No partner found.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  operationPartnerControllerFindOneByPartnerId = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivPartnerOperationViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/operation/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags partner-operation
   * @name OperationPartnerControllerRestartAnOperation
   * @summary Restart an operation
   * @request POST:/partner/{partnerId}/operation/{id}
   * @secure
   * @response `201` `MrfiktivPartnerOperationViewModelGen` The created operation
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` No partner found.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  operationPartnerControllerRestartAnOperation = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivPartnerOperationViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/operation/${id}`,
      method: "POST",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags partner-operation
   * @name OperationPartnerControllerCancelByPartnerId
   * @summary (OPERATION - UPDATE) Cancel operation for a partner.
   * @request PUT:/partner/{partnerId}/operation/{id}/cancel
   * @secure
   * @response `200` `MrfiktivPartnerOperationViewModelGen` The found operations
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` No partner found.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  operationPartnerControllerCancelByPartnerId = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivPartnerOperationViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/operation/${id}/cancel`,
      method: "PUT",
      secure: true,
      format: "json",
      ...params
    });
}
