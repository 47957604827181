var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("v-simple-table", [
    _c("thead", [
      _c(
        "tr",
        [
          _c(
            "th",
            {
              staticClass: "text-left"
            },
            [_vm._v(" " + _vm._s(_vm.$t("common.nouns.vehicle")) + " ")]
          ),
          _vm._l(_vm.years, function(year) {
            return _c(
              "th",
              {
                key: "th-".concat(year),
                staticClass: "text-left"
              },
              [_vm._v(" " + _vm._s(year) + " ")]
            )
          })
        ],
        2
      )
    ]),
    _vm.vehicleIdentifiers.length
      ? _c(
          "tbody",
          _vm._l(_vm.vehicleIdentifiers, function(vehicleIdentifier) {
            return _c(
              "tr",
              {
                key: "tr-".concat(vehicleIdentifier)
              },
              [
                _c("td", [_vm._v(_vm._s(vehicleIdentifier))]),
                _vm._l(_vm.years, function(year) {
                  return _c(
                    "td",
                    {
                      key: "td-".concat(year),
                      staticClass: "text-left"
                    },
                    [
                      _vm._l(_vm.getThgs(vehicleIdentifier, year), function(
                        thg
                      ) {
                        return _c(
                          "div",
                          {
                            key: "user-thg-".concat(thg.id)
                          },
                          [
                            _vm.thgStatusMap.get(thg.status)
                              ? _c(
                                  "tooltip",
                                  {
                                    attrs: {
                                      text: _vm.$t(
                                        _vm.thgStatusMap.get(thg.status)
                                          .portalText
                                      )
                                    }
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function click($event) {
                                            return _vm.goToThgDetail(thg)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              color: _vm.thgStatusMap.get(
                                                thg.status
                                              ).color
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.thgStatusMap.get(
                                                    thg.status
                                                  ).icon
                                                ) +
                                                " "
                                            )
                                          ]
                                        ),
                                        thg.payoutConfiguration
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "ml-1"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      thg.payoutConfiguration
                                                        .isFixed
                                                        ? ""
                                                        : "min."
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      thg.payoutConfiguration
                                                        .revenue
                                                    ) +
                                                    " " +
                                                    _vm._s("€") +
                                                    " "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      !_vm.getThgs(vehicleIdentifier, year).length
                        ? _c("div", [
                            year < _vm.currentYear
                              ? _c("div", [_vm._v(" ❌ ")])
                              : _vm.getThgsForVehicle(vehicleIdentifier).length
                              ? _c(
                                  "div",
                                  [
                                    _c("thg-admin-quick-checkout-dialog", {
                                      attrs: {
                                        thg: _vm.getThgsForVehicle(
                                          vehicleIdentifier
                                        )[0],
                                        selectedUser: _vm.selectedUser
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ])
                        : _vm._e()
                    ],
                    2
                  )
                })
              ],
              2
            )
          }),
          0
        )
      : _c("tbody", [
          _c("tr", [
            _c(
              "td",
              {
                attrs: {
                  colspan: _vm.years.length + 1
                }
              },
              [
                _c("latest-entries-card-empty", {
                  staticClass: "my-2"
                })
              ],
              1
            )
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }