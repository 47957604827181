/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivCreatePartnerLinkDtoGen,
  MrfiktivPageViewModelGen,
  MrfiktivPartnerLinkControllerGetParamsGen,
  MrfiktivPartnerLinkPopulatedPartnerViewModelGen,
  MrfiktivPartnerLinkViewModelGen,
  MrfiktivUpdatePartnerLinkDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class PartnerLinkerService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Partner Linker Service
   * @name PartnerLinkControllerCreate
   * @summary (Link - CREATE) Creates a link
   * @request POST:/partner/{partnerId}/link
   * @secure
   * @response `201` `MrfiktivPartnerLinkViewModelGen` The created link
   * @response `400` `void` Bad Request.
   * @response `409` `void` Conflict.
   */
  partnerLinkControllerCreate = (
    partnerId: string,
    data: MrfiktivCreatePartnerLinkDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivPartnerLinkViewModelGen, void>({
      path: `/partner/${partnerId}/link`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Partner Linker Service
   * @name PartnerLinkControllerGet
   * @summary (Link - READ) Get all links
   * @request GET:/partner/{partnerId}/link
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivPartnerLinkViewModelGen)[] })`
   */
  partnerLinkControllerGet = (
    { partnerId, ...query }: MrfiktivPartnerLinkControllerGetParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivPageViewModelGen & { data?: MrfiktivPartnerLinkViewModelGen[] }, any>({
      path: `/partner/${partnerId}/link`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Partner Linker Service
   * @name PartnerLinkControllerGetOne
   * @summary (Link - READ) Get a link
   * @request GET:/partner/{partnerId}/link/{id}
   * @secure
   * @response `200` `MrfiktivPartnerLinkPopulatedPartnerViewModelGen` The ticket
   */
  partnerLinkControllerGetOne = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivPartnerLinkPopulatedPartnerViewModelGen, any>({
      path: `/partner/${partnerId}/link/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Partner Linker Service
   * @name PartnerLinkControllerUpdate
   * @summary (Link - UPDATE) Updates a link
   * @request PATCH:/partner/{partnerId}/link/{id}
   * @secure
   * @response `201` `MrfiktivPartnerLinkPopulatedPartnerViewModelGen` The updated ticket
   */
  partnerLinkControllerUpdate = (
    partnerId: string,
    id: string,
    data: MrfiktivUpdatePartnerLinkDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivPartnerLinkPopulatedPartnerViewModelGen, any>({
      path: `/partner/${partnerId}/link/${id}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Partner Linker Service
   * @name PartnerLinkControllerDelete
   * @summary (Link - DELETE) Deletes a link
   * @request DELETE:/partner/{partnerId}/link/{id}
   * @secure
   * @response `200` `MrfiktivPartnerLinkPopulatedPartnerViewModelGen` The deleted link
   */
  partnerLinkControllerDelete = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivPartnerLinkPopulatedPartnerViewModelGen, any>({
      path: `/partner/${partnerId}/link/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
}
