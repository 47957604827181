
















































































































































import { autoCompleteCountryCodes } from "@/lib/CountryCodeHelper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { MrfiktivAddressWithGeoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { CreateAddressDto } from "@/store/models/mrfiktiv/address";
import { Component, Prop } from "vue-property-decorator";
import GeoFinderField from "@/components/utility/GeoFinderField.vue";

@Component({
  components: {
    GeoFinderField
  }
})
export default class AddressForm extends DarkModeHighlightMixin {
  @Prop()
  address!: MrfiktivAddressWithGeoGen;

  @Prop({ default: false })
  isLoading!: boolean;

  countryCodes = autoCompleteCountryCodes;

  disabled = true;

  valid = false;

  update: MrfiktivAddressWithGeoGen = new CreateAddressDto(this.address);

  initialize() {
    this.update = new CreateAddressDto(this.address);
  }

  mounted() {
    this.initialize();
  }

  save(): MrfiktivAddressWithGeoGen {
    this.$emit("save", this.update);
    this.disabled = true;

    return this.update;
  }

  abort() {
    this.initialize();
    this.disabled = true;
  }
}
