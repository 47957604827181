var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-dialog",
    {
      attrs: {
        width: "800px"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function fn(_ref) {
            var on = _ref.on,
              attrs = _ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "mx-4",
                      attrs: {
                        color: "error",
                        outlined: "",
                        small: ""
                      }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "components.partner.UserAdministrationResendInvitationDialog.submit"
                      )
                    )
                  )
                ]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function callback($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "title pt-4",
              class: _vm.color
            },
            [
              _c(
                "h4",
                {
                  staticClass: "text-truncate"
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "components.partner.UserAdministrationResendInvitationDialog.title"
                      )
                    )
                  )
                ]
              )
            ]
          ),
          _c("v-divider"),
          _c("v-card-text", [
            _c(
              "p",
              {
                staticClass: "mt-4"
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "components.partner.UserAdministrationResendInvitationDialog.useCase"
                      )
                    ) +
                    " "
                )
              ]
            )
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    text: ""
                  },
                  on: {
                    click: _vm.close
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "components.partner.UserAdministrationResendInvitationDialog.cancel"
                        )
                      ) +
                      " "
                  )
                ]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: _vm.isDisabled,
                    loading: _vm.loading
                  },
                  on: {
                    click: _vm.resendInvitation
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "components.partner.UserAdministrationResendInvitationDialog.submit"
                        )
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }