

























import { requiredRule } from "@/lib/rules/requiredRule";
import { handleError } from "@/lib/utility/handleError";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgCreateFaqDtoGen } from "@/services/thg/v1/data-contracts";
import { FaqModule } from "@/store/modules/faq.store";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import Tooltip from "../utility/tooltip.vue";

@Component({
  components: { Tooltip, ConfirmActionDialog }
})
export default class PartnerFaqCreateDialog extends mixins(DarkModeHighlightMixin) {
  createDto: ThgCreateFaqDtoGen = {
    question: "",
    answer: "",
    isActive: false
  };

  isDialogActive = false;
  isLoading = false;
  isValid = false;

  initialize() {
    this.createDto = {
      question: "",
      answer: "",
      isActive: false
    };
    this.isDialogActive = true;
  }
  get required() {
    return [requiredRule()];
  }
  get partnerId() {
    return this.$route.params.partnerId;
  }
  async create() {
    try {
      this.isLoading = true;
      const faq = await FaqModule.create({ partnerId: this.partnerId, data: this.createDto });
      this.$emit("create", faq);
    } catch (e) {
      handleError(e);
    } finally {
      this.isDialogActive = false;
      this.isLoading = false;
    }
  }
}
