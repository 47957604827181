import { CompanyAddressTypeEnum } from "@/lib/enum/company-address-type.enum";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { DetailFormComponentsEnum } from "@/lib/enum/detail-form-components.enum";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { Form, FormConfig, IsFormable } from "@/lib/formable";
import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";
import { $t } from "@/lib/utility/t";
import { MrfiktivCompanyAddressViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";

@IsFormable
@IsFilterable
class CompanyAddressBase implements Partial<MrfiktivCompanyAddressViewModelGen> {
  @FormConfig({
    category: "common.nouns.address",
    searchKeywords: ["objects.company.addresses.type", "company.company"],
    type: DetailFormComponentsEnum.SELECT_FIELD,
    props: {
      items: Object.values(CompanyAddressTypeEnum).map(v => {
        return {
          text: $t(`enums.CompanyAddressTypeEnum.${v}`),
          value: v
        } as IVSelectItem;
      }),
      rules: [],
      multiple: true,
      itemValue: "value",
      label: "objects.company.addresses.type"
    },
    clearable: true
  })
  @FilterConfig({
    displayName: "objects.company.addresses.type",
    type: FilterTypes.ENUM,
    config: {
      items: Object.values(CompanyAddressTypeEnum).map(v => {
        return {
          text: `enums.CompanyAddressTypeEnum.${v}`,
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value"
    }
  })
  type: CompanyAddressTypeEnum[] = [];

  geo: {
    lat: number;
    lng: number;
  };

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.address",
    searchKeywords: ["objects.address.street", "common.nouns.address"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.address.street"
    },
    clearable: true
  })
  @FilterConfig({
    displayName: "objects.address.street",
    type: FilterTypes.STRING
  })
  street?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.address",
    searchKeywords: ["objects.address.zip", "common.nouns.address"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.address.zip"
    },
    clearable: true
  })
  @FilterConfig({
    displayName: "objects.address.zip",
    type: FilterTypes.STRING
  })
  zip?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.address",
    searchKeywords: ["objects.address.city", "common.nouns.address"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.address.city"
    },
    clearable: true
  })
  @FilterConfig({
    displayName: "objects.address.city",
    type: FilterTypes.STRING
  })
  city?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.address",
    searchKeywords: ["objects.address.state", "common.nouns.address"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.address.state"
    },
    clearable: true
  })
  @FilterConfig({
    displayName: "objects.address.state",
    type: FilterTypes.STRING
  })
  state?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.address",
    searchKeywords: ["objects.address.countryCode", "common.nouns.address"],
    type: DetailFormComponentsEnum.AUTO_COMPLETE,
    props: {
      required: false,
      label: "objects.address.countryCode",
      items: Object.values(CountryCodeEnum).map(v => {
        return {
          text: v,
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value"
    },
    clearable: true
  })
  @FilterConfig({
    displayName: "objects.address.countryCode",
    type: FilterTypes.ENUM,
    config: {
      items: Object.values(CountryCodeEnum).map(v => {
        return {
          text: v,
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value"
    }
  })
  countryCode?: string;

  constructor(address?: Partial<MrfiktivCompanyAddressViewModelGen>) {
    this.street = address?.street;
    this.zip = address?.zip;
    this.city = address?.city;
    this.state = address?.state;
    this.countryCode = address?.countryCode;

    this.geo = address?.geo ?? { lat: 0, lng: 0 };

    this.type = (address?.type as CompanyAddressTypeEnum[]) ?? [];
  }
}
type ICompanyAddress = CompanyAddressBase;
const CompanyAddress = Form.createForClass(Filter.createForClass(CompanyAddressBase));

export { CompanyAddress, ICompanyAddress };
