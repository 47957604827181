

































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { OperationModule } from "@/store/modules/operation.store";
import { Component, Prop } from "vue-property-decorator";
import PaginatedTable from "../utility/v2/PaginatedTable.vue";
import OperationSideCard from "@/components/operations/OperationSideCard.vue";
import { IOperation } from "@/models/operation.entity";
import { PredefinedFilterType } from "@/views/event/filter/event.filter";
import { $t } from "@/lib/utility/t";
import { OperationStatusEnum } from "@/lib/enum/OperationStatus.enum";
import Btn from "@/components/utility/BouncyButton.vue";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { PageFilterElement } from "@/models/page-filter-element.entity";

@Component({
  components: {
    PaginatedTable,
    OperationSideCard,
    Btn
  }
})
export default class OperationTable extends DarkModeHighlightMixin {
  @Prop({ default: () => ({}) })
  baseQuery!: Record<string, string>;

  @Prop({ default: false })
  hideTitle!: boolean;

  @Prop({ default: false })
  hideTypeHeader!: boolean;

  selectedOperation: IOperation | null = null;

  get pagination() {
    return OperationModule;
  }

  get headers() {
    const headers = [];

    headers.push({ text: $t("components.operation.OperationTable.started"), value: "timestamp.created" });
    headers.push({ text: $t("objects.operation.type"), value: "type" });
    headers.push({ text: $t("components.operation.OperationTable.id"), value: "id" });
    headers.push({ text: $t("components.operation.OperationTable.status"), value: "status" });

    return headers;
  }

  get predefinedFilter(): PredefinedFilterType[] {
    const predefinedFilter: PredefinedFilterType[] = [];

    predefinedFilter.push({
      name: $t("failed"),
      filter: [
        new PageFilterElement({
          key: "status",
          operation: PageFilterOperationEnum.EQUAL,
          value: OperationStatusEnum.FAILED
        })
      ]
    });

    return predefinedFilter;
  }

  openOperationSideCard(operation: IOperation) {
    this.selectedOperation = null;
    this.$nextTick(() => {
      this.selectedOperation = operation;
    });
  }

  goToOperationDetail(operationId: string, partnerId?: string) {
    if (partnerId) {
      this.$router.push({ name: "OperationPartnerView", params: { operationId: operationId, partnerId: partnerId } });
    } else {
      this.$router.push({ name: "OperationPartnerView", params: { operationId: operationId } });
    }
  }
}
