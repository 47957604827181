


























import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";
import PartnerDetailAttributeWithToolTip from "@/components/partner/PartnerDetailAttributeWithToolTip.vue";
import { WeekDaysEnum } from "@/lib/enum/weekDays.enum";

@Component({
  components: {
    PartnerDetailAttributeWithToolTip
  }
})
export default class PartnerDetailSettingsOpeningHoursCard extends Vue {
  virtualScrollerHeight = 0;
  get partner() {
    return PartnerModule.partner;
  }

  get partners() {
    return PartnerModule.partners;
  }

  get WeekDays() {
    return WeekDaysEnum;
  }
}
