




















import { ConfigModule } from "@/store/modules/config";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IMachineViewmodel } from "@/lib/interfaces/Docphant/machine.viewmodel.interface";
import { IManufacturer } from "@/lib/interfaces/Docphant/manufacturer.schema.interface";
import QrcodeVue from "qrcode.vue";

@Component({
  components: { QrcodeVue }
})
export default class MachineQrCodeCard extends Vue {
  @Prop({})
  manufacturer!: IManufacturer;

  @Prop({})
  machine!: IMachineViewmodel;

  mounted() {
    this.updateBaseUrl();
  }

  selectedBaseUrl = ConfigModule.docphantUrls[0];
  urlToSelectedMachineForQrCOde = "";
  baseUrlList = ConfigModule.docphantUrls;

  updateBaseUrl() {
    this.urlToSelectedMachineForQrCOde = `${this.selectedBaseUrl}/manufacturer/${this.manufacturer.id}/machine/${this.machine.id}`;
  }
}
