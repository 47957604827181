

































import CustomerContactCard from "@/components/partner/CustomerContactCard.vue";
import PortalBankingDialog from "@/components/partner/PortalBankingDialog.vue";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgBankingControllerNewBankingParamsGen, ThgBankingDtoGen } from "@/services/thg/v1/data-contracts";
import { BankingModule } from "@/store/modules/banking.store";
import { Component, Prop } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import Card from "../utility/Card.vue";

@Component({
  components: {
    CustomerContactCard,
    PortalBankingDialog,
    LatestEntriesCardEmpty,
    Card
  }
})
export default class PortalDetailBanking extends DarkModeHighlightMixin {
  @Prop()
  userId?: string;

  @Prop()
  partnerId?: string;

  bankingLoading = true;

  get banking() {
    return BankingModule.banking;
  }

  async mounted() {
    await this.loadBanking();
  }

  setBankingLoading(loading: boolean) {
    this.bankingLoading = loading;

    /**
     * We emit the status of the banking to let the parent know if a backend call is done
     * this is used to fix a bug where wrong banking data was shown in the user detail view after switching the selected document quickly in the list
     * The fix is, that we pass the inforamtion on the loading status up to the list view and only allow the selected user to be switched, after all data was loaded
     * (@see UserAdministration get _loadingSelectedUser())
     */
    this.$emit("bankingLoading", loading);
  }

  async updateBanking(bankingDto: ThgBankingDtoGen) {
    try {
      this.setBankingLoading(true);
      const query: ThgBankingControllerNewBankingParamsGen = {
        userId: this.userId,
        partnerId: this.partnerId
      };

      await BankingModule.createBanking({ query: query, dto: bankingDto });
      await this.loadBanking();
      this.$toast.success(this.$t("components.partner.UserAdministrationDetailBanking.success"));
    } catch (e) {
      this.$log.error(e);
      this.$toast.error((e as any).message);
    } finally {
      this.setBankingLoading(false);
    }
  }

  async loadBanking() {
    try {
      this.setBankingLoading(true);
      await BankingModule.getBanking({ userId: this.userId, partnerId: this.partnerId });
    } catch (e) {
      this.$log.error(e);
    } finally {
      this.setBankingLoading(false);
    }
  }
}
