import { render, staticRenderFns } from "./MachineAttachmentCreateDialog.vue?vue&type=template&id=5fd82c2b&scoped=true&"
import script from "./MachineAttachmentCreateDialog.vue?vue&type=script&lang=ts&"
export * from "./MachineAttachmentCreateDialog.vue?vue&type=script&lang=ts&"
import style0 from "./MachineAttachmentCreateDialog.vue?vue&type=style&index=0&id=5fd82c2b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fd82c2b",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VAutocomplete,VBtn,VCard,VCardActions,VCardText,VCardTitle,VDialog,VFileInput,VForm,VSpacer,VTextField,VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5fd82c2b')) {
      api.createRecord('5fd82c2b', component.options)
    } else {
      api.reload('5fd82c2b', component.options)
    }
    module.hot.accept("./MachineAttachmentCreateDialog.vue?vue&type=template&id=5fd82c2b&scoped=true&", function () {
      api.rerender('5fd82c2b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/docphant/dashboard/MachineAttachmentCreateDialog.vue"
export default component.exports