var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _vm.listMode && !_vm.hideEditButton
        ? _c(
            "div",
            {
              staticStyle: {
                display: "flex"
              }
            },
            [
              _c("v-spacer"),
              !_vm.isEdit
                ? _c("v-btn", {
                    attrs: {
                      "x-small": "",
                      text: "",
                      elevation: 0
                    },
                    domProps: {
                      textContent: _vm._s(_vm.$t("refs.addReference"))
                    },
                    on: {
                      click: _vm.clickEdit
                    }
                  })
                : _c("v-btn", {
                    attrs: {
                      "x-small": "",
                      text: "",
                      elevation: 0
                    },
                    domProps: {
                      textContent: _vm._s(_vm.$t("confirm"))
                    },
                    on: {
                      click: _vm.clickEdit
                    }
                  })
            ],
            1
          )
        : !_vm.listMode
        ? _c(
            "div",
            {
              staticStyle: {
                display: "flex"
              }
            },
            [
              _c("v-spacer"),
              _c(
                "v-menu",
                {
                  attrs: {
                    bottom: "",
                    "close-on-content-click": false
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function fn(_ref) {
                        var on = _ref.on,
                          attrs = _ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    "x-small": "",
                                    text: "",
                                    elevation: 0
                                  },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("refs.addReference")
                                    )
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            )
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "max-width": "480px"
                      }
                    },
                    [
                      _c("refs-selection", {
                        attrs: {
                          loading:
                            _vm.isLoadingAll || _vm.isLoading || _vm.loading,
                          suggested: _vm.suggested,
                          partnerId: _vm.partnerId,
                          categories: _vm.categories,
                          addToTop: true,
                          hideSuggestions: _vm.hideSuggestions
                        },
                        on: {
                          added: _vm.emitChange
                        },
                        model: {
                          value: _vm.selected,
                          callback: function callback($$v) {
                            _vm.selected = $$v
                          },
                          expression: "selected"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._t("default"),
      _c("refs-selected", {
        attrs: {
          suggested: _vm.suggested,
          partnerId: _vm.partnerId,
          confirmable: _vm.isConfirmable
        },
        on: {
          removed: _vm.emitChange,
          openDetail: _vm.openDetail
        },
        model: {
          value: _vm.selected,
          callback: function callback($$v) {
            _vm.selected = $$v
          },
          expression: "selected"
        }
      }),
      _c("div", {
        attrs: {
          id: _vm.SCROLL_MARKER_ID
        }
      }),
      _vm.listMode && _vm.isEdit
        ? _c("refs-selection", {
            attrs: {
              loading: _vm.isLoadingAll || _vm.isLoading || _vm.loading,
              suggested: _vm.suggested,
              partnerId: _vm.partnerId,
              categories: _vm.categories,
              addToTop: false,
              hideSuggestions: _vm.hideSuggestions,
              outlined: false
            },
            on: {
              added: function added() {
                _vm.scrollToEnd()

                _vm.emitChange()
              }
            },
            model: {
              value: _vm.selected,
              callback: function callback($$v) {
                _vm.selected = $$v
              },
              expression: "selected"
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }