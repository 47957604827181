var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "card",
    {
      attrs: {
        "min-width": "250px",
        flat: "",
        title: "Rollen"
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function fn() {
            return [
              _vm.disabled || _vm.isLoading
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        loading: _vm.isLoading,
                        icon: ""
                      },
                      on: {
                        click: function click($event) {
                          return _vm.edit()
                        }
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            color: "grey lighten-1"
                          }
                        },
                        [_vm._v("mdi-pencil")]
                      )
                    ],
                    1
                  )
                : _c(
                    "span",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            icon: ""
                          },
                          on: {
                            click: function click($event) {
                              return _vm.close()
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                color: "grey lighten-1"
                              }
                            },
                            [_vm._v("mdi-close")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            icon: ""
                          },
                          on: {
                            click: function click($event) {
                              return _vm.save()
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                color: "grey lighten-1"
                              }
                            },
                            [_vm._v("mdi-content-save")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.disabled
        ? _c(
            "v-card-text",
            [
              _c("v-select", {
                attrs: {
                  items: _vm.authRoles,
                  multiple: "",
                  chips: "",
                  disabled: _vm.disabled
                },
                model: {
                  value: _vm.roles,
                  callback: function callback($$v) {
                    _vm.roles = $$v
                  },
                  expression: "roles"
                }
              })
            ],
            1
          )
        : _c(
            "v-card-text",
            [
              _c("v-select", {
                attrs: {
                  items: _vm.authRoles,
                  multiple: "",
                  chips: ""
                },
                model: {
                  value: _vm.editRoles,
                  callback: function callback($$v) {
                    _vm.editRoles = $$v
                  },
                  expression: "editRoles"
                }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }