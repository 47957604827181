var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("m-header", {
        attrs: {
          title: _vm.operation.type,
          actions: _vm.actions,
          chips: _vm.chips,
          breadCrumbs: _vm.breadCrumbs
        },
        on: {
          actionClicked: _vm.processAction
        }
      }),
      _c(
        "v-card-actions",
        [
          _vm.operationLoading
            ? _c("v-skeleton-loader", {
                staticClass: "mx-auto",
                attrs: {
                  type:
                    "list-item-two-line,list-item-two-line,list-item-two-line"
                }
              })
            : _vm.operation && !_vm.loadingFailed
            ? _c(
                "div",
                {
                  staticStyle: {
                    width: "100%"
                  }
                },
                [
                  _c("debug", [
                    _vm._v(
                      " operation.refs " + _vm._s(_vm.operation.refs) + " "
                    ),
                    _c("br")
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "mt-1",
                      staticStyle: {
                        width: "100%"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "mx-1",
                          staticStyle: {
                            display: "flex"
                          }
                        },
                        [_c("small", [_vm._v(" " + _vm._s(_vm.subtitle))])]
                      ),
                      _c("pre", {
                        staticClass: "mx-2",
                        staticStyle: {
                          "min-height": "100px"
                        },
                        domProps: {
                          innerHTML: _vm._s(_vm.operation.message)
                        }
                      })
                    ]
                  ),
                  _vm.operation.error &&
                  Object.keys(_vm.operation.error).length !== 0
                    ? _c(
                        "span",
                        {
                          staticClass: "mt-1"
                        },
                        [
                          _c("small", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "components.thg.thgBillingOperation.error"
                                  )
                                ) +
                                " "
                            )
                          ]),
                          _c("pre", [
                            _vm._v(
                              "          " +
                                _vm._s(_vm.operation.error) +
                                "\n        "
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm.operation.body
                    ? _c("span", [
                        _c("small", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "components.thg.thgBillingOperation.body"
                                )
                              ) +
                              " "
                          )
                        ]),
                        _c("pre", [
                          _vm._v(
                            "          " +
                              _vm._s(_vm.operation.body) +
                              "\n        "
                          )
                        ])
                      ])
                    : _vm._e()
                ],
                1
              )
            : _c(
                "div",
                [
                  _c("latest-entries-card-empty", {
                    staticClass: "mt-5 mb-5"
                  })
                ],
                1
              )
        ],
        1
      ),
      _c("confirm-action-dialog", {
        attrs: {
          isDialogActive: _vm.isCancelDialogActive,
          title: _vm.$t(
            "components.operation.OperationDetail.cancellationTitle"
          ),
          subtitle: _vm.$t(
            "components.operation.OperationDetail.cancellationSubtitle"
          ),
          rightLoading: _vm.isCancellationLoading
        },
        on: {
          "update:isDialogActive": function updateIsDialogActive($event) {
            _vm.isCancelDialogActive = $event
          },
          "update:is-dialog-active": function updateIsDialogActive($event) {
            _vm.isCancelDialogActive = $event
          },
          leftClick: function leftClick($event) {
            _vm.isCancelDialogActive = false
          },
          rightClick: _vm.cancel
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }