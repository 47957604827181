




































































































































































import FormHelpTimeline from "@/components/form/FormHelpTimeline.vue";
import PartnerFooter from "@/components/partner/PartnerFooter.vue";
import LayoutLogo from "@/layouts/LayoutLogo.vue";
import { emailRule } from "@/lib/rules/contactRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { Type } from "@/lib/utility/type";
import { ConfigModule } from "@/store/modules/config";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";
import { VSkeletonLoader } from "vuetify/lib";
import { ContentTooLargeException, UnsupportedMediaTypeException } from "@/lib/exceptions/http";

const PartnerMap = () => ({
  component: import(/* webpackPrefetch: true  */ /* webpackChunkName: "map" */ "@/components/form/PartnerMap.vue"),
  loading: VSkeletonLoader
});

@Component({
  components: {
    LayoutLogo,
    FormHelpTimeline,
    PartnerFooter,
    PartnerMap
  }
})
export default class PartnerContact extends Vue {
  valid = false;
  loading = false;
  dialog = true;
  uploadedImages: any = [];
  defaultLogoUrl = "../../assets/smart-repair-logo.png";
  defaultHeaderImageUrl = "../../assets/smart-repair-logo.png";
  CARD_TITLE_DEFAULT = "Smart Repair";
  CARD_SUBTITLE_DEFAULT = "Einfach. Schnell. Günstig. Schadensbeseitigung vom Profi.";
  CARD_TEXT_DEFAULT =
    "Egal ob Kratzer-, Dellen- oder Beulen-Beseitigung - mit Smart-Repair können Sie Schäden bis zu einer Größe von 2 cm kostengünstig bei uns beheben lassen.";

  get privacyPolicyLink() {
    return ConfigModule.privacyPolicyLink;
  }

  async mounted() {
    document.title = "Angebot anfordern";

    if (!Type.isPresent(this.partner.companyName) || Type.isBlankString(this.partner.companyName)) {
      this.$router.push({ name: "PartnerContactError" });
    } else {
      document.title = this.partner.companyName + " - Angebot anfordern";
    }

    const favIcon: any = document.querySelector("link[rel~='icon']");
    if (this.partner.settings?.favIconUrl) {
      favIcon.href = this.partner.settings?.favIconUrl;
    } else {
      favIcon.href = this.partner.settings?.websites[0].link + "favicon.ico";
    }

    this.partnerContact.images = [];
  }

  async validate() {
    this.valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();

    if (this.valid) {
      this.loading = !this.loading;
      try {
        await PartnerModule.sendContact();
        Vue.$toast.success("Deine Anfrage wurde versendet.");

        if (process.env.NODE_ENV === "production") {
          this.$gtag.event("purchase", {
            currency: "EUR",
            description: "Form",
            transaction_id: PartnerModule.transactionId || "666",
            value: "1",
            items: [
              {
                item_id: "0",
                item_name: PartnerModule.partner.companyUsername
              }
            ]
          });
        }

        this.$router.push({ name: "PartnerContactSuccess" });
      } catch (error) {
        if (error instanceof UnsupportedMediaTypeException) {
          Vue.$toast.error("Bitte ausschließlich Bilder anhängen.");
        } else if (error instanceof ContentTooLargeException) {
          Vue.$toast.error("Bilder zu groß (10 MB maximal).");
        } else {
          Vue.$toast.error("Deine Anfrage konnte nicht versendet werden.");
        }
      } finally {
        this.loading = !this.loading;
      }
    }
  }

  get partnerContact() {
    return PartnerModule.partnerContact;
  }

  get partner() {
    return PartnerModule.partner;
  }

  changeFahrzeugschein(file: any) {
    this.partnerContact.registration = file;
  }

  changeSchadensbild() {
    this.partnerContact.images = Array.from([...new Set([...this.partnerContact.images, ...this.uploadedImages])]);

    this.uploadedImages = this.partnerContact.images;
  }

  remove(index: number) {
    this.uploadedImages.splice(index, 1);

    this.partnerContact.images = this.uploadedImages;
  }

  get emailRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(emailRule());

    return rules;
  }
}
