










import { Component, Vue } from "vue-property-decorator";
import LayoutSimple from "@/layouts/LayoutSimple.vue";

@Component({
  components: {
    LayoutSimple
  }
})
export default class PartnerContactSuccess extends Vue {}
