var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          attrs: {
            flat: "",
            "max-width": "4000"
          }
        },
        [
          _c(
            "v-card-title",
            {
              staticClass: "title pt-4 mb-n7"
            },
            [_c("h4", [_vm._v("Gebuchte Services")])]
          ),
          _c(
            "v-card-text",
            {
              staticClass: "mt-8"
            },
            [
              _c("PartnerDetailAttributeWithToolTip", {
                attrs: {
                  tooltip: "Fahrzeugscheinscanner gebucht.",
                  displayName: _vm.registrationService,
                  icon: "mdi-card-account-details-outline"
                }
              }),
              _c("PartnerDetailAttributeWithToolTip", {
                attrs: {
                  tooltip: "Landingpage gebucht.",
                  displayName: _vm.landingpageService,
                  icon: "mdi-form-select"
                }
              }),
              _c("PartnerDetailAttributeWithToolTip", {
                attrs: {
                  tooltip: "Report gebucht.",
                  displayName: _vm.reportService,
                  icon: "mdi-car-wrench"
                }
              }),
              _c("PartnerDetailAttributeWithToolTip", {
                attrs: {
                  tooltip: "Payment gebucht.",
                  displayName: _vm.paymentService,
                  icon: "mdi-currency-eur"
                }
              }),
              _c("PartnerDetailAttributeWithToolTip", {
                attrs: {
                  tooltip: "Datenweitergabe",
                  displayName: _vm.isDataSharingActive,
                  icon: "mdi-database"
                }
              })
            ],
            1
          ),
          _c(
            "v-card-title",
            {
              staticClass: "title pt-4 mb-n7"
            },
            [_c("h4", [_vm._v("Email Einstellungen")])]
          ),
          _c(
            "v-card-text",
            {
              staticClass: "mt-8"
            },
            [
              _c("PartnerDetailAttributeWithToolTip", {
                attrs: {
                  tooltip: "Ergebnisse des Fahrzeugscheinscanners zeigen",
                  displayName: _vm.registrationShowResults,
                  icon: "mdi-card-account-details-outline"
                }
              }),
              _c("PartnerDetailAttributeWithToolTip", {
                attrs: {
                  tooltip: "KSR Export Button in Email anzeigen.",
                  displayName: _vm.registrationShowKsrButton,
                  icon: "mdi-alpha-k"
                }
              })
            ],
            1
          ),
          _c(
            "v-card-title",
            {
              staticClass: "title pt-4 mb-n7"
            },
            [_c("h4", [_vm._v("Reservierte Domains")])]
          ),
          _c(
            "v-card-text",
            {
              staticClass: "mt-8"
            },
            [
              _c("PartnerDetailAttributeWithToolTip", {
                attrs: {
                  tooltip: "URLs",
                  displayName: _vm.urls,
                  icon: "mdi-card-account-details-outline"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }