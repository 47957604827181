
































































































































import { requiredRule } from "@/lib/rules/requiredRule";
import { BillingInformationCreateDto } from "@/lib/utility/billingInformationCreateDto";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgBillingInformationViewmodelGen, ThgCreateBillingInformationDtoGen } from "@/services/thg/v1/data-contracts";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class PartnerBillingInformationDataDialog extends DarkModeHighlightMixin {
  dialog = false;
  valid = false;

  @Prop()
  billingInformationDocument?: ThgBillingInformationViewmodelGen;

  billingInformationDto: ThgCreateBillingInformationDtoGen = new BillingInformationCreateDto();

  get requiredRules() {
    return [requiredRule()];
  }

  editLoading = false;

  openDialog() {
    this.billingInformationDto = new BillingInformationCreateDto(this.billingInformationDocument);
    this.dialog = true;
  }

  close() {
    this.dialog = false;
  }

  async edit() {
    try {
      this.editLoading = true;
      if (!this.billingInformationDto.isCompany) {
        this.billingInformationDto.company = "";
      }

      if (!this.billingInformationDto.isTaxDeductible) {
        this.billingInformationDto.taxnumber = "";
      }

      this.$emit("submit", this.billingInformationDto);
    } catch (e) {
      this.$log.error(e);
      this.$toast.error((e as any).message);
    } finally {
      this.editLoading = false;
      this.dialog = false;
    }
  }
}
