



































import PartnerCreateFromTemplateDialog from "@/components/partner/PartnerCreateFromTemplateDialog.vue";
import PartnerCreateOrUpdateGeneralInfos from "@/components/partner/PartnerCreateOrUpdateGeneralInfos.vue";
import PartnerCreateOrUpdateLandingpageSettigns from "@/components/partner/PartnerCreateOrUpdateLandingpageSettigns.vue";
import PartnerCreateOrUpdateLicense from "@/components/partner/PartnerCreateOrUpdateLicense.vue";
import PartnerCreateOrUpdateReportSettings from "@/components/partner/PartnerCreateOrUpdateReportSettings.vue";
import PartnerCreateOrUpdateSubmitCard from "@/components/partner/PartnerCreateOrUpdateSubmitCard.vue";
import PartnerDetailImage from "@/components/partner/PartnerDetailImage.vue";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { PartnerEntity } from "@/models/partnerEntity";
import { CreatePartnerDto } from "@/store/models/createPartnerDto";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    PartnerDetailImage,
    PartnerCreateOrUpdateGeneralInfos,
    PartnerCreateOrUpdateLandingpageSettigns,
    PartnerCreateOrUpdateReportSettings,
    PartnerCreateOrUpdateLicense,
    PartnerCreateOrUpdateSubmitCard,
    PartnerCreateFromTemplateDialog
  }
})
export default class PartnerCreateDialog extends DarkModeHighlightMixin {
  dialog = false;
  isValid = false;

  partnerDto = new CreatePartnerDto();

  initialize() {
    this.partnerDto = new CreatePartnerDto();
  }

  closeDialog() {
    this.dialog = false;
  }

  setTemplate(template: PartnerEntity) {
    this.partnerDto = new CreatePartnerDto(template);
  }

  validate() {
    (this.$refs.form as Vue & { validate: () => boolean }).validate();
  }
}
