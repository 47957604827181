
















































































import PartnerBatchUpdateDialog from "@/components/partner/PartnerBatchUpdateDialog.vue";
import PartnerCreateDialog from "@/components/partner/PartnerCreateDialog.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { getFlagEmoji } from "@/lib/CountryCodeHelper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { PartnerEntity } from "@/models/partnerEntity";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { UpdatePartnerDto } from "@/store/models/createPartnerDto";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Watch } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortal,
    PartnerCreateDialog,
    PartnerBatchUpdateDialog
  },
  filters: {
    getFlagEmoji
  }
})
export default class PartnerTable extends DarkModeHighlightMixin {
  isLoadingAllPartner = false;
  isLoading = false;

  search = "";

  selected: PartnerEntity[] = [];

  itemsPerPage = 25;

  snack = false;
  snackColor = "";
  snackText = "";

  expanded = [];

  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;

  headers = [
    { text: "Id", align: "start", value: "_id", width: 200 },
    {
      text: "Land",
      value: "countryCode",
      width: 100,
      align: "center"
    },
    {
      text: "Company Name",
      value: "companyName",
      width: "33%"
    },
    {
      text: "Card Title",
      align: "start",
      value: "settings.cardTitle",
      width: "33%"
    },
    {
      text: "Card Subtitle",
      align: "start",
      value: "settings.cardSubtitle",
      width: "33%"
    }
  ];

  async mounted() {
    await this.getPartners();
  }

  async getPartners() {
    this.isLoadingAllPartner = true;
    try {
      await PartnerModule.getPartners();
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.isLoadingAllPartner = false;
    }
  }

  get partners(): PartnerEntity[] {
    return PartnerModule.partners;
  }

  async update(updatedPartner: PartnerEntity) {
    const dto = new UpdatePartnerDto(updatedPartner);
    this.isLoading = true;

    try {
      const updatePartner = await PartnerModule.updatePartner({
        id: updatedPartner._id,
        partnerUpdateEntity: dto
      });

      if (!updatePartner) {
        this.$toast.error("Fehler beim Ändern des Partners");
      } else {
        this.$toast.success("Partner erfolgreich geändert.");
      }
    } catch (error) {
      this.$log.error(error);
      this.$toast.error("Fehler beim Ändern des Partners");
    } finally {
      this.isLoading = false;
    }
  }

  cancel() {
    this.snack = true;
    this.snackColor = "info";
    this.snackText = "Abgebrochen";
  }

  open() {
    this.snack = true;
    this.snackColor = "info";
    this.snackText = "Ändern";
  }

  close() {
    this.$log.info("Dialog closed");
  }

  @Watch("selected")
  watchSelected() {
    this.$log.info(this.selected);
  }

  async selectFailed(partners: PartnerEntity[]) {
    await this.getPartners();
    this.selected = partners;
  }
}
