import { VehicleTypes } from "@/store/enum/vehicle-types.enum";
import { TransmissionTypeEnum } from "../enum/transmission.enum";
import { CountryCodeEnum } from "../enum/country-code.enum";
import Vue from "vue";

/**
 * Transform excel date to javascript date
 *
 * @param serial the excel time format
 * @param time should the time be parsed. Defaults to `false`. On `true` returns the parsed serial including time as local date.
 * @returns date. On `time` = `false` returns the utc date day. On `time` = `false` returns a local date object (not utc).
 */
export function excelDateToJSDate(serial: number, time = false): Date | undefined {
  if (!serial) {
    return undefined;
  }

  const utc_days = Math.floor(serial - 25569);
  const utc_ms = utc_days * 86400 * 1000;

  Vue.$log.debug(serial, utc_ms, new Date(utc_ms).toISOString());

  const date_info = new Date(utc_ms);

  if (!time) {
    return date_info;
  }

  const fractional_day = serial - Math.floor(serial) + 0.0000001;

  let total_seconds = Math.floor(86400 * fractional_day);

  const seconds = total_seconds % 60;

  total_seconds -= seconds;

  const hours = Math.floor(total_seconds / (60 * 60));
  const minutes = Math.floor(total_seconds / 60) % 60;

  return new Date(date_info.getUTCFullYear(), date_info.getUTCMonth(), date_info.getUTCDate(), hours, minutes, seconds);
}

/**
 * Transform excel date to javascript iso date string
 *
 * @param serial
 * @returns iso date string
 */
export function numberValidation(value: number | string | undefined): number | undefined {
  if (!value) {
    return undefined;
  }

  const transformedValue = +value;

  if (isNaN(transformedValue)) {
    throw new Error("Not a numeric value");
  }
  return transformedValue;
}

/**
 * Boolean validation
 *
 * @param serial
 * @returns iso date string
 */
export function booleanValidation(value: number | string | boolean | undefined): boolean | undefined {
  if (value === false || value === true) {
    return value;
  }

  if (!value) {
    return undefined;
  }

  throw new Error("No boolean");
}

/**
 * Contry code Validation
 */
export function countrycodeValidation(value: number | string | undefined): string | undefined {
  return baseEnumValidation(value, CountryCodeEnum, "Not valid countrycode");
}

/**
 * Valid vehciletypes
 */
export function transmissionValidation(value: number | string | undefined): string | undefined {
  return baseEnumValidation(value, TransmissionTypeEnum, "Not valid transmissiontype");
}

/**
 * Valid vehciletypes
 */
export function vehicleTypeValidation(value: number | string | undefined): string | undefined {
  return baseEnumValidation(value, VehicleTypes, "Not valid vehicleType");
}

/**
 *
 * @param value
 * @param enumObject
 * @param message
 * @returns
 */
export function baseEnumValidation(value: number | string | undefined, enumObject: any, message = "Validationerror") {
  if (!value) {
    return undefined;
  }
  let isValid = false;

  Object.values(enumObject).forEach(enumValue => {
    if (value == enumValue) {
      isValid = true;
    }
  });

  if (!isValid) {
    throw new Error(message);
  }

  return value.toString();
}
