




































import RulesMixin from "@/mixins/RulesMixin.vue";
import { IProject } from "@/models/project.entity";
import { Component, Prop } from "vue-property-decorator";
import CustomFieldConfigurationForm from "../report/CustomFieldConfigurationForm.vue";

@Component({
  components: { CustomFieldConfigurationForm }
})
export default class ProjectForm extends RulesMixin {
  @Prop()
  private value!: IProject;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop()
  private valid!: boolean;

  get project() {
    return this.value;
  }

  set project(project: IProject) {
    this.$emit("input", project);
  }

  get isValid() {
    return this.valid;
  }

  set isValid(v: boolean) {
    this.$emit("update:valid", v);
  }

  mounted() {
    if (!this.disabled) {
      document.getElementById("titleField")?.focus();
    }
  }
}
