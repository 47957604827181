import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { IOperation, Operation } from "@/models/operation.entity";

export const OperationDataAccessLayer = new (class OperationDataAccessLayer extends AbstractLocalDataAccessLayer<
  IOperation
> {
  getIdentifier(entity: IOperation): string {
    return entity.id;
  }

  protected mapEntity(entity: IOperation): IOperation {
    return new Operation(entity);
  }
})();
