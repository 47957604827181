






import { Component, Vue } from "vue-property-decorator";
import TheLayoutBase from "@/layouts/TheLayoutBase.vue";
import { DocphantModule } from "@/store/modules/docphant";

@Component({
  components: {
    TheLayoutBase
  }
})
export default class TheLayoutDocphant extends Vue {
  get logoUrl() {
    return DocphantModule.ibLeimkuehler.settings.logoUrl;
  }
}
