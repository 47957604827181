export enum CronJobEnum {
  /**
   * A cron job that updates the status of a list of thgs
   */
  THG_STATUS_UPDATE = "THG_STATUS_UPDATE",

  /**
   * A cron job that updates the status of a list of meter readings
   */
  THG_METER_READING_STATUS_UPDATE = "THG_METER_READING_STATUS_UPDATE",

  /**
   * A cron job that updates the status of a list of thgs from a batch
   */
  BATCH_THG_STATUS_UPDATE = "BATCH_THG_STATUS_UPDATE",

  /**
   * A cron job that updates the items in a batch
   */
  BATCH_THG_ITEMS_UPDATE = "BATCH_THG_ITEMS_UPDATE",

  /**
   * Generates credit billings for thgs that are ready for billing
   */
  BILLING_CREDIT_AUTO_CREATION = "BILLING_CREDIT_AUTO_CREATION",

  /**
   * Generates a billing document
   */
  BILLING_BATCH_CREATION = "BILLING_BATCH_CREATION",

  /**
   * Generates accounting records for a billing document
   */
  BILLING_BATCH_ACCOUNTING_RECORD_CREATION = "BILLING_BATCH_ACCOUNTING_RECORD_CREATION",

  /**
   * Generates PDFs for a billing document
   */
  BILLING_BATCH_PDF_CREATION = "BILLING_BATCH_PDF_CREATION",

  /**
   * Generates credit billings for thgs that are ready for billing
   */
  BILLING_BATCH_AUTO_CREATION = "BILLING_BATCH_AUTO_CREATION",

  /**
   * Generates a CSV file of all billings.
   */
  BILLING_THG_CSV = "BILLING_THG_CSV",

  /**
   * Generates a CSV file of all creditors.
   */
  CREDITOR_CSV = "CREDITOR_CSV",

  /**
   * A cron job that creates all CSV documents related to a billing
   */
  BILLING_THG_ALL_CSV = "BILLING_THG_ALL_CSV",

  /**
   * Dispatches tasks every monday morning.
   */
  DISPATCHER = "DISPATCHER",

  /**
   *
   */
  EXPORT_REPORT = "EXPORT_REPORT",

  /**
   * Exports a report to KSR
   */
  EXPORT_REPORT_KSR = "EXPORT_REPORT_KSR",

  /**
   * Exports a report to DA
   */
  EXPORT_REPORT_DA = "EXPORT_REPORT_DA",

  /**
   * Exports a report to PDR
   */
  EXPORT_REPORT_PDR = "EXPORT_REPORT_PDR",

  /**
   * Checks the plausibility of account numbers
   */
  PLAUSIBILITY_CHECK_ACCOUNT = "PLAUSIBILITY_CHECK_ACCOUNT",

  /**
   * Checks the plausibility of billing numbers
   */
  PLAUSIBILITY_CHECK_BILLING = "PLAUSIBILITY_CHECK_BILLING",

  /**
   * Publishes a batch of billings
   */
  PUBLISH_BILLINGS = "PUBLISH_BILLINGS",

  /**
   * Sends mails
   */
  SEND_MAIL = "SEND_MAIL",

  /**
   * Digital signature was created
   */
  DIGITAL_SIGNATURE_CREATED = "DIGITAL_SIGNATURE_CREATED",

  /**
   * Task to export a report to dealer desk via mail
   */
  EXPORT_REPORT_DEALERDESK = "EXPORT_REPORT_DEALERDESK",

  /**
   * Task to notify about upcoming bookings
   */
  BOOKING_REMINDER = "BOOKING_REMINDER",

  /**
   * Task to WEBHOOK
   */
  WEBHOOK = "WEBHOOK",

  DEFAULT = "default"
}
