

















































































import { requiredRule } from "@/lib/rules/requiredRule";
import { MrfiktivPermissionDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import Debug from "../utility/Debug.vue";
import { ConfigModule } from "@/store/modules/config";
import { AppContextEnum } from "@/lib/enum/appContext.enum";
import { IAdminUser } from "@/models/user.entity";

/**
 * A list of all licenses
 */
enum LicensesEnum {
  FREE = "free",
  STARTER = "starter",
  BASIC = "basic",
  PREMIUM = "premium"
}
export class Permission {
  resource: BackendResourceEnum;
  partnerId: string;

  actions = [] as any;

  existingActions: ActionEnum[] = [];

  constructor(resource: BackendResourceEnum, partnerId: string, user?: IAdminUser) {
    this.resource = resource;
    this.partnerId = partnerId;

    if (user) {
      const partnerPermission: MrfiktivPermissionDtoGen[] =
        user.permission?.filter(value => value.id === this.partnerId) || [];

      const partnerPermissionForResource = partnerPermission.find(value => value.type === resource);

      if (partnerPermissionForResource) {
        this.existingActions = (partnerPermissionForResource.action as ActionEnum[]) || [];
        this.actions = (partnerPermissionForResource.action as ActionEnum[]) || [];
      }
    }
  }

  print() {
    Vue.$log.debug(this);
  }

  toDto(): MrfiktivPermissionDtoGen {
    return {
      id: this.partnerId,
      type: this.resource,
      action: this.actions.map((a: string) => a as ActionEnum)
    };
  }

  hasAction(action?: ActionEnum) {
    if (action) {
      return this.existingActions.find((a: ActionEnum) => a === action);
    }

    return this.actions.length > 0;
  }
}

@Component({
  components: { ConfirmActionDialog, Debug }
})
export default class UserPermissionAddDialog extends Vue {
  @Prop()
  selectedUser!: IAdminUser;

  isLoading = false;
  dialog = false;

  /**
   * Selected partner id
   */
  partnerId = "";

  /**
   * A list of all resources (based on @see BackendResourceEnum) with all configured actions
   */
  permissionForResources: Permission[] = [];

  /**
   * A map of all permissions for each resource and license
   */
  permissionMap = new Map([
    [
      BackendResourceEnum.PARTNER,
      new Map([
        [LicensesEnum.FREE, [ActionEnum.READ]],
        [LicensesEnum.STARTER, [ActionEnum.READ]],
        [LicensesEnum.BASIC, [ActionEnum.READ]],
        [LicensesEnum.PREMIUM, [ActionEnum.READ]]
      ])
    ],
    [
      BackendResourceEnum.FORM,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, []],
        [LicensesEnum.BASIC, []],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.REPORT,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.USER,
      new Map([
        [LicensesEnum.FREE, [ActionEnum.READ]],
        [LicensesEnum.STARTER, [ActionEnum.READ]],
        [LicensesEnum.BASIC, [ActionEnum.READ]],
        [LicensesEnum.PREMIUM, [ActionEnum.READ]]
      ])
    ],
    [
      BackendResourceEnum.MESSAGE,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.RATING,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, [ActionEnum.READ]],
        [LicensesEnum.BASIC, [ActionEnum.READ]],
        [LicensesEnum.PREMIUM, [ActionEnum.READ]]
      ])
    ],
    [
      BackendResourceEnum.RESOURCE,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.SERVICE,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.BOOKING,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.OPERATION,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, [ActionEnum.READ]],
        [LicensesEnum.BASIC, [ActionEnum.READ]],
        [LicensesEnum.PREMIUM, [ActionEnum.READ]]
      ])
    ],
    [
      BackendResourceEnum.SETTING_PARTNER_INTERNAL,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.ANALYTICS,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, [ActionEnum.READ]],
        [LicensesEnum.BASIC, [ActionEnum.READ]],
        [LicensesEnum.PREMIUM, [ActionEnum.READ]]
      ])
    ],
    [
      BackendResourceEnum.TEMPLATE,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.POOL,
      new Map([
        [LicensesEnum.FREE, [ActionEnum.MANAGE]],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.DRIVER,
      new Map([
        [LicensesEnum.FREE, [ActionEnum.MANAGE]],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.VEHICLE,
      new Map([
        [LicensesEnum.FREE, [ActionEnum.MANAGE]],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.CONTRACT,
      new Map([
        [LicensesEnum.FREE, [ActionEnum.MANAGE]],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.SIGN,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, []],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.DOCUMENT,
      new Map([
        [LicensesEnum.FREE, [ActionEnum.MANAGE]],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.SHARED_CONTENT,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, []],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.FAQ,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.ACTIVITY,
      new Map([
        [LicensesEnum.FREE, [ActionEnum.MANAGE]],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.CUSTOM_FIELD,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, []],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.EVENT,
      new Map([
        [LicensesEnum.FREE, [ActionEnum.MANAGE]],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.PROJECT,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.TICKET,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.ATTACHMENT_REQUEST,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, []],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.MILEAGE,
      new Map([
        [LicensesEnum.FREE, [ActionEnum.MANAGE]],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.COST,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, []],
        [LicensesEnum.BASIC, []],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.COST_GROUP,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, []],
        [LicensesEnum.BASIC, []],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ]
  ]);

  get isReportPortalAppContext() {
    return ConfigModule.appContext === AppContextEnum.REPORT_PORTAL;
  }

  get licenses(): LicensesEnum[] {
    return Object.values(LicensesEnum);
  }

  get actions() {
    return Object.keys(ActionEnum).map(k => ActionEnum[k as any]);
  }

  get resources() {
    return Object.keys(BackendResourceEnum).map(k => BackendResourceEnum[k as any]);
  }

  get rules() {
    return [requiredRule()];
  }

  get partners() {
    return PartnerModule.partners;
  }

  @Watch("partnerId")
  watchPartnerId() {
    const user = this.selectedUser;
    if (!user) return;

    this.permissionForResources = this.resources.map(r => new Permission(r, this.partnerId, user));
  }

  applyLicense(license: LicensesEnum) {
    for (const resource of this.permissionForResources) {
      const actions = this.permissionMap.get(resource.resource)?.get(license);

      if (actions) {
        resource.actions = actions;
      }
    }
  }

  async updatePermissions() {
    this.isLoading = true;

    const permission: MrfiktivPermissionDtoGen[] = [];

    for (const resource of this.permissionForResources) {
      if (resource.hasAction()) {
        permission.push(resource.toDto());
      }
    }

    await this.selectedUser?.addPermissions(permission);

    this.dialog = false;
    this.isLoading = false;
  }
}
