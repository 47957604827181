































import { requiredRule } from "@/lib/rules/requiredRule";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IAdminUser } from "@/models/user.entity";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class UserAdministrationResendInvitationDialog extends DarkModeHighlightMixin {
  @Prop()
  selectedUser!: IAdminUser;

  dialog = false;
  isLoading = false;
  isDisabled = false;

  get rules() {
    return [requiredRule()];
  }

  get loading() {
    return this.isLoading;
  }

  close() {
    this.dialog = false;
  }

  async resendInvitation() {
    try {
      this.isLoading = true;
      await this.selectedUser.resendInvitation();
      this.$toast.success(this.$t("components.partner.UserAdministrationResendInvitationDialog.success").toString());
    } catch (error) {
      this.$log.error(error);
      this.$toast.error(this.$t("components.partner.UserAdministrationResendInvitationDialog.error").toString());
    } finally {
      this.dialog = false;
      this.isLoading = false;
    }
  }
}
