
import { Component, Vue } from "vue-property-decorator";

export enum PartnerReportInitializeDialogPagesEnum {
  CHOICE = "choice",
  QR = "qr",
  MAIL = "mail",
  SEND = "send",
  BATCH = "batch",
  SUCCESS = "success",
  EMPTY = "empty",
  DA = "DA",
  EMPTY_SUCCESS = "emptySuccess",
  DA_CREATE = "DA_CREATE",
  CREATE_TICKET = "CREATE_TICKET"
}

@Component({})
export default class PartnerReportInitializeCardMixin extends Vue {
  get PagesEnum() {
    return PartnerReportInitializeDialogPagesEnum;
  }

  setNextStep(page: PartnerReportInitializeDialogPagesEnum) {
    this.$emit("update:step", page);
  }
}
