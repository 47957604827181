
import { ServiceNotImplementedException } from "@/lib/exceptions/http";
import { IFilterListElement } from "@/lib/interfaces/filter/filterListElement.interface";
import { simpleDate } from "@/lib/utility/date-helper";
import { getDateFilterList } from "@/lib/utility/filter";
import { Component, Prop, Vue } from "vue-property-decorator";

/**
 * Offers default implementations for data fields and services expected by TheLayoutPortalList
 * These services tie the component that implements the TheLayoutPortalList to its parent by emitting
 * updates and waiting for the parent to handle the data fetching and status updates
 */
@Component({})
export default class PaginationListMixin extends Vue {
  @Prop()
  loading!: boolean;

  @Prop()
  isLoadingDetail!: boolean;

  @Prop()
  isLoadingMore!: boolean;

  dateFilter = getDateFilterList("timestamp");

  /**
   * The available categories visible in the filter menu
   */
  get filterList(): IFilterListElement[] {
    return [...this.dateFilter];
  }

  set(item: any) {
    this.$emit("set", item);
  }

  loadMore() {
    this.$emit("loadMore");
  }

  refresh() {
    this.$emit("refresh");
  }

  date(item: any): string {
    return simpleDate(item?.timestamp?.created);
  }

  getModule() {
    throw new ServiceNotImplementedException("getModule");
  }

  isActive(item: any): "darken-4" | "lighten-4" | "" {
    this.$log.debug(item);
    throw new ServiceNotImplementedException("isActive");
  }
}
