var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      attrs: {
        flat: ""
      }
    },
    [
      _c("v-card-text", [
        _vm._v(
          " Deine Daten wurden erfolgreich an uns übermittelt. Wir halten dich über den aktuellen Bearbeitungsstatus auf dem Laufenden. "
        )
      ]),
      _c("v-img", {
        attrs: {
          align: "center",
          justify: "center",
          contain: "",
          "max-height": "300px",
          src: require("@/assets/partner_contact_success.svg")
        }
      }),
      _c(
        "v-btn",
        {
          staticClass: "ma-1 mt-5",
          attrs: {
            block: "",
            color: "success"
          },
          on: {
            click: _vm.openRating
          }
        },
        [_vm._v("Jetzt bei Google bewerten")]
      ),
      _c(
        "v-btn",
        {
          staticClass: "ma-1",
          attrs: {
            block: "",
            text: "",
            outlined: ""
          },
          on: {
            click: _vm.close
          }
        },
        [_vm._v(" Fahrzeugübersicht ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }