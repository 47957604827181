

















import { IMachineViewmodel } from "@/lib/interfaces/Docphant/machine.viewmodel.interface";
import { IManufacturer } from "@/lib/interfaces/Docphant/manufacturer.schema.interface";
import { Component, Prop, Vue } from "vue-property-decorator";
import MachineCard from "../MachineCard.vue";
import MachineQrCodeCard from "./MachineQrCodeCard.vue";

@Component({
  components: { MachineCard, MachineQrCodeCard }
})
export default class MachineInfoDialog extends Vue {
  @Prop()
  manufacturer!: IManufacturer;

  selectedMachine!: IMachineViewmodel;

  dialog = false;

  openDialog(machine: IMachineViewmodel) {
    this.selectedMachine = machine;
    this.dialog = true;
  }

  closeDialog() {
    this.dialog = false;
  }
}
