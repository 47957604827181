








import { Component, Vue } from "vue-property-decorator";
import TopToolbarLogo from "@/components/navigation/TopToolbarLogo.vue";
import MachineSelectionCard from "@/components/docphant/MachineSelectionCard.vue";
import DocphantFooter from "@/components/docphant/DocphantFooter.vue";
import { DocphantModule } from "@/store/modules/docphant";
import { ManufacturerModule } from "@/store/modules/manufacturer";

@Component({
  components: {
    TopToolbarLogo,
    MachineSelectionCard,
    DocphantFooter
  }
})
export default class ManufacturerMachineSelection extends Vue {
  async mounted() {
    const favIcon: any = document.querySelector("link[rel~='icon']");
    favIcon.href = this.partner.settings.favIconUrl;
    const manufacturerId = this.$route.params.manufacturerId;

    try {
      await ManufacturerModule.getManufacturer(manufacturerId);
    } catch {
      this.$router.push({ path: "/" });
      Vue.$toast.error("Es wurde kein Hersteller mit der ID gefunden.");
    }
  }

  /*
   * information on cooperation partner content
   */
  get coop() {
    return DocphantModule.cooperatingPartners;
  }
  get partner() {
    return DocphantModule.ibLeimkuehler;
  }

  get manufacturer() {
    return ManufacturerModule.manufacturer;
  }
}
