var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "800"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function fn(_ref) {
                var on = _ref.on,
                  attrs = _ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            text: ""
                          },
                          on: {
                            click: _vm.initialize
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._v(" Reihenfolge konfigurieren ")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function callback($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _vm._v(" Reihenfolge konfigurieren "),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: ""
                      },
                      on: {
                        click: _vm.closeDialog
                      }
                    },
                    [_c("v-icon", [_vm._v(" mdi-close ")])],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-row",
                {
                  staticClass: "my-4"
                },
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "6"
                      }
                    },
                    _vm._l(_vm.tempSelectedList, function(item, index) {
                      return _c(
                        "v-list",
                        {
                          key: index,
                          staticClass: "mb-n8"
                        },
                        [
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: ""
                                      },
                                      on: {
                                        click: function click($event) {
                                          return _vm.remove(index)
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v(" mdi-close ")])],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("v-list-item-content", [
                                _vm._v(_vm._s(item) + " ")
                              ]),
                              _c(
                                "v-list-item-action",
                                [
                                  _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        dense: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "6"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                disabled:
                                                  index + 1 >=
                                                  _vm.tempSelectedList.length,
                                                icon: ""
                                              },
                                              on: {
                                                click: function click($event) {
                                                  return _vm.down(index)
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v(" mdi-arrow-down ")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "6"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                disabled: index === 0,
                                                icon: ""
                                              },
                                              on: {
                                                click: function click($event) {
                                                  return _vm.up(index)
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v(" mdi-arrow-up ")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        elevation: "0",
                        outlined: "",
                        color: "warning"
                      },
                      on: {
                        click: _vm.closeDialog
                      }
                    },
                    [_vm._v(" Zurücksetzen ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        elevation: "0",
                        color: "success"
                      },
                      on: {
                        click: _vm.updateList
                      }
                    },
                    [_vm._v(" Auswahl übernehmen ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }