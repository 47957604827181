import { render, staticRenderFns } from "./OperationDetail.vue?vue&type=template&id=d8a1e036&scoped=true&"
import script from "./OperationDetail.vue?vue&type=script&lang=ts&"
export * from "./OperationDetail.vue?vue&type=script&lang=ts&"
import style0 from "./OperationDetail.vue?vue&type=style&index=0&id=d8a1e036&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8a1e036",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCardActions,VSkeletonLoader})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d8a1e036')) {
      api.createRecord('d8a1e036', component.options)
    } else {
      api.reload('d8a1e036', component.options)
    }
    module.hot.accept("./OperationDetail.vue?vue&type=template&id=d8a1e036&scoped=true&", function () {
      api.rerender('d8a1e036', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/operations/OperationDetail.vue"
export default component.exports