






import { Component, Vue, Prop } from "vue-property-decorator";
import { IPartnerUser } from "@/models/user.entity";

@Component({
  components: {}
})
export default class UserNameInfo extends Vue {
  @Prop()
  item!: IPartnerUser;

  @Prop({ default: "" })
  text!: string;
}
