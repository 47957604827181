var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "confirm-action-dialog",
    {
      attrs: {
        isDialogActive: _vm.dialog,
        fullscreen: false,
        title: _vm.$t("components.template.detail.language.title"),
        supressKeyboardActions: true,
        hideLeft: true,
        hideRight: true
      },
      on: {
        "update:isDialogActive": function updateIsDialogActive($event) {
          _vm.dialog = $event
        },
        "update:is-dialog-active": function updateIsDialogActive($event) {
          _vm.dialog = $event
        }
      }
    },
    _vm._l(_vm.availableLanguages, function(language) {
      return _c(
        "v-list-item",
        {
          key: "language" + language
        },
        [
          _c("v-list-item-icon", [
            _vm._v(
              " " + _vm._s(_vm._f("getFlagEmojiByLanguage")(language)) + " "
            )
          ]),
          _c(
            "v-list-item-content",
            [
              _vm.loading
                ? _c("v-skeleton-loader", {
                    attrs: {
                      type: "list-item"
                    }
                  })
                : _c("v-list-item-title", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.templates.get(language) ? "✅" : "❌") +
                        " "
                    )
                  ])
            ],
            1
          ),
          !_vm.loading
            ? _c(
                "v-list-item-action",
                [
                  !_vm.templates.get(language)
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            loading: _vm.loadingCopy,
                            elevation: 0
                          },
                          on: {
                            click: function click($event) {
                              return _vm.copyTemplateForLanguage(language)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("create")) + " ")]
                      )
                    : _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            elevation: 0
                          },
                          on: {
                            click: function click($event) {
                              return _vm.goToTemplateDetail(language)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("view")) + " ")]
                      )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }