export enum DuplicateHandlingEnum {
  /**
   * All data is being imported
   */
  IGNORE = "ignore",

  /**
   * Duplicates are Skipped
   */
  SKIP = "skip"
}

/**
 * Interface to define which attributes can be imported by the import dialog
 */
export interface IExcelImportConfig {
  /**
   * Label of the import field, i18n key
   */
  label: string;

  /**
   * Hint of the import field, i18n key
   */
  hint: string;

  /**
   * if this attribute is an required value or not
   */
  required: boolean;

  /**
   * if the value should be importet or not
   */
  import: boolean;

  /**
   * the column name in the original excel file - can be prefilled or be an empty string ""
   */
  originColumnNameInExcelFile: string;

  /**
   *
   */
  duplicateHandling?: {
    /**
     * Duplicate handling method
     */
    method: DuplicateHandlingEnum;

    /**
     * ExistingValues (e.g to avoid to create duplicates based on spefici values, for example the fin)
     */
    existingValues: (string | boolean | number | undefined)[];
  };

  /**
   * Function to transform the input value
   * Should throw an error if validation is failing
   * excelimport component handels error
   * @param value
   */
  transform: (value: any) => string | number | boolean | undefined;
}
