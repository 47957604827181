import chargingStationAdminService from "@/services/thg/services/chargingStationAdminService";
import { ThgChargingStationControllerFindAllParamsGen, ThgPageViewModelGen } from "@/services/thg/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module } from "vuex-module-decorators";
import { ThgChargingStationViewModelGen } from "../../services/thg/v1/data-contracts";
import { BasePagination, PaginationFilterListElement } from "./base-pagination.store";
import { PageFilterTypes } from "@/lib/utility/data/page-filter-types.enum";
import { PageOrderEnum } from "@/lib/enum/pageOrder.enum";

@Module({
  dynamic: true,
  namespaced: true,
  name: "chargingStationPaginatedStore",
  store
})
export class ChargingStationPaginatedStore extends BasePagination<
  ThgChargingStationViewModelGen,
  ThgChargingStationControllerFindAllParamsGen
> {
  protected _pageOrder: PageOrderEnum = PageOrderEnum.DESCENDING;
  protected _itemsPerPage = 100;
  protected _totalPages = 0;
  protected _paginationList: ThgChargingStationViewModelGen[] = [];
  protected _currentPage = 1;
  protected _totalItems = 0;
  protected _isLoadAll = false;
  filterOptions: PaginationFilterListElement[] = [
    { key: "userId", type: PageFilterTypes.OBJECT_ID },
    { key: "name", type: PageFilterTypes.STRING },
    { key: "isRegistered", type: PageFilterTypes.BOOLEAN },
    { key: "connectionPowerInKwh", type: PageFilterTypes.NUMBER },
    { key: "chargingPointCount", type: PageFilterTypes.NUMBER },
    { key: "isPublic", type: PageFilterTypes.BOOLEAN },
    { key: "chargingPointOperator.name", type: PageFilterTypes.STRING },
    { key: "chargingPointOperator.managingDirectorName", type: PageFilterTypes.STRING },
    { key: "chargingPointOperator.address.street", type: PageFilterTypes.STRING },
    { key: "chargingPointOperator.address.zip", type: PageFilterTypes.STRING },
    { key: "chargingPointOperator.address.city", type: PageFilterTypes.STRING },
    { key: "chargingPointOperator.address.state", type: PageFilterTypes.STRING },
    { key: "chargingPointOperator.address._id", type: PageFilterTypes.OBJECT_ID },
    { key: "chargingPointOperator.evseOperatorId", type: PageFilterTypes.STRING },
    { key: "chargingPointOperator.bnetzaOperatorId", type: PageFilterTypes.STRING },
    { key: "chargingPointOperator._id", type: PageFilterTypes.OBJECT_ID },
    { key: "evseId", type: PageFilterTypes.STRING },
    { key: "meLoId", type: PageFilterTypes.STRING },
    { key: "maLoId", type: PageFilterTypes.STRING },
    { key: "_id", type: PageFilterTypes.OBJECT_ID }
  ].map(f => new PaginationFilterListElement(f));

  @Action
  protected async loadDocuments(
    query: ThgChargingStationControllerFindAllParamsGen
  ): Promise<
    ThgPageViewModelGen & {
      data?: ThgChargingStationViewModelGen[] | undefined;
    }
  > {
    return (await chargingStationAdminService.getAll(query)) as ThgPageViewModelGen & {
      data?: ThgChargingStationViewModelGen[] | undefined;
    };
  }
}

export const ChargingStationPaginatedModule = getModule(ChargingStationPaginatedStore);
