























































import PartnerDetailBookedServicesCard from "@/components/partner/PartnerDetailBookedServicesCard.vue";
import PartnerDetailGeneralCard from "@/components/partner/PartnerDetailGeneralCard.vue";
import PartnerDetailSettingsLandingPageCard from "@/components/partner/PartnerDetailSettingsLandingPageCard.vue";
import PartnerDetailSettingsOpeningHoursCard from "@/components/partner/PartnerDetailSettingsOpeningHoursCard.vue";
import PartnerDetailSettingsReportCard from "@/components/partner/PartnerDetailSettingsReportCard.vue";
import PartnerUpdateDialog from "@/components/partner/PartnerUpdateDialog.vue";
import { detailedDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import { MrfiktivCreatePartnerDtoGen, MrfiktivPartnerSettingsDocumentGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { CreatePartnerDto, UpdatePartnerDto } from "@/store/models/createPartnerDto";
import { CreateAddressDto } from "@/store/models/mrfiktiv/address";
import { CreateContactDto } from "@/store/models/mrfiktiv/contact";
import { ConfigModule } from "@/store/modules/config";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop, Vue } from "vue-property-decorator";
import AddressForm from "./AddressForm.vue";
import ContactForm from "./ContactForm.vue";
import PartnerDetailBillingInformationCard from "./PartnerDetailBillingInformationCard.vue";
import PartnerForm from "./PartnerForm.vue";
import PartnerSettingsForm from "./PartnerSettingsForm.vue";
import { CreatePartnerSettingsDto } from "@/store/models/mrfiktiv/partner-settings";

@Component({
  filters: {
    detailedDate
  },
  components: {
    PartnerForm,
    AddressForm,
    ContactForm,
    PartnerSettingsForm,
    PartnerDetailGeneralCard,
    PartnerDetailSettingsLandingPageCard,
    PartnerDetailSettingsOpeningHoursCard,
    PartnerDetailSettingsReportCard,
    PartnerDetailBookedServicesCard,
    PartnerUpdateDialog,
    PartnerDetailBillingInformationCard
  }
})
export default class PartnerDetailCard extends Vue {
  @Prop({ default: false })
  isLoading!: boolean;

  tabs = ["info", "landing", "damage", "license", "banking"];

  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;
  virtualScrollerHeight = 0;
  tab = 0;

  get partner() {
    return PartnerModule.partner;
  }

  get partnerId() {
    return PartnerModule.partner._id || PartnerModule.partner.id;
  }

  get partners() {
    return PartnerModule.partners;
  }

  /**
   * Sets selected tab based on route
   */
  selectTabFromRoute() {
    const tab = this.$route.params.tab;
    const index = this.tabs.findIndex(t => t === tab);
    if (index < 0) {
      return;
    }
    this.tab = index;
  }

  async mounted() {
    this.onResize();
    this.selectTabFromRoute();
  }

  get isPartnerBankingAvailable(): boolean {
    return ConfigModule.isPartnerBankingAvailable;
  }

  onResize() {
    this.virtualScrollerHeight = window.innerHeight - 184;
  }

  setPartner(item: any) {
    PartnerModule.setPartner(item);
  }

  async createPartner(partnerDto: MrfiktivCreatePartnerDtoGen) {
    this.isLoading = true;

    try {
      const createdPartner = await PartnerModule.createPartner(partnerDto);
      if (!createdPartner) {
        Vue.$toast.error("Fehler beim Anlegen des Partners");
      } else {
        Vue.$toast.success("Partner wurde erfolgreich angelegt.");
        this.$router.push({ name: "PartnerDetailView", params: { partnerId: createdPartner._id } });
      }
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoading = false;
    }
  }

  async updatePartnerContact(contactDto: CreateContactDto) {
    this.partner.contact = contactDto;
    await this.updatePartner(new CreatePartnerDto(this.partner));
  }

  async updatePartnerAddress(addressDto: CreateAddressDto) {
    this.partner.address = addressDto;
    await this.updatePartner(new CreatePartnerDto(this.partner));
  }

  async updatePartnerSettings(settingsDto: CreatePartnerSettingsDto) {
    this.partner.settings = settingsDto as MrfiktivPartnerSettingsDocumentGen;
    await this.updatePartner(new CreatePartnerDto(this.partner));
  }

  async updatePartner(partnerDto: MrfiktivCreatePartnerDtoGen) {
    this.isLoading = true;
    try {
      const updatePartner = await PartnerModule.updatePartner({
        id: PartnerModule.partner._id,
        partnerUpdateEntity: new UpdatePartnerDto(partnerDto)
      });

      if (!updatePartner) {
        Vue.$toast.error("Fehler beim Ändern des Partners");
      } else {
        Vue.$toast.success("Partner erfolgreich geändert.");
        this.$router.push({ name: "PartnerDetailView", params: { partnerId: updatePartner._id } });
      }
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoading = false;
    }
  }
}
