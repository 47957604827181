var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          attrs: {
            flat: ""
          }
        },
        [
          _c(
            "v-card-title",
            {
              staticClass: "title pt-4 mb-n7"
            },
            [_c("h4", [_vm._v(" Landingpage-CI Farben ")])]
          ),
          _c(
            "v-card",
            {
              staticClass: "my-2",
              attrs: {
                flat: "",
                "max-width": "700"
              }
            },
            [
              _c(
                "v-card-text",
                [
                  _c("v-color-picker", {
                    attrs: {
                      "dot-size": "25",
                      "hide-mode-switch": "",
                      "hide-sliders": "",
                      mode: "hexa",
                      "show-swatches": "",
                      "swatches-max-height": "100",
                      disabled: _vm.disabled
                    },
                    model: {
                      value: _vm.settings.color,
                      callback: function callback($$v) {
                        _vm.$set(_vm.settings, "color", $$v)
                      },
                      expression: "settings.color"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-card-title",
                {
                  staticClass: "title pt-4 mb-n7"
                },
                [_c("h4", [_vm._v("Landingpage Texte")])]
              ),
              _c(
                "v-card-text",
                {
                  staticClass: "mt-8"
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      type: "text",
                      label: "Kartentitel",
                      hint: "Karteninhalte für die Landingpage",
                      disabled: _vm.disabled,
                      outlined: "",
                      required: ""
                    },
                    model: {
                      value: _vm.settings.cardTitle,
                      callback: function callback($$v) {
                        _vm.$set(_vm.settings, "cardTitle", $$v)
                      },
                      expression: "settings.cardTitle"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      type: "text",
                      label: "Kartenuntertitel",
                      hint: "Karteninhalte für die Landingpage",
                      disabled: _vm.disabled,
                      outlined: "",
                      required: ""
                    },
                    model: {
                      value: _vm.settings.cardSubtitle,
                      callback: function callback($$v) {
                        _vm.$set(_vm.settings, "cardSubtitle", $$v)
                      },
                      expression: "settings.cardSubtitle"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      type: "text",
                      label: "Kartentext",
                      hint: "Text, der in der Karte angezeigt wird.",
                      disabled: _vm.disabled,
                      outlined: "",
                      required: ""
                    },
                    model: {
                      value: _vm.settings.cardText,
                      callback: function callback($$v) {
                        _vm.$set(_vm.settings, "cardText", $$v)
                      },
                      expression: "settings.cardText"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      type: "text",
                      label: "FormTitel",
                      hint: "FormTitel für die Landingpage",
                      disabled: _vm.disabled,
                      outlined: "",
                      required: ""
                    },
                    model: {
                      value: _vm.settings.formTitle,
                      callback: function callback($$v) {
                        _vm.$set(_vm.settings, "formTitle", $$v)
                      },
                      expression: "settings.formTitle"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-card-title",
                {
                  staticClass: "title pt-4 mb-n7"
                },
                [_c("h4", [_vm._v("Landingpage Images")])]
              ),
              _c(
                "v-card-text",
                {
                  staticClass: "mt-8"
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "10"
                          }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              type: "text",
                              label: "Logo",
                              hint: "Url des Logos.",
                              disabled: _vm.disabled,
                              outlined: "",
                              required: ""
                            },
                            model: {
                              value: _vm.settings.logoUrl,
                              callback: function callback($$v) {
                                _vm.$set(_vm.settings, "logoUrl", $$v)
                              },
                              expression: "settings.logoUrl"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "2"
                          }
                        },
                        [
                          !_vm.disabled
                            ? _c("public-image-upload-dialog", {
                                attrs: {
                                  prefix: "logo",
                                  folder: _vm.folderName
                                },
                                on: {
                                  onUpload: _vm.handleLogo
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "10"
                          }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              type: "text",
                              label: "Headerimage",
                              hint: "Url des Headerimages",
                              disabled: _vm.disabled,
                              outlined: "",
                              required: ""
                            },
                            model: {
                              value: _vm.settings.headerImageUrl,
                              callback: function callback($$v) {
                                _vm.$set(_vm.settings, "headerImageUrl", $$v)
                              },
                              expression: "settings.headerImageUrl"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "2"
                          }
                        },
                        [
                          !_vm.disabled
                            ? _c("public-image-upload-dialog", {
                                attrs: {
                                  prefix: "header",
                                  folder: _vm.folderName
                                },
                                on: {
                                  onUpload: _vm.handleHeader
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "10"
                          }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              type: "text",
                              label: "Favicon",
                              hint: "Url zum Favicon",
                              disabled: _vm.disabled,
                              outlined: "",
                              required: ""
                            },
                            model: {
                              value: _vm.settings.favIconUrl,
                              callback: function callback($$v) {
                                _vm.$set(_vm.settings, "favIconUrl", $$v)
                              },
                              expression: "settings.favIconUrl"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "2"
                          }
                        },
                        [
                          !_vm.disabled
                            ? _c("public-image-upload-dialog", {
                                attrs: {
                                  prefix: "favicon",
                                  folder: _vm.folderName
                                },
                                on: {
                                  onUpload: _vm.handleFavIcon
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("PartnerDetailImage", {
                        attrs: {
                          favIconUrl: _vm.settings.favIconUrl,
                          headerImageUrl: _vm.settings.headerImageUrl,
                          logoUrl: _vm.settings.logoUrl
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-title",
                {
                  staticClass: "title pt-4 mb-n7"
                },
                [_c("h4", [_vm._v("Karte auf der Landingpage anzeigen")])]
              ),
              _c(
                "v-card-text",
                {
                  staticClass: "mt-8"
                },
                [
                  _c("v-checkbox", {
                    attrs: {
                      label: "Karte auf der Landingpage anzeigen",
                      disabled: _vm.disabled
                    },
                    model: {
                      value: _vm.settings.displayMap,
                      callback: function callback($$v) {
                        _vm.$set(_vm.settings, "displayMap", $$v)
                      },
                      expression: "settings.displayMap"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }