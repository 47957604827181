var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "card",
    {
      staticClass: "ma-2 mb-4",
      staticStyle: {
        "border-color": "red"
      },
      attrs: {
        outlined: "",
        "min-width": "250px",
        flat: "",
        title: _vm.$t(
          "components.partner.UserAdminsitrationDangerZoneCard.title"
        )
      }
    },
    [
      _c("v-subheader", [
        _vm._v(
          _vm._s(
            _vm.$t(
              "components.partner.UserAdminsitrationDangerZoneCard.resend.title"
            )
          )
        )
      ]),
      _c("v-card-text", [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t(
                "components.partner.UserAdminsitrationDangerZoneCard.resend.description"
              )
            ) +
            " "
        )
      ]),
      _c("user-administration-resend-invitation-dialog", {
        attrs: {
          selectedUser: _vm.selectedUser
        }
      }),
      _c("br"),
      _c("v-divider", {
        staticClass: "mt-4"
      }),
      _c("v-subheader", [
        _vm._v(
          _vm._s(
            _vm.$t(
              "components.partner.UserAdminsitrationDangerZoneCard.marketing.title"
            )
          )
        )
      ]),
      _vm.isMarketingOptin
        ? _c(
            "div",
            [
              _c("v-card-text", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "components.partner.UserAdminsitrationDangerZoneCard.marketing.description"
                      )
                    ) +
                    " "
                )
              ]),
              _c(
                "v-btn",
                {
                  staticClass: "mx-4",
                  attrs: {
                    color: "error",
                    loading: _vm.isLoadingUnsubscribe,
                    outlined: "",
                    small: ""
                  },
                  on: {
                    click: function click($event) {
                      _vm.isUnsubscribeDialogActive = true
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "components.partner.UserAdminsitrationDangerZoneCard.marketing.btnTxt"
                        )
                      ) +
                      " "
                  )
                ]
              ),
              _c(
                "confirm-action-dialog",
                {
                  attrs: {
                    isDialogActive: _vm.isUnsubscribeDialogActive,
                    supressKeyboardActions: true,
                    rightLoading: _vm.isLoadingUnsubscribe
                  },
                  on: {
                    "update:isDialogActive": function updateIsDialogActive(
                      $event
                    ) {
                      _vm.isUnsubscribeDialogActive = $event
                    },
                    "update:is-dialog-active": function updateIsDialogActive(
                      $event
                    ) {
                      _vm.isUnsubscribeDialogActive = $event
                    },
                    leftClick: function leftClick($event) {
                      _vm.isUnsubscribeDialogActive = false
                    },
                    rightClick: function rightClick($event) {
                      return _vm.unsubscribe()
                    }
                  }
                },
                [
                  _c("v-card-text", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "components.partner.UserAdminsitrationDangerZoneCard.marketing.confirm"
                          )
                        ) +
                        " "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        : _c(
            "div",
            [
              _c("v-card-text", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "components.partner.UserAdminsitrationDangerZoneCard.marketing.info"
                      )
                    ) +
                    " "
                )
              ])
            ],
            1
          ),
      _c("v-divider", {
        staticClass: "mt-4"
      }),
      _c("v-subheader", [
        _vm._v(
          _vm._s(
            _vm.$t(
              "components.partner.UserAdminsitrationDangerZoneCard.delete.title"
            )
          )
        )
      ]),
      _c("v-card-text", [
        _vm._v(
          _vm._s(
            _vm.$t(
              "components.partner.UserAdminsitrationDangerZoneCard.delete.description"
            )
          ) + " "
        )
      ]),
      _c(
        "v-btn",
        {
          staticClass: "mx-4 mb-4",
          attrs: {
            color: "error",
            outlined: "",
            loading: _vm.isLoadingDeletion,
            small: ""
          },
          on: {
            click: function click($event) {
              return _vm.openDeleteDialog()
            }
          }
        },
        [
          _vm._v(
            _vm._s(
              _vm.$t(
                "components.partner.UserAdminsitrationDangerZoneCard.delete.btnTxt"
              )
            ) + "n"
          )
        ]
      ),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            isDialogActive: _vm.isDeleteDialogActive,
            rightDisabled: !_vm.deletionParamsValid,
            supressKeyboardActions: true,
            rightLoading: _vm.isLoadingDeletion
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isDeleteDialogActive = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isDeleteDialogActive = $event
            },
            leftClick: function leftClick($event) {
              _vm.isDeleteDialogActive = false
            },
            rightClick: function rightClick($event) {
              return _vm.deleteUser()
            }
          }
        },
        [
          !_vm.isLoadingThg
            ? _c(
                "v-card-text",
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "components.partner.UserAdminsitrationDangerZoneCard.delete.confirm"
                        )
                      ) +
                      " "
                  ),
                  _vm.thgs.length
                    ? _c("v-subheader", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "components.partner.UserAdminsitrationDangerZoneCard.delete.ghgTitle"
                            )
                          )
                        )
                      ])
                    : _vm._e(),
                  _vm.thgs.length
                    ? _c("v-data-table", {
                        attrs: {
                          headers: _vm.headers,
                          items: _vm.thgs,
                          "items-per-page": 5
                        }
                      })
                    : _vm._e(),
                  _vm.isDeletionAllowed && _vm.selectedUser
                    ? _c(
                        "v-form",
                        {
                          ref: "form",
                          model: {
                            value: _vm.isDeletionValid,
                            callback: function callback($$v) {
                              _vm.isDeletionValid = $$v
                            },
                            expression: "isDeletionValid"
                          }
                        },
                        [
                          _c("v-card-text", [
                            _vm._v(" " + _vm._s(_vm.selectedUser._id) + " ")
                          ]),
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              label: "UserId",
                              rules: _vm.requiredRule
                            },
                            model: {
                              value: _vm.userIdToDelete,
                              callback: function callback($$v) {
                                _vm.userIdToDelete = $$v
                              },
                              expression: "userIdToDelete"
                            }
                          }),
                          _c("v-card-text", [
                            _vm._v(
                              " " + _vm._s(_vm.selectedUser.userName) + " "
                            )
                          ]),
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              label: "UserName",
                              rules: _vm.requiredRule
                            },
                            model: {
                              value: _vm.userNameToDelete,
                              callback: function callback($$v) {
                                _vm.userNameToDelete = $$v
                              },
                              expression: "userNameToDelete"
                            }
                          })
                        ],
                        1
                      )
                    : _c("v-card-text", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.partner.UserAdminsitrationDangerZoneCard.delete.ghgInfo"
                              )
                            ) +
                            " "
                        )
                      ])
                ],
                1
              )
            : _c(
                "v-card-text",
                [
                  _c("v-skeleton-loader", {
                    attrs: {
                      type:
                        "list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
                    }
                  })
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }