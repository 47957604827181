/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

export enum MachineEnum {
  MACHINE = "machine",
  CHANGEABLE_EQUIPMENT = "changeable_equipment",
  SAFTEYCOMPONENT = "safteycomponent",
  LOAD_CARRYING_EQUIPMENT = "load_carrying_equipment",
  CHAINS = "chains",
  CARDANSHAFTS = "cardanshafts",
  INCOMPLETE_MACHINE = "incomplete_machine",
  OTHER = "other",
  UNKNOWN = "unknown"
}

export const MachineDisplayNames = [
  { displayName: "Maschine", enumValues: ["machine"] },
  { displayName: "Austauschbares Equipment", enumValues: ["changeable_equipment"] },
  { displayName: "Sicherheitskomponente", enumValues: ["safteycomponent"] },
  { displayName: "Ladungsträger", enumValues: ["load_carrying_equipment"] },
  { displayName: "Ketten", enumValues: ["chains"] },
  { displayName: "Gelenkwellen", enumValues: ["cardanshafts"] },
  { displayName: "Unfertige Maschine", enumValues: ["incomplete_machine"] },
  { displayName: "Andere", enumValues: ["other"] },
  { displayName: "Unbekannt", enumValues: ["unknown"] }
];
