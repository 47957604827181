var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          staticClass: "ma-2",
          attrs: {
            outlined: "",
            flat: ""
          }
        },
        [
          _c(
            "v-card-title",
            {
              staticClass: "title pt-4",
              class: _vm.color
            },
            [
              _c(
                "h4",
                {
                  staticClass: "text-truncate"
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "components.partner.PartnerReportDetailInfoWitnessCard.title"
                      )
                    )
                  )
                ]
              )
            ]
          ),
          _c("v-divider"),
          _c("v-data-table", {
            attrs: {
              "item-key": "name",
              headers: _vm.headers,
              items: _vm.witness,
              search: _vm.search,
              "items-per-page": 5
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }