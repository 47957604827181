





















































































































import ContactCustomerDialog from "@/components/utility/ContactCustomerDialog.vue";
import { handleError } from "@/lib/utility/handleError";
import { PartnerModule } from "@/store/modules/partner";
import QrcodeVue from "qrcode.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import CreateReportForm from "../fleet/CreateReportForm.vue";
import { CreateReportAsPartnerDto } from "@/lib/dto/create-report-as-partner-dto";
import Card from "../utility/Card.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import TableWrapper from "../utility/TableWrapper.vue";
import PartnerReportBatchImportDialog from "./PartnerReportBatchImportDialog.vue";
import PartnerReportInitializeCard from "./PartnerReportInitializeCard.vue";
import PartnerReportInitializeCardMixin, {
  PartnerReportInitializeDialogPagesEnum
} from "./PartnerReportInitializeCardMixin.vue";
import { default as DaService } from "@/services/mrfiktiv/services/daService";
import Debug from "../utility/Debug.vue";
import {
  MrfiktivDAServiceEventDetailViewModelGen,
  MrfiktivDaCustomerViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { CreateReportUrlFactory } from "@/lib/utility/createReportUrlFactory";
import { ReportType } from "@/store/enum/reportType";
import { ExportModule } from "@/store/modules/export.store";

@Component({
  components: {
    ContactCustomerDialog,
    PartnerReportInitializeCard,
    QrcodeVue,
    Card,
    TableWrapper,
    PartnerReportBatchImportDialog,
    CreateReportForm,
    ConfirmActionDialog,
    Debug
  }
})
export default class PartnerReportInitializeDialogFromDa extends mixins(PartnerReportInitializeCardMixin) {
  @Prop()
  step!: PartnerReportInitializeDialogPagesEnum;

  @Prop()
  reportDto!: CreateReportAsPartnerDto;

  tabs = ["Kunden", "Aufträge"];
  tab = 0;

  loading = false;

  searchCustomer = "";
  searchLicensePlate = "";

  customerUrl = "";
  serviceEventUrl = "";

  ksrNr = "";

  customers: MrfiktivDaCustomerViewModelGen[] = [];
  serviceEvents: MrfiktivDAServiceEventDetailViewModelGen[] = [];

  isValid = false;

  isConfirmDialogActive = false;

  get headers() {
    return [
      {
        text: "Vorname",
        value: "firstName"
      },
      { text: "Nachname", value: "lastName" },
      { text: "Email", value: "email" },
      { text: "Telefon", value: "phone" }
    ];
  }
  get serviceEventHeaders() {
    return [
      {
        text: "Vorname",
        value: "firstName"
      },
      { text: "Nachname", value: "lastName" },
      { text: "Email", value: "email" },
      { text: "Telefon", value: "phoneNumber" },
      { text: "Nummernschild", value: "numberPlate" },
      { text: "Externe Id", value: "externalId" }
    ];
  }

  get partnerId() {
    return this.$route.params.partnerId ?? PartnerModule.partner.id;
  }

  reset() {
    this.isConfirmDialogActive = false;
  }

  async findServiceEvent() {
    try {
      this.loading = true;
      const partnerId = this.partnerId;

      this.$log.debug("search", this.searchLicensePlate);

      this.serviceEvents = await DaService.getServiceEvents({ partnerId, licensePlate: this.searchLicensePlate });

      this.$log.debug("search", this.serviceEvents);

      this.$toast.success("👍");
    } catch (e) {
      handleError(e);
    } finally {
      this.loading = false;
    }
  }
  async findCustomer() {
    try {
      this.loading = true;
      const partnerId = this.partnerId;

      this.$log.debug("search", this.searchCustomer);

      this.customers = await DaService.findCustomers({ partnerId, value: this.searchCustomer, type: "name" });

      this.$toast.success("👍");
    } catch (e) {
      handleError(e);
    } finally {
      this.loading = false;
    }
  }

  /**
   * Get the base report URl of the Partner from the Partnermodulke
   */
  get baseReportUrls() {
    return PartnerModule.partner.settings?.urls;
  }

  get isKsrSetup() {
    return ExportModule.isKsrSetup;
  }

  /**
   * Copy created Report URL to clipboard
   */
  copyLinkToClipboard(url: string) {
    navigator.clipboard.writeText(url);
    this.$toast.info(this.$t("components.partner.PartnerReportInitializeCard.linkCopied"));
  }

  click(e: MrfiktivDaCustomerViewModelGen) {
    this.$log.debug(e);

    const defaultUrl = PartnerModule.partner.settings?.defaultUrl;
    const initReport = new CreateReportUrlFactory({
      firstName: e?.firstName ?? "",
      lastName: e?.lastName ?? "",
      email: e?.email ?? "",
      phone: e?.phone ?? "",
      city: e?.city ?? "",
      street: e?.street ?? "",
      zip: e?.zip ?? "",
      reportType: ReportType.CAR,
      numberPlate: "",
      externalId: ""
    }).setFromCreateDialog(this.reportDto);

    this.customerUrl = initReport.url(defaultUrl ?? "");

    this.copyLinkToClipboard(this.customerUrl);
  }

  click2(serviceEvent: any) {
    const defaultUrl = PartnerModule.partner.settings?.defaultUrl;
    const initReport = new CreateReportUrlFactory({
      firstName: serviceEvent.firstName,
      lastName: serviceEvent.lastName,
      email: serviceEvent.email,
      phone: serviceEvent.phoneNumber,
      numberPlate: serviceEvent.numberPlate,
      city: serviceEvent.city,
      street: serviceEvent.street,
      zip: serviceEvent.zip,
      externalId: serviceEvent.serviceEventId,
      reportType: ReportType.CAR
    }).setFromCreateDialog(this.reportDto);

    if (this.isKsrSetup && serviceEvent.externalId) {
      this.ksrNr = serviceEvent.externalId;
    }

    initReport.serviceEventId = serviceEvent.serviceEventId;

    this.serviceEventUrl = initReport.url(defaultUrl ?? "");
  }

  appendKsr() {
    this.serviceEventUrl += `&jobId=${encodeURI(this.ksrNr)}`;
  }

  confirm() {
    this.$log.debug("confirm", this.serviceEventUrl, this.customerUrl);

    // 🤷
    if (this.tab === 0) {
      this.copyLinkToClipboard(this.customerUrl);
      this.$emit("update", this.customerUrl);
    } else {
      this.copyLinkToClipboard(this.serviceEventUrl);
      this.$emit("update", this.serviceEventUrl);
    }

    this.setNextStep(PartnerReportInitializeDialogPagesEnum.SEND);
  }
}
