/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ThgBillingInformationControllerFindAllParamsGen,
  ThgBillingInformationViewmodelGen,
  ThgCreateBillingInformationDtoGen,
  ThgExceptionViewmodelGen,
  ThgPageViewModelGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class BillingInformation<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags BillingInformation
   * @name BillingInformationControllerFindAll
   * @summary (ADMIN) List all billing information
   * @request GET:/billing-information
   * @secure
   * @response `200` `(ThgPageViewModelGen & { data?: (ThgBillingInformationViewmodelGen)[] })`
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  billingInformationControllerFindAll = (
    query: ThgBillingInformationControllerFindAllParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgPageViewModelGen & { data?: ThgBillingInformationViewmodelGen[] }, ThgExceptionViewmodelGen>({
      path: `/billing-information`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags BillingInformation
   * @name BillingInformationControllerCreateOrUpdateForPartner
   * @summary Creates a billing information for a partner
   * @request POST:/partner/{partnerId}/billing-information
   * @secure
   * @response `200` `ThgBillingInformationViewmodelGen` The meter reading
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  billingInformationControllerCreateOrUpdateForPartner = (
    partnerId: string,
    data: ThgCreateBillingInformationDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgBillingInformationViewmodelGen, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/billing-information`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags BillingInformation
   * @name BillingInformationControllerGetForPartner
   * @summary Gets a billing information for a partner
   * @request GET:/partner/{partnerId}/billing-information
   * @secure
   * @response `200` `ThgBillingInformationViewmodelGen` The meter reading
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  billingInformationControllerGetForPartner = (partnerId: string, params: RequestParams = {}) =>
    this.http.request<ThgBillingInformationViewmodelGen, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/billing-information`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
}
