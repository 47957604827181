


























import { handleError } from "@/lib/utility/handleError";
import { ProgressStatusEnum } from "@/store/enum/partner/progress.status.enum";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import { GoToHelper } from "@/lib/utility/goToHelper";

@Component({ components: { ConfirmActionDialog } })
export default class PartnerReportDetailDeleteDialog extends Vue {
  @Prop({ default: false })
  hideButton?: boolean;

  isDeleteDialogActive = false;
  /**
   * local loading status is used here next to isProgressUpdateLoading (managed by store), because if progress status is changed in other dialog, this dialog should be disabled but not loading
   */
  isDeleteLoading = false;

  get isProgressUpdateLoading() {
    return PartnerModule.progressUpdateLoading;
  }

  /*
   * sends request to update progress to backend
   */
  async startDeleteDialog() {
    this.isDeleteDialogActive = true;
  }

  /**
   * Updates status without handling status
   */
  async deleteReport() {
    try {
      this.isDeleteLoading = true;
      await PartnerModule.report.updateProgressStatus(ProgressStatusEnum.DELETED);

      await new GoToHelper(this.$router).goToReportList(PartnerModule.report.partnerId ?? this.$route.params.partnerId);
    } catch (e) {
      handleError(e);
    } finally {
      this.isDeleteLoading = false;
      this.isDeleteDialogActive = false;
    }
  }

  show() {
    this.isDeleteDialogActive = true;
  }
}
