var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      on: {
        dragover: function dragover($event) {
          $event.preventDefault()
          return _vm.dragOver.apply(null, arguments)
        },
        dragleave: function dragleave($event) {
          $event.preventDefault()
          return _vm.dragLeave.apply(null, arguments)
        },
        drop: function drop($event) {
          $event.preventDefault()
          return _vm.onFileDrop($event)
        }
      }
    },
    [
      _vm.isDrag
        ? _c(
            "div",
            {
              staticClass: "drag fadeIn"
            },
            [
              !_vm.isDrop
                ? _c(
                    "v-icon",
                    {
                      staticStyle: {
                        zoom: "3"
                      },
                      attrs: {
                        "x-large": ""
                      }
                    },
                    [_vm._v("mdi-file")]
                  )
                : _vm.isFailed
                ? _c(
                    "v-icon",
                    {
                      staticClass: "fade",
                      staticStyle: {
                        zoom: "3"
                      },
                      attrs: {
                        "x-large": "",
                        color: "error"
                      }
                    },
                    [_vm._v("mdi-close")]
                  )
                : _c(
                    "v-icon",
                    {
                      staticClass: "fade",
                      staticStyle: {
                        zoom: "3"
                      },
                      attrs: {
                        "x-large": "",
                        color: "success"
                      }
                    },
                    [_vm._v("mdi-check-bold")]
                  )
            ],
            1
          )
        : _vm._e(),
      _c("paginated-table", {
        attrs: {
          store: _vm.store,
          predefinedFilter: _vm.store.commonPredefinedFilter,
          baseQuery: {
            partnerId: _vm.partner.id
          },
          controlElements: _vm.controlElements,
          headers: _vm.headers,
          loading: _vm.isLoadingAll || _vm.isLoading || _vm.load,
          itemsPerPage: 25
        },
        scopedSlots: _vm._u(
          [
            {
              key: "headerActions",
              fn: function fn() {
                return [_vm._t("headerActions")]
              },
              proxy: true
            },
            {
              key: "bodyActions",
              fn: function fn() {
                return [
                  _c("sign-document-create-dialog", {
                    ref: "signDocumentCreateDialog",
                    on: {
                      save: _vm.save
                    }
                  }),
                  _c("report-document-sign-request-dialog", {
                    ref: "signRequestDialog"
                  })
                ]
              },
              proxy: true
            },
            {
              key: "item.isTemplate",
              fn: function fn(_ref) {
                var item = _ref.item
                return [
                  _c(
                    "tooltip",
                    {
                      attrs: {
                        left: "",
                        text: item.isSigned
                          ? _vm.$t("sign.DocumentDetail.signed")
                          : item.isTemplate
                          ? _vm.$t("sign.DocumentDetail.template")
                          : _vm.$t("sign.DocumentDetail.file")
                      }
                    },
                    [
                      _c("v-icon", [
                        _vm._v(
                          " " +
                            _vm._s(
                              item.isSigned
                                ? "mdi-signature-freehand"
                                : item.isTemplate
                                ? "mdi-file-multiple-outline"
                                : "mdi-file-document-outline"
                            ) +
                            " "
                        )
                      ])
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "item.timestamp.lastModified",
              fn: function fn(_ref2) {
                var item = _ref2.item
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("simpleDoubleDigitDate")(
                          item.timestamp.lastModified
                        )
                      ) +
                      " "
                  )
                ]
              }
            },
            {
              key: "item.timestamp.created",
              fn: function fn(_ref3) {
                var item = _ref3.item
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("simpleDoubleDigitDate")(item.timestamp.created)
                      ) +
                      " "
                  )
                ]
              }
            },
            {
              key: "item.title",
              fn: function fn(_ref4) {
                var item = _ref4.item
                return [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "DocumentDetailView",
                          params: {
                            partnerId: item.partnerId,
                            documentId: item.id
                          }
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(item.title) + " ")]
                  )
                ]
              }
            },
            {
              key: "item.name",
              fn: function fn(_ref5) {
                var item = _ref5.item
                return [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "DocumentDetailView",
                          params: {
                            partnerId: item.partnerId,
                            documentId: item.id
                          }
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(item.name) + " ")]
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      }),
      _c("confirm-action-dialog", {
        attrs: {
          isDialogActive: _vm.isDeleteDialogActive,
          title: _vm.$t("sign.DocumentTable.confirmDeleteTitle"),
          subtitle: _vm.$t("sign.DocumentTable.confirmDeleteSubtitle"),
          leftLoading: _vm.isDeleteDialogLoading,
          rightLoading: _vm.isDeleteDialogLoading
        },
        on: {
          "update:isDialogActive": function updateIsDialogActive($event) {
            _vm.isDeleteDialogActive = $event
          },
          "update:is-dialog-active": function updateIsDialogActive($event) {
            _vm.isDeleteDialogActive = $event
          },
          leftClick: function leftClick($event) {
            _vm.isDeleteDialogActive = false
          },
          rightClick: _vm.deleteDocument
        }
      }),
      _c("sign-request-create-dialog", {
        attrs: {
          dialog: _vm.createSignRequest,
          documents: _vm.selected
        },
        on: {
          "update:documents": function updateDocuments($event) {
            _vm.selected = $event
          },
          save: _vm.signRequestSaved
        }
      }),
      _vm.signDocumentCreateDialog
        ? _c(
            "v-btn",
            {
              attrs: {
                elevation: "6",
                color: "primary",
                fab: "",
                fixed: "",
                bottom: "",
                right: ""
              },
              on: {
                click: _vm.signDocumentCreateDialog.show
              }
            },
            [
              _c(
                "v-icon",
                {
                  attrs: {
                    color: "white"
                  }
                },
                [_vm._v(" mdi-plus ")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }