


















import PartnerDetailCard from "@/components/partner/PartnerDetailCard.vue";
import PartnerListCard from "@/components/partner/PartnerListCard.vue";
import Logo from "@/components/utility/Logo.vue";
import TheLayoutPortalSplit from "@/layouts/TheLayoutPortalSplit.vue";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortalSplit,
    Logo,
    PartnerListCard,
    PartnerDetailCard
  }
})
export default class Partners extends Vue {
  isLoadingAllPartner = false;
  isLoadingSelectedPartner = false;

  async mounted() {
    this.isLoadingAllPartner = true;
    this.setLoadingSelectedPartner(true);

    try {
      await this.getPartners();
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.isLoadingAllPartner = false;
    }

    try {
      await PartnerModule.getPartnerById(PartnerModule.partners[0]._id);
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.setLoadingSelectedPartner(false);
    }
  }

  async getPartners() {
    await PartnerModule.getPartners();
  }

  setLoadingSelectedPartner(isLoading: any) {
    this.isLoadingSelectedPartner = isLoading;
  }
}
