




















































































































import { Component, Vue, Prop } from "vue-property-decorator";
import FilterCard from "@/components/docphant/FilterCard.vue";
import { IFilters } from "@/lib/interfaces/Docphant/filters.interface";
import { IMachineAttachmentViewmodel } from "@/lib/interfaces/Docphant/machine.attachment.viewmodel";
import { IMachineViewmodelWithManufacturerAndAttachment } from "@/lib/interfaces/Docphant/machine.viewmodel.interface";
import { getDisplayNameFromEnumValue } from "@/lib/EnumDisplayNameHelpers.ts";
import { LanugageDisplayNames } from "@/store/enum/docphant/language.enum";
import { MachineAttachmentDisplayNames } from "@/store/enum/docphant/machine.attachment.type.enum";
import { MachineStatusDisplayNames } from "@/store/enum/docphant/machine.status.enum";
@Component({
  components: {
    FilterCard
  }
})
export default class MachineCard extends Vue {
  @Prop({})
  machine!: IMachineViewmodelWithManufacturerAndAttachment;

  attachments: IMachineAttachmentViewmodel[] = [];
  filteredAttachments: IMachineAttachmentViewmodel[] = [];
  filterInactive = false;
  LanugageDisplayNames = LanugageDisplayNames;
  MachineAttachmentDisplayNames = MachineAttachmentDisplayNames;

  getDisplayNameFromEnumValue(p1: any, p2: string) {
    return getDisplayNameFromEnumValue(p1, p2);
  }

  filters: IFilters = {
    selectedStatus: [],
    selectedLanguages: [],
    selectedAttachementTypes: [],
    selectedEditor: "",
    startDate: "",
    endDate: ""
  };

  visible: string[] = [];
  search = "";

  headers = [
    {
      text: "Datum",
      value: "timestamps.created"
    },
    {
      text: "Typ",
      value: "attachmentType"
    },
    {
      text: "Name",
      value: "name"
    }
  ];

  constructor() {
    super();
  }

  mounted() {
    const attachments: IMachineAttachmentViewmodel[] = this.machine.machineAttachment;
    attachments.forEach(element => {
      let mostCurrent = element.timestamps.created;
      if (element.timestamps.lastModified && element.timestamps.lastModified > element.timestamps.created) {
        mostCurrent = element.timestamps.lastModified;
      }
      element.timestamps.created = mostCurrent;
    });
    this.attachments = attachments;
    this.filteredAttachments = attachments;
  }
  /**
   * applies set filters to select which attachments are displayed
   */
  filterMethod() {
    const timeFormatHelper = (timestamp: Date) => {
      const date = new Date(timestamp);
      const year = "" + date.getFullYear();
      const month = date.getMonth() + 1;
      let monthStr = month.toString();
      if (month < 10) {
        monthStr = "0" + monthStr;
      }
      const day = date.getDate();
      let dayStr = day.toString();
      if (day < 10) {
        dayStr = "0" + dayStr;
      }
      return year + "-" + monthStr + "-" + dayStr;
    };
    this.filteredAttachments = this.attachments.filter(el => {
      let match = true;
      if (this.filters.selectedStatus.length !== 0) {
        if (!this.filters.selectedStatus.includes(getDisplayNameFromEnumValue(MachineStatusDisplayNames, el.status))) {
          match = false;
        }
      }
      if (this.filters.selectedLanguages.length !== 0) {
        if (!this.filters.selectedLanguages.includes(getDisplayNameFromEnumValue(LanugageDisplayNames, el.language))) {
          match = false;
        }
      }
      if (this.filters.selectedAttachementTypes.length !== 0) {
        if (
          !this.filters.selectedAttachementTypes.includes(
            getDisplayNameFromEnumValue(MachineAttachmentDisplayNames, el.attachmentType)
          )
        ) {
          match = false;
        }
      }
      if (this.filters.selectedEditor !== "") {
        if (!el.editor.toLowerCase().includes(this.filters.selectedEditor.toLowerCase())) {
          match = false;
        }
      }
      if (this.filters.startDate !== "") {
        if ("" + this.filters.startDate > timeFormatHelper(el.timestamps.created)) {
          match = false;
        }
      }
      if (this.filters.endDate !== "") {
        if (this.filters.endDate < timeFormatHelper(el.timestamps.created)) {
          match = false;
        }
      }
      return match;
    });
  }
  /**
   * Helper to correctly display the date
   */
  chipDateDisplayHelper(date: string) {
    return date.slice(8, 10) + "." + date.slice(5, 7) + "." + date.slice(0, 4);
  }
  /**
   * returns an array of set filters
   */
  get filterItems() {
    const items = [];
    this.filters.selectedStatus.forEach(el => {
      items.push({ element: el, category: "selectedStatus" });
    });
    this.filters.selectedLanguages.forEach(el => {
      items.push({ element: el, category: "selectedLanguages" });
    });
    this.filters.selectedAttachementTypes.forEach(el => {
      items.push({ element: el, category: "selectedAttachementTypes" });
    });
    if (this.filters.selectedEditor) {
      items.push({ element: this.filters.selectedEditor, category: "selectedEditor" });
    }
    if (this.filters.startDate !== "") {
      items.push({ element: this.filters.startDate, category: "startDate" });
    }
    if (this.filters.endDate !== "") {
      items.push({ element: this.filters.endDate, category: "endDate" });
    }
    return items;
  }

  /**
   * checks if filters were selected
   * returns true if there are active filters
   */
  get isFiltersSet() {
    return this.filterItems.length !== 0;
  }

  /*
   * removes all filters
   */
  clearFilter() {
    this.filters = {
      selectedStatus: [],
      selectedLanguages: [],
      selectedAttachementTypes: [],
      selectedEditor: "",
      startDate: "",
      endDate: ""
    };
    this.filterMethod();
  }

  /**
   * removes a certain item from a certain category of filters
   */
  removeFilterItem(item: string | Date, category: string) {
    if (category === "selectedEditor" || category === "startDate" || category === "endDate") {
      this.filters[category] = "";
    }
    const index = this.filters[category].indexOf(item);
    if (index > -1) {
      this.filters[category].splice(index, 1);
    }
    this.filterMethod();
  }

  /**
   * expands a card or contracts it
   */
  toggleExpand(id: string) {
    const el = document.getElementById(id);
    if (el) {
      const index = this.visible.indexOf(id);
      if (index === -1) {
        el.style.display = "";
        this.visible.push(id);
      } else {
        el.style.display = "none";
        this.visible.splice(index, 1);
      }
    }
  }

  /**
   * enable or disable filter card
   */
  toggleFilter() {
    this.filterInactive = !this.filterInactive;
  }

  /**
   * sets the filters. used in filterCard
   */
  setFilters(filters: IFilters) {
    this.filters = filters;
    this.filterMethod();
  }

  /**
   * opens url
   */
  open(url: string) {
    window.open(url);
  }

  /**
   * returns true if the search string is included in some of the items properties
   */
  searchMethod(value: any, search: string, item: any) {
    return (
      this.includes(item.attachmentCode, search) ||
      this.includes(getDisplayNameFromEnumValue(MachineAttachmentDisplayNames, item.attachmentType), search) ||
      this.includes(item.descripton, search) ||
      this.includes(item.editor, search) ||
      this.includes(item.name, search) ||
      this.includes(getDisplayNameFromEnumValue(MachineAttachmentDisplayNames, item.attachmentType), search)
    );
  }

  /**
   * checks if a string includes the other one
   */
  includes(includer: string, included: string) {
    return includer.toLowerCase().includes(included.toLowerCase());
  }
  /**
   * custom sort
   */
  customSort(items: any[], index: any[], isDesc: any[]) {
    const compareString = (a: string, b: string) => {
      let fact = 1;
      if (isDesc[0]) {
        fact = -1;
      }

      if (a < b) {
        return -1 * fact;
      }
      if (a > b) {
        return 1 * fact;
      }
      return 0;
    };

    if (index[0] === "name") {
      items.sort((a, b) => compareString(a.name, b.name));
    } else if (index[0] === "attachmentType") {
      items.sort((a, b) =>
        compareString(
          getDisplayNameFromEnumValue(MachineAttachmentDisplayNames, a.attachmentType),
          getDisplayNameFromEnumValue(MachineAttachmentDisplayNames, b.attachmentType)
        )
      );
    } else if (index[0] === "timestamps.created") {
      items.sort((a, b) => compareString(a.timestamps.created, b.timestamps.created));
    }
    return items;
  }
}
