



















import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { HttpException } from "@/lib/exceptions/http";
import { detailedDate, simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { IReport } from "@/models/report.entity";
import { ISignDocument, SignDocument } from "@/models/sign-document.entity";
import { ISignRequest, SignRequest } from "@/models/sign-request.entity";
import { SignDigitalSignatureViewModelGen } from "@/services/sign/v1/data-contracts";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { PartnerModule } from "@/store/modules/partner";
import { SignRequestModule } from "@/store/modules/sign-request.store";
import { SignModule } from "@/store/modules/sign.store";
import { Component, Mixins } from "vue-property-decorator";
import DocumentDetailCard from "./DocumentDetailCard.vue";

@Component({
  components: {
    TheLayoutPortal,
    LatestEntriesCardEmpty,
    DocumentDetailCard
  },
  filters: { simpleDoubleDigitDate }
})
export default class SignRequestDetail extends Mixins(PartnerFallbackMixin, DarkModeHighlightMixin) {
  isLoading = true;

  report: IReport | null = null;
  document: ISignDocument | null = null;
  signature: SignDigitalSignatureViewModelGen | null = null;
  signatureRequest: ISignRequest | null = null;

  async mounted() {
    const partnerId = this.$route.params.partnerId;
    const requestId = this.$route.params.signRequestId;

    try {
      this.isLoading = true;
      await this.trySetByRouteOrDefault();

      const signatureRequest = await (
        SignRequestModule.maps.id.get(requestId)[0] ?? new SignRequest({ partnerId, id: requestId })
      ).fetch();
      this.signatureRequest = signatureRequest;

      const signatures = await SignModule.findAll({ partnerId, requestId });
      if (signatures.length) {
        const signature = await SignModule.findOne({ partnerId, requestId, signId: signatures[0].id });
        this.signature = signature;

        if (signature.documents?.length) {
          this.document = new SignDocument(signature.documents[0]);
        }
      }

      if (!this.document) {
        this.document = await new SignDocument({ partnerId: partnerId, id: signatureRequest.documents[0] }).fetch();
      }

      const reportReferenceId = signatureRequest.refs?.find(ref => ref.refType === BackendResourceEnum.REPORT)?.refId;
      if (reportReferenceId) {
        PartnerModule.getReportByPartnerIdAndReportId({ partnerId, documentId: reportReferenceId })
          .then(report => {
            this.report = report ?? null;
          })
          .catch(handleError);
      }
    } catch (error) {
      this.$log.error(error);
      if (error instanceof HttpException) {
        this.$toast.error(error.message);
      } else {
        this.$toast.error(this.$t("sign.Sign.error.network"));
      }
    } finally {
      this.isLoading = false;
    }
  }

  get partnerId() {
    return PartnerModule.partner.id;
  }

  get date() {
    if (this.signatureRequest?.timestamp?.created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";
      return detailedDate(this.signatureRequest.timestamp.created, locale);
    }
    return this.$t("sign.DocumentDetail.noDate");
  }
}
