








































import { Component, Vue, Prop } from "vue-property-decorator";
import { ICooperationPartners } from "@/lib/interfaces/Docphant/cooperationPartners.interface";

@Component({
  components: {}
})
export default class CooperationPartners extends Vue {
  @Prop()
  cooperationpartnersImagesAndUrls!: ICooperationPartners;

  redirectToPartner1() {
    window.open(this.cooperationpartnersImagesAndUrls.partnerUrlOne);
  }
  redirectToPartner2() {
    window.open(this.cooperationpartnersImagesAndUrls.partnerUrlTwo);
  }
}
