


















































































































































































































































import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import { numberplateRule } from "@/lib/rules/numberplateRule";
import { handleError } from "@/lib/utility/handleError";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IThirdPartySystem } from "@/models/third-party-system.entity";
import { IGetFreeSlotDto } from "@/services/mrfiktiv/services/daService";
import { MrfiktivDAServiceEventDetailViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { DaSettingsKeys } from "@/store/enum/manage/da-setting-keys.enum";
import { ExportModule } from "@/store/modules/export.store";
import { PartnerModule } from "@/store/modules/partner";
import { SettingModule } from "@/store/modules/setting.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import Debug from "../utility/Debug.vue";
import { DaWebhookModule } from "@/store/modules/da-webhook.store";
import { simpleDate } from "@/lib/utility/date-helper";

enum SearchOptions {
  DOSSIER_ID,
  NUMBERPLATE
}

export enum SendToDaDialogStepperWindowOptions {
  SEARCH,
  NEW,
  EXISTING
}

@Component({ components: { ConfirmActionDialog, Debug }, filters: { simpleDate } })
export default class PartnerReportDetailSendToDaDialogStepper extends mixins(PermissionMixin) {
  readonly WindowOptions = SendToDaDialogStepperWindowOptions;

  @Prop()
  thirdPartySystem?: IThirdPartySystem;

  @Prop()
  value!: SendToDaDialogStepperWindowOptions;

  @Prop({ default: false })
  disableCreation?: boolean;

  @Prop({ default: false })
  onlySelectDossier?: boolean;

  get window() {
    return this.value;
  }

  set window(value: SendToDaDialogStepperWindowOptions) {
    this.$emit("input", value);
  }

  isLoadingDaServiceEvents = false;
  resourcesLoading = false;
  eventTypesLoading = false;
  workshopServicesLoading = false;
  freeSlotLoading = false;

  searchOption = SearchOptions.NUMBERPLATE;

  numberPlate = "";
  externalId = "";
  search = "";

  selectionResourceId = Number(ExportModule.daSetup?.resource) ?? NaN;
  selectionEventTypeId = Number(ExportModule.daSetup?.eventType) ?? NaN;
  selectionWorkshopServiceId = Number(ExportModule.daSetup?.workshopService) ?? NaN;
  selectionFreeSlot: string | null | undefined = null;
  selectedServiceEvent: MrfiktivDAServiceEventDetailViewModelGen | null | undefined = null;

  // from partner setting
  center = "";

  // from partner setting
  group = "";

  isLoadingSettings = false;

  // if settings are unavailable, then supress
  showExternalLink = true;

  /**
   * look for a dossier id or a numberplate
   */
  get SearchOptions() {
    return SearchOptions;
  }

  get numberPlateRules() {
    return [numberplateRule()];
  }

  get daResources() {
    return ExportModule.daResources?.resources;
  }

  get daEventTypes() {
    return ExportModule.daEventTypes?.eventTypes;
  }

  get daServiceEvents() {
    return ExportModule.daServiceEvents;
  }

  get daWorkshopServices() {
    return ExportModule.daWorkshopServices?.workshopServices;
  }

  get hasDefaults() {
    return ExportModule.daSetup?.isMandatoryConfiguration;
  }

  get daFreeSlots(): IDateDisplay[] | undefined {
    const freeSlots = ExportModule.daFreeSlot?.freeSlots.map(x => {
      return {
        value: x,
        display: new Date(x).toLocaleString("de-De")
      };
    });

    return freeSlots;
  }

  get dossier() {
    return DaWebhookModule.dossier;
  }

  setFromWebhook() {
    if (!this.dossier) {
      throw Error("no dossier");
    }

    this.selectedServiceEvent = {
      serviceEventId: `${this.dossier.data?.dossier.id}`,
      numberPlate: this.dossier.data?.dossier?.vehicle?.license_plate,
      firstName: this.dossier.data?.dossier?.contact?.first_name,
      lastName: this.dossier.data?.dossier?.contact?.last_name,
      email: this.dossier.data?.dossier?.contact?.email
    } as MrfiktivDAServiceEventDetailViewModelGen;

    this.window = SendToDaDialogStepperWindowOptions.EXISTING;
  }

  rightClick() {
    switch (this.window) {
      case SendToDaDialogStepperWindowOptions.SEARCH:
        return () => Promise.resolve();

      case SendToDaDialogStepperWindowOptions.NEW: {
        return this.$emit("createServiceEvent", {
          numberPlate: this.numberPlate,
          resourceId: this.selectionResourceId,
          eventTypeId: this.selectionEventTypeId,
          startDate: this.selectionFreeSlot ?? undefined
        });
      }

      case SendToDaDialogStepperWindowOptions.EXISTING: {
        this.$emit("createWorkshopTask", {
          serviceEventId: this.selectedServiceEvent?.serviceEventId,
          workshopTaskId: `${this.selectionWorkshopServiceId}`
        });

        return;
      }

      default:
        return () => Promise.resolve();
    }
  }

  getFieldText(resource: any) {
    if (!resource) {
      return "";
    }

    const id = resource.id;
    const name = resource.name;

    return `${name} (${id})`;
  }

  get selectedResource() {
    return this.daResources?.find(x => x.id === this.selectionResourceId);
  }

  get selectedEventType() {
    return this.daEventTypes?.find(x => x.id === this.selectionEventTypeId);
  }

  get partner() {
    return PartnerModule.partner;
  }

  get partnerId() {
    return this.partner.id;
  }

  async mounted() {
    this.clearSelection();
    this.numberPlate = PartnerModule.report.numberplate || "";
    this.search = this.numberPlate;

    this.externalId =
      this.dossier?.data?.dossier?.id?.toString() ??
      this.thirdPartySystem?.externalId ??
      PartnerModule.report.externalId ??
      "";

    if (this.externalId) {
      this.search = this.externalId;
      this.searchOption = SearchOptions.DOSSIER_ID;
    }

    if (this.can(ActionEnum.READ, BackendResourceEnum.SETTING_PARTNER_INTERNAL)) {
      await this.fetchSettingsForExternalLink();
    }
  }

  async fetchSettingsForExternalLink() {
    this.isLoadingSettings = true;
    try {
      const [centerSetting, groupSetting] = await Promise.all([
        SettingModule.getSettingByKey({
          key: DaSettingsKeys.CENTER,
          partnerId: this.partnerId
        }),
        SettingModule.getSettingByKey({
          key: DaSettingsKeys.GROUP,
          partnerId: this.partnerId
        })
      ]);

      this.center = centerSetting?.value ?? "";
      this.group = groupSetting?.value ?? "";
    } catch (error) {
      this.$log.error(error);
      this.showExternalLink = false;
    } finally {
      this.isLoadingSettings = false;
    }
  }

  getExternalLink(item: MrfiktivDAServiceEventDetailViewModelGen) {
    const group = this.group;
    const center = this.center;
    const viewId = item.resourceId;
    const caseId = item.serviceEventId;

    if (!group || !center || !viewId || !caseId) return "";
    else return `https://werkstattplanung.net/${group}/${center}/kic/da/index.html#/views/${viewId}?caseId=${caseId}`;
  }

  async fetch() {
    this.resourcesLoading = true;
    this.eventTypesLoading = true;
    this.workshopServicesLoading = true;

    try {
      await Promise.all([
        ExportModule.getDaResources(this.partnerId).finally(() => (this.resourcesLoading = false)),
        ExportModule.getDaEventTypes(this.partnerId).finally(() => (this.eventTypesLoading = false)),
        ExportModule.getDaWorkshopServices(this.partnerId).finally(() => (this.workshopServicesLoading = false))
      ]);

      if (this.search) {
        await this.fetchDossiers();
      }
    } catch (error) {
      handleError(error);
    }
  }

  clearSelection() {
    this.selectionResourceId = NaN;
    this.selectionEventTypeId = NaN;
    this.selectionFreeSlot = undefined;
    ExportModule.clearDaFreeSlot();
    ExportModule.clearDaServiceEvents();
  }

  switchToNew() {
    this.selectionResourceId = Number(ExportModule.daSetup?.resource);
    this.selectionEventTypeId = Number(ExportModule.daSetup?.eventType);

    if (this.selectionResourceId) {
      this.fetchFreeSlots(`${this.selectionResourceId}`);
    }

    this.window = SendToDaDialogStepperWindowOptions.NEW;
  }

  selectDossier(item: MrfiktivDAServiceEventDetailViewModelGen) {
    this.selectedServiceEvent = item;
    this.selectionWorkshopServiceId = Number(ExportModule.daSetup?.workshopService);

    if (this.onlySelectDossier) {
      this.$emit("selectDossier", item);
    } else {
      this.window = SendToDaDialogStepperWindowOptions.EXISTING;
    }
  }

  async fetchFreeSlots(resourceId: string) {
    await this.getFreeSlot(resourceId, new Date());

    this.selectionFreeSlot = ExportModule.daFreeSlot?.freeSlots[0];
  }

  async getFreeSlot(resourceId: string, startDate: Date) {
    this.freeSlotLoading = true;

    const freeSlotRequest: IGetFreeSlotDto = {
      partnerId: this.partnerId,
      resourceId: Number(resourceId),
      startDate: startDate.toISOString()
    };
    try {
      await ExportModule.getDaFreeSlot(freeSlotRequest).finally(() => (this.freeSlotLoading = false));
    } catch (error) {
      handleError(error);
    }
  }

  async fetchDossiers() {
    if (!this.search) {
      this.$log.error("Search empty");

      return;
    }

    this.isLoadingDaServiceEvents = true;

    try {
      if (this.searchOption === SearchOptions.DOSSIER_ID) {
        await ExportModule.getDaServiceEvent({ serviceEventId: this.search, partnerId: this.partnerId });
      } else {
        await ExportModule.getDaServiceEvents({ licensePlate: this.search, partnerId: this.partnerId });
      }
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoadingDaServiceEvents = false;
    }
  }
}

interface IDateDisplay {
  value: string;
  display: string;
}
