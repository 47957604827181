


















































import { Component, Vue, Prop } from "vue-property-decorator";
import { requiredRule } from "@/lib/rules/requiredRule.ts";

@Component({
  components: {}
})
export default class MachineSelectionCard extends Vue {
  @Prop({ default: false })
  hideManufacturerInput!: boolean;

  @Prop()
  manufacturerId!: string;

  valid = false;
  machineId = "";
  constructor() {
    super();
  }

  get rules() {
    return [requiredRule()];
  }
  submit() {
    this.$router.push({ path: `/manufacturer/${this.manufacturerId}/machine/${this.machineId}` });
  }
}
