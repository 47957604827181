import { render, staticRenderFns } from "./UserNameInfo.vue?vue&type=template&id=62b7bb37&"
import script from "./UserNameInfo.vue?vue&type=script&lang=ts&"
export * from "./UserNameInfo.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('62b7bb37')) {
      api.createRecord('62b7bb37', component.options)
    } else {
      api.reload('62b7bb37', component.options)
    }
    module.hot.accept("./UserNameInfo.vue?vue&type=template&id=62b7bb37&", function () {
      api.rerender('62b7bb37', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/utility/UserNameInfo.vue"
export default component.exports