














import TheLayoutBase from "@/layouts/TheLayoutBase.vue";
import { AssetEnum, AssetRepository } from "@/lib/AssetRepository";
import { ConfigModule } from "@/store/modules/config";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutBase
  }
})
export default class TheLayoutPortalSplit extends Vue {
  @Prop({ default: AssetEnum.mmmint })
  private headerLogo!: AssetEnum;

  get logoUrl() {
    return AssetRepository.getAsset(ConfigModule.darkMode, ConfigModule._portalLogo);
  }
}
