































import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class PartnerDetailImage extends Vue {
  @Prop()
  logoUrl!: string;

  @Prop()
  headerImageUrl!: string;

  @Prop()
  favIconUrl!: string;
}
