var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("refs-select", {
    attrs: {
      partnerId: _vm.partnerId,
      loading: _vm.isLoading,
      suggested: _vm.suggested,
      listMode: _vm.listMode,
      hideEditButton: _vm.hideEditButton,
      categories: _vm.categories,
      isConfirmable: _vm.isConfirmable
    },
    on: {
      confirm: _vm.confirm,
      change: _vm.onSelectedChange
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v
      },
      expression: "selected"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }