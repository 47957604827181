import { render, staticRenderFns } from "./ManufacturerDetailCard.vue?vue&type=template&id=2cff71a5&scoped=true&"
import script from "./ManufacturerDetailCard.vue?vue&type=script&lang=ts&"
export * from "./ManufacturerDetailCard.vue?vue&type=script&lang=ts&"
import style0 from "./ManufacturerDetailCard.vue?vue&type=style&index=0&id=2cff71a5&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cff71a5",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VCard,VCardActions,VCardText,VCardTitle,VIcon,VImg})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2cff71a5')) {
      api.createRecord('2cff71a5', component.options)
    } else {
      api.reload('2cff71a5', component.options)
    }
    module.hot.accept("./ManufacturerDetailCard.vue?vue&type=template&id=2cff71a5&scoped=true&", function () {
      api.rerender('2cff71a5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/docphant/dashboard/ManufacturerDetailCard.vue"
export default component.exports