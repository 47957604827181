























import { IMachineAttachmentViewmodel } from "@/lib/interfaces/Docphant/machine.attachment.viewmodel";
import { IMachineViewmodel } from "@/lib/interfaces/Docphant/machine.viewmodel.interface";
import { IManufacturer } from "@/lib/interfaces/Docphant/manufacturer.schema.interface";
import { IMachineDeleteStoreDto } from "@/store/interface/machine.delete.store.interface";
import { MachineModule } from "@/store/modules/machine";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class MachineDeleteDialog extends Vue {
  @Prop()
  manufacturer!: IManufacturer;
  @Prop()
  machineAttachmentList!: IMachineAttachmentViewmodel[];

  selectedMachine!: IMachineViewmodel;

  dialog = false;

  closeDialog() {
    this.dialog = false;
  }

  openDialog(machine: IMachineViewmodel) {
    this.selectedMachine = machine;
    this.dialog = true;
  }

  cancelMachineDeleteAndCloseDialog() {
    this.dialog = false;
  }

  async confirmMachineDelete() {
    const machineDeleteStoreDto: IMachineDeleteStoreDto = {
      manufacturerId: this.selectedMachine.manufacturer,
      machineId: this.selectedMachine.id
    };
    this.dialog = false;
    if (this.selectedMachine.machineAttachment.length > 0) {
      Vue.$toast.error("Maschine kann erst gelöscht werden, wenn keine Dokumente mehr zugewiesen sind.");
    } else {
      if (!(await MachineModule.delete(machineDeleteStoreDto))) {
        Vue.$toast.error("Maschine konnte nicht gelöscht werden.");
      } else {
        Vue.$toast.success("Maschine wurde erfolgreich gelöscht.");
      }
    }
  }
}
