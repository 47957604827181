var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("the-layout-portal-split", {
    scopedSlots: _vm._u([
      {
        key: "list",
        fn: function fn() {
          return [
            _c("partner-message-list-paginated", {
              attrs: {
                loading: _vm.loading
              },
              on: {
                isLoadingDetail: _vm.setIsLoadingDetail
              }
            }),
            _vm.loading
              ? _c("v-skeleton-loader", {
                  staticClass: "mx-auto",
                  attrs: {
                    type:
                      "divider, card-heading, divider, list-item-two-line,list-item-two-line, divider"
                  }
                })
              : _vm._e(),
            _vm.canCreateMessage
              ? _c(
                  "v-btn",
                  {
                    attrs: {
                      fab: "",
                      dark: "",
                      color: "primary",
                      fixed: "",
                      bottom: "",
                      right: ""
                    }
                  },
                  [
                    _c("template-dialog", {
                      ref: "templateDialog",
                      attrs: {
                        icon: "mdi-plus",
                        partnerId: _vm.partner._id,
                        to: [],
                        from: [_vm.partner],
                        fullscreen: false,
                        width: "1200px"
                      },
                      on: {
                        sent: _vm.refresh
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]
        },
        proxy: true
      },
      {
        key: "content",
        fn: function fn() {
          return [
            _vm.isDetailLoading
              ? _c("v-skeleton-loader", {
                  staticClass: "mx-auto",
                  attrs: {
                    type: "card@1"
                  }
                })
              : _vm.message
              ? _c("partner-message-detail", {
                  attrs: {
                    value: _vm.message,
                    loading: _vm.isDetailLoading
                  }
                })
              : _c(
                  "v-card",
                  {
                    staticClass: "justify-center align-center",
                    attrs: {
                      flat: ""
                    }
                  },
                  [
                    _c(
                      "v-card-text",
                      [
                        _c("v-img", {
                          staticClass: "my-8",
                          attrs: {
                            contain: "",
                            src: require("@/assets/undraw/undraw_envelope_re_f5j4.svg"),
                            height: "240",
                            justify: "center"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-card-title",
                      {
                        staticClass: "justify-center"
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.PartnerMessageListPaginated.empty"
                              )
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "v-card-subtitle",
                      {
                        staticClass: "justify-center",
                        attrs: {
                          align: "center"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.PartnerMessageListPaginated.selectOrCreate"
                              )
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "v-card-actions",
                      {
                        staticClass: "justify-center",
                        attrs: {
                          align: "center"
                        }
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              text: "",
                              color: "primary"
                            },
                            on: {
                              click: function click($event) {
                                _vm.$refs.templateDialog.isDialogActive = true
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "components.PartnerMessageListPaginated.new"
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }