import { CompanyServiceEnum } from "@/lib/enum/company-service.enum";
import { DetailFormComponentsEnum } from "@/lib/enum/detail-form-components.enum";
import { LanguageCodeEnum } from "@/lib/enum/language-code.enum";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { Form, FormConfig, IsFormable } from "@/lib/formable";
import { IsCustomViewable } from "@/lib/interfaces/is-custom-viewable.interface";
import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";
import { ICreateDto } from "@/lib/utility/data/create-dto.interface";
import { IUpdateDto } from "@/lib/utility/data/update-dto.interface";
import { $t } from "@/lib/utility/t";
import companyService from "@/services/mrfiktiv/services/companyService";
import {
  MrfiktivCompanyAddressViewModelGen,
  MrfiktivCompanyViewModelGen,
  MrfiktivCreateCompanyDtoGen,
  MrfiktivUpdateCompanyDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { BackendResourceEnum, ResourceEnum } from "@/store/enum/authResourceEnum";
import { CompanyDataAccessLayer } from "@/store/modules/access-layers/company.access-layer";
import { CompanyGroupModule } from "@/store/modules/company-group.store";
import { ConfigModule } from "@/store/modules/config";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import Vue from "vue";
import { CompanyAddress, ICompanyAddress } from "./company-address.entity";
import { ContactPartial, IContactPartial } from "./contact-partial.entity";
import { Contact } from "./contact.entity";
import { CustomFieldValue, ICustomFieldValue } from "./custom-field-value.entity";
import { ITimestamp, Timestamp } from "./timestamp.entity";
import { RulesEnum } from "@/lib/rules/rules.map";
import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";
import { ActivityLog } from "./activity-log.entity";

@IsFormable
@IsFilterable
export class CompanyBase
  implements Omit<MrfiktivCompanyViewModelGen, "values">, ICreateDto<ICompany>, IsCustomViewable, IUpdateDto<ICompany> {
  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.id",
    config: {
      itemCallback: () => CompanyDataAccessLayer.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-company"
    }
  })
  id: string;

  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.company.userId",
    config: {
      itemCallback: () => PartnerUserModule.paginationList,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-user"
    }
  })
  userId?: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.company.partnerId"
  })
  partnerId: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "company.companyGroup",
    searchKeywords: ["objects.company.groupId", "company.companyGroup"],
    type: DetailFormComponentsEnum.SELECT_ENTITY,
    props: {
      refType: BackendResourceEnum.COMPANY_GROUP,
      label: "objects.company.groupId"
    },
    rules: [],
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.company.groupId",
    config: {
      itemCallback: () => CompanyGroupModule.paginationList,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-company-group"
    }
  })
  groupId?: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: Timestamp
  })
  timestamp: ITimestamp;

  @FormConfig({
    category: "common.nouns.address",
    searchKeywords: ["common.nouns.address"],
    type: CompanyAddress,
    clearable: true,
    isArray: true,
    props: { label: "common.nouns.address" }
  })
  @FilterConfig({
    type: CompanyAddress
  })
  addresses?: ICompanyAddress[];

  @FormConfig({
    category: "objects.nouns.contact",
    searchKeywords: ["objects.nouns.contact"],
    type: ContactPartial,
    clearable: true
  })
  @FilterConfig({
    type: ContactPartial
  })
  contact?: IContactPartial;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "company.company",
    searchKeywords: ["objects.company.isFleet", "company.company"],
    type: DetailFormComponentsEnum.SELECT_FIELD,
    props: {
      label: "objects.company.isFleet",
      items: [
        { text: $t("yes"), value: true },
        { text: $t("no"), value: false }
      ],
      itemValue: "value"
    },
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.BOOLEAN,
    displayName: "objects.company.isFleet"
  })
  isFleet?: boolean;

  @FormConfig({
    category: "company.company",
    searchKeywords: ["objects.company.services", "company.company"],
    type: DetailFormComponentsEnum.AUTO_COMPLETE,
    props: {
      items: Object.values(CompanyServiceEnum).map(v => {
        return {
          text: $t(`enums.CompanyServiceEnum.${v}`),
          value: v
        } as IVSelectItem;
      }),
      multiple: true,
      itemValue: "value",
      label: "objects.company.services"
    },
    rules: [],
    clearable: true
  })
  @FilterConfig({
    displayName: "objects.company.services",
    type: FilterTypes.ENUM,
    config: {
      items: Object.values(CompanyServiceEnum).map(v => {
        return {
          text: `enums.CompanyServiceEnum.${v}`,
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value"
    }
  })
  services?: CompanyServiceEnum[];

  @FormConfig({
    category: "company.company",
    searchKeywords: ["objects.company.assignees", "company.company"],
    type: DetailFormComponentsEnum.SELECT_ASSIGNEES,
    props: {
      label: "objects.company.assignees"
    },
    clearable: true
  })
  @FilterConfig({
    displayName: "objects.company.assignees",
    type: FilterTypes.OBJECT_ID,
    width: "120",
    config: {
      itemCallback: () => PartnerUserModule.paginationList,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-user"
    }
  })
  assignees?: string[];

  company?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "company.company",
    searchKeywords: ["objects.company.companyName", "company.company"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.company.companyName"
    },
    rules: [RulesEnum.REQUIRED_RULE]
  })
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.company.companyName"
  })
  companyName: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "company.company",
    searchKeywords: ["objects.company.firstName", "company.company"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.company.firstName"
    },
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.company.firstName"
  })
  firstName?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "company.company",
    searchKeywords: ["objects.company.lastName", "company.company"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.company.lastName"
    },
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.company.lastName"
  })
  lastName?: string;

  isCompany?: boolean;

  @FormConfig({
    category: "company.company",
    searchKeywords: ["objects.company.language", "company.company"],
    type: DetailFormComponentsEnum.AUTO_COMPLETE,
    props: {
      items: ConfigModule.availableLanguages.map(v => {
        return {
          text: $t(`enums.LanguageCodeEnum.${v}`),
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value",
      label: "objects.company.language"
    },
    rules: [RulesEnum.REQUIRED_RULE]
  })
  @FilterConfig({
    displayName: "objects.company.language",
    type: FilterTypes.ENUM,
    config: {
      items: ConfigModule.availableLanguages.map(v => {
        return {
          text: $t(`enums.LanguageCodeEnum.${v}`),
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value"
    }
  })
  language: LanguageCodeEnum;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "company.company",
    searchKeywords: ["objects.company.taxnumber", "company.company"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.company.taxnumber"
    },
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.company.taxnumber"
  })
  taxnumber?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "company.company",
    searchKeywords: ["objects.company.isTaxDeductible", "company.company"],
    type: DetailFormComponentsEnum.SELECT_FIELD,
    props: {
      label: "objects.company.isTaxDeductible",
      items: [
        { text: $t("yes"), value: true },
        { text: $t("no"), value: false }
      ],
      itemValue: "value"
    }
  })
  @FilterConfig({
    type: FilterTypes.BOOLEAN,
    displayName: "objects.company.isTaxDeductible"
  })
  isTaxDeductible?: boolean;

  @FilterConfig({ type: CustomFieldValue })
  values: ICustomFieldValue[] = [];

  loading = false;

  get titleReadable() {
    return this.companyName;
  }

  /**
   * Construct company
   */
  constructor(company?: Partial<CompanyBase | MrfiktivCompanyViewModelGen>) {
    this.id = company?.id ?? "";
    this.userId = company?.userId;
    this.partnerId = company?.partnerId ?? "";
    this.timestamp = new Timestamp(company?.timestamp);
    this.addresses = (company?.addresses as MrfiktivCompanyAddressViewModelGen[])?.map(a => new CompanyAddress(a));
    this.contact = company?.contact ? new Contact(company?.contact) : undefined;
    this.services = (company?.services ?? []) as CompanyServiceEnum[];
    this.assignees = company?.assignees || [];
    this.company = company?.companyName;
    this.companyName = company?.companyName ?? "";
    this.firstName = company?.firstName;
    this.lastName = company?.lastName;
    this.groupId = company?.groupId;
    this.isCompany = company?.isCompany;
    this.language = (company?.language as LanguageCodeEnum) ?? LanguageCodeEnum.DE;
    this.taxnumber = company?.taxnumber;
    this.isTaxDeductible = company?.isTaxDeductible;
    this.values = CustomFieldValue.buildCustomFieldValues(company?.values || []);
  }

  /**
   * fetch company
   */
  async fetch(): Promise<this> {
    this.loading = true;

    try {
      const res = await companyService.getOne(this.partnerId, this.id);

      this.map(res);
      CompanyDataAccessLayer.set(this);
    } catch (e) {
      Vue.$log.error(e);
      this.loading = false;
    } finally {
      this.loading = false;
    }

    return this;
  }

  /**
   * map props from viewmodel to this
   */
  map(company?: MrfiktivCompanyViewModelGen) {
    if (!company) return;
    this.id = company?.id ?? "";
    this.userId = company?.userId;
    this.partnerId = company?.partnerId ?? "";
    this.timestamp = new Timestamp(company?.timestamp);
    this.addresses = (company?.addresses as ICompanyAddress[])?.map(a => new CompanyAddress(a));
    this.contact = company?.contact ? new Contact(company?.contact) : undefined;
    this.services = (company?.services ?? []) as CompanyServiceEnum[];
    this.isFleet = company?.isFleet ?? false;
    this.assignees = company?.assignees || [];
    this.company = company?.companyName;
    this.companyName = company?.companyName ?? "";
    this.firstName = company?.firstName;
    this.lastName = company?.lastName;
    this.groupId = company?.groupId ?? "";
    this.isCompany = company?.isCompany ?? true;
    this.language = (company?.language as LanguageCodeEnum) ?? LanguageCodeEnum.DE;
    this.taxnumber = company?.taxnumber;
    this.isTaxDeductible = company?.isTaxDeductible ?? false;
    this.values = CustomFieldValue.buildCustomFieldValues(company?.values || []);
  }

  /**
   * create fetch company
   */
  async create() {
    const data: MrfiktivCreateCompanyDtoGen = {
      firstName: this.firstName || undefined,
      lastName: this.lastName || undefined,
      isCompany: this.isCompany,
      company: this.companyName,
      companyName: this.companyName,
      contact: this.contact || undefined,
      addresses: this.addresses || undefined,
      groupId: this.groupId,
      isTaxDeductible: this.isTaxDeductible,
      language: this.language || undefined,
      services: this.services || undefined,
      isFleet: this.isFleet,
      assignees: this.assignees || undefined,
      taxnumber: this.taxnumber || undefined,
      values: CustomFieldValue.buildCustomFieldValuesDto(this.values || [])
    };
    const res = await companyService.create(this.partnerId, data);

    this.map(res);

    CompanyDataAccessLayer.set(this);

    return this;
  }

  /**
   * delete company
   */
  async delete() {
    const res = await companyService.delete(this.partnerId, this.id);

    this.map(res);
    CompanyDataAccessLayer.delete(this);
  }

  /**
   * update company
   * @returns
   */
  async update() {
    const data: MrfiktivUpdateCompanyDtoGen = {
      addresses: this.addresses,
      company: this.companyName,
      companyName: this.companyName,
      contact: this.contact,
      groupId: this.groupId,
      isCompany: this.isCompany,
      taxnumber: this.taxnumber,
      isTaxDeductible: this.isTaxDeductible,
      language: this.language,
      services: this.services,
      isFleet: this.isFleet,
      assignees: this.assignees,
      firstName: this.firstName,
      lastName: this.lastName,
      values: CustomFieldValue.buildCustomFieldValuesDto(this.values || [])
    };
    const res = await companyService.update(this.partnerId, this.id, data);
    this.map(res);
    CompanyDataAccessLayer.set(this);

    return this;
  }

  /**
   * update company via dto
   * @param dto
   * @returns
   */
  async updatePartial(dto: MrfiktivUpdateCompanyDtoGen) {
    const res = await companyService.update(this.partnerId, this.id, dto);

    this.map(res);

    CompanyDataAccessLayer.set(this);

    return this;
  }

  get isUpdateable() {
    return true;
  }

  /**
   * Create an activity log for assignee changes
   * @param newAssignees The ids of the new assignees of the ticket
   * @param activityType Whether the assignee was added or removed
   */
  async createAssigneeActivity(
    activityType: ActivityTypeEnum.CREATE_ASSIGNEE | ActivityTypeEnum.DELETE_ASSIGNEE,
    newAssignees?: string[]
  ) {
    if (!newAssignees?.length) return;

    await new ActivityLog().createAssigneeActivity({
      partnerId: this.partnerId,
      source: {
        refType: ResourceEnum.COMPANY,
        refId: this.id
      },
      newAssignees,
      activityType
    });
  }
}

type ICompany = CompanyBase;
const Company = Form.createForClass(Filter.createForClass(CompanyBase));

export { Company, ICompany };
