import { LanguageCodeEnum } from "@/lib/enum/language-code.enum";
import { ContactEntity } from "@/models/contactEntity";
import { PartnerEntity } from "@/models/partnerEntity";
import {
  MrfiktivAddressWithGeoGen,
  MrfiktivCreatePartnerDtoGen,
  MrfiktivPartnerSettingsDtoGen,
  MrfiktivPartnerViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { CountryCodeEnum } from "../../lib/enum/country-code.enum";
import { PartnerTypeEnum } from "../enum/partner/partner.type.enum";
import { ReportScreenEnum } from "../enum/partner/report-screen.enum";
import {
  MrfiktivBookedServicesDtoGen,
  MrfiktivContactDtoGen,
  MrfiktivEmailSettingsDtoGen,
  MrfiktivUpdatePartnerDtoGen
} from "./../../services/mrfiktiv/v1/data-contracts";
import { CreateAddressDto } from "./mrfiktiv/address";
import { CreateContactDto } from "./mrfiktiv/contact";
import { CreatePartnerSettingsDto } from "./mrfiktiv/partner-settings";

/**
 * @inheritdoc
 */
export class UpdatePartnerDto implements MrfiktivUpdatePartnerDtoGen {
  /**
   * @inheritdoc
   */
  companyName = "";

  /**
   * @inheritdoc
   */
  partnerType: PartnerTypeEnum | any = PartnerTypeEnum.WORKSHOP;

  /**
   * @inheritdoc
   */
  countryCode: CountryCodeEnum | any = CountryCodeEnum.germany;

  /**
   * @inheritdoc
   */
  language: LanguageCodeEnum | any = LanguageCodeEnum.DE;

  /**
   * @inheritdoc
   */
  contact: MrfiktivContactDtoGen = CreatePartnerDto.EMPTY_CONTACT;

  /**
   * @inheritdoc
   */
  address: MrfiktivAddressWithGeoGen = CreatePartnerDto.EMPTY_ADDRESS;

  /**
   * @inheritdoc
   */
  settings: MrfiktivPartnerSettingsDtoGen = CreatePartnerDto.EMPTY_SETTINGS;

  constructor(partner?: MrfiktivPartnerViewModelGen | PartnerEntity | MrfiktivCreatePartnerDtoGen) {
    if (partner) {
      this.companyName = partner.companyName;
      this.partnerType = partner.partnerType;
      this.countryCode = partner.countryCode;
      this.language = partner.language;

      if (partner.contact) {
        this.contact = new CreateContactDto(partner.contact);
      }

      if (partner.address) {
        this.address = new CreateAddressDto(partner.address);
      }

      if (partner.settings) {
        this.settings = new CreatePartnerSettingsDto(partner.settings);
      }
    }
  }
}

export class CreatePartnerDto extends UpdatePartnerDto implements MrfiktivCreatePartnerDtoGen {
  /**
   * @inheritdoc
   */
  companyUsername = "";

  constructor(partner?: MrfiktivPartnerViewModelGen | PartnerEntity) {
    super(partner);

    if (partner) {
      this.companyUsername = partner.companyUsername;
    }
  }

  public static readonly EMPTY_ADDRESS: MrfiktivAddressWithGeoGen = {
    state: "",
    street: "",
    zip: "",
    city: "",
    countryCode: CountryCodeEnum.germany,
    geo: {
      lat: 0,
      lng: 0
    }
  };

  public static readonly THG_SCREENORDER: ReportScreenEnum[] = [
    ReportScreenEnum.thghome,
    ReportScreenEnum.thgProducts,
    ReportScreenEnum.thgVehicleClass,
    ReportScreenEnum.thgregistration,
    ReportScreenEnum.thgcontact,
    ReportScreenEnum.thgpassword,
    ReportScreenEnum.thgSustainable,
    ReportScreenEnum.thgCheckout
  ];

  public static readonly THG_SCREENORDER_CUSTOMER_ACCOUNT: ReportScreenEnum[] = [
    ReportScreenEnum.thghome,
    ReportScreenEnum.thgProducts,
    ReportScreenEnum.thgVehicleClass,
    ReportScreenEnum.thgregistration,
    ReportScreenEnum.thgPayoutConfiguration,
    ReportScreenEnum.thgcontact,
    ReportScreenEnum.thgpassword,
    ReportScreenEnum.thgCheckout
  ];

  public static readonly THG_SCREENORDER_ONLY_CUSTOMER_ACCOUNT: ReportScreenEnum[] = [
    ReportScreenEnum.thghome,
    ReportScreenEnum.thgProducts,
    ReportScreenEnum.thgVehicleClass,
    ReportScreenEnum.thgregistration,
    ReportScreenEnum.thgCustomerAccount,
    ReportScreenEnum.thgcontact,
    ReportScreenEnum.thgpassword,
    ReportScreenEnum.thgCheckout
  ];

  public static readonly THG_AT_SCREENORDER: ReportScreenEnum[] = [
    ReportScreenEnum.thghome,
    ReportScreenEnum.thgProducts,
    ReportScreenEnum.thgVehicleClass,
    ReportScreenEnum.thgregistrationat,
    ReportScreenEnum.thgcontact,
    ReportScreenEnum.thgpassword,
    ReportScreenEnum.thgSustainableTrees,
    ReportScreenEnum.thgCheckout
  ];

  public static readonly THG_AT_SCREENORDER_CUSTOMER_ACCOUNT: ReportScreenEnum[] = [
    ReportScreenEnum.thghome,
    ReportScreenEnum.thgProducts,
    ReportScreenEnum.thgVehicleClass,
    ReportScreenEnum.thgregistrationat,
    ReportScreenEnum.thgPayoutConfiguration,
    ReportScreenEnum.thgcontact,
    ReportScreenEnum.thgpassword,
    ReportScreenEnum.thgCheckout
  ];

  public static readonly THG_AT_SCREENORDER_ONLY_CUSTOMER_ACCOUNT: ReportScreenEnum[] = [
    ReportScreenEnum.thghome,
    ReportScreenEnum.thgProducts,
    ReportScreenEnum.thgVehicleClass,
    ReportScreenEnum.thgregistrationat,
    ReportScreenEnum.thgCustomerAccount,
    ReportScreenEnum.thgcontact,
    ReportScreenEnum.thgpassword,
    ReportScreenEnum.thgCheckout
  ];

  public static readonly REPORT_SCREENORDER: ReportScreenEnum[] = [
    ReportScreenEnum.welcome,
    ReportScreenEnum.registration,
    ReportScreenEnum.damagelocator,
    ReportScreenEnum.overviewimage,
    ReportScreenEnum.componentimage,
    ReportScreenEnum.damagedetail,
    ReportScreenEnum.mileage,
    ReportScreenEnum.message,
    ReportScreenEnum.daterequest,
    ReportScreenEnum.contactdetails,
    ReportScreenEnum.closing
  ];

  public static readonly EMPTY_EMAIL_SETTINGS: MrfiktivEmailSettingsDtoGen = {
    registrationShowResults: false,
    registrationShowKsrButton: false
  };

  public static readonly EMPTY_BOOKED_SERVICES: MrfiktivBookedServicesDtoGen = {
    registrationService: false,
    form: true,
    report: true,
    payment: false,
    marketplace: false,
    onlineBooking: false,
    sign: false,
    chargingStation: true
  };

  public static readonly EMPTY_SETTINGS: MrfiktivPartnerSettingsDtoGen = {
    logoUrl: "",
    headerImageUrl: "",
    favIconUrl: "",
    formTitle: "",
    color: "",
    contact: {
      email: "",
      phone: ""
    },
    openingHours: {
      monday: "",
      tuesday: "",
      wednesday: "",
      thursday: "",
      friday: "",
      saturday: "",
      sunday: ""
    },
    websites: [
      {
        text: "",
        link: ""
      }
    ],
    agbUrl: {
      text: "",
      link: ""
    },
    cardTitle: "",
    cardText: "",
    cardSubtitle: "",
    emailSettings: CreatePartnerDto.EMPTY_EMAIL_SETTINGS,
    bookedServices: CreatePartnerDto.EMPTY_BOOKED_SERVICES,
    reportSettings: {
      logoUrl: "",
      headerImageUrl: "",
      headerTitle: "",
      headerBtn: "",
      welcomeImageUrl: "",
      welcomeVideoUrl: "",
      welcomeTitle: "",
      welcomeText: [],
      welcomeBtn: "",
      favIconUrl: "",
      firstColor: "",
      secondColor: "",
      screenOrder: [
        ReportScreenEnum.welcome,
        ReportScreenEnum.registration,
        ReportScreenEnum.damagelocator,
        ReportScreenEnum.overviewimage,
        ReportScreenEnum.componentimage,
        ReportScreenEnum.damagedetail,
        ReportScreenEnum.mileage,
        ReportScreenEnum.message,
        ReportScreenEnum.daterequest,
        ReportScreenEnum.contactdetails,
        ReportScreenEnum.closing
      ],
      screens: []
    },
    displayMap: true,
    urls: [],
    isDatasharingActive: false,
    inboxes: []
  };

  public static readonly EMPTY_CONTACT: ContactEntity = {
    email: "",
    phone: ""
  };

  public emptyCreatePartnerDto(): MrfiktivCreatePartnerDtoGen {
    return {
      companyUsername: "",
      companyName: "",
      partnerType: PartnerTypeEnum.WORKSHOP,
      contact: CreatePartnerDto.EMPTY_CONTACT,
      address: CreatePartnerDto.EMPTY_ADDRESS,
      settings: CreatePartnerDto.EMPTY_SETTINGS
    };
  }

  public defaulScreenOrder(): ReportScreenEnum[] {
    return CreatePartnerDto.REPORT_SCREENORDER;
  }

  public defaultThgScreenOrder(): ReportScreenEnum[] {
    return CreatePartnerDto.THG_SCREENORDER;
  }

  public defaultThgATScreenOrder(): ReportScreenEnum[] {
    return CreatePartnerDto.THG_AT_SCREENORDER;
  }

  public defaultThgScreenOrderCustomerAccount(): ReportScreenEnum[] {
    return CreatePartnerDto.THG_SCREENORDER_CUSTOMER_ACCOUNT;
  }

  public defaultThgATScreenOrderCustomerAccount(): ReportScreenEnum[] {
    return CreatePartnerDto.THG_AT_SCREENORDER_CUSTOMER_ACCOUNT;
  }

  public defaultThgScreenOrderOnlyCustomerAccount(): ReportScreenEnum[] {
    return CreatePartnerDto.THG_SCREENORDER_ONLY_CUSTOMER_ACCOUNT;
  }

  public defaultThgATScreenOrderOnlyCustomerAccount(): ReportScreenEnum[] {
    return CreatePartnerDto.THG_AT_SCREENORDER_ONLY_CUSTOMER_ACCOUNT;
  }
}
