import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { IPartnerMessage } from "@/models/partner-message.entity";
import { MrfiktivPartnerMessageControllerFindAllByPartnerIdParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import messageService from "@/services/shared/messageService";

export const PartnerMessagePageDataProvider = new (class extends AbstractPageDataProvider<
  IPartnerMessage,
  MrfiktivPartnerMessageControllerFindAllByPartnerIdParamsGen
> {
  itemsPerPage = 25;

  async getPage(
    query: MrfiktivPartnerMessageControllerFindAllByPartnerIdParamsGen
  ): Promise<IPageViewModel<IPartnerMessage>> {
    const res = await messageService.getAllPaginated({ ...query });

    if (!res) {
      throw Error("Wrong context");
    }

    const meta = res.meta;
    const data = (res.data || []) as IPartnerMessage[];

    return { meta, data };
  }
})();
