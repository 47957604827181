
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { CustomFieldModule } from "@/store/modules/custom-field.store";
import { UserModule } from "@/store/modules/me-user.store";
import { PartnerModule } from "@/store/modules/partner";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { ProjectModule } from "@/store/modules/project.store";
import { TicketModule } from "@/store/modules/ticket.store";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import { PageFilterElement } from "@/models/page-filter-element.entity";

/**
 * Mixin with methods to fill all the (page) lists that are important for all the project views
 */
@Component({
  components: {},
  filters: {}
})
export default class ProjectInitializeListsMixin extends mixins(PermissionMixin) {
  loadingTickets = false;
  loadingPartnerUsers = false;
  loadingProjects = false;
  loadingVehicles = false;

  partnerId = "";
  projectId = "";
  ticketNumber = "";

  async initializeProjectLists(partnerId: string) {
    const promises = [];

    // get tickets
    if (
      partnerId &&
      (!TicketModule.paginationList.length ||
        TicketModule.paginationList[0].partnerId !== partnerId ||
        (!TicketModule.hiddenFilter.length && this.projectId) ||
        (TicketModule.hiddenFilter.length && !this.projectId))
    ) {
      this.$log.debug("Filter set");
      TicketModule.setFilter([]);
      if (this.projectId) {
        TicketModule.setHiddenFilter([
          new PageFilterElement({ key: "projectId", value: this.projectId, operation: "$eq" })
        ]);
      } else {
        TicketModule.setHiddenFilter([]);
      }
      promises.push(this.getTickets(partnerId));
    }

    // get projects
    if (!ProjectModule.paginationList.length || ProjectModule.paginationList[0].partnerId !== partnerId) {
      ProjectModule.setFilter([]);

      promises.push(this.getProjects(partnerId));
    }

    // get users
    if (!PartnerUserModule.paginationList.length) {
      PartnerUserModule.setFilter([]);

      promises.push(this.getPartnerUsers(partnerId));
    }

    // get custom fields
    if (!CustomFieldModule.paginationList.length || CustomFieldModule.paginationList[0].partnerId !== partnerId) {
      promises.push(this.getCustomFields(partnerId));
    }

    // get vehicles
    if (!VehicleModule.paginationList.length) {
      VehicleModule.setFilter([]);

      promises.push(this.getVehicles(partnerId));
    }

    await Promise.all(promises);
  }

  async initializeProjectCustomView(partnerId: string) {
    const promises = [];

    // get projects
    if (!ProjectModule.paginationList.length || ProjectModule.paginationList[0].partnerId !== partnerId) {
      ProjectModule.setFilter([]);

      promises.push(this.getProjects(partnerId));
    }

    // get users
    if (!PartnerUserModule.paginationList.length) {
      PartnerUserModule.setFilter([]);

      promises.push(this.getPartnerUsers(partnerId));
    }

    // get custom fields
    if (!CustomFieldModule.paginationList.length || CustomFieldModule.paginationList[0].partnerId !== partnerId) {
      promises.push(this.getCustomFields(partnerId));
    }

    // get vehicles
    if (!VehicleModule.paginationList.length) {
      VehicleModule.setFilter([]);

      promises.push(this.getVehicles(partnerId));
    }

    await Promise.all(promises);
  }

  async refresh() {
    const promises = [];
    promises.push(this.getTickets());
    promises.push(this.getProjects());
    promises.push(this.getPartnerUsers());
    promises.push(this.getCustomFields());
    promises.push(this.getVehicles());
    await Promise.all(promises);
  }

  async getCustomFields(partnerId?: string) {
    if (!partnerId) {
      partnerId = PartnerModule.partner._id;
    }

    if (!UserModule.abilities.can(ActionEnum.READ, ResourceEnum.CUSTOM_FIELD, partnerId)) return;

    try {
      this.loadingTickets = true;
      await CustomFieldModule.fetchAllFromBeginning({
        partnerId
      });
    } catch (e) {
      this.$log.error(e);
    } finally {
      this.loadingTickets = false;
    }
  }

  async getTickets(partnerId?: string) {
    if (!partnerId) {
      partnerId = PartnerModule.partner._id;
    }

    if (!UserModule.abilities.can(ActionEnum.READ, ResourceEnum.TICKET, partnerId)) {
      this.$log.error("Missing permission");
      return;
    }

    try {
      this.loadingTickets = true;
      await TicketModule.fetchAll({
        partnerId
      });
    } catch (e) {
      this.$log.error(e);
    } finally {
      this.loadingTickets = false;
    }
  }

  async getProjects(partnerId?: string) {
    if (!partnerId) {
      partnerId = PartnerModule.partner._id;
    }

    if (!UserModule.abilities.can(ActionEnum.READ, ResourceEnum.PROJECT, partnerId)) return;

    try {
      this.loadingProjects = true;
      await ProjectModule.fetchFirstPage({ partnerId });
    } catch (e) {
      this.$log.error(e);
    } finally {
      this.loadingProjects = false;
    }
  }

  async getPartnerUsers(partnerId?: string) {
    if (!partnerId) {
      partnerId = PartnerModule.partner._id;
    }

    if (!UserModule.abilities.can(ActionEnum.READ, ResourceEnum.USER, partnerId)) return;

    this.$log.debug("getPartnerUsers");
    try {
      this.loadingPartnerUsers = true;
      await PartnerUserModule.fetchFirstPage({
        partnerId
      });
    } catch (e) {
      this.$log.error(e);
    } finally {
      this.loadingPartnerUsers = false;
    }
  }

  async getVehicles(partnerId?: string) {
    if (!partnerId) {
      partnerId = PartnerModule.partner._id;
    }

    if (!UserModule.abilities.can(this.ActionEnum.READ, ResourceEnum.VEHICLE, partnerId)) return;

    try {
      this.loadingVehicles = true;
      await VehicleModule.fetchAll({
        partnerId
      });
    } catch (e) {
      this.$log.error(e);
    } finally {
      this.loadingVehicles = false;
    }
  }
}
