


























import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { SignDocument } from "@/models/sign-document.entity";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Ref } from "vue-property-decorator";
import SignDocumentForm from "./DocumentForm.vue";

@Component({
  components: { ConfirmActionDialog, SignDocumentForm }
})
export default class SignDocumentCreateDialog extends DarkModeHighlightMixin {
  @Ref("signDocumentForm")
  signDocumentForm!: SignDocumentForm;

  isDialogActive = false;
  isValid = false;
  isLoading = false;

  closeDialog() {
    this.isDialogActive = false;
  }

  get requiredRule() {
    return requiredRule();
  }

  get partner() {
    return PartnerModule.partner;
  }

  show() {
    this.isDialogActive = true;
  }

  async save() {
    this.isLoading = true;

    const document = this.signDocumentForm.createDocumentDto;

    this.$log.debug(document);

    const doc = await new SignDocument({ partnerId: this.partner._id, ...document }).create();
    if (doc.id) {
      this.$emit("save", doc);
      this.$toast.success("👍");
      this.closeDialog();
    }
    this.isLoading = false;
  }
}
