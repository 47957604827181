














































import PartnerMessageDetail from "@/components/partner/PartnerMessageDetail.vue";
import PartnerMessageListPaginated from "@/components/partner/PartnerMessagePaginatedList.vue";
import PartnerReportDetail from "@/components/partner/PartnerReportDetail.vue";
import PartnerReportList from "@/components/partner/PartnerReportList.vue";
import TheLayoutPortalSplit from "@/layouts/TheLayoutPortalSplit.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { PartnerMessageV2PaginationModule } from "@/store/modules/message-pagination.store";
import { MessageModule } from "@/store/modules/message.store";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import TemplateDialog from "@/components/template/TemplateDialog.vue";

@Component({
  components: {
    TheLayoutPortalSplit,
    PartnerReportList,
    PartnerReportDetail,
    PartnerMessageListPaginated,
    PartnerMessageDetail,
    TemplateDialog
  }
})
export default class PartnerMessages extends mixins(PartnerFallbackMixin, PermissionMixin) {
  isDetailLoading = false;

  loading = true;

  setIsLoadingDetail(value: boolean) {
    this.isDetailLoading = value;
  }

  async mounted() {
    await this.trySetByRouteOrDefault();
    await this.load();
  }

  async refresh() {
    await this.load();
  }

  get message() {
    return MessageModule.message;
  }

  get partner() {
    return PartnerModule.partner;
  }

  async load() {
    this.loading = true;

    try {
      await PartnerMessageV2PaginationModule.fetchFirstPage({ partnerId: PartnerModule.partner.id });
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.loading = false;
    }
  }
}
