













import CooperationPartners from "@/components/docphant/CooperationPartners.vue";
import ManufacturerListCard from "@/components/docphant/dashboard/ManufacturerListCard.vue";
import TopToolbarLogo from "@/components/navigation/TopToolbarLogo.vue";
import PartnerFooter from "@/components/partner/PartnerFooter.vue";
import { DocphantModule } from "@/store/modules/docphant";
import { ManufacturerModule } from "@/store/modules/manufacturer";
import { Component, Vue } from "vue-property-decorator";
import DocphantFooter from "@/components/docphant/DocphantFooter.vue";
import TheLayoutDocphant from "@/layouts/TheLayoutDocphant.vue";

@Component({
  components: {
    TheLayoutDocphant,
    PartnerFooter,
    TopToolbarLogo,
    ManufacturerListCard,
    CooperationPartners,
    DocphantFooter
  }
})
export default class ManufacturerListPage extends Vue {
  /**
   * gets machine from store
   */
  get manufacturerList() {
    return ManufacturerModule.manufacturerList;
  }

  drawer = true;

  async mounted() {
    const favIcon: any = document.querySelector("link[rel~='icon']");
    favIcon.href = this.partner.settings.favIconUrl;
    this.adjustMachineCardMinHeight();

    try {
      await ManufacturerModule.getAllManufacturer();
    } catch {
      Vue.$toast.error("Keine Hersteller gefunden");
      this.$router.push({ name: "DocphantErrorPage" });
    }
  }

  created() {
    window.addEventListener("resize", this.adjustMachineCardMinHeight);
  }

  destroyed() {
    window.removeEventListener("resize", this.adjustMachineCardMinHeight);
  }

  adjustMachineCardMinHeight() {
    const container = document.getElementById("cardContainer");
    const footer = document.getElementById("footer");
    const header = document.getElementById("header");

    if (container && footer && header) {
      const top = header.clientHeight + 20;
      const bottom = footer.clientHeight;
      container.style.marginTop = `${top}px`;
      container.style.marginBottom = "22px";
      container.style.minHeight = `calc( 100vh - ${top}px - ${bottom}px - 22px )`;
    }
  }

  /*
   * information on cooperation partner content
   */
  get coop() {
    return DocphantModule.cooperatingPartners;
  }
  get partner() {
    return DocphantModule.ibLeimkuehler;
  }
}
