














































import { IMachineAttachmentViewmodel } from "@/lib/interfaces/Docphant/machine.attachment.viewmodel";
import { IMachineViewmodel } from "@/lib/interfaces/Docphant/machine.viewmodel.interface";
import { IManufacturer } from "@/lib/interfaces/Docphant/manufacturer.schema.interface";
import { LanguageEnum } from "@/store/enum/docphant/language.enum";
import { MachineAttachmentStatusEnum } from "@/store/enum/docphant/machine.attachment.status.enum";
import { MachineAttachmentTypeEnum } from "@/store/enum/docphant/machine.attachment.type.enum";
import { IMachineAddRemoveAttachmentStoreDto } from "@/store/interface/machine.add.remove.machine.attachment.store.interface";
import { MachineModule } from "@/store/modules/machine";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class MachineAttachmentRemoveDialog extends Vue {
  @Prop()
  manufacturer!: IManufacturer;
  @Prop()
  machineAttachmentList!: IMachineAttachmentViewmodel[];

  selectedMachine!: IMachineViewmodel;
  assignableMachineAttachment: IMachineAttachmentViewmodel[] = [] as any;
  selectedDocument: IMachineAttachmentViewmodel = {
    id: "",
    name: "",
    descripton: "",
    attachmentType: MachineAttachmentTypeEnum.OTHER,
    status: MachineAttachmentStatusEnum.ACTIVATED,
    attachmentCode: "",
    editor: "string",
    language: LanguageEnum.DE,
    file: "" as any,
    timestamps: {} as any,
    manufacturer: "string",
    machines: []
  };

  dialog = false;
  searchAssignableMachineAttachment = "";
  MachineAttachmentHeaders = [
    {
      text: "Name",
      align: "start",
      value: "name"
    },
    {
      text: "Sprache",
      value: "language"
    },
    {
      text: "Typ",
      value: "attachmentType"
    }
  ];

  closeDialog() {
    this.dialog = false;
  }

  openDialog(machine: IMachineViewmodel) {
    this.selectedMachine = machine;
    this.refreshUnAssignableMachineAttachment(machine);
    this.dialog = true;
  }

  selectMachineAttachmentToRemove(machineattachment: IMachineAttachmentViewmodel) {
    this.selectedDocument = machineattachment;
  }

  unselectMachineAttachmentToRemove() {
    this.selectedDocument = {
      id: "",
      name: "",
      descripton: "",
      attachmentType: MachineAttachmentTypeEnum.OTHER,
      status: MachineAttachmentStatusEnum.ACTIVATED,
      attachmentCode: "",
      editor: "string",
      language: LanguageEnum.DE,
      file: "" as any,
      timestamps: {} as any,
      manufacturer: "string",
      machines: []
    };
  }
  refreshUnAssignableMachineAttachment(machine: any) {
    this.assignableMachineAttachment = [] as any;
    for (const machineAttachment of this.machineAttachmentList) {
      if (machine.machineAttachment.includes(machineAttachment.id)) {
        this.assignableMachineAttachment.push(machineAttachment);
      }
    }
  }

  async removeDocumentFromMachine() {
    const machineAddRemoveAttachmentStoreDto: IMachineAddRemoveAttachmentStoreDto = {
      manufacturerId: this.manufacturer.id,
      machineId: this.selectedMachine.id,
      documentId: this.selectedDocument.id
    };
    MachineModule.removeMachineAttachment(machineAddRemoveAttachmentStoreDto);
    this.unselectMachineAttachmentToRemove();
    this.dialog = false;
  }
}
