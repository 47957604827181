var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      on: {
        dragover: function dragover($event) {
          $event.preventDefault()
          return _vm.dragOver.apply(null, arguments)
        },
        dragleave: function dragleave($event) {
          $event.preventDefault()
          return _vm.dragLeave.apply(null, arguments)
        },
        drop: function drop($event) {
          $event.preventDefault()
          return _vm.onFileDrop($event)
        }
      }
    },
    [
      _vm.isDrag
        ? _c(
            "div",
            {
              staticClass: "drag fadeIn"
            },
            [
              !_vm.isDrop
                ? _c(
                    "v-icon",
                    {
                      staticStyle: {
                        zoom: "3"
                      },
                      attrs: {
                        "x-large": ""
                      }
                    },
                    [_vm._v("mdi-file")]
                  )
                : _vm.isFailed
                ? _c(
                    "v-icon",
                    {
                      staticClass: "fade",
                      staticStyle: {
                        zoom: "3"
                      },
                      attrs: {
                        "x-large": "",
                        color: "error"
                      }
                    },
                    [_vm._v("mdi-close")]
                  )
                : _c(
                    "v-icon",
                    {
                      staticClass: "fade",
                      staticStyle: {
                        zoom: "3"
                      },
                      attrs: {
                        "x-large": "",
                        color: "success"
                      }
                    },
                    [_vm._v("mdi-check-bold")]
                  )
            ],
            1
          )
        : _vm._e(),
      _c("paginated-table", {
        attrs: {
          store: _vm.store,
          baseQuery: {
            partnerId: _vm.partner.id
          },
          controlElements: _vm.controlElements,
          headers: _vm.headers,
          itemsPerPage: 25,
          predefinedFilter: _vm.predefinedFilter
        },
        scopedSlots: _vm._u(
          [
            {
              key: "headerActions",
              fn: function fn() {
                return [_vm._t("headerActions")]
              },
              proxy: true
            },
            {
              key: "bodyActions",
              fn: function fn() {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        text: "",
                        outlined: ""
                      },
                      on: {
                        click: _vm.showDialog
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("sign.SignRequestCreateDialog.create")
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c("report-document-sign-request-dialog", {
                    ref: "signRequestDialog"
                  })
                ]
              },
              proxy: true
            },
            {
              key: "item.status",
              fn: function fn(_ref) {
                var item = _ref.item
                return [
                  _c("report-document-card-status-menu", {
                    attrs: {
                      signRequest: item
                    }
                  })
                ]
              }
            },
            {
              key: "item.assignees",
              fn: function fn(_ref2) {
                var item = _ref2.item
                return [
                  _vm.isUserLoaded && !_vm.store.isLoading
                    ? _c("assignee-preview", {
                        attrs: {
                          value: item.assignees
                        }
                      })
                    : _c("v-skeleton-loader")
                ]
              }
            },
            {
              key: "item.timestamp.created",
              fn: function fn(_ref3) {
                var item = _ref3.item
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("simpleDoubleDigitDate")(item.timestamp.created)
                      ) +
                      " "
                  )
                ]
              }
            },
            {
              key: "item.timestamp.lastModified",
              fn: function fn(_ref4) {
                var item = _ref4.item
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("simpleDoubleDigitDate")(
                          item.timestamp.lastModified
                        )
                      ) +
                      " "
                  )
                ]
              }
            },
            {
              key: "item.title",
              fn: function fn(_ref5) {
                var item = _ref5.item
                return [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "SignRequestDetailView",
                          params: {
                            partnerId: item.partnerId,
                            signRequestId: item.id
                          }
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(item.title) + " ")]
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      }),
      _c("confirm-action-dialog", {
        attrs: {
          isDialogActive: _vm.isDeleteDialogActive,
          title: _vm.$t("sign.SignRequestTable.confirmDeleteTitle"),
          subtitle: _vm.$t("sign.SignRequestTable.confirmDeleteSubtitle"),
          leftLoading: _vm.isDeleteDialogLoading,
          rightLoading: _vm.isDeleteDialogLoading,
          rightDisabled: _vm.isDeleteDisabled
        },
        on: {
          "update:isDialogActive": function updateIsDialogActive($event) {
            _vm.isDeleteDialogActive = $event
          },
          "update:is-dialog-active": function updateIsDialogActive($event) {
            _vm.isDeleteDialogActive = $event
          },
          leftClick: function leftClick($event) {
            _vm.isDeleteDialogActive = false
          },
          rightClick: _vm.deleteSignRequest
        }
      }),
      _c(
        "v-btn",
        {
          attrs: {
            elevation: "6",
            color: "primary",
            fab: "",
            fixed: "",
            bottom: "",
            right: ""
          },
          on: {
            click: _vm.showDialog
          }
        },
        [
          _c(
            "v-icon",
            {
              attrs: {
                color: "white"
              }
            },
            [_vm._v(" mdi-plus ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }