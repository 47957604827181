import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { DetailFormComponentsEnum } from "@/lib/enum/detail-form-components.enum";
import { IsFilterable, Filter, FilterTypes, FilterConfig } from "@/lib/filterable";
import { FormConfig, IsFormable, Form } from "@/lib/formable";
import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";
import { RulesEnum } from "@/lib/rules/rules.map";
import { MrfiktivAddressGen, MrfiktivAddressWithGeoGen } from "@/services/mrfiktiv/v1/data-contracts";

@IsFormable
@IsFilterable
class AddressBase implements MrfiktivAddressGen {
  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.address",
    searchKeywords: ["objects.address.street", "common.nouns.address"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.address.street"
    },
    rules: [RulesEnum.REQUIRED_RULE]
  })
  @FilterConfig({
    displayName: "objects.address.street",
    type: FilterTypes.STRING
  })
  street: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.address",
    searchKeywords: ["objects.address.zip", "common.nouns.address"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.address.zip"
    },
    rules: [RulesEnum.REQUIRED_RULE]
  })
  @FilterConfig({
    displayName: "objects.address.zip",
    type: FilterTypes.STRING
  })
  zip: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.address",
    searchKeywords: ["objects.address.city", "common.nouns.address"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.address.city"
    },
    rules: [RulesEnum.REQUIRED_RULE]
  })
  @FilterConfig({
    displayName: "objects.address.city",
    type: FilterTypes.STRING
  })
  city: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.address",
    searchKeywords: ["objects.address.zip", "common.nouns.address"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.address.zip"
    },
    rules: [RulesEnum.REQUIRED_RULE]
  })
  @FilterConfig({
    displayName: "objects.address.state",
    type: FilterTypes.STRING
  })
  state: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.address",
    searchKeywords: ["objects.address.countryCode", "common.nouns.address"],
    type: DetailFormComponentsEnum.AUTO_COMPLETE,
    props: {
      label: "objects.address.countryCode",
      items: Object.values(CountryCodeEnum).map(v => {
        return {
          text: v,
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value"
    },
    rules: [RulesEnum.REQUIRED_RULE]
  })
  @FilterConfig({
    displayName: "objects.address.countryCode",
    type: FilterTypes.ENUM,
    config: {
      items: Object.values(CountryCodeEnum).map(v => {
        return {
          text: v,
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value"
    }
  })
  countryCode?: string | undefined;

  constructor(address?: Partial<MrfiktivAddressGen>) {
    this.street = address?.street ?? "";
    this.zip = address?.zip ?? "";
    this.city = address?.city ?? "";
    this.state = address?.state ?? "";
    this.countryCode = address?.countryCode;
  }
}
class AddressWithGeoBase extends AddressBase implements MrfiktivAddressWithGeoGen {
  geo: {
    lat: number;
    lng: number;
  };
  constructor(address?: Partial<MrfiktivAddressWithGeoGen>) {
    super(address);
    this.geo = address?.geo ?? { lat: 0, lng: 0 };
  }
}
type IAddress = AddressBase;
type IAddressWithGeo = AddressWithGeoBase;
const Address = Form.createForClass(Filter.createForClass(AddressBase));
const AddressWithGeo = Filter.createForClass(AddressWithGeoBase);

export { Address, AddressWithGeo, IAddress, IAddressWithGeo };
