






import { debounce } from "debounce";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class BouncyButton extends Vue {
  debounceClick = debounce(this.onClick, 500);

  onClick() {
    this.$emit("click");
  }
}
