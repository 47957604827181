

































import ThgQuoteWorkitemRegistrationCard from "@/components/thg/ThgQuoteWorkitemRegistrationCard.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import {
  MrfiktivCreateTemplateContentDtoGen,
  MrfiktivTemplateContentViewModelGen,
  MrfiktivUpdatePartnerTemplateDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { ThgTemplateContentViewModelGen } from "@/services/thg/v1/data-contracts";
import { AdminTemplateModule } from "@/store/modules/admin-template.store";
import { PartnerTemplateModule } from "@/store/modules/partner-template.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import TemplateEditor, { TemplateEditConfiguration } from "./TemplateEditor.vue";
import TemplateEditorSubject from "./TemplateEditorSubject.vue";
import { ConfigModule } from "@/store/modules/config";
import { LanguageCodeEnum } from "@/lib/enum/language-code.enum";
import { IDialog } from "@/lib/interfaces/dialog.interface";

@Component({
  components: {
    ThgQuoteWorkitemRegistrationCard,
    Card,
    TemplateEditor,
    TemplateEditorSubject,
    ConfirmActionDialog
  }
})
export default class TemplateDetailContentUpdateDialog extends mixins(PermissionMixin, DarkModeHighlightMixin)
  implements IDialog {
  @Prop()
  partnerId!: string;

  @Prop()
  content!: MrfiktivTemplateContentViewModelGen | ThgTemplateContentViewModelGen;

  @Prop()
  templateKey!: string;

  @Prop()
  language!: LanguageCodeEnum;

  dialog = false;
  updateTemplateBody: MrfiktivCreateTemplateContentDtoGen = {
    subject: "",
    body: ""
  };
  loading = false;

  get requiredRules() {
    return [requiredRule()];
  }

  get editConfiguration() {
    return new TemplateEditConfiguration(this.partnerId, true);
  }

  get debug() {
    return ConfigModule.debug;
  }

  close() {
    this.dialog = false;
  }

  /**
   * Copy body and open dialog
   */
  async open() {
    this.updateTemplateBody.body = this.content.body;
    this.updateTemplateBody.subject = this.content.subject;

    this.dialog = true;
  }

  async update() {
    try {
      this.loading = true;
      const updateTemplateDto: MrfiktivUpdatePartnerTemplateDtoGen = {
        content: this.updateTemplateBody
      };

      if (this.partnerId) {
        await PartnerTemplateModule.updateTemplate({
          partnerId: this.partnerId,
          key: this.templateKey,
          language: this.language,
          data: updateTemplateDto
        });
      } else {
        await AdminTemplateModule.updateTemplate({
          key: this.templateKey,
          language: this.language,
          data: updateTemplateDto
        });
      }
      this.close();
    } catch (e) {
      this.$log.error(e);
      this.$toast.error((e as any).message);
    } finally {
      this.loading = false;
    }
  }
}
