var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "card",
        {
          staticClass: "ma-2 mb-4",
          attrs: {
            outlined: "",
            "min-width": "250px",
            flat: "",
            title: _vm.$t("components.partner.UserAdministrationGhgCard.title")
          }
        },
        _vm._l(_vm.actions, function(action, i) {
          return _c(
            "div",
            {
              key: i
            },
            [
              _c("v-subheader", [_vm._v(_vm._s(_vm.$t(action.title)))]),
              _vm._l(action.actions, function(btn, i2) {
                return _c(
                  "span",
                  {
                    key: i2
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "ml-4 mb-4",
                        attrs: {
                          color: "info",
                          outlined: "",
                          small: ""
                        },
                        on: {
                          click: function click($event) {
                            return btn.action()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t(btn.btnText)))]
                    )
                  ],
                  1
                )
              }),
              action.divider ? _c("v-divider") : _vm._e()
            ],
            2
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }