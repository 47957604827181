























import { Component, Vue, Prop } from "vue-property-decorator";
import ManufacturerDeleteDialog from "@/components/docphant/dashboard/ManufacturerDeleteDialog.vue";
import ManufacturerUpdateDialog from "@/components/docphant/dashboard/ManufacturerUpdateDialog.vue";
import { IManufacturer } from "@/lib/interfaces/Docphant/manufacturer.schema.interface";

@Component({
  components: {
    ManufacturerDeleteDialog,
    ManufacturerUpdateDialog
  }
})
export default class ManufacturerDetailCard extends Vue {
  @Prop({})
  manufacturer!: IManufacturer;
}
