var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "span",
    [
      _c(
        "v-btn",
        {
          staticClass: "mx-2",
          attrs: {
            loading: _vm.isLoading,
            disabled: _vm.isLoading,
            outlined: "",
            "x-small": "",
            dense: "",
            color: "info"
          },
          on: {
            click: _vm.init
          }
        },
        [
          _c(
            "span",
            {
              staticClass: "ma-2"
            },
            [_vm._v(" edit ")]
          )
        ]
      ),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            supressKeyboardActions: true,
            title: _vm.$t(
              "partner.PartnerReportDetailChangeProgress.confirmEditTitle"
            ),
            isDialogActive: _vm.isDialogActive,
            rightLoading: _vm.isLoading
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isDialogActive = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isDialogActive = $event
            },
            leftClick: function leftClick($event) {
              _vm.isDialogActive = false
            },
            rightClick: _vm.update
          }
        },
        [
          _c("v-text-field", {
            attrs: {
              outlined: "",
              label: "Q"
            },
            model: {
              value: _vm.dto.question,
              callback: function callback($$v) {
                _vm.$set(_vm.dto, "question", $$v)
              },
              expression: "dto.question"
            }
          }),
          _c("v-textarea", {
            attrs: {
              outlined: "",
              label: "A"
            },
            model: {
              value: _vm.dto.answer,
              callback: function callback($$v) {
                _vm.$set(_vm.dto, "answer", $$v)
              },
              expression: "dto.answer"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }