var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "tooltip",
    {
      attrs: {
        text: _vm.faq.isActive
          ? _vm.$t("partner.PartnerFaqToggleActive.active")
          : _vm.$t("partner.PartnerFaqToggleActive.disabled")
      }
    },
    [
      _c(
        "v-btn",
        {
          staticClass: "mx-2",
          attrs: {
            outlined: "",
            "x-small": "",
            dense: "",
            loading: _vm.isLoading,
            disabled: _vm.isLoading,
            color: _vm.faq.isActive ? "success" : "warning"
          },
          on: {
            click: _vm.update
          }
        },
        [
          _vm.faq.isActive
            ? _c(
                "div",
                {
                  staticClass: "ma-2"
                },
                [_vm._v("Active")]
              )
            : _c(
                "div",
                {
                  staticClass: "ma-2"
                },
                [_vm._v("Inactive")]
              )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }