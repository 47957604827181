var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-logo",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            {
              attrs: {
                align: "center"
              }
            },
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    md: "12"
                  }
                },
                [
                  _c("v-skeleton-loader", {
                    staticClass: "mx-auto",
                    attrs: {
                      type:
                        "image, card-heading, list-item-three-line,  card-heading, list-item-three-line, card-heading, list-item-three-line, card-heading, table, actions, divider, image"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }