


































import AnalyticsDataTableTimeSeriesDialog from "@/components/analytics/AnalyticsDataTableTimeSeriesDialog.vue";
import AnalyticsKpiColumn from "@/components/analytics/AnalyticsKpiColumn.vue";
import { IKpi } from "@/lib/interfaces/kpi-interface";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ConfigModule } from "@/store/modules/config";
import { EChartsOption } from "echarts/types/dist/shared";
import { Component, Prop, Watch } from "vue-property-decorator";
import Card from "../utility/Card.vue";

/**
 * Series data options. Some of this properties are already in the typings of ECharts
 * like @see SeriesOption$1 (imported from echarts/types/dist/shared)
 * Others like `symbol` are not in the type but are valid options.
 * Currently only supported for `type` is "line" and for `symbol` is "none".
 * Add more options like so:
 * OLD:
 * type: "line"
 * NEW
 * type: "line" | "line2" | "line3" etc
 */
export type SeriesLineChartOptions = {
  name: string;
  type: "line"; // type of chart displayed
  symbol: "none"; // should it add a symbol on the data points or display a smooth line.
  data: number[];
};
@Component({
  components: { AnalyticsKpiColumn, Card, AnalyticsDataTableTimeSeriesDialog }
})
export default class TimeSeriesLineChartCard extends DarkModeHighlightMixin {
  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: "" })
  title!: string;

  @Prop({ default: false })
  showSwitch!: boolean;

  @Prop({ default: "" })
  switchTitle!: string;

  @Prop({ default: 1 })
  yAxixFactor!: number;

  @Prop({ default: "" })
  yAxixAppendix!: string;

  @Prop({ default: () => [] })
  data!: SeriesLineChartOptions;

  @Prop({
    default() {
      return [];
    }
  })
  kpiGroup!: IKpi[];

  displayAverageTrend = false;

  get shopwKpiGroup(): boolean {
    return this.kpiGroup.length != 0;
  }

  get chartOptions(): EChartsOption {
    return this.createEchartOtpions(this.data);
  }

  @Watch("displayAverageTrend")
  emitAverageTrend() {
    this.$emit("update:displayAverageTrend", this.displayAverageTrend);
  }

  kpiColor(i: number) {
    return ConfigModule.color.analyticsColors[i];
  }

  createEchartOtpions(series: SeriesLineChartOptions): EChartsOption {
    const yAxixFactor = this.yAxixFactor;
    const yAxixAppendix = this.yAxixAppendix;
    const options = {
      color: ConfigModule.color.analyticsColors,
      tooltip: {
        trigger: "axis"
      },
      xAxis: {
        type: "time",

        boundaryGap: false,
        axisLabel: {
          interval: 0,
          rotate: 45
        }
      },
      yAxis: {
        type: "value",
        boundaryGap: false,
        axisLabel: {
          formatter: function(value: number): string {
            return value / yAxixFactor + yAxixAppendix;
          }
        }
      },
      useUTC: false,
      series: series
    };

    return options as EChartsOption;
  }
}
