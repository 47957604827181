


















































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgTemplateMetaViewModelGen } from "@/services/thg/v1/data-contracts";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import LabelComponent from "../utility/LabelComponent.vue";
import { getFlagEmojiByLanguage } from "@/lib/CountryCodeHelper";
import { ConfigModule } from "@/store/modules/config";

@Component({
  components: {
    Card,
    LabelComponent
  },
  filters: { getFlagEmojiByLanguage }
})
export default class TemplateDetailMetaFields extends mixins(DarkModeHighlightMixin) {
  readonly availableLanguages = ConfigModule.availableLanguages;

  @Prop()
  partnerId!: string;

  @Prop({ default: true })
  showLanguage!: string;

  @Prop()
  meta!: ThgTemplateMetaViewModelGen;
  get _meta() {
    return this.meta;
  }
  set _meta(meta: ThgTemplateMetaViewModelGen) {
    this.$emit("update:meta", meta);
  }

  @Prop()
  isPublic!: boolean;
  get _isPublic() {
    return this.isPublic;
  }
  set _isPublic(isPublic: boolean) {
    this.$emit("update:isPublic", isPublic);
  }
}
