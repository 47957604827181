





























import { MessageContentFormatEnum } from "@/lib/enum/templateEnums/messageContentFormat.enum";
import { TemplateEditorEnum } from "@/lib/enum/templateEnums/tempalteEditor.enum";
import { ConflictException } from "@/lib/exceptions/http";
import { requiredRule } from "@/lib/rules/requiredRule";
import { convertToKey } from "@/lib/utility/convertToKey";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import {
  MrfiktivCreateAdminTemplateDtoGen,
  MrfiktivCreatePartnerTemplateDtoGen,
  MrfiktivCreateTemplateContentDtoGen,
  MrfiktivCreateTemplateMetaDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { ThgCreateAdminTemplateDtoGen } from "@/services/thg/v1/data-contracts";
import { AdminTemplateModule } from "@/store/modules/admin-template.store";
import { PartnerTemplateModule } from "@/store/modules/partner-template.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import TemplateDetailMetaFields from "./TemplateDetailMetaFields.vue";
import { LanguageCodeEnum } from "@/lib/enum/language-code.enum";

@Component({
  components: {
    ConfirmActionDialog,
    TemplateDetailMetaFields
  }
})
export default class TemplateDetailCreateDialog extends mixins(PermissionMixin, DarkModeHighlightMixin) {
  @Prop()
  partnerId!: string;

  isValid = false;

  createDialog = false;

  loading = false;

  valid = false;

  isPublic = false;

  createTemplateBody: MrfiktivCreateTemplateContentDtoGen = {
    subject: " ",
    body: " "
  };
  templateMetaDto: MrfiktivCreateTemplateMetaDtoGen = {
    categories: [],
    description: "",
    editor: TemplateEditorEnum.TIPTAP,
    contentFormat: MessageContentFormatEnum.HTML,
    language: LanguageCodeEnum.DE,
    title: ""
  };

  get MessageContentFormatEnum() {
    return MessageContentFormatEnum;
  }

  get rules() {
    return [requiredRule()];
  }

  async create() {
    this.loading = true;
    const adminTemplate: MrfiktivCreateAdminTemplateDtoGen = {
      content: this.createTemplateBody,
      meta: this.templateMetaDto,
      isPublic: this.isPublic
    };
    const partnerTemplate: MrfiktivCreatePartnerTemplateDtoGen = {
      content: this.createTemplateBody,
      meta: this.templateMetaDto
    };

    try {
      let template:
        | MrfiktivCreateAdminTemplateDtoGen
        | ThgCreateAdminTemplateDtoGen
        | MrfiktivCreatePartnerTemplateDtoGen;
      if (this.partnerId) {
        template = await PartnerTemplateModule.createTemplate({ partnerId: this.partnerId, data: partnerTemplate });
      } else {
        template = await AdminTemplateModule.createTemplate(adminTemplate);
      }
      this.$toast.success("👍");

      if (!this.partnerId) {
        this.$router.push({
          name: "TemplateDetailView",
          params: { key: convertToKey(template.meta.title) },
          query: { language: this.templateMetaDto.language }
        });
      } else {
        this.$router.push({
          name: "TemplateDetailPartnerView",
          params: { key: convertToKey(template.meta.title), partnerId: this.partnerId },
          query: { language: this.templateMetaDto.language }
        });
      }

      this.createDialog = false;
    } catch (e) {
      this.$log.error(e);
      this.$toast((e as any).message);
      if (e instanceof ConflictException) {
        this.$toast.error("Key taken");

        return;
      }
    } finally {
      this.loading = false;
    }
  }
}
