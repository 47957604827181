import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { BackendResourceEnum, ResourceEnum } from "@/store/enum/authResourceEnum";
import { PaginatedBaseStore } from "@/store/paginated-base.store";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module } from "vuex-module-decorators";
import { PaginationFilterListElement } from "./base-pagination.store";
import { ReportPaginationModule } from "./report-pagination.store";

import { EventUIDto, IEventUIDto } from "@/lib/dto/event/event-ui.dto";
import { IReport } from "@/models/report.entity";
import { ISignDocument, SignDocument } from "@/models/sign-document.entity";
import { ISignRequest, SignRequest } from "@/models/sign-request.entity";
import { ISnapshot, Snapshot } from "@/models/snapshot.entity";
import { ITicket, Ticket } from "@/models/ticket.entity";
import { IVehicle, Vehicle } from "@/models/vehicle.entity";

import { EventPageDataProvider } from "./page-data-provider/event.page-data-provider";
import { ReportPageDataProvider } from "./page-data-provider/report.page-data-provider";
import { SignDocumentPageDataProvider } from "./page-data-provider/sign-document.page-data-provider";
import { SignRequestPageDataProvider } from "./page-data-provider/sign-request.page-data-provider";
import { TicketPageDataProvider } from "./page-data-provider/ticket.page-data-provider";
import { VehiclePageDataProvider } from "./page-data-provider/vehicle.page-data-provider";
import { PartnerImagePageDataProvider } from "./page-data-provider/partner-image.page-data-provider";
import { PartnerMessagePageDataProvider } from "./page-data-provider/partner-message.page-data-provider";

import { EventDataAccessLayer } from "./access-layers/event.access-layer";
import { ReportDataAccessLayer } from "./access-layers/report.access-layer";
import { SignDocumentDataAccessLayer } from "./access-layers/sign-document.access-layer";
import { SignRequestDataAccessLayer } from "./access-layers/sign-request.access-layer";
import { HandoverSnapshotDataAccessLayer } from "./access-layers/snapshot.access-layer";
import { TicketDataAccessLayer } from "./access-layers/ticket.access-layer";
import { VehicleAccessLayer } from "./access-layers/vehicle.access-layer";
import { ActivityLogDataAccessLayer } from "./access-layers/activity-log-service.access-layer";
import { PartnerImageDataAccessLayer } from "./access-layers/partner-image.access-layer";
import { PartnerMessageDataAccessLayer } from "./access-layers/partner-message.access-layer";

import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { ActivityLog } from "@/models/activity-log.entity";
import { IPageFilterElement, PageFilterElement } from "@/models/page-filter-element.entity";
import { IPartnerMessage, PartnerMessage } from "@/models/partner-message.entity";
import { TimestampDocument } from "@/models/timestamp.entity";
import {
  MrfiktivAttachmentRequestControllerGetAllForPartnerParamsGen,
  MrfiktivCompanyControllerGetParamsGen,
  MrfiktivCompanyGroupControllerGetParamsGen,
  MrfiktivCostControllerGetParamsGen,
  MrfiktivCreateActivityLogDtoGen,
  MrfiktivEventControllerGetParamsGen,
  MrfiktivPartnerImageViewModelGen,
  MrfiktivPartnerMessageControllerFindAllByPartnerIdParamsGen,
  MrfiktivReportControllerFindAllParamsGen,
  MrfiktivTicketControllerGetParamsGen,
  MrfiktivVehicleControllerGetAllParamsGen
} from "@/services/mrfiktiv/v1/data-contracts";
import {
  SignDocumentControllerFindAllParamsGen,
  SignSignRequestControllerFindAllParamsGen
} from "@/services/sign/v1/data-contracts";
import { BaseStore } from "../base.store";
import { AttachmentRequestPageDataProvider } from "./attachment-request.store";
import { AttachmentRequestDataAccessLayer } from "./access-layers/attachment-request.access-layer";
import { AttachmentRequest, IAttachmentRequest } from "@/models/attachment-request.entity";
import { ActionEnum } from "../enum/authActionEnum";
import { IPartnerMessageStore } from "./inbox-message.store";
import { UserModule } from "./me-user.store";
import { Cost, ICost } from "@/models/cost.entity";
import { CostDataAccessLayer } from "./access-layers/cost.access-layer";
import { CostPageDataProvider } from "./page-data-provider/cost.page-data-provider";
import { Company, ICompany } from "@/models/company.entity";
import { CompanyGroup, ICompanyGroup } from "@/models/company-group.entity";
import { CompanyPageDataProvider } from "./page-data-provider/company.page-data-provider";
import { CompanyDataAccessLayer } from "./access-layers/company.access-layer";
import { CompanyGroupDataAccessLayer } from "./access-layers/company-group.access-layer";
import { CompanyGroupPageDataProvider } from "./page-data-provider/company-group.page-data-provider.ts";

@Module({
  dynamic: true,
  namespaced: true,
  name: "refs-vehicle",
  store
})
class RefsVehicleStore extends PaginatedBaseStore<IVehicle, MrfiktivVehicleControllerGetAllParamsGen> {
  _data = VehicleAccessLayer;
  _pageProvider = VehiclePageDataProvider;
  _pager = new PageDataHandler(this._data, this._pageProvider);
  filterOptions: PaginationFilterListElement[] = Vehicle.filterables;
}

/**
 * wraps the timed event endpoint into a makeshift event pagination @see RefTypeMap
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "refs-event",
  store
})
class RefsEventStore extends PaginatedBaseStore<IEventUIDto, MrfiktivEventControllerGetParamsGen> {
  protected _data = EventDataAccessLayer;
  protected _pageProvider = EventPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = EventUIDto.filterables;

  @Action
  addToList(document: IEventUIDto): void {
    this._data.set(document);
  }
}

/**
 * wraps the timed event endpoint into a makeshift event pagination @see RefTypeMap
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "refs-report",
  store
})
class RefsReportStore extends PaginatedBaseStore<IReport, MrfiktivReportControllerFindAllParamsGen> {
  protected _data = ReportDataAccessLayer;
  protected _pageProvider = ReportPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = ReportPaginationModule.filterOptions;

  @Action
  addToList(document: IReport): void {
    this._data.set(document);
  }
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "refs-ticket",
  store
})
export class RefsTicketStore extends PaginatedBaseStore<ITicket, MrfiktivTicketControllerGetParamsGen> {
  _data = TicketDataAccessLayer;
  _pageProvider = TicketPageDataProvider;
  _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = Ticket.filterables;
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "refs-sign-document",
  store
})
export class RefsSignDocumentStore extends PaginatedBaseStore<ISignDocument, SignDocumentControllerFindAllParamsGen> {
  _data = SignDocumentDataAccessLayer;
  _pageProvider = SignDocumentPageDataProvider;
  _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = SignDocument.filterables;
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "refs-snapshot",
  store
})
export class RefsSnapshotStore extends BaseStore<ISnapshot> {
  _data = HandoverSnapshotDataAccessLayer;
  _pageProvider = SignDocumentPageDataProvider;

  filterOptions: PaginationFilterListElement[] = Snapshot.filterables;
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "refs-sign-request",
  store
})
export class RefsSignRequestStore extends PaginatedBaseStore<ISignRequest, SignSignRequestControllerFindAllParamsGen> {
  _data = SignRequestDataAccessLayer;
  _pageProvider = SignRequestPageDataProvider;
  _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = SignRequest.filterables;
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "refs-attachment-request",
  store
})
export class RefsAttachmentRequestStore extends PaginatedBaseStore<
  IAttachmentRequest,
  MrfiktivAttachmentRequestControllerGetAllForPartnerParamsGen
> {
  _data = AttachmentRequestDataAccessLayer;
  _pageProvider = new AttachmentRequestPageDataProvider();
  _pager = new PageDataHandler<IAttachmentRequest, MrfiktivAttachmentRequestControllerGetAllForPartnerParamsGen>(
    this._data,
    this._pageProvider
  );

  filterOptions: PaginationFilterListElement[] = AttachmentRequest.filterables;
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "refs-message-store",
  store
})
export class RefsMessagesStore
  extends PaginatedBaseStore<IPartnerMessage, MrfiktivPartnerMessageControllerFindAllByPartnerIdParamsGen>
  implements IPartnerMessageStore {
  _data = PartnerMessageDataAccessLayer;
  _pageProvider = PartnerMessagePageDataProvider;
  _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = PartnerMessage.filterables;

  @Action
  async generateFrontendTimeLineElementsForMessagesAssociatedWithReference({
    refId,
    partnerId
  }: {
    refId: string;
    partnerId: string;
  }): Promise<void> {
    await this._generateTimeLineMessagesForFilter({
      partnerId,
      refId: refId,
      refType: BackendResourceEnum.REPORT,
      filters: [new PageFilterElement({ value: refId, key: "refs.refId", operation: PageFilterOperationEnum.EQUAL })]
    });
  }

  @Action
  async generateTimeLineMessagesForUser(data: {
    id: string;
    userName: string;
    contact?: { email: string };
    partnerId: string;
    resource: BackendResourceEnum.USER | BackendResourceEnum.CUSTOMER_DATA;
  }) {
    if (!data?.userName) {
      throw new Error("no user");
    }
    await this.generateTimeLineMessagesForMail({
      refId: data.id,
      refType: data.resource,
      mail: data.userName,
      partnerId: data.partnerId
    });

    if (data.contact?.email && data.contact?.email !== data.userName) {
      await this.generateTimeLineMessagesForMail({
        refId: data.id,
        refType: data.resource,
        mail: data.contact.email,
        partnerId: data.partnerId
      });
    }
  }

  @Action
  async generateTimeLineMessagesForMail(data: {
    refId: string;
    refType: BackendResourceEnum;
    partnerId: string;
    mail: string;
  }): Promise<void> {
    await this._generateTimeLineMessagesForFilter({
      ...data,
      filters: [new PageFilterElement({ value: data.mail, key: "from", operation: PageFilterOperationEnum.EQUAL })]
    });

    await this._generateTimeLineMessagesForFilter({
      ...data,
      filters: [new PageFilterElement({ value: data.mail, key: "to", operation: PageFilterOperationEnum.EQUAL })]
    });
  }

  @Action
  private async _generateTimeLineMessagesForFilter({
    refId,
    refType,
    partnerId,
    filters
  }: {
    refId: string;
    refType: BackendResourceEnum;
    partnerId: string;
    filters: IPageFilterElement[];
  }): Promise<void> {
    if (!UserModule.abilities.can(ActionEnum.READ, BackendResourceEnum.MESSAGE, partnerId)) {
      return;
    }

    this.setFilters(filters);
    for (const message of this.filtered) {
      this.createLocalActivityLogForMessage({
        refType,
        refId,
        partnerId,
        message
      });
    }

    await this.fetchFirstPage({ partnerId });
    for (const message of this.filtered) {
      this.createLocalActivityLogForMessage({
        refType,
        refId,
        partnerId,
        message
      });
    }
  }

  @Action
  private createLocalActivityLogForMessage({
    refType,
    refId,
    partnerId,
    message
  }: {
    refType: BackendResourceEnum;
    refId: string;
    partnerId: string;
    message: IPartnerMessage;
  }) {
    const data: MrfiktivCreateActivityLogDtoGen = {
      source: { refType: refType, refId: refId },
      target: [{ refType: BackendResourceEnum.MESSAGE, refId: message.id }],
      actionType: ActionEnum.CREATE,
      activity: ActivityTypeEnum.MESSAGE
    };
    const activityLog = new ActivityLog({
      ...data,
      id: refType + message.id,
      partnerId: partnerId,
      timestamp: new TimestampDocument(message.timestamp),
      userId: message.userId,
      isDeletable: false
    });

    ActivityLogDataAccessLayer.set(activityLog);
  }
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "refs-image-store",
  store
})
export class RefsImageStore extends PaginatedBaseStore<
  MrfiktivPartnerImageViewModelGen,
  MrfiktivReportControllerFindAllParamsGen
> {
  _data = PartnerImageDataAccessLayer;
  _pageProvider = PartnerImagePageDataProvider;
  _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = [];
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "refs-cost-store",
  store
})
export class RefsCostStore extends PaginatedBaseStore<ICost, MrfiktivCostControllerGetParamsGen> {
  protected _data = CostDataAccessLayer;
  protected _pageProvider = CostPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = Cost.filterables;
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "refs-company-store",
  store
})
export class RefsCompanyStore extends PaginatedBaseStore<ICompany, MrfiktivCompanyControllerGetParamsGen> {
  protected _data = CompanyDataAccessLayer;
  protected _pageProvider = CompanyPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = Company.filterables;
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "refs-company-group-store",
  store
})
export class RefsCompanyGroupStore extends PaginatedBaseStore<
  ICompanyGroup,
  MrfiktivCompanyGroupControllerGetParamsGen
> {
  protected _data = CompanyGroupDataAccessLayer;
  protected _pageProvider = CompanyGroupPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = CompanyGroup.filterables;
}

export const RefsVehicleModule = getModule(RefsVehicleStore);
export const RefsEventModule = getModule(RefsEventStore);
export const RefsReportModule = getModule(RefsReportStore);
export const RefsTicketModule = getModule(RefsTicketStore);
export const RefsSignDocumentModule = getModule(RefsSignDocumentStore);
export const RefsSnapshotModule = getModule(RefsSnapshotStore);
export const RefsSignRequestModule = getModule(RefsSignRequestStore);
export const RefAttachmentRequestModule = getModule(RefsAttachmentRequestStore);
export const RefsImageModule = getModule(RefsImageStore);
export const RefsMessagesModule = getModule(RefsMessagesStore);
export const RefsCostModule = getModule(RefsCostStore);
export const RefsCompanyModule = getModule(RefsCompanyStore);
export const RefsCompanyGroupModule = getModule(RefsCompanyGroupStore);

/**
 * Maps reference types to their respective modules and icons
 */
export const RefTypeMap: Map<
  ResourceEnum,
  { module: PaginatedBaseStore<any, any> | BaseStore<ISnapshot>; icon: string }
> = (() => {
  const map = new Map();
  map.set(ResourceEnum.VEHICLE, { module: RefsVehicleModule, icon: "mdi-car" });
  map.set(ResourceEnum.REPORT, {
    module: RefsReportModule,
    icon: "mdi-wrench-outline"
  });
  map.set(ResourceEnum.EVENT, { module: RefsEventModule, icon: "mdi-calendar" });
  map.set(ResourceEnum.TICKET, {
    module: RefsTicketModule,
    icon: "mdi-ticket-outline"
  });
  map.set(ResourceEnum.DOCUMENT, {
    module: RefsSignDocumentModule,
    icon: "mdi-file-multiple-outline"
  });
  map.set(ResourceEnum.SNAPSHOT, {
    module: RefsSnapshotModule,
    icon: "mdi-clipboard-text-outline"
  });
  map.set(ResourceEnum.SIGN, {
    module: RefsSignRequestModule,
    icon: "mdi-signature"
  });
  map.set(ResourceEnum.ATTACHMENT_REQUEST, {
    module: RefAttachmentRequestModule,
    icon: "mdi-paperclip"
  });
  map.set(ResourceEnum.IMAGE, {
    module: RefsImageModule,
    icon: "mdi-image"
  });
  map.set(ResourceEnum.MESSAGE, {
    module: RefsMessagesModule,
    icon: "mdi-email-outline"
  });
  map.set(ResourceEnum.COST, {
    module: RefsCostModule,
    icon: "mdi-cash-multiple"
  });
  map.set(ResourceEnum.COMPANY, {
    module: RefsCompanyModule,
    icon: "mdi-factory"
  });
  map.set(ResourceEnum.COMPANY_GROUP, {
    module: RefsCompanyGroupModule,
    icon: "mdi-factory"
  });

  map.set("", { module: undefined, icon: "mdi-progress-question" });

  return map;
})();
