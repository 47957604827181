var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                {
                  attrs: {
                    height: "200",
                    outlined: "",
                    width: "200"
                  }
                },
                [
                  _vm._v(" Logo "),
                  _c(
                    "v-card-text",
                    {
                      staticClass: "text-center justify-center"
                    },
                    [
                      _c("v-img", {
                        attrs: {
                          "max-height": "130",
                          contain: "",
                          src: _vm.logoUrl
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c(
                "v-card",
                {
                  attrs: {
                    height: "200",
                    outlined: "",
                    width: "200"
                  }
                },
                [
                  _vm._v(" Header Foto "),
                  _c(
                    "v-card-text",
                    {
                      staticClass: "text-center justify-center"
                    },
                    [
                      _c("v-img", {
                        attrs: {
                          "max-height": "130",
                          contain: "",
                          src: _vm.headerImageUrl
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c(
                "v-card",
                {
                  attrs: {
                    height: "200",
                    outlined: "",
                    width: "200"
                  }
                },
                [
                  _vm._v(" Favicon "),
                  _c(
                    "v-card-text",
                    {
                      staticClass: "text-center justify-center"
                    },
                    [
                      _c("v-img", {
                        attrs: {
                          "max-height": "130",
                          contain: "",
                          src: _vm.favIconUrl
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }