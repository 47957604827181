
























import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { Project } from "@/models/project.entity";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import ProjectForm from "./ProjectForm.vue";
import { PartnerModule } from "@/store/modules/partner";

@Component({
  components: {
    ConfirmActionDialog,
    ProjectForm
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class ProjectCreateDialog extends mixins(PermissionMixin) {
  dialogActive = false;

  isLoading = false;

  isValid = false;

  project = new Project({ partnerId: PartnerModule.partner._id });

  /**
   * Method to create project
   * @param {Project} project the ticket to create
   */
  async createProject() {
    try {
      this.isLoading = true;

      if (!this.project) {
        return;
      }

      await this.project.create();

      this.$toast.success("👍");

      this.dialogActive = false;

      this.$emit("created", this.project);

      // Reset project
      this.project = new Project({ partnerId: PartnerModule.partner._id });
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
