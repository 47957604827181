var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _c(
        "v-container",
        [
          _c("m-header", {
            attrs: {
              title: _vm.$t("sign.DocumentTable.title"),
              actions: _vm.actions,
              breadCrumbs: _vm.breadCrumbs,
              chips: _vm.chips
            },
            on: {
              actionClicked: function actionClicked($event) {
                return $event.exec()
              }
            }
          }),
          _c(
            "v-tabs",
            {
              class: _vm.isMobile ? "" : "pl-2",
              model: {
                value: _vm.tab,
                callback: function callback($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _c("v-tabs-slider", {
                attrs: {
                  color: "yellow"
                }
              }),
              _vm._l(_vm.items, function(tab, index) {
                return _c(
                  "v-tab",
                  {
                    key: tab,
                    on: {
                      click: function click($event) {
                        return _vm.setTab(tab)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t(_vm.items[index])) + " ")]
                )
              })
            ],
            2
          ),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.tab,
                callback: function callback($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _vm.$can(_vm.ActionEnum.READ, _vm.ResourceEnum.SIGN)
                ? _c(
                    "v-tab-item",
                    [
                      !_vm.partner.id
                        ? _c("v-skeleton-loader", {
                            attrs: {
                              type: "table"
                            }
                          })
                        : _c("sign-request-table")
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$can(_vm.ActionEnum.READ, _vm.ResourceEnum.DOCUMENT)
                ? _c(
                    "v-tab-item",
                    {
                      attrs: {
                        eager: true
                      }
                    },
                    [
                      !_vm.partner.id
                        ? _c("v-skeleton-loader", {
                            attrs: {
                              type: "table"
                            }
                          })
                        : _c("document-table")
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }