var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-dialog",
    {
      attrs: {
        width: "600px"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function fn(_ref) {
            var on = _ref.on,
              attrs = _ref.attrs
            return [
              _c("v-btn", _vm._g(_vm._b({}, "v-btn", attrs, false), on), [
                _vm._v(" Hinzufügen ")
              ])
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function callback($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c(
              "span",
              {
                staticClass: "title"
              },
              [_vm._v("Neues Dokument anlegen.")]
            )
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                [
                  _c("v-text-field", {
                    attrs: {
                      rules: _vm.rules,
                      type: "text",
                      label: "Maschinenname *",
                      outlined: "",
                      required: ""
                    },
                    model: {
                      value: _vm.machine.machineName,
                      callback: function callback($$v) {
                        _vm.$set(_vm.machine, "machineName", $$v)
                      },
                      expression: "machine.machineName"
                    }
                  }),
                  _c("v-autocomplete", {
                    attrs: {
                      rules: _vm.rules,
                      type: "text",
                      items: _vm.machineTypes,
                      label: "Typ *",
                      outlined: "",
                      required: ""
                    },
                    model: {
                      value: _vm.machine.machineType,
                      callback: function callback($$v) {
                        _vm.$set(_vm.machine, "machineType", $$v)
                      },
                      expression: "machine.machineType"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      rules: _vm.rules,
                      type: "text",
                      label: "Variante *",
                      outlined: "",
                      required: ""
                    },
                    model: {
                      value: _vm.machine.variant,
                      callback: function callback($$v) {
                        _vm.$set(_vm.machine, "variant", $$v)
                      },
                      expression: "machine.variant"
                    }
                  }),
                  _c("v-textarea", {
                    attrs: {
                      rules: _vm.rules,
                      type: "text",
                      label: "Beschreibung *",
                      outlined: "",
                      required: ""
                    },
                    model: {
                      value: _vm.machine.description,
                      callback: function callback($$v) {
                        _vm.$set(_vm.machine, "description", $$v)
                      },
                      expression: "machine.description"
                    }
                  }),
                  _c("v-autocomplete", {
                    attrs: {
                      rules: _vm.rules,
                      items: _vm.weightUnits,
                      type: "text",
                      label: "Gewicht Einheit *",
                      outlined: "",
                      required: ""
                    },
                    model: {
                      value: _vm.machine.weight.unit,
                      callback: function callback($$v) {
                        _vm.$set(_vm.machine.weight, "unit", $$v)
                      },
                      expression: "machine.weight.unit"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      rules: _vm.rules,
                      type: "number",
                      label: "Gewicht *",
                      suffix: _vm.machine.weight.unit,
                      outlined: "",
                      required: ""
                    },
                    model: {
                      value: _vm.machine.weight.amount,
                      callback: function callback($$v) {
                        _vm.$set(_vm.machine.weight, "amount", _vm._n($$v))
                      },
                      expression: "machine.weight.amount"
                    }
                  }),
                  _c("v-autocomplete", {
                    attrs: {
                      rules: _vm.rules,
                      items: _vm.dimensionUnits,
                      type: "text",
                      label: "Dimension Einheit *",
                      outlined: "",
                      required: ""
                    },
                    model: {
                      value: _vm.machine.dimension.unit,
                      callback: function callback($$v) {
                        _vm.$set(_vm.machine.dimension, "unit", $$v)
                      },
                      expression: "machine.dimension.unit"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      rules: _vm.rules,
                      type: "number",
                      label: "Höhe *",
                      suffix: _vm.machine.dimension.unit,
                      outlined: "",
                      required: ""
                    },
                    model: {
                      value: _vm.machine.dimension.height,
                      callback: function callback($$v) {
                        _vm.$set(_vm.machine.dimension, "height", _vm._n($$v))
                      },
                      expression: "machine.dimension.height"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      rules: _vm.rules,
                      type: "number",
                      label: "Breite *",
                      suffix: _vm.machine.dimension.unit,
                      outlined: "",
                      required: ""
                    },
                    model: {
                      value: _vm.machine.dimension.width,
                      callback: function callback($$v) {
                        _vm.$set(_vm.machine.dimension, "width", _vm._n($$v))
                      },
                      expression: "machine.dimension.width"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      rules: _vm.rules,
                      type: "number",
                      label: "Tiefe *",
                      suffix: _vm.machine.dimension.unit,
                      outlined: "",
                      required: ""
                    },
                    model: {
                      value: _vm.machine.dimension.depth,
                      callback: function callback($$v) {
                        _vm.$set(_vm.machine.dimension, "depth", _vm._n($$v))
                      },
                      expression: "machine.dimension.depth"
                    }
                  }),
                  _c("v-autocomplete", {
                    attrs: {
                      rules: _vm.rules,
                      items: _vm.machineStati,
                      type: "text",
                      label: "Status *",
                      outlined: "",
                      required: ""
                    },
                    model: {
                      value: _vm.machine.status,
                      callback: function callback($$v) {
                        _vm.$set(_vm.machine, "status", $$v)
                      },
                      expression: "machine.status"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    text: ""
                  },
                  on: {
                    click: _vm.closeUpdate
                  }
                },
                [_vm._v(" Abbrechen ")]
              ),
              _c(
                "v-btn",
                {
                  on: {
                    click: _vm.createMachine
                  }
                },
                [_vm._v(" Speichern ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }