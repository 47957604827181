var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-card-title",
        {
          staticClass: "title",
          staticStyle: {
            "word-break": "break-word"
          }
        },
        [
          _c("h3", [
            _vm._v(
              _vm._s(_vm.machine.machineName) +
                " (" +
                _vm._s(_vm.machine.variante) +
                ")"
            )
          ])
        ]
      ),
      _c(
        "v-card-text",
        {
          staticClass: "content"
        },
        [
          _vm._v(" " + _vm._s(_vm.machine.description) + " "),
          _c("br"),
          _vm._v(" Typ: " + _vm._s(_vm.type) + " "),
          _c("br"),
          _vm._v(" Status: " + _vm._s(_vm.status) + " "),
          _c("br"),
          _c(
            "v-row",
            {
              staticClass: "pt-3 pl-3 pr-3",
              staticStyle: {
                "font-weight": "500"
              }
            },
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "3",
                    align: "center"
                  }
                },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: {
                        top: ""
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function fn(_ref) {
                            var on = _ref.on
                            return [
                              _c(
                                "v-icon",
                                _vm._g(
                                  {
                                    staticClass: "icon"
                                  },
                                  on
                                ),
                                [_vm._v(" mdi-weight ")]
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v("Gewicht")])]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "mt-2"
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.machine.weight.amount) +
                          " " +
                          _vm._s(_vm.machine.weight.unit)
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "3",
                    align: "center"
                  }
                },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: {
                        top: ""
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function fn(_ref2) {
                            var on = _ref2.on
                            return [
                              _c(
                                "v-icon",
                                _vm._g(
                                  {
                                    staticClass: "icon",
                                    attrs: {
                                      id: "heightIcon"
                                    }
                                  },
                                  on
                                ),
                                [_vm._v("mdi-arrow-expand-horizontal")]
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v("Höhe")])]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "mt-2"
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.machine.dimension.height) +
                          _vm._s(_vm.machine.dimension.unit)
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "3",
                    align: "center"
                  }
                },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: {
                        top: ""
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function fn(_ref3) {
                            var on = _ref3.on
                            return [
                              _c(
                                "v-icon",
                                _vm._g(
                                  {
                                    staticClass: "icon",
                                    attrs: {
                                      id: "widthIcon"
                                    }
                                  },
                                  on
                                ),
                                [_vm._v("mdi-arrow-expand-horizontal")]
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v("Breite")])]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "mt-2"
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.machine.dimension.width) +
                          _vm._s(_vm.machine.dimension.unit)
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "3",
                    align: "center"
                  }
                },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: {
                        top: ""
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function fn(_ref4) {
                            var on = _ref4.on
                            return [
                              _c(
                                "v-icon",
                                _vm._g(
                                  {
                                    staticClass: "icon",
                                    attrs: {
                                      id: "depthIcon"
                                    }
                                  },
                                  on
                                ),
                                [_vm._v("mdi-arrow-expand-horizontal")]
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v("Tiefe")])]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "mt-2"
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.machine.dimension.depth) +
                          _vm._s(_vm.machine.dimension.unit)
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }