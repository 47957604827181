




















































import { Component, Prop } from "vue-property-decorator";
import { ThgPaginationModule } from "@/store/modules/thg-pagination.store";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ITableWrapperHeader } from "../utility/TableWrapper.vue";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { ThgStatusEnum } from "@/store/enum/thg/thg-status.enum";
import Tooltip from "../utility/tooltip.vue";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import { handleError } from "@/lib/utility/handleError";
import { thgStatusMap } from "@/lib/utility/thgStatusMap";
import ThgAdminQuickCheckoutDialog from "./ThgAdminQuickCheckoutDialog.vue";
import { IThg } from "@/models/thg.entity";
import { IAdminUser } from "@/models/user.entity";
import { IPageFilterElement, PageFilterElement } from "@/models/page-filter-element.entity";

@Component({
  components: {
    LatestEntriesCardEmpty,
    Tooltip,
    ThgAdminQuickCheckoutDialog
  }
})
export default class ThgUserTable extends DarkModeHighlightMixin {
  @Prop()
  selectedUser!: IAdminUser;

  readonly ThgStatusEnum = ThgStatusEnum;

  readonly UNDEFINED = "UNDEFINED";

  readonly thgStatusMap = thgStatusMap;

  get pagination() {
    return ThgPaginationModule;
  }

  get headers(): ITableWrapperHeader[] {
    const headers: ITableWrapperHeader[] = [];

    return headers;
  }

  /**
   * Get list of VINs from thgs
   */
  get vehicleIdentifiers() {
    const ids = new Set<string>();
    this.thgs.forEach(thg =>
      ids.add(thg.registration.identificationnumber || thg.numberplate || thg.registration.numberplate || "")
    );

    return Array.from(ids).sort((a, b) => (a < b ? -1 : 1));
  }

  get thgs() {
    return this.pagination.paginationList;
  }

  get filter(): IPageFilterElement[] {
    return [
      new PageFilterElement({
        key: "userId",
        operation: "$eq",
        value: this.selectedUser?._id ?? ""
      })
    ];
  }

  get currentYear() {
    return new Date().getFullYear();
  }

  get years() {
    const years: number[] = [];
    let year = 2022;
    while (year <= this.currentYear + 1) {
      years.push(year);
      year++;
    }

    return years;
  }

  mounted() {
    try {
      ThgPaginationModule.setFilter(this.filter);
      ThgPaginationModule.fetchAll({});
    } catch (e) {
      handleError(e);
    }
  }

  getThgs(identifier: string, year: number) {
    return this.getThgsForVehicle(identifier).filter(thg => thg.year === year);
  }

  getThgsForVehicle(identifier: string) {
    return this.thgs.filter(thg =>
      [thg.registration.identificationnumber, thg.numberplate, thg.registration.numberplate].includes(identifier)
    );
  }

  goToThgDetail(thg: IThg) {
    new GoToHelper(this.$router).goToThgDetail(thg.partnerId, thg.id, true);
  }
}
