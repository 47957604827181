var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-report",
    {
      attrs: {
        renderBackAndForthFooter: false,
        isProgressDone: true,
        showFooter: false
      }
    },
    [
      _c(
        "v-container",
        {
          staticClass: "padded"
        },
        [
          _c(
            "v-card",
            {
              attrs: {
                flat: ""
              }
            },
            [
              _c("v-card-title", [_c("h1", [_vm._v("Datenschutzerklärung")])]),
              _c("v-card-text", [
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Wir freuen uns, dass Sie unsere Web-Anwendung besuchen. Der Schutz und die Sicherheit Ihrer persönlichen Informationen bei der Nutzung unserer Website ist für uns sehr wichtig. Wir möchten Sie daher an dieser Stelle darüber informieren, welche Ihrer personenbezogenen Daten wir beim Besuch unserer Website erfassen und für welche Zwecke diese verwendet werden. Bei personenbezogenen Daten handelt es sich um Einzelangaben über persönliche oder sachliche Verhältnisse einer bestimmten oder bestimmbaren natürlichen Person (Betroffener), z. B. Name, Adresse, E-Mail-Adressen, Nutzerverhalten. Hierbei handelt es sich also um Daten, mit denen wir Sie identifizieren können. Darüber hinaus finden Sie hier vereinzelt auch Informationen zu Datenverarbeitungsprozessen außerhalb dieser Web-Anwendung (z. B. Videokonferenzen oder Newsletter)."
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c("b", [
                      _c(
                        "span",
                        {
                          attrs: {
                            lang: "DE"
                          }
                        },
                        [_vm._v("Verantwortlicher für die Datenverarbeitung")]
                      )
                    ])
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c("b", [
                      _c(
                        "span",
                        {
                          attrs: {
                            lang: "DE"
                          }
                        },
                        [_vm._v("Verantwortlicher")]
                      )
                    ])
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "für die Verarbeitung personenbezogener Daten im Sinne der EU-Datenschutz-Grundverordnung (DSGVO)"
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "EN-US"
                        }
                      },
                      [
                        _vm._v("mint future GmbH"),
                        _c("br"),
                        _vm._v(" Marie-Curie-Str. ")
                      ]
                    ),
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v("3"),
                        _c("br"),
                        _vm._v(" 49076 Osnabrück"),
                        _c("br"),
                        _vm._v(" DE"),
                        _c("br"),
                        _vm._v(" +49 541 96328788"),
                        _c("br"),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "mailto:info@mintfuture.de"
                            }
                          },
                          [_vm._v("info@mintfuture.de")]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c("b", [
                      _c(
                        "span",
                        {
                          attrs: {
                            lang: "DE"
                          }
                        },
                        [_vm._v("Datenschutzbeauftragte/r")]
                      )
                    ])
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v("exkulpa gmbh "),
                        _c("br"),
                        _vm._v(" Waldfeuchter Str. 266"),
                        _c("br"),
                        _vm._v(" 52525 Heinsberg"),
                        _c("br"),
                        _vm._v(" Telefon: 02452 / 99 33 11"),
                        _c("br"),
                        _vm._v(" E-Mail: "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "mailto:datenschutz@mintfuture.de"
                            }
                          },
                          [_vm._v("datenschutz@mintfuture.de")]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c("b", [
                      _c(
                        "span",
                        {
                          attrs: {
                            lang: "DE"
                          }
                        },
                        [_vm._v("Allgemeines ")]
                      )
                    ])
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Diese Datenschutzerklärung erfüllt die gesetzlichen Anforderungen an die Transparenz bei der Verarbeitung personenbezogener Daten. Dies sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen. Hierzu gehören beispielsweise Informationen wie Ihr Name, Ihr Alter, Ihre Anschrift, Ihre Telefonnummer, Ihr Geburtsdatum, Ihre E-Mail-Adresse, Ihre IP-Adresse oder das Nutzerverhalten beim Besuch einer Website. Informationen, bei denen wir keinen (oder nur mit einem unverhältnismäßigen Aufwand) Bezug zu Ihrer Person herstellen können, z. B. durch Anonymisierung, sind keine personenbezogenen Daten. Die Verarbeitung von personenbezogenen Daten (bspw. das Erheben, das Abfragen, die Verwendung, die Speicherung oder die Übermittlung) bedarf immer einer gesetzlichen Grundlage und eines definierten Zwecks. "
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Gespeicherte personenbezogene Daten werden gelöscht, sobald der Zweck der Verarbeitung erreicht wurde und es keine rechtmäßigen Gründe für eine weitere Aufbewahrung der Daten gibt. Wir informieren Sie in den einzelnen Verarbeitungsvorgängen über die konkreten Speicherfristen bzw. Kriterien für die Speicherung. Unabhängig davon, speichern wir Ihre personenbezogenen Daten in Einzelfällen zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen und bei Vorliegen gesetzlicher Aufbewahrungsfristen. "
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c("b", [
                      _c(
                        "span",
                        {
                          attrs: {
                            lang: "DE"
                          }
                        },
                        [_vm._v("Informationen nach Art. 13 DSGVO")]
                      )
                    ])
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Diese Informationen richten sich an Kunden, Interessenten, Lieferanten und Mitarbeiter. Ihre personenbezogenen Daten werden von uns zu folgenden Zwecken verarbeitet:"
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "ul",
                  {
                    staticStyle: {
                      "margin-top": "0cm"
                    },
                    attrs: {
                      type: "square"
                    }
                  },
                  [
                    _c(
                      "li",
                      {
                        staticClass: "MsoNormal"
                      },
                      [
                        _c(
                          "span",
                          {
                            attrs: {
                              lang: "DE"
                            }
                          },
                          [
                            _vm._v(
                              "Zur Erfüllung unserer vertraglichen Pflichten denen wir Ihnen gegenüber verpflichtet sind (Art. 6 Abs. 1 lit. b DSGVO)."
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "li",
                      {
                        staticClass: "MsoNormal"
                      },
                      [
                        _c(
                          "span",
                          {
                            attrs: {
                              lang: "DE"
                            }
                          },
                          [
                            _vm._v(
                              "Zur Durchführung vorvertraglicher Pflichten (Art. 6 Abs. 1 Lit. b DSGVO)."
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "li",
                      {
                        staticClass: "MsoNormal"
                      },
                      [
                        _c(
                          "span",
                          {
                            attrs: {
                              lang: "DE"
                            }
                          },
                          [
                            _vm._v(
                              "Zur Beantwortung von Anfragen (Art. 6 abs. 1 Lit. b DSGVO)."
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "li",
                      {
                        staticClass: "MsoNormal"
                      },
                      [
                        _c(
                          "span",
                          {
                            attrs: {
                              lang: "DE"
                            }
                          },
                          [
                            _vm._v(
                              "Sofern Sie uns eine Einwilligung zur Verarbeitung Ihrer personenbezogenen Daten für bestimmte Zwecke erteilt haben (etwa zum Empfang unseres Newsletters), findet die Datenverarbeitung auf Basis Ihrer Einwilligung statt (Art. 6 Abs. 1 Lit. a DSGVO)."
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "li",
                      {
                        staticClass: "MsoNormal"
                      },
                      [
                        _c(
                          "span",
                          {
                            attrs: {
                              lang: "DE"
                            }
                          },
                          [
                            _vm._v(
                              "Zur Erfüllung rechtlicher Verpflichtungen, denen unser Unternehmen unterliegt (Art. 6 Abs. 1 lit. c DSGVO)."
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "li",
                      {
                        staticClass: "MsoNormal"
                      },
                      [
                        _c(
                          "span",
                          {
                            attrs: {
                              lang: "DE"
                            }
                          },
                          [
                            _vm._v(
                              "Soweit erforderlich verarbeiten wir Ihre Daten auch zur Wahrung unserer berechtigten Interessen, insbesondere zur Geltendmachung rechtlicher Ansprüche und die Verteidigung bei rechtlichen Streitigkeiten oder die Gewährleistung der IT-Sicherheit, zur Konsultation von und dem Datenaustausch mit Auskunfteien zur Ermittlung von Bonitäts- und Ausfallrisiken, zur Direktwerbung und Marktforschung soweit Sie der Nutzung Ihrer Daten zu diesem Zweck nicht widersprochen haben, bei Maßnahmen zur Geschäftssteuerung und Weiterentwicklung von Dienstleistungen und Produkten, bei Maßnahmen zur Produkt- und Vertriebsoptimierung, bei Maßnahmen zur Risikosteuerung, zur Verhinderung oder Aufklärung von Straftaten (Art. 6 Abs. 1 Lit. f DSGVO)."
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c("b", [
                      _c(
                        "span",
                        {
                          attrs: {
                            lang: "DE"
                          }
                        },
                        [
                          _vm._v(
                            "Kategorien von Empfängern der personenbezogenen Daten"
                          )
                        ]
                      )
                    ])
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Innerhalb unseres Unternehmens haben nur diejenigen Mitarbeiter Zugriff auf die Daten, die diese unbedingt zur Erfüllung Ihrer Aufgaben benötigen (need-to-know-Prinzip). Einzelne Prozesse und Serviceleistungen werden durch sorgfältig ausgewählte und datenschutzkonform beauftragte Dienstleister ausgeführt, die Ihren Sitz innerhalb des EWR haben. Sofern von uns beauftragte Dienstleister bei der Durchführung Ihrer Leistungen Zugriff auf personenbezogene Daten erhalten, wurden mit diesen Auftragsverarbeitungsverträge nach Art. 28 Abs. 3 DSGVO geschlossen. "
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c("b", [
                      _c(
                        "span",
                        {
                          attrs: {
                            lang: "DE"
                          }
                        },
                        [_vm._v("Dauer der Datenspeicherung")]
                      )
                    ])
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Die von uns verarbeiteten Daten werden für die Dauer des Bestehens und der Abwicklung des Vertragsverhältnisses sowie unter Einhaltung von gesetzlichen Aufbewahrungsfristen gespeichert. Dies sind insbesondere handels- und steuerrechtliche Aufbewahrungspflichten nach dem Handelsgesetzbuch (HGB) und die Abgabenordnung (AO). Die regelmäßigen Aufbewahrungs- bzw. Dokumentationsfristen belaufen sich hiernach auf bis zu zehn Jahre. Kommt es zu keinem Vertragsverhältnis, verarbeiten wir die Daten nur so lange, wie es der konkrete Zweck erfordert."
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c("b", [
                      _c(
                        "span",
                        {
                          attrs: {
                            lang: "DE"
                          }
                        },
                        [_vm._v("Ihre Betroffenenrechte")]
                      )
                    ])
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Als Betroffener haben Sie folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten gegenüber uns:"
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "ul",
                  {
                    staticStyle: {
                      "margin-top": "0cm"
                    },
                    attrs: {
                      type: "square"
                    }
                  },
                  [
                    _c(
                      "li",
                      {
                        staticClass: "MsoNormal"
                      },
                      [
                        _c(
                          "span",
                          {
                            attrs: {
                              lang: "DE"
                            }
                          },
                          [
                            _vm._v(
                              "Recht auf Auskunft über die zu Ihrer Person bei uns verarbeiteten Daten."
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "li",
                      {
                        staticClass: "MsoNormal"
                      },
                      [
                        _c(
                          "span",
                          {
                            attrs: {
                              lang: "DE"
                            }
                          },
                          [
                            _vm._v(
                              "Recht auf Berichtigung oder Löschung, sofern diese falsch, nicht aktuell oder unrechtmäßig von uns erhoben worden sind."
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "li",
                      {
                        staticClass: "MsoNormal"
                      },
                      [
                        _c(
                          "span",
                          {
                            attrs: {
                              lang: "DE"
                            }
                          },
                          [
                            _vm._v(
                              "Recht auf Einschränkung der Verarbeitung, sofern eine vollständige Löschung nicht möglich ist, z.B. weil wir gesetzliche Aufbewahrungspflichten zu wahren haben."
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "li",
                      {
                        staticClass: "MsoNormal"
                      },
                      [
                        _c(
                          "span",
                          {
                            attrs: {
                              lang: "DE"
                            }
                          },
                          [
                            _vm._v(
                              "Recht auf Widerspruch gegen die Verarbeitung, sofern sich die Datenverarbeitung auf eine Interessenabwägung (das sogenannte berechtigte Interesse) stützt, wie oben unter „Zweck der Verarbeitung“ beschrieben. Dies ist der Fall, wenn die Verarbeitung insbesondere nicht zur Erfüllung eines Vertrages mit Ihnen erforderlich ist. Bei Geltendmachung Ihres Widerspruchsrechts bitten wir um Darlegung der Gründe, weshalb wir Ihre Daten nicht wie von uns durchgeführt verarbeiten sollten."
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Selbstverständlich können Sie auch der Verarbeitung Ihrer personenbezogenen Daten für Zwecke der Werbung jederzeit widersprechen. Richten Sie Ihren Widerspruch hierzu an unsere im Impressum angegebene Adresse oder schreiben Sie uns eine E-Mail an die im Impressum angegebene Adresse."
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "ul",
                  {
                    staticStyle: {
                      "margin-top": "0cm"
                    },
                    attrs: {
                      type: "square"
                    }
                  },
                  [
                    _c(
                      "li",
                      {
                        staticClass: "MsoNormal"
                      },
                      [
                        _c(
                          "span",
                          {
                            attrs: {
                              lang: "DE"
                            }
                          },
                          [
                            _vm._v(
                              "Recht auf Widerruf, sofern Sie uns eine Einwilligung zur Verarbeitung Ihrer Daten erteilt haben. Ihren Widerruf können Sie jederzeit ohne Angabe von Gründen gegenüber unserem Unternehmen geltend machen. Wenden Sie sich hierzu bitte an die im Impressum angegebene Adresse."
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "li",
                      {
                        staticClass: "MsoNormal"
                      },
                      [
                        _c(
                          "span",
                          {
                            attrs: {
                              lang: "DE"
                            }
                          },
                          [
                            _vm._v(
                              "Darüber hinaus haben Sie das Recht, sich bei einer Datenschutzaufsichtsbehörde über die Verarbeitung Ihrer personenbezogenen Daten durch unser Unternehmen zu beschweren."
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Bei Fragen zum Datenschutz können Sie sich gerne per E-Mail an die im Impressum angegebene Adresse wenden."
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c("b", [
                      _c(
                        "span",
                        {
                          attrs: {
                            lang: "DE"
                          }
                        },
                        [_vm._v("Cookies")]
                      )
                    ])
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Cookies sind kleine Textdateien, die im Rahmen Ihres Besuchs unserer Internetseiten von uns an den Browser Ihres Endgeräts gesendet und dort gespeichert werden. Alternativ zum Einsatz von Cookies können Informationen auch im lokalen Speicher (local storage) Ihres Browsers gespeichert werden. Einige Funktionen unserer Internetseite können ohne den Einsatz von Cookies bzw. local storage nicht angeboten werden (technisch notwendige Cookies). Andere Cookies ermöglichen uns hingegen verschiedene Analysen, sodass wir beispielsweise in der Lage sind, den von Ihnen verwendeten Browser bei einem erneuten Besuch unserer Webseite wiederzuerkennen und verschiedene Informationen an uns zu übermitteln (nicht notwendige Cookies). Mithilfe von Cookies können wir unter anderem unser Internetangebot für Sie nutzerfreundlicher und effektiver gestalten, indem wir etwa Ihre Nutzung unserer Website nachvollziehen und Ihre bevorzugten Einstellungen (bspw. Länder- und Spracheneinstellungen) feststellen. Sofern Dritte über Cookies Informationen verarbeiten, erheben diese die Informationen direkt über Ihren Browser. Cookies richten auf Ihrem Endgerät keinen Schaden an. Sie können keine Programme ausführen und keine Viren enthalten."
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Über die jeweiligen Dienste, für die wir Cookies einsetzen, informieren wir in den einzelnen Verarbeitungsvorgängen. Ausführliche Informationen zu den eingesetzten Cookies finden Sie in den Cookie-Einstellungen oder im Consent Manager dieser Website."
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c("b", [
                      _c(
                        "span",
                        {
                          attrs: {
                            lang: "DE"
                          }
                        },
                        [_vm._v("Ihre Rechte ")]
                      )
                    ])
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Unter den Voraussetzungen der gesetzlichen Vorschriften der Datenschutz-Grundverordnung (DSGVO) haben Sie als betroffene Person folgende Rechte:"
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "ul",
                  {
                    staticStyle: {
                      "margin-top": "0cm"
                    },
                    attrs: {
                      type: "square"
                    }
                  },
                  [
                    _c(
                      "li",
                      {
                        staticClass: "MsoNormal"
                      },
                      [
                        _c("b", [
                          _c(
                            "span",
                            {
                              attrs: {
                                lang: "DE"
                              }
                            },
                            [_vm._v("Auskunft")]
                          )
                        ]),
                        _c(
                          "span",
                          {
                            attrs: {
                              lang: "DE"
                            }
                          },
                          [
                            _vm._v(
                              " gem. Art. 15 DSGVO über die zu Ihrer Person gespeicherten Daten in Form von aussagekräftigen Informationen zu den Einzelheiten der Verarbeitung sowie eine Kopie Ihrer Daten;"
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "li",
                      {
                        staticClass: "MsoNormal"
                      },
                      [
                        _c("b", [
                          _c(
                            "span",
                            {
                              attrs: {
                                lang: "DE"
                              }
                            },
                            [_vm._v("Berichtigung")]
                          )
                        ]),
                        _c(
                          "span",
                          {
                            attrs: {
                              lang: "DE"
                            }
                          },
                          [
                            _vm._v(
                              " gem. Art. 16 DSGVO von unrichtigen oder unvollständigen Daten, die bei uns gespeichert sind;"
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "li",
                      {
                        staticClass: "MsoNormal"
                      },
                      [
                        _c("b", [
                          _c(
                            "span",
                            {
                              attrs: {
                                lang: "DE"
                              }
                            },
                            [_vm._v("Löschung")]
                          )
                        ]),
                        _c(
                          "span",
                          {
                            attrs: {
                              lang: "DE"
                            }
                          },
                          [
                            _vm._v(
                              " gem. Art. 17 DSGVO der bei uns gespeicherten Daten, soweit die Verarbeitung nicht zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;"
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "li",
                      {
                        staticClass: "MsoNormal"
                      },
                      [
                        _c("b", [
                          _c(
                            "span",
                            {
                              attrs: {
                                lang: "DE"
                              }
                            },
                            [_vm._v("Einschränkung")]
                          )
                        ]),
                        _c(
                          "span",
                          {
                            attrs: {
                              lang: "DE"
                            }
                          },
                          [
                            _vm._v(
                              " der Verarbeitung gem. Art. 18 DSGVO, soweit die Richtigkeit der Daten bestritten wird, die Verarbeitung unrechtmäßig ist, wir die Daten nicht mehr benötigen und Sie deren Löschung ablehnen, weil Sie diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie Widerspruch gegen die Verarbeitung gem. Art. 21 DSGVO erhoben haben."
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "li",
                      {
                        staticClass: "MsoNormal"
                      },
                      [
                        _c("b", [
                          _c(
                            "span",
                            {
                              attrs: {
                                lang: "DE"
                              }
                            },
                            [_vm._v("Datenübertragbarkeit")]
                          )
                        ]),
                        _c(
                          "span",
                          {
                            attrs: {
                              lang: "DE"
                            }
                          },
                          [
                            _vm._v(
                              " gem. Art. 20 DSGVO, soweit Sie uns personenbezogene Daten im Rahmen einer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO oder auf Grundlage eines Vertrages gem. Art. 6 Abs. 1 lit. b DSGVO bereitgestellt haben und diese durch uns mithilfe automatisierter Verfahren verarbeitet wurden. Sie erhalten Ihre Daten in einem strukturierten, gängigen und maschinenlesbaren Format bzw. wir übermitteln die Daten direkt an einen anderen Verantwortlichen, soweit dies technisch machbar ist."
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "li",
                      {
                        staticClass: "MsoNormal"
                      },
                      [
                        _c("b", [
                          _c(
                            "span",
                            {
                              attrs: {
                                lang: "DE"
                              }
                            },
                            [_vm._v("Widerspruch")]
                          )
                        ]),
                        _c(
                          "span",
                          {
                            attrs: {
                              lang: "DE"
                            }
                          },
                          [
                            _vm._v(
                              " gem. Art. 21 DSGVO gegen die Verarbeitung Ihrer personenbezogenen Daten, soweit diese auf Grundlage des Art. 6 Abs. 1 lit. e, f DSGVO erfolgt und dafür Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben oder sich der Widerspruch gegen Direktwerbung richtet. Das Recht auf Widerspruch besteht nicht, wenn überwiegende, zwingende schutzwürdige Gründe für die Verarbeitung nachgewiesen werden oder die Verarbeitung zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erfolgt. Soweit das Recht auf Widerspruch bei einzelnen Verarbeitungsvorgängen nicht besteht, ist dies dort angegeben."
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "li",
                      {
                        staticClass: "MsoNormal"
                      },
                      [
                        _c("b", [
                          _c(
                            "span",
                            {
                              attrs: {
                                lang: "DE"
                              }
                            },
                            [_vm._v("Widerruf")]
                          )
                        ]),
                        _c(
                          "span",
                          {
                            attrs: {
                              lang: "DE"
                            }
                          },
                          [
                            _vm._v(
                              " gem. Art. 7 Abs. 3 DSGVO Ihrer erteilten Einwilligung mit Wirkung für die Zukunft."
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "li",
                      {
                        staticClass: "MsoNormal"
                      },
                      [
                        _c("b", [
                          _c(
                            "span",
                            {
                              attrs: {
                                lang: "DE"
                              }
                            },
                            [_vm._v("Beschwerde")]
                          )
                        ]),
                        _c(
                          "span",
                          {
                            attrs: {
                              lang: "DE"
                            }
                          },
                          [
                            _vm._v(
                              " gem. Art. 77 DSGVO bei einer Aufsichtsbehörde, wenn Sie der Ansicht sind, die Verarbeitung Ihrer personenbezogenen Daten verstößt gegen die DSGVO. In der Regel können Sie sich an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes, Ihres Arbeitsplatzes oder unseres Unternehmenssitzes wenden."
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c("b", [
                      _c(
                        "span",
                        {
                          attrs: {
                            lang: "DE"
                          }
                        },
                        [
                          _vm._v(
                            "Datenverarbeitung durch unsere Web-Anwendung "
                          )
                        ]
                      )
                    ])
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Nachfolgend informieren wir Sie über die einzelnen Verarbeitungsvorgänge, den Umfang und den Zweck der Datenverarbeitung, die Rechtsgrundlage, die Pflicht zur Bereitstellung Ihrer Daten und die jeweilige Speicherdauer. Eine automatisierte Entscheidung im Einzelfall, einschließlich Profiling findet nicht statt."
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c("b", [
                      _c(
                        "span",
                        {
                          attrs: {
                            lang: "DE"
                          }
                        },
                        [
                          _vm._v(
                            "Anfrage innerhalb der Web-Anwendung, per E-Mail oder Telefon"
                          )
                        ]
                      )
                    ])
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Wenn Sie uns kontaktieren (z. B. via Kontaktformular innerhalb der Web-Anwendung, per E-Mail oder Telefon), wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (z. B. Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. "
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. A DSGVO) und/oder auf unseren berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO), da wir ein berechtigtes Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen haben. "
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Die von Ihnen an uns per Kontaktanfrage übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt. "
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c("b", [
                      _c(
                        "span",
                        {
                          attrs: {
                            lang: "DE"
                          }
                        },
                        [_vm._v("Registrierung eines Benutzerkontos")]
                      )
                    ])
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c("b", [
                      _c(
                        "span",
                        {
                          attrs: {
                            lang: "DE"
                          }
                        },
                        [_vm._v("Art und Umfang der Verarbeitung")]
                      )
                    ])
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Für die Nutzung bestimmter Bereiche unserer Website haben Sie die Möglichkeit ein Benutzerkonto zu registrieren. Die Informationen, die während der Registrierung über die Pflichtfelder erhoben werden, sind für die Bereitstellung eines Zugangs zum Benutzerkonto erforderlich. Darüber hinaus können Sie freiwillig zusätzliche Informationen für ergänzende (Komfort-)Funktionen bereitstellen."
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Für die Registrierung eines Benutzerkontos erfolgt die Weitergabe Ihrer personenbezogenen Daten ausschließlich gemäß dieser Datenschutzerklärung."
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          " Wir geben deine Daten nur an Dritte weiter, wenn wir hierzu aufgrund deiner Einwilligung berechtigt oder aufgrund des geltenden Rechts berechtigt oder verpflichtet sind (dies schließt auch eine Übermittlung der Daten an das Umweltbundesamt und andere möglicherweise zuständige Behörden zur Geltendmachung der THG-Quote ein). Gleiches gilt, wenn wir deine Daten von Dritten, d.h. weder von euch noch von uns beauftragten Unternehmen, erhalten. "
                        ),
                        _c("br"),
                        _c("br"),
                        _vm.isDatasharingActive
                          ? _c(
                              "span",
                              {
                                ref: "isDatasharingActive",
                                attrs: {
                                  lang: "DE"
                                }
                              },
                              [
                                _vm._v(
                                  " Sofern du hierzu deine Zustimmung erteilt hast, geben wir deine Daten insbesondere an unseren Partner, " +
                                    _vm._s(_vm.companyName) +
                                    ", " +
                                    _vm._s(_vm.street) +
                                    ", " +
                                    _vm._s(_vm.zip) +
                                    " " +
                                    _vm._s(_vm.city) +
                                    ", weiter. Soweit der Vertrag auf der Grundlage eines Vermittlers zustande gekommen ist, werden mit diesem nur die notwendigen Daten zur Provisionsabrechnung ausgetauscht"
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm.isDatasharingActive ? _c("br") : _vm._e(),
                        _vm.isDatasharingActive ? _c("br") : _vm._e(),
                        _vm._v(
                          " Neben den in diesen Datenschutzhinweisen ausdrücklich genannten Dritten können gegebenenfalls auch externe Dienstleister für den Betrieb unserer Website im Rahmen von Auftragsverarbeitungsverträgen, Staatliche Stellen und Behörden, soweit dies zur Erfüllung einer gesetzlichen Verpflichtung erforderlich ist, oder zur Durchführung unseres Geschäftsbetriebs eingesetzte Personen (wie z.B. Auditoren, Rechtsberater, Versicherungen, Banken, Aufsichtsbehörden) Zugriff auf deine Daten erhalten. "
                        ),
                        _c("br"),
                        _c("br"),
                        _vm._v(
                          " Soweit externe Dienstleister mit Deinen personenbezogenen Daten in Berührung kommen, stellen wir durch rechtliche, technische und organisatorische Maßnahmen sicher, dass diese die Vorschriften der Datenschutzgesetze einhalten und – soweit sie als Auftragsverarbeiter tätig werden – deine Daten nur in unserem Auftrag und nach unseren Weisungen verarbeiten. "
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c("b", [
                      _c(
                        "span",
                        {
                          attrs: {
                            lang: "DE"
                          }
                        },
                        [_vm._v("Zweck und Rechtsgrundlage")]
                      )
                    ])
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Wir verarbeiten Ihre Daten zum Zweck der Bereitstellung eines Benutzerkontos zur Erfüllung eines Vertrags mit Ihnen gem. Art. 6 Abs. 1 lit. b DSGVO. Es besteht eine vertragliche Pflicht zur Bereitstellung Ihrer Daten, da diese Informationen zur Identifizierung Ihrer Person sowie zur Vertragserfüllung unsererseits erforderlich sind. Eine gesetzliche Pflicht zur Bereitstellung der Daten besteht nicht. Ohne die Bereitstellung dieser Informationen ist die Registrierung eines Benutzerkontos und somit ein Vertragsschluss nicht möglich."
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Darüber hinaus erfolgt die Verarbeitung zusätzlich freiwillig angegebener Informationen zum Zweck der Bereitstellung weiterer (Komfort-)Funktionen auf Grundlage Ihrer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO. Durch die Deaktivierung der Funktionen / Durch die Löschung der freiwilligen Angaben im Benutzerkonto können Sie jederzeit mit Wirkung für die Zukunft Ihren Widerruf gem. Art. 7 Abs. 3 DSGVO erklären."
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c("b", [
                      _c(
                        "span",
                        {
                          attrs: {
                            lang: "DE"
                          }
                        },
                        [_vm._v("Speicherdauer")]
                      )
                    ])
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Wir speichern Ihre personenbezogenen Daten im Rahmen der Bereitstellung des Benutzerkontos für die Dauer des Vertragsverhältnisses. Nach Ende des Vertrages / Löschen des Benutzerkontos erfolgt eine weitere Speicherung Ihrer Daten nur, sofern gesetzliche Aufbewahrungspflichten (z.B. Steuer- und Handelsrecht) bestehen."
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Zusätzliche Informationen, die Sie uns auf Grundlage Ihrer Einwilligung bereitstellen, werden nur so lange gespeichert, bis Sie Ihre Einwilligung durch Deaktivierung der Funktionen / durch Löschen der Daten widerrufen, längstens jedoch bis zum Ende des Vertrages, dem die Bereitstellung des Benutzerkontos zugrunde liegt."
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c("b", [
                      _c(
                        "span",
                        {
                          attrs: {
                            lang: "DE"
                          }
                        },
                        [
                          _vm._v(
                            "Erfassung personenbezogener Daten im Rahmen der App-Nutzung"
                          )
                        ]
                      )
                    ])
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Wenn Sie unsere App nutzen, erfassen wir folgende personenbezogene Daten von Ihnen: "
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoListParagraphCxSpFirst",
                    staticStyle: {
                      "text-indent": "-18.0pt"
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-family": "Symbol"
                        },
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v("·"),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              font: '7.0pt "Times New Roman"'
                            }
                          },
                          [_vm._v("       ")]
                        )
                      ]
                    ),
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [_vm._v("Vor- und Nachname")]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoListParagraphCxSpMiddle",
                    staticStyle: {
                      "text-indent": "-18.0pt"
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-family": "Symbol"
                        },
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v("·"),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              font: '7.0pt "Times New Roman"'
                            }
                          },
                          [_vm._v("       ")]
                        )
                      ]
                    ),
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [_vm._v("E-Mail-Adresse")]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoListParagraphCxSpMiddle",
                    staticStyle: {
                      "text-indent": "-18.0pt"
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-family": "Symbol"
                        },
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v("·"),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              font: '7.0pt "Times New Roman"'
                            }
                          },
                          [_vm._v("       ")]
                        )
                      ]
                    ),
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [_vm._v("Anschrift")]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoListParagraphCxSpMiddle",
                    staticStyle: {
                      "text-indent": "-18.0pt"
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-family": "Symbol"
                        },
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v("·"),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              font: '7.0pt "Times New Roman"'
                            }
                          },
                          [_vm._v("       ")]
                        )
                      ]
                    ),
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [_vm._v("Ggf. Steuernummer")]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoListParagraphCxSpMiddle",
                    staticStyle: {
                      "text-indent": "-18.0pt"
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-family": "Symbol"
                        },
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v("·"),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              font: '7.0pt "Times New Roman"'
                            }
                          },
                          [_vm._v("       ")]
                        )
                      ]
                    ),
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [_vm._v("Angaben zum Fahrzeug ")]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoListParagraphCxSpMiddle",
                    staticStyle: {
                      "text-indent": "-18.0pt"
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-family": "Symbol"
                        },
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v("·"),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              font: '7.0pt "Times New Roman"'
                            }
                          },
                          [_vm._v("       ")]
                        )
                      ]
                    ),
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [_vm._v("Nutzungsdaten ")]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoListParagraphCxSpMiddle",
                    staticStyle: {
                      "text-indent": "-18.0pt"
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-family": "Symbol"
                        },
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v("·"),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              font: '7.0pt "Times New Roman"'
                            }
                          },
                          [_vm._v("       ")]
                        )
                      ]
                    ),
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [_vm._v("IP-Adresse")]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoListParagraphCxSpLast",
                    staticStyle: {
                      "text-indent": "-18.0pt"
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-family": "Symbol"
                        },
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v("·"),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              font: '7.0pt "Times New Roman"'
                            }
                          },
                          [_vm._v("       ")]
                        )
                      ]
                    ),
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [_vm._v("Metadaten ")]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Die Verarbeitung dieser personenbezogenen Daten ist erforderlich, um die Funktionalität der App-Anwendung zu gewährleisten. Rechtsgrundlage für diese Datenverarbeitung ist unser berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DS-GVO, Ihre Einwilligung im Sinne des Art. 6 Abs. 1 lit. a DS-GVO und/oder – sofern ein Vertrag geschlossen wurde – die Erfüllung unserer vertraglichen Verpflichtungen (Art. 6 Abs. 1 lit. b DS-GVO). "
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c("b", [
                      _c(
                        "span",
                        {
                          attrs: {
                            lang: "DE"
                          }
                        },
                        [_vm._v("MMM Intelligence UG")]
                      )
                    ]),
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(" ("),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "http://www.mmmint.ai"
                            }
                          },
                          [_vm._v("www.mmmint.ai")]
                        ),
                        _vm._v(") ")
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Bei Aufruf und Nutzung unserer Web-Anwendung erheben wir die personenbezogenen Daten, die Ihre Browser automatisch an unseren Server übermittelt. Die folgenden Informationen werden temporär in einem sog. Logfile gespeichert: "
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoListParagraphCxSpFirst",
                    staticStyle: {
                      "text-indent": "-18.0pt"
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-family": "Symbol"
                        },
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v("·"),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              font: '7.0pt "Times New Roman"'
                            }
                          },
                          [_vm._v("       ")]
                        )
                      ]
                    ),
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [_vm._v("IP-Adresse des anfragenden Rechners ")]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoListParagraphCxSpMiddle",
                    staticStyle: {
                      "text-indent": "-18.0pt"
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-family": "Symbol"
                        },
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v("·"),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              font: '7.0pt "Times New Roman"'
                            }
                          },
                          [_vm._v("       ")]
                        )
                      ]
                    ),
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [_vm._v("Datum und Uhrzeit des Zugriffs")]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoListParagraphCxSpMiddle",
                    staticStyle: {
                      "text-indent": "-18.0pt"
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-family": "Symbol"
                        },
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v("·"),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              font: '7.0pt "Times New Roman"'
                            }
                          },
                          [_vm._v("       ")]
                        )
                      ]
                    ),
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [_vm._v("Name und URL der abgerufenen Datei ")]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoListParagraphCxSpMiddle",
                    staticStyle: {
                      "text-indent": "-18.0pt"
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-family": "Symbol"
                        },
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v("·"),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              font: '7.0pt "Times New Roman"'
                            }
                          },
                          [_vm._v("       ")]
                        )
                      ]
                    ),
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Webseite, von der aus der Zugriff erfolgt (Referrer-URL)"
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoListParagraphCxSpLast",
                    staticStyle: {
                      "text-indent": "-18.0pt"
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-family": "Symbol"
                        },
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v("·"),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              font: '7.0pt "Times New Roman"'
                            }
                          },
                          [_vm._v("       ")]
                        )
                      ]
                    ),
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Verwendeter Browser und ggf. das Betriebssystem Ihres Rechners, sowie der Name Ihres Access-Providers"
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Unsere Web-Anwendung wird nicht von uns selbst gehostet, sondern bei einem Dienstleister, der für den Zweck der Bereitstellung der Webseite die zuvor genannten Daten in unserem Auftrag gem. Art. 28 DSGVO verarbeitet. "
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO)."
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [_vm._v("Wir setzen folgenden Dienstleister ein: ")]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v("MMM Intelligence UG"),
                        _c("br"),
                        _vm._v(" Marie-Curie-Str. 3"),
                        _c("br"),
                        _vm._v(" 49076 Osnabrück")
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c("b", [
                      _c(
                        "span",
                        {
                          attrs: {
                            lang: "DE"
                          }
                        },
                        [_vm._v("Microsoft Azure CDN")]
                      )
                    ])
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Wir nutzen das Content Delivery Network Microsoft Azure CDN. Anbieter ist die Microsoft Corporation, One Microsoft Way, Redmond, WA 98052-6399, USA."
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Ein CDN ermöglicht uns die schnelle Auslieferung einiger Inhalte, insbesondere geht es hierbei um große Mediendateien. Dies geschieht über ein Netz aus regional verteilten Servern, die über das Internet verbunden sind. Der Anbieter kann auf diese Weise die Datenübermittlung zwischen Ihrem Browser und unseren Servern analysieren und potenziell bösartigen Datenverkehr filtern. Die Verarbeitung der Daten der Nutzer erfolgt allein zu den vorgenannten Zwecken und dient der Aufrechterhaltung der Sicherheit und Funktionsfähigkeit des CDN. "
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Der Einsatz von Microsoft Azure CDN beruht auf unserem berechtigten Interesse an einer möglichst fehlerfreien und sicheren Bereitstellung unseres Webangebotes (Art. 6 Abs. 1 lit. f DS-GVO). "
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details finden Sie hier: "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "https://learn.microsoft.com/de-de/compliance/regulatory/offering-eu-model-clauses"
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "blue"
                                }
                              },
                              [
                                _vm._v(
                                  "https://learn.microsoft.com/de-de/compliance/regulatory/offering-eu-model-clauses"
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [_vm._v(".")]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Weitere Informationen zu Microsoft Azure CDN finden Sie hier: "
                        )
                      ]
                    ),
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "https://azure.microsoft.com/de-de/products/cdn/#overview"
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "blue"
                                }
                              },
                              [
                                _vm._v(
                                  "https://azure.microsoft.com/de-de/products/cdn/#overview"
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [_vm._v(".")]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c("b", [
                      _c(
                        "span",
                        {
                          attrs: {
                            lang: "DE"
                          }
                        },
                        [_vm._v("Auftragsverarbeitung ")]
                      )
                    ])
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Um sicherzustellen, dass personenbezogene Daten nach unseren Vorgaben und unter Einhaltung der DS-GVO verarbeitet werden, haben wir einen Vertrag über Auftragsverarbeitung (AVV) mit dem Anbieter geschlossen. "
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c("b", [
                      _c(
                        "span",
                        {
                          attrs: {
                            lang: "DE"
                          }
                        },
                        [_vm._v("Datenanalyse")]
                      )
                    ])
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Wenn Sie auf unsere App zugreifen, kann Ihre Verhalten mit Hilfe bestimmter Analyse-Werkzeuge statistisch ausgewertet und zu Werbe- und Marktforschungszwecken oder zur Verbesserung unserer Angebote analysiert werden. Bei der Verwendung derartiger Tools achten wir auf die Einhaltung der gesetzlichen Datenschutzbestimmungen. Beim Einsatz externer Dienstleister (Auftragsverarbeiter) stellen wir durch entsprechende Verträge mit den Dienstleistern sicher, dass die Datenverarbeitung den deutschen und europäischen Datenschutzstandards entspricht. "
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c("b", [
                      _c(
                        "span",
                        {
                          attrs: {
                            lang: "DE"
                          }
                        },
                        [_vm._v("Microsoft Application Insights ")]
                      )
                    ])
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Wir nutzen Microsoft Application Insights, ein Dienst von Azure Monitor. Anbieter ist die Microsoft Corporation, One Microsoft Way, Redmond, WA 98052-6399, USA."
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Application Insights hilft uns bei der Optimierung der Leistung und Benutzerfreundlichkeit unserer Web-Anwendung, indem der Dienst beispielsweise Aufschluss darüber gibt, zu welchen Tageszeiten die Anwendung am meisten besucht wird, wie gut die Anwendung reagiert oder wo mögliche Fehler- und Problemquellen liegen. Hierzu werden Telemetriedaten (wie die URL oder die IP-Adresse) erhoben und verarbeitet. "
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Bei der Nutzung von Microsoft Application Insights stützen wir uns auf Art. 6 Abs. 1 lit. f DS-GVO als rechtliche Grundlage für die Speicherung und die Analyse von personenbezogenen Daten, da wir ein berechtigtes Interesse daran haben, die Nutzung unserer App zu analysieren. Dadurch können wir unsere Onlinepräsenz und unsere Angebote für Sie optimieren. Wenn Sie zuvor eine Einwilligung zur Datenverarbeitung auf dieser Website durch Application Insights abgegeben haben, findet die Verarbeitung Ihrer Daten auf der rechtlichen Grundlage von Art. 6 Abs. 1 lit. a DS-GVO statt. Sie können Ihre Einwilligung jederzeit widerrufen. "
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal",
                    staticStyle: {
                      "margin-bottom": "0cm",
                      "line-height": "normal"
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Die Übertragung Ihrer personenbezogenen Daten in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Nähere Informationen hierzu finden Sie hier: "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "https://learn.microsoft.com/de-de/compliance/regulatory/offering-eu-model-clauses"
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "blue"
                                }
                              },
                              [
                                _vm._v(
                                  "https://learn.microsoft.com/de-de/compliance/regulatory/offering-eu-model-clauses"
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [_vm._v(".")]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal",
                    staticStyle: {
                      "margin-bottom": "0cm",
                      "line-height": "normal"
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [_vm._v(" ")]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Weitere Informationen zur Datenverarbeitung durch Microsoft Application Insights bzw. zu Azure Monitor finden Sie hier: "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "https://learn.microsoft.com/de-de/azure/azure-monitor/app/data-retention-privacy"
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "blue"
                                }
                              },
                              [
                                _vm._v(
                                  "https://learn.microsoft.com/de-de/azure/azure-monitor/app/data-retention-privacy"
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [_vm._v(".")]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c("b", [
                      _c(
                        "span",
                        {
                          attrs: {
                            lang: "DE"
                          }
                        },
                        [_vm._v("Google Tag Manager")]
                      )
                    ])
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Wir nutzen auf dieser Website Dienste und Funktionen vom Google Tag Manager, die von der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland angeboten werden. "
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Der Google Tag Manager ist ein Tool, mit dessen Hilfe wir andere Tools auf unserer Website einsetzen können. Er erstellt keine Benutzerprofile, er speichert keine Cookies und er führt keine unabhängigen Analysen durch. Allerdings wird Ihre IP-Adresse erfasst und gegebenenfalls in die USA übermittelt. Der Google Tag Manager selbst wird nur für die Verwaltung dieser Tools verwendet, die über ihn integriert sind."
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Beim Einsatz des Google Tag Managers auf dieser Website stützen wir uns auf Art. 6 Abs. 1 lit. f DSGVO als rechtliche Grundlage, da wir ein berechtigtes Interesse daran haben, Tracking-Tools auf dieser Website einfach und schnell zu implementieren und zu lenken. Wenn Sie zuvor eine Einwilligung zur Datenverarbeitung auf dieser Website durch den Google Tag Manager abgegeben haben, findet die Verarbeitung Ihrer Daten allein auf der rechtlichen Grundlage von Art. 6 Abs. 1 lit. a DSGVO § 25 Abs. 1 TTDSG statt. Sie können Ihre Einwilligung jederzeit widerrufen."
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          'Das Unternehmen ist nach dem "EU-US Data Privacy Framework" (DPF) zertifiziert, ein Abkommen zwischen der Europäischen Union und den USA, welches darauf abzielt, die Einhaltung europäischer Datenschutzstandards bei der Datenverarbeitung in den USA zu sichern. Die Zertifizierung nach dem DPF verpflichtet Unternehmen, diese Datenschutzstandards zu beachten. Nähere Informationen erhalten Sie unter: '
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active",
                              target: "_blank"
                            }
                          },
                          [
                            _vm._v(
                              "https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active"
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c("b", [
                      _c(
                        "span",
                        {
                          attrs: {
                            lang: "DE"
                          }
                        },
                        [_vm._v("Google Ads")]
                      )
                    ])
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Wir nutzen auf dieser Website Dienste und Funktionen von Google Ads, die von der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland angeboten werden."
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Mit Hilfe von Google Ads können wir Online-Werbeanzeigen in der Google-Suchmaschine oder auf anderen Websites anzeigen lassen, wenn Nutzer nach bestimmten Begriffen suchen. Darüber hinaus können Anzeigen auf Grundlage von Nutzerdaten für bestimmte Zielgruppen geschaltet werden. Die Anzeigen sind beispielsweise auf die Interessen und den Standort der Nutzer ausgerichtet. Wir werten diese Nutzerdaten und die Anzahl der Klicks aus, um den Erfolg unserer Werbeanzeigen messen zu können."
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c("b", [
                      _c(
                        "span",
                        {
                          attrs: {
                            lang: "DE"
                          }
                        },
                        [_vm._v("Rechtsgrundlagen")]
                      )
                    ])
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Bei der Nutzung von Google Ads stützen wir uns auf Art. 6 Abs. 1 lit. f DSGVO als rechtliche Grundlage, da wir ein berechtigtes Interesse daran haben, die Nutzung unserer Website zu analysieren, um unsere Dienstleistungen und Produkte erfolgreich zu vermarkten."
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Die Übertragung Ihrer personenbezogenen Daten in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Nähere Informationen hierzu finden Sie unter "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "https://policies.google.com/privacy/frameworks",
                              target: "_blank"
                            }
                          },
                          [
                            _vm._v(
                              "https://policies.google.com/privacy/frameworks"
                            )
                          ]
                        ),
                        _vm._v(" und"),
                        _c("br"),
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "https://privacy.google.com/businesses/controllerterms/mccs/",
                              target: "_blank"
                            }
                          },
                          [
                            _vm._v(
                              "https://privacy.google.com/businesses/controllerterms/mccs/"
                            )
                          ]
                        ),
                        _vm._v(".")
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          'Das Unternehmen ist nach dem "EU-US Data Privacy Framework" (DPF) zertifiziert, ein Abkommen zwischen der Europäischen Union und den USA, welches darauf abzielt, die Einhaltung europäischer Datenschutzstandards bei der Datenverarbeitung in den USA zu sichern. Die Zertifizierung nach dem DPF verpflichtet Unternehmen, diese Datenschutzstandards zu beachten. Nähere Informationen erhalten Sie unter: '
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active",
                              target: "_blank"
                            }
                          },
                          [
                            _vm._v(
                              "https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active"
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c("b", [
                      _c(
                        "span",
                        {
                          attrs: {
                            lang: "DE"
                          }
                        },
                        [_vm._v("Erweiterte Conversions")]
                      )
                    ])
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Wir nutzen auf unserer Website die Funktionen der Erweiterten Conversions mit Google Ads."
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Wenn Sie auf unserer Website eine Conversion durchführen, erheben wir Ihre personenbezogenen Daten wie Ihre E-Mail-Adresse, Ihren Name, Ihre Wohnanschrift oder Ihre Telefonnummer, die Sie auf unserer Website eingeben. Diese Informationen können mittels der Erweiterten Conversion in Conversion-Tracking-Tags aufgezeichnet, gehasht und in Form von Hash-Daten an Google weitergeleitet werden, um die Messung von Conversions auf unserer Website zu optimieren."
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Der Einsatz der Erweiterten Conversions erfolgt auf Grundlage Ihrer Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO in Verbindung mit §25 Abs. 1 TTDSG statt. Sie können Ihre Einwilligung jederzeit widerrufen."
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "MsoNormal"
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          lang: "DE"
                        }
                      },
                      [
                        _vm._v(
                          "Mehr Informationen zu der Funktionsweise der Erweiterten Conversions finden Sie hier: "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "https://support.google.com/google-ads/answer/9888656?hl=de",
                              target: "_blank"
                            }
                          },
                          [
                            _vm._v(
                              "https://support.google.com/google-ads/answer/9888656?hl=de"
                            )
                          ]
                        )
                      ]
                    )
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }