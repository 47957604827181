import { IMachineViewmodel } from "@/lib/interfaces/Docphant/machine.viewmodel.interface";
import MachineService from "@/services/mrfiktiv/services/machineService";
import store from "@/store/VuexPlugin";
import { IMachineAddRemoveAttachmentStoreDto } from "@/store/interface/machine.add.remove.machine.attachment.store.interface";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { IMachineCreateStoreDto } from "../interface/machine.create.store.interface";
import { IMachineDeleteStoreDto } from "../interface/machine.delete.store.interface";

/**
 * Manages the machine document
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "machine",
  store
})
class Machine extends VuexModule {
  machineList: IMachineViewmodel[] = [];

  /**
   *
   * Mutation follow
   */

  /**
   * Updates the stored machines
   * @param machineList
   */
  @Mutation
  setAll(machineList: IMachineViewmodel[]) {
    this.machineList = machineList;
  }

  /**
   * Updates the stored machine
   * @param machine
   */
  @Mutation
  appendOneToList(machine: IMachineViewmodel) {
    this.machineList.push(machine);
  }

  /**
   *
   * Actions follow
   */

  /**
   * Gets all machines
   * @param manufacturerId the id of the manufacturer
   */
  @Action
  async getAll(manufacturerId: string) {
    const machineList = await MachineService.getAll(manufacturerId);
    this.context.commit("setAll", machineList);
  }

  @Action
  async delete(machineDeleteStoreDto: IMachineDeleteStoreDto): Promise<boolean> {
    const isDeleted = await MachineService.delete(
      machineDeleteStoreDto.manufacturerId,
      machineDeleteStoreDto.machineId
    );
    await this.getAll(machineDeleteStoreDto.manufacturerId);
    return isDeleted;
  }

  /**
   * Adds an Document to a Machine and refresh state by get all
   * @param addMachineAttachment
   */
  @Action
  async addMachineAttachment(addMachineAttachment: IMachineAddRemoveAttachmentStoreDto) {
    await MachineService.addMachineAttachment(
      addMachineAttachment.manufacturerId,
      addMachineAttachment.machineId,
      addMachineAttachment.documentId
    );
    await this.getAll(addMachineAttachment.manufacturerId);
  }

  /**
   * Removes a Document from a machine and refresh state by get all
   * @param addMachineAttachment
   */
  @Action
  async removeMachineAttachment(addMachineAttachment: IMachineAddRemoveAttachmentStoreDto) {
    await MachineService.removeMachineAttachment(
      addMachineAttachment.manufacturerId,
      addMachineAttachment.machineId,
      addMachineAttachment.documentId
    );
    await this.getAll(addMachineAttachment.manufacturerId);
  }

  /**
   * Creates a machine Attachment and update Store
   * @param machineCreateStoreDto the machineAttachmentStore Dto consists of manufacturerId and machineAttachmentDto
   */
  @Action
  async create(machineCreateStoreDto: IMachineCreateStoreDto) {
    const newMachine = await MachineService.create(machineCreateStoreDto.manufacturerId, machineCreateStoreDto.dto);
    this.context.commit("appendOneToList", newMachine);
  }

  /**
   *
   * Getters follow
   */

  /**
   *
   * Returns all Machines
   */
  get getMachinelist(): IMachineViewmodel[] {
    return this.machineList;
  }
}

export const MachineModule = getModule(Machine);
