


































































































































































import CustomField from "@/components/report/CustomField.vue";
import CustomFieldDateTime from "@/components/report/CustomFieldDateTime.vue";
import { DetailFormComponentsEnum } from "@/lib/enum/detail-form-components.enum";
import { IMDetailFormConfig } from "@/lib/formable";
import { getNestedObjectValues, propertiesToArray } from "@/lib/objectPath-helper";
import { $t } from "@/lib/utility/t";
import RulesMixin from "@/mixins/RulesMixin.vue";
import { ICustomField } from "@/models/custom-field.entity";
import { CustomFieldHelper } from "@/store/modules/custom-field.store";
import Fuse from "fuse.js";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { VAutocomplete, VTextarea } from "vuetify/lib/components";
import Debug from "../Debug.vue";
import PartnerSingleImageUpload from "../PartnerSingleImageUpload.vue";
import RefsSelect from "../RefsSelect.vue";
import SelectAssignees from "../SelectAssignees.vue";
import SelectEntity from "../SelectEntity.vue";
import MDetailFormArrayForm from "./MDetailFormArrayForm.vue";
import GeoCoordinateMap from "../GeoCoordinateMap.vue";
import { RulesMap } from "@/lib/rules/rules.map";
import { findArrayDifferences } from "@/lib/utility/find-array-differences";

export interface IClearableCategory {
  /**
   * The key of the clearable leaf
   */
  key: string;

  /**
   * Name of the category of the key
   */
  category: string;
}
/**
 * Factory to configure a form configuration
 */
export class MDetailFormConfigFactory<T extends Record<string, any>> {
  /**
   * Default category in which every item is placed initially. Can be updated via setCategory for each items
   * @example General, Allgemiaon
   */
  private readonly BASE_CAT = $t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.generalTitle");

  /**
   * Default type of every item. Can be updated via setType for each item
   * @default v-text-field
   */
  private readonly BASE_TYPE = DetailFormComponentsEnum.TEXT_FIELD;

  /**
   * Map of all configs
   * key: key of the config
   * value: config
   */
  private readonly configs = new Map<string, IMDetailFormConfig>();

  /**
   * @param model the object that should be used to create the form
   * @param keys subset of keys that should be used to create the form. If not set all keys of the model are used
   * @param i18nPath path to the i18n file where the labels are stored
   */
  constructor(private readonly model: T, keys?: string[], private readonly i18nPath = "") {
    for (const key of keys ? keys : propertiesToArray(this.model)) {
      const label = $t(this.i18nPath ? [this.i18nPath, key].join(".") : key);
      this.configs.set(key, {
        category: this.BASE_CAT,
        key: key,
        searchKeywords: [this.BASE_CAT, label],
        type: this.BASE_TYPE,
        model: getNestedObjectValues(this.model, key),
        props: {
          label: label
        }
      });
    }
  }

  /**
   * Set the category of the config. Category should be the title of the group
   */
  setCategory(key: string, category: string) {
    const config = this.configs.get(key);

    if (!config) {
      Vue.$log.error(new Error(`Config with key ${key} not found`));

      return this;
    }

    config.category = category;
    config.searchKeywords[0] = category;

    return this;
  }

  /**
   * Set the type of the config. Type defines how the component is rendered
   * default is v-text-field
   */
  setType(key: string, type: DetailFormComponentsEnum) {
    const config = this.configs.get(key);

    if (!config) {
      Vue.$log.error(new Error(`Config with key ${key} not found`));

      return this;
    }

    config.type = type;

    return this;
  }

  /**
   * Add props to the config
   * this can be used to customize the component depending on the type
   */
  addProps(key: string, props: any) {
    const config = this.configs.get(key);

    if (!config) {
      Vue.$log.error(new Error(`Config with key ${key} not found`));

      return this;
    }

    config.props = { ...config.props, ...props };

    return this;
  }

  /**
   * get the list of configs as configured
   */
  create() {
    return Array.from(this.configs.values());
  }
}

type CustomFieldItem = {
  id: string;
  value: number | boolean | string;
};

@Component({
  name: "MDetailForm",
  components: {
    Debug,
    CustomField,
    VTextarea,
    VAutocomplete,
    PartnerSingleImageUpload,
    RefsSelect,
    CustomFieldDateTime,
    SelectEntity,
    SelectAssignees,
    MDetailFormArrayForm,
    GeoCoordinateMap
  }
})
export default class<T extends { [key in keyof T]: CustomFieldItem[] }> extends RulesMixin {
  readonly DetailFormComponentsEnum = DetailFormComponentsEnum;

  /**
   * Title of the table
   */
  @Prop()
  title?: string;

  /**
   * Description of the table
   */
  @Prop()
  description?: string;

  /**
   * Item which data should be displayed
   */
  @Prop()
  item!: T;

  /**
   * Config of the tablev-text
   */
  @Prop()
  config!: IMDetailFormConfig[];

  /**
   * Form is disabled completly
   */
  @Prop({ default: false })
  disabled!: boolean;

  /**
   * Form is readonly
   */
  @Prop({ default: false })
  readonly!: boolean;

  /**
   * key where the custom fields are in the object
   */
  @Prop({ default: "values" })
  customFieldKey!: Extract<keyof T, string>;

  @Prop()
  customCategoryNamePath?: string;

  /**
   * Available custom fields for this form
   */
  @Prop()
  customFields!: ICustomField[];

  /**
   * Key that triggers the loadCustomConfig function
   */
  @Prop()
  customConfigKey?: string;

  /**
   * Function to load custom config, e.g. if groupId changes
   */
  @Prop()
  loadCustomConfig?: (value: string) => Promise<ICustomField[]>;

  @Prop()
  partnerId!: string;

  /**
   * Function that sync changes
   * You have to map the config to your object to update your object changes
   */
  @Prop()
  syncChanges!: () => Promise<void>;

  /**
   * Function to abort changes
   * You have to map the object to your config to abort your changes
   */
  @Prop()
  abortChanges!: () => Promise<void>;

  /**
   * Count of extra panels passed in the extraPanels slot
   * needed to open all panels
   */

  @Prop({ default: 0 })
  extraPanelsCount!: number;

  @Prop({ default: false })
  containButtons!: boolean;

  /**
   * A list of categories that can be cleared.
   */
  @Prop({ default: () => [] })
  clearableCategories!: IClearableCategory[];

  @Prop()
  outsideSearch!: string;

  @Prop()
  hideSearch!: boolean;

  @Prop()
  forceSyncButton!: boolean;

  @Prop()
  autoSync!: boolean;

  @Prop()
  smallTitles?: boolean;

  /**
   * A list of leaves that can be cleared.
   */
  @Prop({ default: () => [] })
  clearableLeaves!: Omit<IClearableCategory, "category">[];

  @Prop()
  loading!: boolean;

  isLoading = false;

  search = "";

  /**
   * If a change of an input field is detected changes detected is set to true
   */
  changesDetected = false;

  /**
   * open panels
   */
  panels: number[] = [];

  /**
   * Used to reevaluate conditions
   */
  inputKey = 0;

  /**
   * Cuz sometimes category does not update in UI when removeCategory(cat),... force rerencder
   */
  removeCategoryUpdateKey = "";

  isValid = true;

  filteredList: IMDetailFormConfig<T>[] = [];

  get customFieldGroup() {
    if (this.customCategoryNamePath) return this.customCategoryNamePath;
    return "custom";
  }

  get clusters() {
    return this.filteredList.map(f => f.category).filter((v, i, a) => a.indexOf(v) === i);
  }

  clearLeaf(key: string) {
    if (!this.isClearableLeaf(key)) {
      throw new Error(`Leaf with key ${key} is not clearable`);
    }

    const config = this.config.find(f => f.key === key);
    if (!config) {
      throw new Error(`Config with key ${key} not found`);
    }

    config.model = "";

    this.onInput(key, "");
  }

  clearCategory(category: string) {
    if (!this.isClearableCategory(category)) {
      throw new Error(`Category with key ${category} is not clearable`);
    }

    const configs = this.config.filter(config => config.category === category);
    configs.forEach(config => {
      config.model = "";
      this.onInput(config.key, "");
    });

    this.removeCategoryUpdateKey = category + Math.random();
  }

  isClearableLeaf(key: string) {
    return !!this.clearableLeaves.find(c => c.key === key);
  }

  isClearableCategory(category: string) {
    return !!this.clearableCategories.find(c => c.category === category);
  }

  getObjectsForCategory(categoryName: string): IMDetailFormConfig[] {
    return this.filteredList.filter(item => item.category === categoryName);
  }

  countObjectsForCategory(categoryName: string): number {
    return this.config.filter(item => item.category === categoryName).length;
  }

  async beforeMount() {
    for (const config of this.config ?? []) {
      // sometimes the items of a select field are not available/ are different on configuration time than on mounted
      if (
        (config.type === DetailFormComponentsEnum.SELECT_FIELD ||
          config.type === DetailFormComponentsEnum.AUTO_COMPLETE) &&
        config.props?.itemCallback
      ) {
        config.props.items = config.props.itemCallback();
      }

      if (config.props?.getPartnerId) {
        config.props.partnerId = config.props.getPartnerId();
      }
    }
    try {
      let customFields = this.customFields ?? [];
      if (!customFields.length && this.customConfigKey && this.loadCustomConfig) {
        this.isLoading = true;
        customFields = await this.loadCustomConfig(getNestedObjectValues(this.item, this.customConfigKey) as string);
      }

      for (const customField of customFields) {
        const index = this.config.findIndex(c => c.key === customField.id);
        if (index !== -1) {
          this.config.splice(index, 1);
        }
        this.config.push(this.createCustomFieldConfig(customField));
      }
    } catch (e) {
      this.$log.error(e);
    }
    this.isLoading = false;

    this.search = "";
    this.setFilteredList();
    this.openAllPanels();
  }

  /**
   * If the customConfigKey is set and the value of the key changes, the custom fields are updated
   * Needed because if for example a groupId is changed indicating that a differnt custom fields config is needed, the custom fields should be updated
   */
  async tryUpdateCustomFields(key: string, value: string) {
    if (!this.customConfigKey || !this.loadCustomConfig) {
      return;
    }

    if (key !== this.customConfigKey) {
      return;
    }

    let newFields: ICustomField[] = [];
    this.isLoading = true;
    const oldFields = this.config.filter(c => c.category === this.customFieldGroup && c.key !== this.customConfigKey);
    try {
      newFields = value ? await this.loadCustomConfig(value) : [];
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.isLoading = false;
    }

    const { addedFields, removedFields } = findArrayDifferences(oldFields, "key", newFields, "id");

    // if newFields has no fields, that are not in oldFields, or vice versa, do nothing
    if (!addedFields.length && !removedFields.length) {
      return;
    }

    // remove old custom fields that are not in the new custom fields
    for (const removedField of removedFields) {
      const index = this.config.findIndex(c => c.key === removedField.key);
      if (index === -1) continue;
      this.config.splice(index, 1);
    }

    // add new custom fields that are not in the old custom fields
    for (const addedField of addedFields) {
      this.config.push(this.createCustomFieldConfig(addedField));
    }

    // if (
    //   newFields.length &&
    //   newFields.every(nf => oldFields?.find(of => of.key === nf.id)) &&
    //   oldFields.length &&
    //   oldFields.every(of => newFields.find(nf => nf.id === of.key))
    // ) {
    //   return;
    // }

    // // remove old custom fields that are not in the new custom fields
    // for (const oldField of oldFields) {
    //   if (!newFields.find(nf => nf.id === oldField.key)) {
    //     const index = this.config.findIndex(c => c.key === oldField.key);
    //     if (index !== -1) {
    //       this.config.splice(index, 1);
    //     }
    //   }
    // }

    // // add new custom fields that are not in the old custom fields
    // for (const newField of newFields) {
    //   if (!oldFields?.find(of => of.key === newField.id)) {
    //     this.config.push(this.createCustomFieldConfig(newField));
    //   }
    // }

    this.setFilteredList();
  }

  @Watch("config", { deep: true })
  @Watch("outsideSearch")
  @Watch("search")
  setFilteredList() {
    const fuse = new Fuse(this.config ?? [], {
      threshold: 0.35,
      keys: ["searchKeywords", "model", "props.label"]
    });
    const fused: IMDetailFormConfig[] = [];
    if (this.outsideSearch) {
      const found = fuse.search(this.outsideSearch).map(f => f.item);
      fused.push(...found);
    } else if (this.search) {
      const found = fuse.search(this.search).map(f => f.item);
      fused.push(...found);
    } else {
      const found = this.config ?? [];
      fused.push(...found);
    }

    this.filteredList.splice(0, this.filteredList.length, ...fused);
  }

  createCustomFieldConfig(field: ICustomField) {
    const found = this.item[this.customFieldKey].find(c => c.id === field.id);
    return {
      category: this.customFieldGroup,
      key: field.id,
      type: DetailFormComponentsEnum.CUSTOM_FIELD,
      model: found?.value ?? "",
      searchKeywords: [field.name, this.customCategoryNamePath ?? this.customFieldGroup],
      props: {
        customField: new CustomFieldHelper(field)
      }
    };
  }

  /**
   * Go through the passed config and translate the properties again.
   * This ensures we always show translated keys no matter if the parent component translated them or not.
   */
  @Watch("$i18n.locale", { immediate: true, deep: false })
  onLocaleChanged() {
    for (const config of this.config) {
      this.translateConfig(config);
    }
  }

  onInput(key: string, value: string) {
    this.tryUpdateCustomFields(key, value);

    this.inputKey++;
    this.validate();

    if (!this.autoSync) {
      this.changesDetected = true;
    } else {
      this.sync();
    }
  }

  createOpenPanelsArray(numberPanels: number) {
    this.panels = Array.from({ length: numberPanels }, (_, index) => index);
  }

  @Watch("search")
  @Watch("outsideSearch")
  openAllPanels() {
    this.createOpenPanelsArray(this.filteredList.length + this.extraPanelsCount);
  }

  areAllValuesInCategoryEmpty(category: string) {
    return this.config.filter(f => f.category === category).every(f => !f.model);
  }

  showClearButton(hover: boolean, inputFieldConfig: IMDetailFormConfig) {
    if (!hover) return false;
    if (!inputFieldConfig.model) return false;

    if (inputFieldConfig.isArray) return false; // arrays are cleared with a button in the header area
    if (inputFieldConfig.type === DetailFormComponentsEnum.SELECT_ENTITY) return false; // select entity has a clear button in it

    if (!this.isClearableLeaf(inputFieldConfig.key)) return false;

    return true;
  }

  validate() {
    for (const config of this.config) {
      if (
        this.clearableCategories.find(c => c.category === config.category) &&
        this.areAllValuesInCategoryEmpty(config.category)
      ) {
        continue;
      }

      // handle array with nested form.
      if (config?.props && !config?.props?.rules?.length) {
        const rules = [];

        for (const rule of config.rules ?? []) {
          const ruleFunction = RulesMap.get(rule);
          if (ruleFunction) {
            rules.push(ruleFunction());
          }
        }

        config.props.rules = rules;
      }

      if (config.props?.rules) {
        // if condition is unsatisfied field is not rendered and not part of form validation
        if (!this.getIsConditionSatisfied(config.condition)) continue;

        for (const rule of config.props.rules) {
          if (typeof rule !== "function") {
            continue;
          }
          const isValid = rule(config.model);
          if (isValid !== true) {
            this.isValid = false;
            return;
          }
        }
      }
    }
    this.isValid = true;
  }

  getFieldForKey(key: string) {
    const field = this.filteredList.find(f => f.key === key);

    return field;
  }

  getIsConditionSatisfied(condition?: { key: string; value: string }) {
    if (!condition) return true;

    const field = this.getFieldForKey(condition.key);

    return field?.model === condition.value;
  }

  /**
   * Translate certain properties of the IMDetailFormConfig object
   * @param config the config with keys for translation.
   * Examples are: props.label, category and the searchKeywords array.
   * This mutates the config object passed.
   */
  private translateConfig(config: IMDetailFormConfig<T>) {
    // translate label
    if (config.props?.label) {
      config.props.label = $t(config.props.label);
    }

    // translate search strings
    config.searchKeywords = config.searchKeywords?.map(keyword => $t(keyword));
  }

  async sync() {
    const customConfig = this.config.filter(c => c.category === this.customFieldGroup);
    for (const config of customConfig) {
      const found = this.item[this.customFieldKey].find(v => v.id === config.key);
      if (config.model) {
        if (found) {
          // change value if custom field already exists on object
          found.value = config.model;
        } else {
          // adds custom field if not exists
          this.item[this.customFieldKey].push({ id: config.key, value: config.model as string });
        }
      } else {
        // Remove the object from the array when config.model is falsy (null or undefined)
        (this.item[this.customFieldKey] as CustomFieldItem[]) = this.item[this.customFieldKey].filter(
          v => v.id !== config.key
        );
      }
    }
    await this.syncChanges();

    if (!this.autoSync) {
      this.changesDetected = false;
    }
  }

  async abort() {
    const customConfig = this.config.filter(c => c.category === this.customFieldGroup);
    for (const config of customConfig) {
      config.model = this.item[this.customFieldKey].find(v => v.id === config.key)?.value || "";
    }
    await this.abortChanges();
    this.changesDetected = false;
  }
}
