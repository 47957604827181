/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */
export enum MachineAttachmentTypeEnum {
  OPERATING_INSTRUCTION = "operating_instructions",
  EG_CONFORMITY_DECLARATION = "eg_conformity_declaration",
  USER_INFO = "user_info",
  RISK_ASSESSMENT = "risk_assessment",
  APPLIED_STANDARD = "applied_standards",
  MACHINE_DRAWING = "machined_rawing",
  TEST_RESULTS = "test_results",
  WIRING_DIAGRAM = "wiring_diagram",
  PIECE_LIST = "piecelist",
  TECHNICAL_INSPECTION_REPORT = "technical_inspection_report",
  PURCHASE_PART_DOCUMENTATION = "purchase_part_documentation",
  SIGNATURE_LIST = "signature_list",
  TECHNICAL_SPEZIFICATION = "technical_spezification",
  REGULATIONS = "regulations",
  TEST_PROTOCOL = "test_protocol",
  OTHER = "other"
}

/**
 *
 * Don't rename this because it's written in law.
 */
export const MachineAttachmentDisplayNames = [
  { displayName: "Betriebsanleitung", enumValues: ["operating_instructions"] },
  { displayName: "EG-Konformitätserklärung", enumValues: ["eg_conformity_declaration"] },
  { displayName: "Nutzerinformation", enumValues: ["user_info"] },
  { displayName: "Risikobeurteilung", enumValues: ["risk_assessment"] },
  { displayName: "Angewandte Normen", enumValues: ["applied_standards"] },
  { displayName: "Zeichnungen", enumValues: ["machined_drawing"] },
  { displayName: "Prüfergebnisse", enumValues: ["test_results"] },
  { displayName: "Schaltplan", enumValues: ["wiring_diagram"] },
  { displayName: "Stückliste", enumValues: ["piecelist"] },
  { displayName: "Technischer Prüfbericht", enumValues: ["technical_inspection_report"] },
  { displayName: "Kaufteildokumentation", enumValues: ["purchase_part_documentation"] },
  { displayName: "Unterschriftenliste", enumValues: ["signature_list"] },
  { displayName: "Spezifikation", enumValues: ["technical_spezification"] },
  { displayName: "Vorschriften", enumValues: ["regulations"] },
  { displayName: "Prüfprotokoll", enumValues: ["test_protocol"] },
  { displayName: "Sonstiges", enumValues: ["other"] }
];
