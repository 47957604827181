var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          attrs: {
            flat: "",
            "max-width": "4000"
          }
        },
        [
          _c(
            "v-card-title",
            {
              staticClass: "title pt-4 mb-n7"
            },
            [
              _c("h4", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "partner.PartnerDetailSettingsOpeningHoursCard.openingHours"
                    )
                  )
                )
              ])
            ]
          ),
          _c(
            "v-card",
            {
              staticClass: "my-2",
              attrs: {
                flat: ""
              }
            },
            [
              _c(
                "v-card-text",
                [
                  _c("PartnerDetailAttributeWithToolTip", {
                    attrs: {
                      tooltip: _vm.$t(
                        "partner.PartnerDetailSettingsOpeningHoursCard.tooltip"
                      ),
                      displayName: _vm.$t(
                        "partner.PartnerDetailSettingsOpeningHoursCard.openingHours"
                      ),
                      icon: "mdi-clock-outline"
                    }
                  }),
                  _vm._l(Object.values(_vm.WeekDays), function(weekday) {
                    return _c(
                      "div",
                      {
                        key: weekday
                      },
                      [
                        _c("PartnerDetailAttributeWithToolTip", {
                          attrs: {
                            tooltip: _vm.$t(
                              "partner.PartnerDetailSettingsOpeningHoursCard.".concat(
                                weekday
                              )
                            ),
                            displayName:
                              _vm.partner.settings.openingHours[weekday],
                            icon: "mdi-alpha-".concat(weekday[0])
                          }
                        })
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }