









































import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";
import PartnerDetailAttributeWithToolTip from "@/components/partner/PartnerDetailAttributeWithToolTip.vue";
import PartnerDetailImage from "@/components/partner/PartnerDetailImage.vue";

@Component({
  components: {
    PartnerDetailAttributeWithToolTip,
    PartnerDetailImage
  }
})
export default class PartnerDetailSettingsReportCard extends Vue {
  virtualScrollerHeight = 0;
  get partner() {
    return PartnerModule.partner;
  }

  get partners() {
    return PartnerModule.partners;
  }
}
