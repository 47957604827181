


















































































































































































































































import { WeekDaysEnum } from "@/lib/enum/weekDays.enum";
import { emailRule, extendetPhoneRule } from "@/lib/rules/contactRule";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import {
  MrfiktivInboxDtoGen,
  MrfiktivPartnerSettingsDocumentGen,
  MrfiktivPartnerSettingsDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { CreatePartnerSettingsDto } from "@/store/models/mrfiktiv/partner-settings";
import { Component, Prop } from "vue-property-decorator";
import PartnerCreateOrUpdateLandingpageSettigns from "./PartnerCreateOrUpdateLandingpageSettigns.vue";
import PartnerCreateOrUpdateLicense from "./PartnerCreateOrUpdateLicense.vue";
import PartnerCreateOrUpdateReportSettings from "./PartnerCreateOrUpdateReportSettings.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";

@Component({
  components: {
    ConfirmActionDialog,
    PartnerCreateOrUpdateLandingpageSettigns,
    PartnerCreateOrUpdateReportSettings,
    PartnerCreateOrUpdateLicense
  }
})
export default class PartnerSettingsForm extends DarkModeHighlightMixin {
  tab = 0;

  @Prop()
  settings!: MrfiktivPartnerSettingsDocumentGen;

  @Prop({ default: false })
  isLoading!: boolean;

  newInbox: MrfiktivInboxDtoGen = {
    source: "email", //TODO add enum
    name: "",
    identifier: "",
    isActive: false
  };

  weekDays = Object.keys(WeekDaysEnum).map(k => WeekDaysEnum[k as any]);

  disabled = true;

  valid = false;

  isUpdateInboxesValid = false;

  update: MrfiktivPartnerSettingsDtoGen = new CreatePartnerSettingsDto(this.settings);

  get requiredRule() {
    return [requiredRule()];
  }

  get mailRule() {
    return [requiredRule(), emailRule()];
  }

  isAddUpdateInboxesDialogActive = false;

  mounted() {
    this.initialize();
  }

  initialize() {
    this.update = new CreatePartnerSettingsDto(this.settings);
  }

  addNewInbox() {
    if (!this.update.inboxes) {
      this.update.inboxes = [];
    }

    this.update.inboxes.push(this.newInbox);
    this.newInbox = {
      source: "email", //TODO add enum
      name: "",
      identifier: "",
      isActive: false
    };
    this.isAddUpdateInboxesDialogActive = false;
  }

  save(): MrfiktivPartnerSettingsDtoGen {
    this.$emit("save", this.update);
    this.disabled = true;

    return this.update;
  }

  abort() {
    this.initialize();
    this.disabled = true;
  }

  get emailRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(emailRule());

    return rules;
  }

  get phoneRules() {
    const rules = [];

    rules.push(extendetPhoneRule());

    return rules;
  }
}
