









import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import OperationDetail from "@/components/operations/OperationDetail.vue";
import { Component, Vue } from "vue-property-decorator";
import { IOperation, Operation } from "@/models/operation.entity";

@Component({
  components: {
    TheLayoutPortal,
    OperationDetail
  }
})
export default class OperationView extends Vue {
  operation: IOperation | null = null;

  isLoading = true;

  async mounted() {
    this.isLoading = true;
    const operationId = this.$route.params.operationId;
    const partnerId = this.$route.params.partnerId;

    this.operation = await new Operation({ id: operationId, partnerId: partnerId }).fetch();

    this.isLoading = false;
  }
}
