





























import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ProjectModule } from "@/store/modules/project.store";
import { ITicket } from "@/models/ticket.entity";
import ProjectGoToMixin from "@/views/project/mixins/ProjectGoToMixin.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import CustomFieldListForm from "../report/CustomFieldListForm.vue";
import TicketForm from "./TicketForm.vue";
import TicketRefsSelect from "./TicketRefsSelect.vue";

@Component({
  components: { TicketForm, CustomFieldListForm, TicketRefsSelect }
})
export default class TicketCreateForm extends mixins(PermissionMixin, ProjectGoToMixin) {
  @Prop()
  value!: ITicket;

  @Prop()
  isTicketFormValid!: boolean;

  @Prop()
  isTicketCustomFieldsValid!: boolean;

  @Prop()
  isReferencesValid!: boolean;

  @Prop()
  partnerId!: string;

  @Prop({ default: "" })
  projectId!: string;

  @Prop({ default: () => [] })
  refs!: MrfiktivReferenceGen[];

  @Prop()
  titleSuggestions?: string[];

  @Prop({ default: false })
  hideTitle!: boolean;

  @Prop({ default: false })
  hideBody!: boolean;

  @Prop({ default: false })
  hideAssignees!: boolean;

  @Prop({ default: false })
  hideDue!: boolean;

  @Prop({ default: false })
  hideTags!: boolean;

  @Prop({ default: false })
  hideProject!: boolean;

  @Prop({ default: false })
  hideReferences!: boolean;

  get ticket() {
    return this.value;
  }

  set ticket(ticket: ITicket) {
    this.$emit("update:value", ticket);
  }

  get isTicketFormValidLocal() {
    return this.isTicketFormValid;
  }

  set isTicketFormValidLocal(v: boolean) {
    this.$emit("update:isTicketFormValid", v);
  }

  get isTicketCustomFieldsValidLocal() {
    return this.isTicketCustomFieldsValid;
  }

  set isTicketCustomFieldsValidLocal(v: boolean) {
    this.$emit("update:isTicketCustomFieldsValid", v);
  }

  get isReferencesValidLocal() {
    return this.isReferencesValid;
  }

  set isReferencesValidLocal(v: boolean) {
    this.$emit("update:isReferencesValid", v);
  }

  get selectedProject() {
    const selectedId = this.ticket.projectId;
    if (!selectedId) {
      return undefined;
    }

    return ProjectModule._data.get(selectedId);
  }
}
