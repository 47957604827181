

































































































import CustomerContactCard from "@/components/partner/CustomerContactCard.vue";
import PartnerDetailAttributeWithToolTip from "@/components/partner/PartnerDetailAttributeWithToolTip.vue";
import PartnerRegistrationCard from "@/components/partner/PartnerRegistrationCard.vue";
import PartnerReportDetailImageCard from "@/components/partner/PartnerReportDetailImageCard.vue";
import PartnerReportDetailInfoDateCard from "@/components/partner/PartnerReportDetailInfoDateCard.vue";
import PartnerReportDetailInfoMessageCard from "@/components/partner/PartnerReportDetailInfoMessageCard.vue";
import PartnerReportDetailMetaCard from "@/components/partner/PartnerReportDetailMetaCard.vue";
import { IExportReportMeta } from "@/lib/interfaces/Report/export-report-meta.interface";
import { simpleDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { RegistrationResultsEntity } from "@/models/registrationResultsEntity";
import { ExportModule } from "@/store/modules/export.store";
import { PartnerModule } from "@/store/modules/partner";
import { Component } from "vue-property-decorator";
import PartnerReportDetailInfoWitnessCard from "./PartnerReportDetailInfoWitnessCard.vue";
import DamageLocatorCombined from "@/components/damagelocator/DamageLocatorCombined.vue";

@Component({
  components: {
    PartnerDetailAttributeWithToolTip,
    CustomerContactCard,
    PartnerReportDetailInfoMessageCard,
    PartnerReportDetailInfoDateCard,
    PartnerRegistrationCard,
    PartnerReportDetailImageCard,
    PartnerReportDetailMetaCard,
    PartnerReportDetailInfoWitnessCard,
    DamageLocatorCombined
  }
})
export default class PartnerReportDetailInfoCard extends DarkModeHighlightMixin {
  renderComponent = true;
  registrationUpdateLoading = false;

  get report() {
    return PartnerModule.report;
  }

  get getImages() {
    return PartnerModule.report.images;
  }

  get hasImages() {
    return (
      this.getImages.cockpits.length >= 1 ||
      this.getImages.damages.length >= 1 ||
      this.getImages.damagesDetail.length >= 1 ||
      this.getImages.overviews.length >= 1 ||
      this.getImages.registrations.length >= 1
    );
  }

  get hasBody() {
    if (!this.report.body) {
      return false;
    }

    return this.report.body.length >= 1;
  }

  get witness() {
    return PartnerModule?.report?.witness || [];
  }

  get getRegistrationImageLink(): string {
    if (PartnerModule.report.images.registrations.length === 0) {
      return "";
    }

    if (PartnerModule.report.images.registrations[0].url) {
      return PartnerModule.report.images.registrations[0].url;
    } else {
      return "";
    }
  }

  get metas(): IExportReportMeta[] {
    return ExportModule.exportReportMetas.get(PartnerModule.report._id) || [];
  }

  get damageLocation() {
    this.forceRerender();
    return this.report.damage;
  }

  get getDamageLocatorWidth() {
    return "250px";
  }

  /**
   * Calculates how many meta cards are shown per row
   */
  get metaRowAmount() {
    const maxItemsPerRow = 4;

    return this.metas.length < maxItemsPerRow + 1 ? 12 / this.metas.length : maxItemsPerRow;
  }

  forceRerender() {
    // remove the my-component component from the DOM
    this.renderComponent = false;

    this.$nextTick(() => {
      // add my-component component in DOM
      this.renderComponent = true;
    });
  }

  async updateRegistration(registration: RegistrationResultsEntity) {
    this.registrationUpdateLoading = true;
    if (!registration) {
      this.$toast.error("Deine Anfrage konnte nicht versendet werden.");
    }

    const partnerEntity = await PartnerModule.report.addRegistrationResults(registration);

    if (!partnerEntity) {
      this.$toast.error("Deine Anfrage konnte nicht versendet werden.");
    }

    this.registrationUpdateLoading = false;
  }

  get datePreference() {
    return simpleDate(PartnerModule.report.datePreference);
  }
}
