


























































import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";
import PartnerDetailAttributeWithToolTip from "@/components/partner/PartnerDetailAttributeWithToolTip.vue";
import PartnerDetailImage from "@/components/partner/PartnerDetailImage.vue";

@Component({
  components: {
    PartnerDetailAttributeWithToolTip,
    PartnerDetailImage
  }
})
export default class PartnerDetailBookedServicesCard extends Vue {
  virtualScrollerHeight = 0;
  get partner() {
    return PartnerModule.partner;
  }

  get registrationService() {
    return this.partner?.settings?.bookedServices?.registrationService;
  }

  get landingpageService() {
    return this.partner?.settings?.bookedServices?.form;
  }

  get reportService() {
    return this.partner?.settings?.bookedServices?.report;
  }

  get paymentService() {
    return this.partner?.settings?.bookedServices?.payment;
  }

  get isDataSharingActive() {
    return this.partner?.settings?.isDatasharingActive;
  }

  get registrationShowResults() {
    return this.partner?.settings?.emailSettings?.registrationShowResults;
  }

  get registrationShowKsrButton() {
    return this.partner?.settings?.emailSettings?.registrationShowKsrButton;
  }

  get urls() {
    return this.partner?.settings?.urls;
  }

  get partners() {
    return PartnerModule.partners;
  }
}
