var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-list-item",
    {
      attrs: {
        "three-line": ""
      }
    },
    [
      _vm.isLoading || (_vm.group && _vm.group.loading)
        ? _c("v-skeleton-loader", {
            attrs: {
              type: "list-item-two-line"
            }
          })
        : _vm.group
        ? _c(
            "v-list-item-content",
            [
              _c(
                "v-list-item-title",
                {
                  staticClass: "text-truncate "
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex"
                      }
                    },
                    [
                      _c("div", [
                        _vm.hasOpenDetailListener()
                          ? _c(
                              "a",
                              {
                                on: {
                                  click: function click($event) {
                                    $event.stopPropagation()
                                    return _vm.emitOpenDetail.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.group.title) + " ")]
                            )
                          : _c("span", [
                              _vm._v(" " + _vm._s(_vm.group.title) + " ")
                            ])
                      ]),
                      _c("v-spacer")
                    ],
                    1
                  )
                ]
              ),
              _vm.group.description
                ? _c(
                    "v-list-item-subtitle",
                    {
                      staticClass: "text-xs"
                    },
                    [_vm._v(" " + _vm._s(_vm.group.description) + " ")]
                  )
                : _vm._e()
            ],
            1
          )
        : _c("latest-entries-card-empty")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }