










import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class PartnerCreateRequirementsList extends Vue {
  @Prop()
  requirementList!: any;

  fail = "✘";
  working = "✔";

  validate() {
    this.$emit("validate");
  }
}
