










































import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { Ticket } from "@/models/ticket.entity";
import ProjectGoToMixin from "@/views/project/mixins/ProjectGoToMixin.vue";
import TicketCrudMixin from "@/views/project/mixins/TicketCrudMixin.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import TicketCreateCard from "./TicketCreateCard.vue";

@Component({
  components: { ConfirmActionDialog, TicketCreateCard },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class TicketCreateDialog extends mixins(PermissionMixin, ProjectGoToMixin, TicketCrudMixin) {
  @Prop()
  projectId?: string;

  @Prop()
  partnerIdProp!: string;

  @Prop()
  smallBtn!: boolean;

  @Prop()
  private isDialogActive!: boolean;

  @Prop()
  hideButton?: boolean;

  @Prop()
  isIconButton?: boolean;

  @Prop({ default: () => [] })
  refs!: MrfiktivReferenceGen[];

  @Prop({ default: false })
  hideToast!: boolean;

  @Prop()
  onStartDialog?: Function;

  beforeMount() {
    this.partnerId = this.partnerIdProp;
  }

  isLoadingStartDialog = false;

  isLoading = false;

  ticket = new Ticket({ projectId: this.projectId, refs: this.refs });

  get dialogActive() {
    return this.isDialogActive;
  }

  set dialogActive(v: boolean) {
    this.$emit("update:isDialogActive", v);
  }

  close() {
    this.dialogActive = false;
    this.$emit("close");
  }

  async startDialog() {
    this.isLoadingStartDialog = true;

    if (this.onStartDialog) await this.onStartDialog();
    this.ticket = new Ticket({ projectId: this.projectId, refs: this.refs });

    this.dialogActive = true;
    this.isLoadingStartDialog = false;
  }

  /**
   * Method to create ticket
   */
  async onCreateTicket() {
    if (!this.ticket) return;

    this.isLoading = true;

    this.ticket.partnerId = this.$route.params.partnerId;
    const created = await this.ticket.create();

    this.isLoading = false;

    if (!created) return;

    this.ticket = new Ticket({ projectId: this.projectId, refs: this.refs });

    this.dialogActive = false;

    this.$emit("created", created);

    if (!this.hideToast) {
      this.$toast.success(this.$t("project.ticket.created", { number: created.number }).toString(), {
        onClick: () => {
          this.goTo.ticketDetailSideCard(created);
        }
      });
    }
  }
}
