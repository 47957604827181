import { AppContextEnum } from "@/lib/enum/appContext.enum";
import { deepCopy } from "@/lib/utility/deep-copy";
import billingInformationService from "@/services/thg/services/billingInformationService";
import { ThgBillingInformationViewmodelGen, ThgCreateBillingInformationDtoGen } from "@/services/thg/v1/data-contracts";
import store from "@/store/VuexPlugin";
import Vue from "vue";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { CountryCodeEnum } from "../../lib/enum/country-code.enum";
import { ConfigModule } from "./config";

@Module({
  dynamic: true,
  namespaced: true,
  name: "billing-information",
  store
})
export class BillingInformationStore extends VuexModule {
  /**
   * Empty BillingInformation
   */
  _emptyBillingInformation: ThgBillingInformationViewmodelGen = {
    id: "",
    partnerId: "",
    firstName: "",
    lastName: "",
    isCompany: false,
    isTaxDeductible: false,
    company: "",
    taxnumber: "",
    contact: {
      email: "",
      phone: ""
    },
    address: {
      state: "",
      city: "",
      zip: "",
      street: "",
      countryCode: CountryCodeEnum.germany
    },
    timestamp: {
      created: "",
      modified: [],
      lastModified: ""
    }
  };

  /**
   * My Banking information
   */
  private _partnerBillingInformation: ThgBillingInformationViewmodelGen = {
    id: "",
    partnerId: "",
    firstName: "",
    lastName: "",
    isCompany: false,
    isTaxDeductible: false,
    company: "",
    taxnumber: "",
    contact: {
      email: "",
      phone: ""
    },
    address: {
      state: "",
      city: "",
      zip: "",
      street: "",
      countryCode: CountryCodeEnum.germany
    },
    timestamp: {
      created: "",
      modified: [],
      lastModified: ""
    }
  };

  get partnerBillingInformation(): ThgBillingInformationViewmodelGen {
    return this._partnerBillingInformation;
  }

  get emptyPartnerBillingInformation(): ThgBillingInformationViewmodelGen {
    return deepCopy(this._emptyBillingInformation);
  }

  get isBillingInformation(): boolean {
    return this._partnerBillingInformation.id !== "";
  }

  @Mutation
  _muatatePartnerBillingInformation(billingInformation: ThgBillingInformationViewmodelGen) {
    this._partnerBillingInformation = billingInformation;
  }

  @Action
  async getPartnerBillingInformation(partnerId: string) {
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      const partnerBillingInformation: ThgBillingInformationViewmodelGen = await billingInformationService.getBillingInformationForPartner(
        partnerId
      );
      this.context.commit("_muatatePartnerBillingInformation", partnerBillingInformation);

      return partnerBillingInformation;
    } else {
      Vue.$log.error(`getPartnerBillingInformation not available in app context ${ConfigModule.appContext}`);
    }
  }

  @Action
  async createOrUpdateBillingInformation(dto: {
    partnerId: string;
    createOrUpdateDto: ThgCreateBillingInformationDtoGen;
  }) {
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      const partnerBillingInformation: ThgBillingInformationViewmodelGen = await billingInformationService.createOrUpdateBillingInformationForPartner(
        dto.partnerId,
        dto.createOrUpdateDto
      );

      this.context.commit("_muatatePartnerBillingInformation", partnerBillingInformation);
    } else {
      Vue.$log.error(`createOrUpdateBillingInformation not available in app context ${ConfigModule.appContext}`);
    }
  }
}

export const BillingInformationModule = getModule(BillingInformationStore);
