var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "800",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
        transition: "dialog-bottom-transition"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function fn(_ref) {
            var on = _ref.on,
              attrs = _ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "mx-2",
                      attrs: {
                        icon: ""
                      }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_c("v-icon", [_vm._v("mdi-help-circle-outline")])],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function callback($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        {
          attrs: {
            flat: ""
          }
        },
        [
          _c(
            "v-card-title",
            {
              style: _vm.partnerColor.gradientStyle
            },
            [
              _c(
                "span",
                {
                  style: "color:white"
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("components.thg.ThgVehicleTable.help.title"))
                  )
                ]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: _vm.partnerColor.primaryDarken_3,
                    icon: ""
                  },
                  on: {
                    click: _vm.close
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            {
              staticClass: "mt-4"
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12"
                      }
                    },
                    [
                      _c("v-chip", {
                        attrs: {
                          small: "",
                          color: "success"
                        }
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "components.thg.ThgVehicleTable.help.success"
                            )
                          ) +
                          " "
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12"
                      }
                    },
                    [
                      _c("v-chip", {
                        attrs: {
                          small: "",
                          color: "info"
                        }
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("components.thg.ThgVehicleTable.help.info")
                          ) +
                          " "
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12"
                      }
                    },
                    [
                      _c("v-chip", {
                        attrs: {
                          small: "",
                          color: "warning"
                        }
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "components.thg.ThgVehicleTable.help.warning"
                            )
                          ) +
                          " "
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12"
                      }
                    },
                    [
                      _c("v-chip", {
                        attrs: {
                          small: "",
                          color: "error"
                        }
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("components.thg.ThgVehicleTable.help.error")
                          ) +
                          " "
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }