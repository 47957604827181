













import { Component, Vue } from "vue-property-decorator";
import DocphantFooter from "@/components/docphant/DocphantFooter.vue";
import TheLayoutDocphant from "@/layouts/TheLayoutDocphant.vue";
import LoginCardUnauthorized from "@/components/login/LoginCardUnauthorized.vue";
import { DocphantModule } from "@/store/modules/docphant.ts";

@Component({
  components: {
    TheLayoutDocphant,
    DocphantFooter,
    LoginCardUnauthorized
  }
})
export default class ManufacturerListPage extends Vue {
  get partner() {
    return DocphantModule.ibLeimkuehler;
  }
}
