








































import ContactCustomerDialog from "@/components/utility/ContactCustomerDialog.vue";
import { GoToHelper } from "@/lib/utility/goToHelper";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { ITicket } from "@/models/ticket.entity";
import { MrfiktivUpdateReportDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { PartnerModule } from "@/store/modules/partner";
import ProjectGoToMixin from "@/views/project/mixins/ProjectGoToMixin.vue";
import QrcodeVue from "qrcode.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import CreateReportForm from "../fleet/CreateReportForm.vue";
import Card from "../utility/Card.vue";
import RefsTicket from "../utility/RefsTicket.vue";
import TableWrapper from "../utility/TableWrapper.vue";
import PartnerReportBatchImportDialog from "./PartnerReportBatchImportDialog.vue";
import PartnerReportInitializeCard from "./PartnerReportInitializeCard.vue";
import PartnerReportInitializeCardMixin, {
  PartnerReportInitializeDialogPagesEnum
} from "./PartnerReportInitializeCardMixin.vue";

@Component({
  components: {
    ContactCustomerDialog,
    PartnerReportInitializeCard,
    QrcodeVue,
    Card,
    TableWrapper,
    PartnerReportBatchImportDialog,
    CreateReportForm,
    RefsTicket
  }
})
export default class PartnerReportInitializeDialogEmptySuccess extends mixins(
  PartnerReportInitializeCardMixin,
  PermissionMixin,
  ProjectGoToMixin
) {
  @Prop()
  step!: PartnerReportInitializeDialogPagesEnum;

  @Prop({
    default: () => []
  })
  tickets!: ITicket[];

  loading = false;

  updateReportDto: MrfiktivUpdateReportDtoGen = {};

  isValid = false;

  get partnerId() {
    return this.$route.params.partnerId ?? PartnerModule.partner.id;
  }

  get reportId() {
    return PartnerModule.report._id;
  }

  async openReport() {
    new GoToHelper(this.$router).goToReportDetail(this.reportId, this.partnerId);
  }

  createTicket() {
    this.setNextStep(PartnerReportInitializeDialogPagesEnum.CREATE_TICKET);
  }

  openDetail(ticket: ITicket) {
    this.goTo.ticketDetail({ partnerId: ticket.partnerId, number: ticket.number, newTab: true });
  }
}
