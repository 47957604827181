var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-simple",
    [
      _c(
        "v-container",
        [
          _c("partner-faq-detail-card", {
            attrs: {
              isDetailLoading: _vm.isLoadingDetail,
              faq: _vm.faq
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }