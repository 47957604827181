





























import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import {
  MrfiktivCreateActivityLogDtoGen,
  MrfiktivReferenceGen,
  MrfiktivReportViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { ActivityLogModule, ActivityTypeEnum } from "@/store/modules/activity-log.store";
import { PartnerModule } from "@/store/modules/partner";
import { ITicket, Ticket } from "@/models/ticket.entity";
import ProjectInitializeListsMixin from "@/views/project/mixins/ProjectInitializeListsMixin.vue";
import TicketCrudMixin from "@/views/project/mixins/TicketCrudMixin.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import TicketCreateCard from "../project/TicketCreateCard.vue";
import Card from "../utility/Card.vue";
import PartnerReportInitializeCardMixin, {
  PartnerReportInitializeDialogPagesEnum
} from "./PartnerReportInitializeCardMixin.vue";
import { ProjectModule } from "@/store/modules/project.store";

@Component({
  components: {
    TicketCreateCard,
    Card
  }
})
export default class PartnerReportInitializeDialogOnCreateTicket extends mixins(
  PartnerReportInitializeCardMixin,
  ProjectInitializeListsMixin,
  TicketCrudMixin
) {
  @Prop()
  step!: PartnerReportInitializeDialogPagesEnum;

  @Prop()
  report!: MrfiktivReportViewModelGen;

  loading = false;

  isTicketFormValid = false;

  isTicketCustomFieldsValid = true;

  isReferencesValid = true;

  isCreateLoading = false;

  ticket: ITicket = new Ticket();

  get selectedProject() {
    return ProjectModule.maps.id.get(this.ticket.projectId ?? "");
  }

  beforeMount() {
    const ticket = new Ticket();
    const vehicleId = this.report.vehicleId ?? this.$route.params.vehicleId;
    this.partnerId = this.report.partnerId ?? this.$route.params.partnerId ?? PartnerModule.partner.id;
    ticket.refs = [
      {
        refId: this.report.id ?? this.report._id,
        refType: BackendResourceEnum.REPORT
      }
    ];

    if (vehicleId) {
      ticket.refs.push({
        refId: vehicleId,
        refType: BackendResourceEnum.VEHICLE
      });
    }

    ticket.title = this.report.numberplate;

    this.ticket = ticket;
  }

  async mounted() {
    this.loading = true;

    await this.initializeProjectLists(this.partnerId);

    this.loading = false;
  }

  async openReport() {
    new GoToHelper(this.$router).goToReportDetail(this.report.id, this.report.partnerId);
  }

  back() {
    this.setNextStep(PartnerReportInitializeDialogPagesEnum.EMPTY_SUCCESS);
  }

  /**
   * Method to create ticket
   * @param {Ticket} ticket the ticket to create
   */
  async onCreateTicket() {
    this.isCreateLoading = true;

    this.ticket.partnerId = this.partnerId;

    const created = await this.ticket.create();

    this.isCreateLoading = false;

    if (!created?.id) {
      return;
    }

    this.$emit("created", created);

    this.$toast.success("👍");

    const source: MrfiktivReferenceGen = {
      refType: BackendResourceEnum.REPORT,
      refId: this.report._id
    };

    const log: MrfiktivCreateActivityLogDtoGen = {
      source,
      target: [{ refType: BackendResourceEnum.TICKET, refId: created.id }],
      actionType: ActionEnum.CREATE,
      activity: ActivityTypeEnum.CREATE_TICKET
    };

    await ActivityLogModule.create({
      partnerId: created.partnerId,
      data: log
    }).catch(handleError);

    this.setNextStep(PartnerReportInitializeDialogPagesEnum.EMPTY_SUCCESS);
  }
}
