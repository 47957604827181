var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-dialog",
    {
      attrs: {
        width: "600px"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function fn(_ref) {
            var on = _ref.on,
              attrs = _ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        icon: ""
                      },
                      on: {
                        click: _vm.resetDialog
                      }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c(
                    "v-icon",
                    {
                      attrs: {
                        color: "grey lighten-1"
                      }
                    },
                    [_vm._v("mdi-plus")]
                  )
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function callback($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "title pt-4",
              class: _vm.color
            },
            [
              _c(
                "h4",
                {
                  staticClass: "text-truncate"
                },
                [_vm._v("Neue Berechtigung anlegen.")]
              )
            ]
          ),
          _c("v-divider"),
          _c(
            "v-window",
            {
              model: {
                value: _vm.step,
                callback: function callback($$v) {
                  _vm.step = $$v
                },
                expression: "step"
              }
            },
            [
              _c(
                "v-window-item",
                {
                  attrs: {
                    value: 1
                  }
                },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("p", [
                        _vm._v(
                          ' Wählen Sie "Standard", wenn es Sie nur die Berechtigungen für die Landingpages und Schadensmeldung hinzufügen möchten. Bei komplexeren Berechtigungen wählen Sie "Individuell". '
                        )
                      ]),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-2",
                          on: {
                            click: _vm.setReportStandardPermissions
                          }
                        },
                        [_vm._v("Standard")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-2",
                          on: {
                            click: _vm.setThgStandardPermissions
                          }
                        },
                        [_vm._v("Standard-THG")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-2",
                          on: {
                            click: function click($event) {
                              _vm.step = 3
                            }
                          }
                        },
                        [_vm._v("Individuell")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-window-item",
                {
                  attrs: {
                    value: 2
                  }
                },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("p", [_vm._v("Bitte wählen Sie den Partner aus.")]),
                      _c("v-autocomplete", {
                        attrs: {
                          rules: _vm.rules,
                          items: _vm.partners,
                          "item-value": "_id",
                          "item-text": "companyName",
                          label: "Partner"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function fn(data) {
                              return [
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", {
                                      domProps: {
                                        innerHTML: _vm._s(data.item.companyName)
                                      }
                                    }),
                                    _c("v-list-item-subtitle", [
                                      _vm._v(
                                        _vm._s(data.item.companyUsername) +
                                          " (" +
                                          _vm._s(data.item.id) +
                                          ")"
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.standardPermissions.id,
                          callback: function callback($$v) {
                            _vm.$set(_vm.standardPermissions, "id", $$v)
                          },
                          expression: "standardPermissions.id"
                        }
                      }),
                      _c("v-checkbox", {
                        attrs: {
                          label:
                            _vm.$t("BackendResourceEnum.partner") + " (lesen)"
                        },
                        model: {
                          value: _vm.standardPermissions.partner,
                          callback: function callback($$v) {
                            _vm.$set(_vm.standardPermissions, "partner", $$v)
                          },
                          expression: "standardPermissions.partner"
                        }
                      }),
                      _c("v-checkbox", {
                        attrs: {
                          label: _vm.$t("BackendResourceEnum.form")
                        },
                        model: {
                          value: _vm.standardPermissions.form,
                          callback: function callback($$v) {
                            _vm.$set(_vm.standardPermissions, "form", $$v)
                          },
                          expression: "standardPermissions.form"
                        }
                      }),
                      _c("v-checkbox", {
                        attrs: {
                          label: _vm.$t("BackendResourceEnum.report")
                        },
                        model: {
                          value: _vm.standardPermissions.report,
                          callback: function callback($$v) {
                            _vm.$set(_vm.standardPermissions, "report", $$v)
                          },
                          expression: "standardPermissions.report"
                        }
                      }),
                      _c("v-checkbox", {
                        attrs: {
                          label: _vm.$t(
                            "BackendResourceEnum.setting_partner_internal"
                          )
                        },
                        model: {
                          value: _vm.standardPermissions.settings,
                          callback: function callback($$v) {
                            _vm.$set(_vm.standardPermissions, "settings", $$v)
                          },
                          expression: "standardPermissions.settings"
                        }
                      }),
                      _c("v-checkbox", {
                        attrs: {
                          label: _vm.$t("BackendResourceEnum.booking")
                        },
                        model: {
                          value: _vm.standardPermissions.booking,
                          callback: function callback($$v) {
                            _vm.$set(_vm.standardPermissions, "booking", $$v)
                          },
                          expression: "standardPermissions.booking"
                        }
                      }),
                      _c("v-checkbox", {
                        attrs: {
                          label: _vm.$t("BackendResourceEnum.shared_content")
                        },
                        model: {
                          value: _vm.standardPermissions.share,
                          callback: function callback($$v) {
                            _vm.$set(_vm.standardPermissions, "share", $$v)
                          },
                          expression: "standardPermissions.share"
                        }
                      }),
                      _c("v-checkbox", {
                        attrs: {
                          label: _vm.$t("BackendResourceEnum.vehicle")
                        },
                        model: {
                          value: _vm.standardPermissions.vehicle,
                          callback: function callback($$v) {
                            _vm.$set(_vm.standardPermissions, "vehicle", $$v)
                          },
                          expression: "standardPermissions.vehicle"
                        }
                      }),
                      _c("v-checkbox", {
                        attrs: {
                          label: _vm.$t("BackendResourceEnum.document")
                        },
                        model: {
                          value: _vm.standardPermissions.document,
                          callback: function callback($$v) {
                            _vm.$set(_vm.standardPermissions, "document", $$v)
                          },
                          expression: "standardPermissions.document"
                        }
                      }),
                      _c("v-checkbox", {
                        attrs: {
                          label: _vm.$t("BackendResourceEnum.sign")
                        },
                        model: {
                          value: _vm.standardPermissions.sign,
                          callback: function callback($$v) {
                            _vm.$set(_vm.standardPermissions, "sign", $$v)
                          },
                          expression: "standardPermissions.sign"
                        }
                      }),
                      _c("v-checkbox", {
                        attrs: {
                          label: _vm.$t("BackendResourceEnum.template")
                        },
                        model: {
                          value: _vm.standardPermissions.template,
                          callback: function callback($$v) {
                            _vm.$set(_vm.standardPermissions, "template", $$v)
                          },
                          expression: "standardPermissions.template"
                        }
                      }),
                      _c("v-checkbox", {
                        attrs: {
                          label: _vm.$t("BackendResourceEnum.message")
                        },
                        model: {
                          value: _vm.standardPermissions.message,
                          callback: function callback($$v) {
                            _vm.$set(_vm.standardPermissions, "message", $$v)
                          },
                          expression: "standardPermissions.message"
                        }
                      }),
                      _c("v-checkbox", {
                        attrs: {
                          label: _vm.$t("BackendResourceEnum.partner_banking")
                        },
                        model: {
                          value: _vm.standardPermissions.partnerBanking,
                          callback: function callback($$v) {
                            _vm.$set(
                              _vm.standardPermissions,
                              "partnerBanking",
                              $$v
                            )
                          },
                          expression: "standardPermissions.partnerBanking"
                        }
                      }),
                      _c("v-checkbox", {
                        attrs: {
                          label: _vm.$t(
                            "BackendResourceEnum.billing_information"
                          )
                        },
                        model: {
                          value: _vm.standardPermissions.billingInformation,
                          callback: function callback($$v) {
                            _vm.$set(
                              _vm.standardPermissions,
                              "billingInformation",
                              $$v
                            )
                          },
                          expression: "standardPermissions.billingInformation"
                        }
                      }),
                      _c("v-checkbox", {
                        attrs: {
                          label:
                            _vm.$t("BackendResourceEnum.billing") + " (lesen)"
                        },
                        model: {
                          value: _vm.standardPermissions.billing,
                          callback: function callback($$v) {
                            _vm.$set(_vm.standardPermissions, "billing", $$v)
                          },
                          expression: "standardPermissions.billing"
                        }
                      }),
                      _c("v-checkbox", {
                        attrs: {
                          label:
                            _vm.$t("BackendResourceEnum.operation") + " (lesen)"
                        },
                        model: {
                          value: _vm.standardPermissions.operations,
                          callback: function callback($$v) {
                            _vm.$set(_vm.standardPermissions, "operations", $$v)
                          },
                          expression: "standardPermissions.operations"
                        }
                      }),
                      _c("v-checkbox", {
                        attrs: {
                          label:
                            _vm.$t("BackendResourceEnum.analytics") + " (lesen)"
                        },
                        model: {
                          value: _vm.standardPermissions.analytics,
                          callback: function callback($$v) {
                            _vm.$set(_vm.standardPermissions, "analytics", $$v)
                          },
                          expression: "standardPermissions.analytics"
                        }
                      }),
                      _c("v-checkbox", {
                        attrs: {
                          label:
                            _vm.$t("BackendResourceEnum.partner") +
                            " (administration)"
                        },
                        model: {
                          value: _vm.standardPermissions.admin,
                          callback: function callback($$v) {
                            _vm.$set(_vm.standardPermissions, "admin", $$v)
                          },
                          expression: "standardPermissions.admin"
                        }
                      }),
                      _c("v-checkbox", {
                        attrs: {
                          label: _vm.$t("BackendResourceEnum.customer_data")
                        },
                        model: {
                          value: _vm.standardPermissions.customer_data,
                          callback: function callback($$v) {
                            _vm.$set(
                              _vm.standardPermissions,
                              "customer_data",
                              $$v
                            )
                          },
                          expression: "standardPermissions.customer_data"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: ""
                          },
                          on: {
                            click: function click($event) {
                              _vm.step = 1
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("back")) + " ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            loading: _vm.loading,
                            disabled: !_vm.standardPermissions.id
                          },
                          on: {
                            click: _vm.addPermissionsStandard
                          }
                        },
                        [_vm._v(" Zuweisen ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-window-item",
                {
                  attrs: {
                    value: 3
                  }
                },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("p", [_vm._v("Bitte wählen Sie den Partner aus.")]),
                      _c("v-autocomplete", {
                        attrs: {
                          rules: _vm.rules,
                          items: _vm.partners,
                          "item-value": "_id",
                          "item-text": "companyName",
                          label: "Partner *"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function fn(data) {
                              return [
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", {
                                      domProps: {
                                        innerHTML: _vm._s(data.item.companyName)
                                      }
                                    }),
                                    _c("v-list-item-subtitle", [
                                      _vm._v(
                                        _vm._s(data.item.companyUsername) +
                                          " (" +
                                          _vm._s(data.item.id) +
                                          ")"
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.newPermission.id,
                          callback: function callback($$v) {
                            _vm.$set(_vm.newPermission, "id", $$v)
                          },
                          expression: "newPermission.id"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: ""
                          },
                          on: {
                            click: function click($event) {
                              _vm.step--
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("back")) + " ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: !_vm.newPermission.id
                          },
                          on: {
                            click: function click($event) {
                              _vm.step++
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("next")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-window-item",
                {
                  attrs: {
                    value: 4
                  }
                },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("p", [
                        _vm._v("Bitte wählen Sie den Ressourcen Typ aus.")
                      ]),
                      _c("v-autocomplete", {
                        attrs: {
                          rules: _vm.rules,
                          items: _vm.resource,
                          type: "text",
                          label: "Ressourcentyp *",
                          outlined: "",
                          required: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function fn(_ref2) {
                              var item = _ref2.item
                              return [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "BackendResourceEnum.".concat(item)
                                        )
                                      ) +
                                      " "
                                  )
                                ]),
                                _c("v-list-item-subtitle", [
                                  _vm._v(" " + _vm._s(item) + " ")
                                ])
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.newPermission.type,
                          callback: function callback($$v) {
                            _vm.$set(_vm.newPermission, "type", $$v)
                          },
                          expression: "newPermission.type"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: ""
                          },
                          on: {
                            click: function click($event) {
                              _vm.step = 1
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("back")) + " ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: !_vm.newPermission.type
                          },
                          on: {
                            click: function click($event) {
                              _vm.step++
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("next")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-window-item",
                {
                  attrs: {
                    value: 5
                  }
                },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("p", [
                        _vm._v(
                          "Bitte wählen Sie die entsprechenden Berechtigungen aus."
                        )
                      ]),
                      _c("v-select", {
                        attrs: {
                          items: _vm.actions,
                          filled: "",
                          chips: "",
                          multiple: ""
                        },
                        model: {
                          value: _vm.newPermission.action,
                          callback: function callback($$v) {
                            _vm.$set(_vm.newPermission, "action", $$v)
                          },
                          expression: "newPermission.action"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: ""
                          },
                          on: {
                            click: function click($event) {
                              _vm.step--
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("back")) + " ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: _vm.isDisabled,
                            loading: _vm.loading
                          },
                          on: {
                            click: _vm.addPermissions
                          }
                        },
                        [_vm._v(" Speichern ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }