var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "span",
    [
      _vm.isDifferent
        ? _c(
            "v-btn",
            {
              attrs: {
                fab: "",
                elevation: 0,
                loading: _vm.loading,
                color: _vm.isDifferent ? "success" : "",
                dense: ""
              },
              on: {
                click: _vm.save
              }
            },
            [_c("v-icon", [_vm._v(" mdi-content-save-alert ")])],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }