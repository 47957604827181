var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "span",
    [
      !_vm.hideButton
        ? _c(
            "v-btn",
            {
              staticClass: "mx-2",
              attrs: {
                loading: _vm.isDeleteLoading,
                disabled: _vm.isProgressUpdateLoading,
                outlined: "",
                "x-small": "",
                color: "error"
              },
              on: {
                click: _vm.startDeleteDialog
              }
            },
            [
              _c(
                "span",
                {
                  staticClass: "ma-2"
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "partner.PartnerReportDetailChangeProgress.delete"
                        )
                      ) +
                      " "
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _c("confirm-action-dialog", {
        attrs: {
          title: _vm.$t(
            "partner.PartnerReportDetailChangeProgress.confirmDeleteTitle"
          ),
          isDialogActive: _vm.isDeleteDialogActive,
          rightLoading: _vm.isDeleteLoading
        },
        on: {
          "update:isDialogActive": function updateIsDialogActive($event) {
            _vm.isDeleteDialogActive = $event
          },
          "update:is-dialog-active": function updateIsDialogActive($event) {
            _vm.isDeleteDialogActive = $event
          },
          leftClick: function leftClick($event) {
            _vm.isDeleteDialogActive = false
          },
          rightClick: _vm.deleteReport
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }