




































import ContactCustomerDialog from "@/components/utility/ContactCustomerDialog.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import PartnerReportInitializeCard from "./PartnerReportInitializeCard.vue";
import PartnerReportInitializeCardMixin, {
  PartnerReportInitializeDialogPagesEnum
} from "./PartnerReportInitializeCardMixin.vue";
import { ExportModule } from "@/store/modules/export.store";

@Component({
  components: {
    ContactCustomerDialog,
    PartnerReportInitializeCard,
    Card
  }
})
export default class PartnerReportInitializeDialogSelection extends mixins(PartnerReportInitializeCardMixin) {
  @Prop()
  step!: PartnerReportInitializeDialogPagesEnum;

  setNextStep(page: PartnerReportInitializeDialogPagesEnum) {
    this.$emit("update:step", page);
  }

  get title() {
    return this.$t("components.partner.PartnerReportInitializeCard.selection.title");
  }

  get subtitle() {
    return this.$t("components.partner.PartnerReportInitializeCard.selection.subtitle");
  }

  get pages() {
    const pages = [
      {
        icon: "mdi-qrcode-edit",
        title: this.$t("components.partner.PartnerReportInitializeCard.selection.qr.title"),
        subtitle: this.$t("components.partner.PartnerReportInitializeCard.selection.qr.subtitle"),
        buttonText: this.$t("components.partner.PartnerReportInitializeCard.selection.qr.buttonText"),
        nextPage: PartnerReportInitializeDialogPagesEnum.QR,
        isEnabled: true
      },
      {
        icon: "mdi-email-arrow-right-outline",
        title: this.$t("components.partner.PartnerReportInitializeCard.selection.email.title"),
        subtitle: this.$t("components.partner.PartnerReportInitializeCard.selection.email.subtitle"),
        buttonText: this.$t("components.partner.PartnerReportInitializeCard.selection.email.buttonText"),
        nextPage: PartnerReportInitializeDialogPagesEnum.MAIL,
        isEnabled: true
      },
      {
        icon: "mdi-multicast",
        title: this.$t("components.partner.PartnerReportInitializeCard.selection.batch.title"),
        subtitle: this.$t("components.partner.PartnerReportInitializeCard.selection.batch.subtitle"),
        buttonText: this.$t("components.partner.PartnerReportInitializeCard.selection.batch.buttonText"),
        nextPage: PartnerReportInitializeDialogPagesEnum.BATCH,
        isEnabled: true
      },
      {
        icon: "mdi-form-select",
        title: this.$t("components.partner.PartnerReportInitializeCard.selection.empty.title"),
        subtitle: this.$t("components.partner.PartnerReportInitializeCard.selection.empty.subtitle"),
        buttonText: this.$t("components.partner.PartnerReportInitializeCard.selection.empty.buttonText"),
        nextPage: PartnerReportInitializeDialogPagesEnum.EMPTY,
        isEnabled: true
      },
      {
        icon: "mdi-car-connected",
        title: this.$t("components.partner.PartnerReportInitializeCard.selection.da.title"),
        subtitle: this.$t("components.partner.PartnerReportInitializeCard.selection.da.subtitle"),
        buttonText: this.$t("components.partner.PartnerReportInitializeCard.selection.da.buttonText"),
        nextPage: PartnerReportInitializeDialogPagesEnum.DA,
        isEnabled: this.isDaSetup
      }
    ];

    return pages.filter(p => p.isEnabled);
  }

  get isDaSetup() {
    return ExportModule.isDaSetup ?? false;
  }
}
