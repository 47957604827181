





















































import { simpleDate } from "@/lib/utility/date-helper";
import { PartnerEntity } from "@/models/partnerEntity";
import { PartnerModule } from "@/store/modules/partner";
import { Component } from "vue-property-decorator";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import FilterCard from "@/components/filter/FilterCard.vue";
import { IFilterListElement } from "@/lib/interfaces/filter/filterListElement.interface";
import PartnerReportInitializeDialog from "@/components/partner/PartnerReportInitializeDialog.vue";
import { AssetRepository, AssetEnum } from "@/lib/AssetRepository";
import { FeatureModule } from "@/store/modules/feature.store";
import { ProgressStatusEnum } from "@/store/enum/partner/progress.status.enum";
import { getDateFilterList } from "@/lib/utility/filter";
import { IReport } from "@/models/report.entity";
import { ExportModule } from "@/store/modules/export.store";
import { reportStatusIconMap } from "@/store/mapper/report-icon.map";

@Component({
  components: {
    FilterCard,
    PartnerReportInitializeDialog
  }
})
export default class PartnerReportList extends DarkModeHighlightMixin {
  search = "";

  get report() {
    return PartnerModule.report;
  }

  get reports() {
    return PartnerModule.reports;
  }

  get emptyDataSrc() {
    return AssetRepository.getAsset(false, AssetEnum.emptyData);
  }

  /**
   * check whether progress status designs are shown (bold font & icons)
   */
  get isUpdateProgressStatusEnabled() {
    return FeatureModule.isUpdateProgressStatusEnabled;
  }

  /**
   * Choose Icon depending on progress status
   */
  getProgressIcon(progressStatus: ProgressStatusEnum): string {
    if (!this.isUpdateProgressStatusEnabled) return "";
    return reportStatusIconMap.get(progressStatus) || "";
  }

  /**
   * Get icon indicating if export of report is requested
   */
  getExportReportMetaIcon(report: IReport): string {
    if (!ExportModule.isKsrSetup) return "";

    const found = ExportModule.exportReportMetas.get(report._id);

    return found ? "mdi-file-send" : "";
  }

  isActive(item: any) {
    return item._id === PartnerModule.report._id ? "v-item--active v-list-item--active" : "";
  }

  date(item: any): string {
    return simpleDate(item.timestamps?.created);
  }

  async mounted() {
    if (PartnerModule.reports.length > 0) {
      await PartnerModule.getReportByIdForPartner(PartnerModule.reports[0]._id);
    } else {
      PartnerModule.setReport({} as PartnerEntity);
    }
  }

  async set(item: IReport) {
    this.$log.debug(`PartnerReportList.set: ${item._id}`);
    this.$emit("set", item);
  }

  /**
   * The available categories visible in the filter menu
   */
  filterList: IFilterListElement[] = getDateFilterList("timestamps").concat(
    FeatureModule.isUpdateProgressStatusEnabled
      ? [
          {
            label: String(this.$t("components.partner.PartnerReportList.progressStatus")),
            value: [],
            items: [ProgressStatusEnum.FINISHED, ProgressStatusEnum.IN_PROGRESS, ProgressStatusEnum.NEW],
            multiple: true,
            conditionCheck: (obj: IReport, condition: string) => {
              return obj.progressStatus === condition;
            }
          }
        ]
      : []
  );

  items = PartnerModule.reports.slice();

  get filteredItems() {
    return this.items;
  }
}
