















































import Debug from "@/components/utility/Debug.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { handleError } from "@/lib/utility/handleError";
import {
  MrfiktivPartnerLinkViewModelGen,
  MrfiktivPartnerWithDistanceViewModelGen,
  MrfiktivReportControlViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { PartnerModule } from "@/store/modules/partner";
import { PartnerFinderModule } from "@/store/modules/partner-link-finder.store";
import { PartnerLinkerModule } from "@/store/modules/partner-linker.store";
import { ReportControlModule } from "@/store/modules/report-control.store";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortal,
    Debug
  }
})
export default class ApiComponent extends Vue {
  loading = false;

  selectedPartnerId?: string;

  get from() {
    return PartnerModule.partners;
  }

  get res(): MrfiktivPartnerLinkViewModelGen[] {
    return PartnerLinkerModule.entities;
  }

  get res2(): MrfiktivPartnerWithDistanceViewModelGen[] {
    return PartnerFinderModule.partners;
  }

  get res3(): MrfiktivReportControlViewModelGen[] {
    return ReportControlModule.entities;
  }

  async mounted() {
    this.selectedPartnerId = PartnerModule.partner.id;
    await PartnerModule.getPartners();
  }

  async click() {
    if (!this.selectedPartnerId) {
      this.$toast.error("Select partner");
      return;
    }

    this.loading = true;

    PartnerLinkerModule.fetchFirstPage({ partnerId: this.selectedPartnerId })
      .catch(e => handleError(e))
      .finally(() => (this.loading = false));
  }

  async click2() {
    if (!this.selectedPartnerId) {
      this.$toast.error("Select partner");
      return;
    }

    this.loading = true;

    await PartnerFinderModule.findPartners({
      partnerId: this.selectedPartnerId,
      searchstring: "Auf der Halle, Georgsmarienhütte",
      maxDistance: 1
    })
      .catch(e => handleError(e))
      .finally(() => (this.loading = false));
  }

  async click3() {
    if (!this.selectedPartnerId) {
      this.$toast.error("Select partner");
      return;
    }

    this.loading = true;

    ReportControlModule.fetchFirstPage({ partnerId: this.selectedPartnerId })
      .catch(e => handleError(e))
      .finally(() => (this.loading = false));
  }
}
