




















































































































































































import CostCreateDialog from "@/components/cost/CostCreateDialog.vue";
import PartnerReportDetailChangeProgress from "@/components/partner/PartnerReportDetailChangeProgress.vue";
import PartnerReportDetailInfoCard from "@/components/partner/PartnerReportDetailInfoCard.vue";
import PartnerReportDetailInfoTimeLineCard from "@/components/partner/PartnerReportDetailInfoTimeLineCard.vue";
import SendToExternOption from "@/components/partner/PartnerReportDetailSendToExternOption.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import ContactCustomerDialog from "@/components/utility/ContactCustomerDialog.vue";
import { getFlagEmojiByLanguage } from "@/lib/CountryCodeHelper";
import { ContactCustomerEnum } from "@/lib/enum/ContactCustomer.enum";
import { CreatePortalUrlFactory } from "@/lib/utility/createPortalUrlFactory";
import { detailedDate, simpleDate, simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import ExportMixin from "@/mixins/XMLExportMixin.vue";
import { IReport } from "@/models/report.entity";
import { ITicket } from "@/models/ticket.entity";
import aiService from "@/services/mrfiktiv/services/aiService";
import {
  MrfiktivCreateActivityLogDtoGen,
  MrfiktivPartnerMessageViewModelGen,
  MrfiktivReferenceGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { ThgActivityLogViewModelGen, ThgPartnerMessageViewModelGen } from "@/services/thg/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum, ResourceEnum } from "@/store/enum/authResourceEnum";
import { ActivityLogModule, ActivityTypeEnum } from "@/store/modules/activity-log.store";
import { ExportModule } from "@/store/modules/export.store";
import { FeatureModule } from "@/store/modules/feature.store";
import { UserModule } from "@/store/modules/me-user.store";
import { PartnerModule } from "@/store/modules/partner";
import { ReportPaginationModule } from "@/store/modules/report-pagination.store";
import ReportDocumentSignRequestDialog from "@/views/sign/ReportDocumentSignRequestDialog.vue";
import { mixins } from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import TicketCreateDialog from "../project/TicketCreateDialog.vue";
import TemplateDialog from "../template/TemplateDialog.vue";
import CopyClipboardText from "../utility/CopyClipboardText.vue";
import Debug from "../utility/Debug.vue";
import MHeader, { IAction, IAlert, IBreadcrumb, IChips } from "../utility/mmmint/MHeader.vue";
import TableWrapper from "../utility/TableWrapper.vue";
import Tooltip from "../utility/tooltip.vue";
import PartnerReportDetailDeleteDialog from "./PartnerReportDetailDeleteDialog.vue";
import PartnerReportInitializeDialog from "./PartnerReportInitializeDialog.vue";
import PartnerReportUpdateReportDialog, { ReportUpdateTabEnum } from "./PartnerReportUpdateReportDialog.vue";
import ReportActivityShareDialog from "./ReportActivityShareDialog.vue";
import ReportActivityUploadDialog from "./ReportActivityUploadDialog.vue";

@Component({
  components: {
    MHeader,
    CopyClipboardText,
    PartnerReportDetailInfoCard,
    PartnerReportDetailInfoTimeLineCard,
    PartnerReportDetailChangeProgress,
    PartnerReportDetailDeleteDialog,
    ContactCustomerDialog,
    SendToExternOption,
    ReportDocumentSignRequestDialog,
    TemplateDialog,
    TableWrapper,
    Debug,
    ReportActivityShareDialog,
    PartnerReportUpdateReportDialog,
    ReportActivityUploadDialog,
    PartnerReportInitializeDialog,
    TicketCreateDialog,
    CostCreateDialog,
    Tooltip,
    ConfirmActionDialog
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class PartnerReportDetail extends mixins(ExportMixin, PermissionMixin) {
  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: true })
  displayToDetailView!: boolean;

  @Prop({ default: false })
  showClose!: boolean;

  @Prop({ default: "calc(100vh - 300px)" })
  height!: string;

  isTicketCreateDialogActive = false;

  isCostCreateDialogActive = false;

  isSummaryDialogActive = false;

  summary: string | null = null;

  summaryLoading = false;

  ticketRefs: MrfiktivReferenceGen[] = [];

  get title() {
    if (this.report.title) return this.report.title;

    let title = `${this.report.numberplate}`;

    if (this.report.companyName) {
      title += ` -  ${this.report.companyName}`;

      if (this.report.customerName) {
        title += ` (${this.report.customerName})`;
      }

      return title.trim();
    }

    if (this.report.customerName) {
      title + ` - ${this.report.customerName}`;
    }

    return title.trim();
  }

  get breadCrumbs(): IBreadcrumb[] | undefined {
    const breadCrumbs: IBreadcrumb[] = [];

    return breadCrumbs;
  }

  get chips(): IChips[] {
    const chips: IChips[] = [];

    if (this.report.distributor) {
      chips.push({
        key: "forwardedBy",
        text: this.$t("components.partner.PartnerReportDetail.forwardedBy", {
          by: this.report.distributor.companyName
        }).toString(),
        color: "primary"
      });
    }

    if (this.partner.settings?.isLanguageSelectionActive) {
      chips.push({
        key: "language",
        text: `${getFlagEmojiByLanguage(this.report.language)} ${this.$t(
          `enums.LanguageCodeEnum.${this.report.language}`
        ).toString()}`,
        color: "grey"
      });
    }

    if (this.report.numberplate) {
      chips.push({
        key: "numberplate",
        text: this.report.numberplate,
        color: "primary",
        exec: () => {
          navigator.clipboard.writeText(this.report.numberplate);
          this.$toast.info(this.$t("components.CopyClipboardText.confirmCopy"));
        },
        tooltip: this.$t("sign.SignInitializeCard.copy").toString()
      });
    }

    if (this.report.status) {
      chips.push({
        key: "status",
        text: this.report.status,
        color: "primary",
        exec: () => {
          if (this.report.status) navigator.clipboard.writeText(this.report.status);
          this.$toast.info(this.$t("components.CopyClipboardText.confirmCopy"));
        },
        tooltip: this.$t("sign.SignInitializeCard.copy").toString()
      });
    }

    if (this.report.externalId) {
      chips.push({
        key: "externalId",
        text: this.$t("components.partner.PartnerReportDetail.reference") + " " + this.report.externalId,
        color: "primary",
        exec: () => {
          if (this.report.externalId) {
            navigator.clipboard.writeText(this.report.externalId);
            this.$toast.info(this.$t("components.CopyClipboardText.confirmCopy"));
          }
        },
        tooltip: this.$t("sign.SignInitializeCard.copy").toString()
      });
    }

    return chips;
  }

  get alerts(): IAlert[] {
    const alerts: IAlert[] = [];

    // FIXME: This should be displayed here instead of in the report
    if (
      // eslint-disable-next-line no-constant-condition
      false &&
      this.isFleetPartner &&
      !this.report.vehicleId &&
      UserModule.abilities.can(ActionEnum.READ, ResourceEnum.VEHICLE, this.report.partnerId)
    ) {
      alerts.push({
        text: String(this.$t("timeLine.VehicleTimeLineElement.suggestion")),
        type: "info"
      });
    }

    return alerts;
  }

  get actions() {
    const actions: IAction[] = [];

    if (this.displayToDetailView) {
      actions.push({
        text: String(this.$t("project.ticket.actions.openInNewTab")),
        key: "OPEN",
        icon: "mdi-open-in-new",
        exec: this.toDetailView
      });
    }

    if (this.canUpdateReport) {
      actions.push({
        text: String(this.$t("common.verbs.edit")),
        key: "EDIT",
        icon: "mdi-pencil",
        exec: this.showUpdateDialog
      });
    }

    actions.push({
      key: "copy",
      icon: "mdi-share-variant-outline",
      text: this.$t("project.ticket.actions.copyLink").toString(),
      exec: () => {
        const url = new CreatePortalUrlFactory();
        navigator.clipboard.writeText(url.buildForReport(this.report));
        this.$toast.info(this.$t("components.CopyClipboardText.confirmCopy"));
      }
    });

    if (this.canShareContent) {
      actions.push({
        text: this.$t("timeLine.PartnerReportDetailInfoTimeLineCard.actionCard.share").toString(),
        key: "forward",
        icon: "mdi-file-send-outline",
        exec: this.showShareDialog
      });
    }

    if (this.canCreateMessage) {
      actions.push({
        text: this.$t("components.partner.PartnerReportInitializeCard.sendMail.title").toString(),
        key: "MAIl",
        icon: "mdi-email-outline",
        exec: this.showEmailDialog
      });
    }

    if (this.canCreateTicket) {
      actions.push({
        text: this.$t("components.partner.PartnerReportInitializeCard.empty.createTicket").toString(),
        key: "ticket",
        icon: "mdi-ticket-outline",
        exec: this.showTicketDialog
      });
    }

    if (this.canCreateCost) {
      actions.push({
        text: this.$t("components.partner.PartnerReportInitializeCard.empty.createCost").toString(),
        key: "cost",
        icon: "mdi-cash-multiple",
        exec: this.showCostDialog
      });
    }

    if (this.canCreateSign) {
      actions.push({
        text: this.$t("sign.DocumentTable.sign").toString(),
        key: "sign",
        icon: "mdi-file-sign",
        exec: this.showSignDialog
      });
    }

    if (this.canCreateDocument) {
      actions.push({
        text: this.$t("sign.SignDocumentCreateDialog.title").toString(),
        key: "share",
        icon: "mdi-file-upload-outline",
        exec: this.showUploadDialog
      });
    }

    this.exportOptions.forEach(option => {
      actions.push({
        text: this.$t("components.partner.PartnerReportDetail.SendToExternOption.tooltip").toString() + " " + option,
        key: `export-${option}`,
        icon: "mdi-lan-connect"
      });
    });

    if (this.isDownloadFileEnabled) {
      actions.push({
        text: this.$t("components.partner.PartnerReportDetail.tooltip.downloadExport").toString(),
        key: "download",
        icon: "mdi-download-circle-outline",
        exec: this.downloadExport
      });
    }

    if (!this.displayToDetailView) {
      actions.push({
        text: this.$t("views.PartnerSharedReport.print").toString(),
        key: "print",
        icon: "mdi-printer-outline",
        exec: this.print
      });
    }

    if (UserModule.abilities.can(ActionEnum.CREATE, ResourceEnum.AI, this.report.partnerId)) {
      actions.push({
        text: String(this.$t("common.verbs.summarize")),
        key: "summarize",
        icon: "mdi-creation",
        color: "success",
        exec: this.summarizeReport
      });
    }

    if (UserModule.abilities.can(ActionEnum.DELETE, ResourceEnum.REPORT, this.report.partnerId)) {
      actions.push({
        text: String(this.$t("common.verbs.delete")),
        key: "DELETE",
        icon: "mdi-trash-can",
        color: "error",
        exec: this.showDeleteDialog
      });
    }

    return actions;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }

  get ReportUpdateTabEnum() {
    return ReportUpdateTabEnum;
  }

  get headlineName() {
    const name = this.report.customerName;
    const firmenname = this.report.companyName || "";

    if (firmenname) {
      return `${firmenname} (${name})`;
    } else {
      return name;
    }
  }

  get isTemplateActive() {
    return FeatureModule.isTemplateEnabled;
  }

  get isTimelineEnabled() {
    return FeatureModule.isReportEnabled.isTimelineEnabled;
  }

  get isSignEnabled() {
    return FeatureModule.isDigitalSignatureEnabled.isSignEnabled;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get report() {
    return PartnerModule.report;
  }

  get reports() {
    return ReportPaginationModule.paginationList;
  }

  get reportsCount() {
    return ReportPaginationModule.totalItems;
  }

  get resource() {
    return ContactCustomerEnum.REPORT;
  }

  get numberOfImages() {
    return (
      PartnerModule?.report?.images?.cockpits.length +
      PartnerModule?.report?.images?.damages.length +
      PartnerModule?.report?.images?.damagesDetail.length +
      PartnerModule?.report?.images?.overviews.length
    );
  }

  get date() {
    if (PartnerModule.report?.timestamps?.created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";
      return detailedDate(PartnerModule.report?.timestamps?.created, locale);
    }
    return "Kein Datum";
  }

  get dateSimple() {
    if (PartnerModule.report?.timestamps?.created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";
      return simpleDate(PartnerModule.report?.timestamps?.created, locale);
    }
    return "";
  }

  get datePreference() {
    if (PartnerModule.report?.datePreference) {
      return simpleDate(PartnerModule.report?.datePreference);
    }
    return "";
  }

  get fahrzeugscheinImage() {
    if (this.report.images.registrations[0]) {
      return this.report.images.registrations[0].url;
    }
    return "";
  }

  get isUpdateProgressStatusEnabled() {
    return FeatureModule.isUpdateProgressStatusEnabled;
  }

  get activityLog() {
    return ActivityLogModule.paginationList;
  }

  get headers() {
    return [
      { text: this.$t("partner.ActivityLogTable.timestamp"), align: "start", value: "timestamp.created", width: 100 },
      { text: this.$t("partner.ActivityLogTable.userName"), align: "start", value: "user.userName" },
      { text: this.$t("partner.ActivityLogTable.actionType.title"), align: "start", value: "actionType", width: 100 },
      { text: this.$t("partner.ActivityLogTable.activity"), align: "start", value: "activity", width: 100 },
      { text: this.$t("partner.ActivityLogTable.comment"), align: "start", value: "comment" },
      { text: "", align: "end", value: "controls", width: 200, sortable: false }
    ];
  }

  get isDownloadFileEnabled() {
    return true;
  }

  get exportOptions() {
    const exportOptions = [];

    if (ExportModule.isKsrSetup) {
      exportOptions.push("KSR");
    }

    if (ExportModule.isDaSetup) {
      exportOptions.push("DA");
    }

    if (ExportModule.isPdrSetup) {
      exportOptions.push("PDR");
    }

    return exportOptions;
  }

  @Watch("report.id")
  @Watch("report._id")
  @Watch("report.vehicleId")
  setRefs() {
    this.ticketRefs.splice(0);

    if (this.report._id ?? this.report.id) {
      this.ticketRefs.push({
        refType: BackendResourceEnum.REPORT,
        refId: this.report._id ?? this.report.id
      });
    }

    if (this.report.vehicleId) {
      this.ticketRefs.push({
        refType: BackendResourceEnum.VEHICLE,
        refId: this.report.vehicleId
      });
    }
  }

  async processAction(action: IAction) {
    if (action.exec) {
      await action?.exec();
    }

    if (action.key.startsWith("export")) {
      const exportOption = action.key.split("-");
      this.showExportDialog(exportOption[1]);
    }
  }

  showExportDialog(option: string) {
    (this.$refs.sendToExternDialog as SendToExternOption).openSendDialog(option);
  }

  showUpdateDialog() {
    (this.$refs.updateDialog as PartnerReportUpdateReportDialog).show();
  }

  showDeleteDialog() {
    (this.$refs.deleteDialog as PartnerReportDetailDeleteDialog).show();
  }

  showSignDialog() {
    (this.$refs.signDialog as ReportDocumentSignRequestDialog).show();
  }

  showEmailDialog() {
    (this.$refs.templateDialog as TemplateDialog).show();
  }

  showShareDialog() {
    (this.$refs.shareDialog as ReportActivityShareDialog).show();
  }

  showUploadDialog() {
    (this.$refs.uploadDialog as ReportActivityUploadDialog).show();
  }

  async showTicketDialog() {
    await (this.$refs.ticketDialog as TicketCreateDialog).startDialog();
  }

  async showCostDialog() {
    await (this.$refs.costDialog as CostCreateDialog).show();
  }

  toDetailView() {
    this.$router.push({
      name: "PartnerReportsDetailView",
      params: { reportId: this.report.id, partnerId: this.report.partnerId }
    });
  }

  downloadExport() {
    this.downloadReportExport("ksr", this.report);
  }

  print() {
    window.print();
  }

  /**
   * Set the active entry to the newest entry in the entries available.
   */
  async setToLatestEntry() {
    if (ReportPaginationModule.paginationList) {
      this.$emit("setReport", ReportPaginationModule.paginationList[0]);
    }
  }

  get templateDialogMethods() {
    return {
      context: {
        partner: this.partner,
        report: this.report
      },
      to: [this.report.customerContact?.email || ""],
      from: [this.partner]
    };
  }

  async onTicketCreated(ticket: ITicket) {
    if (!ticket.id) {
      return;
    }

    try {
      const created = await ActivityLogModule.create({
        partnerId: this.partner.id,
        data: {
          target: [{ refType: BackendResourceEnum.TICKET, refId: ticket.id }],
          actionType: ActionEnum.CREATE,
          activity: ActivityTypeEnum.CREATE_TICKET,
          source: {
            refType: BackendResourceEnum.REPORT,
            refId: this.report._id
          }
        }
      });

      ActivityLogModule.addToList(created);
    } catch (e) {
      this.$log.error(e);
    }
  }

  onCreatedActivity(activity: ThgActivityLogViewModelGen) {
    if (activity.source.refId === this.report._id) {
      ActivityLogModule.addToList(activity);
    }
  }

  async sent(messages: MrfiktivPartnerMessageViewModelGen[] | ThgPartnerMessageViewModelGen[] | undefined) {
    if (!messages || messages.length <= 0) {
      return;
    }

    for (const message of messages) {
      const source: MrfiktivReferenceGen = {
        refType: BackendResourceEnum.REPORT,
        refId: this.report._id
      };
      const data: MrfiktivCreateActivityLogDtoGen = {
        source,
        target: [{ refType: BackendResourceEnum.MESSAGE, refId: message.id }],
        actionType: ActionEnum.CREATE,
        activity: ActivityTypeEnum.MESSAGE
      };

      const activity = await ActivityLogModule.create({
        partnerId: this.partner.id,
        data
      }).catch(handleError);

      if (activity) {
        ActivityLogModule.addToList(activity);
      }
    }
  }

  setReport(report: IReport) {
    this.$emit("setReport", report);
  }

  async summarizeReport() {
    try {
      this.summaryLoading = true;
      this.isSummaryDialogActive = true;
      this.summary = "";

      const result = await aiService.getReportSummary(this.report.partnerId, this.report.id, true);
      this.$log.info(result);

      if (result) {
        this.summary = result.summary ?? null;
        this.report.summary = result.summary;
      } else {
        this.$toast.warning(this.$t("components.partner.PartnerReportDetail.summarize"));
      }
    } catch (e) {
      handleError(e);
    } finally {
      this.summaryLoading = false;
    }
  }
}
