var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "side-card",
    {
      attrs: {
        fullscreen: _vm.isMobile,
        height: _vm.sideCardHeight,
        width: _vm.sideCardWidth,
        title: _vm.$t("sign.DocumentTemplateDetailTokenList.title")
      },
      on: {
        close: function close($event) {
          return _vm.$emit("close")
        }
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function fn() {
            return [
              _c("document-template-detail-token-list-keyboard-instructions", {
                staticClass: "mr-2"
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("document-template-detail-token-list", {
        attrs: {
          height: _vm.isMobile
            ? "calc(100vh - 72px)"
            : "calc(".concat(_vm.sideCardHeight, " - 72px - 174px)"),
          currentPageNumber: _vm.currentPageNumber,
          isLoadingSave: _vm.isLoadingSave,
          disabled: _vm.disabled,
          page: _vm.page
        },
        on: {
          setCurrentPage: _vm.setCurrentPage
        },
        scopedSlots: _vm._u(
          [
            !_vm.isMobile
              ? {
                  key: "addToken",
                  fn: function fn() {
                    return [_vm._t("addToken")]
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }