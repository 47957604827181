var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("the-layout-portal-split", {
    scopedSlots: _vm._u([
      {
        key: "list",
        fn: function fn() {
          return [
            _c("partner-faq-list-card", {
              attrs: {
                loading: _vm.loadingAll,
                isLoadingDetail: _vm.isLoadingDetail,
                isLoadingMore: _vm.isLoadingMore
              },
              on: {
                set: _vm.set,
                create: _vm.onCreate,
                loadMore: _vm.loadMore,
                refresh: function refresh($event) {
                  return _vm.refresh({
                    partnerId: _vm.partnerId
                  })
                }
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "content",
        fn: function fn() {
          return [
            _c("partner-faq-detail-card", {
              attrs: {
                isDetailLoading: _vm.isLoadingDetail,
                faq: _vm.faq
              },
              on: {
                delete: _vm.onDelete,
                update: _vm.onUpdate
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }