/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * A simple csv to object parser
 */
export class SimpleCsvParser {
  /**
   * The string to parse
   */
  csv: string;

  /**
   * Custom header to parse into
   */
  headers?: string[];

  /**
   * The character to split row by
   */
  delimiter = ";";

  /**
   * Creates a simple csv parser
   *
   * @param csv
   * @param delimiter
   * @param headers
   */
  constructor(csv: string, delimiter = ";", headers?: string[]) {
    this.csv = csv;
    if (headers) {
      this.headers = headers;
    }

    this.delimiter = delimiter;
  }

  /**
   * Creates an array based on csv string;
   *
   * @param map function to parse the element.
   * @returns
   */
  toArray(map?: Function): any[] {
    const array = SimpleCsvParser.csvToArray(this.csv, this.delimiter, this.headers);

    if (!map) {
      return array;
    }

    return array.map(el => map(el));
  }

  /**
   *
   * @param str
   * @param delimiter
   * @param headers
   */
  static csvToArray(str: string, delimiter = ",", headers?: string[]): any[] {
    /**
     * 	slice from start of text to the first \n index use split to create an array from string by delimiter
     */
    if (!headers || headers.length <= 0) {
      headers = str.slice(0, str.indexOf("\r")).split(delimiter);
    }

    /**
     * slice from \n index + 1 to the end of the text use split to create an array of each csv value row
     */
    const rows = str.slice(str.indexOf("\r") + 1).split("\r");

    /**
     * - map the rows
     * - split values from each row into an array
     * - use headers.reduce to create an object
     * - object properties derived from headers:values
     *
     * @returns the object passed as an element of the array
     */
    const arr = rows.map(function(row) {
      if (!row || row.length <= 0) {
        throw new Error("Empty row");
      }

      const values = row.split(delimiter);
      const el = headers?.reduce(function(object, header, index) {
        // Prevent adding empty item
        if (values[index]) {
          object[header] = values[index];
        }

        return object;
      }, {});

      return el;
    });

    return arr;
  }
}
