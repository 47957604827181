var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "coopContainer"
    },
    [
      _c("v-divider"),
      _c(
        "v-card-title",
        [
          _c(
            "v-col",
            {
              attrs: {
                align: "center"
              }
            },
            [
              _c(
                "h3",
                {
                  staticClass: "title",
                  staticStyle: {
                    "font-size": "100%"
                  }
                },
                [_vm._v("Kooperationspartner")]
              )
            ]
          )
        ],
        1
      ),
      _c("v-row", [
        _c(
          "div",
          {
            staticStyle: {
              margin: "auto"
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "coopBox"
              },
              [
                _c("v-img", {
                  staticClass: "ma-auto",
                  attrs: {
                    "max-width": "100px",
                    "max-height": "100px",
                    src: _vm.cooperationpartnersImagesAndUrls.partnerImageTwo,
                    "lazy-src":
                      _vm.cooperationpartnersImagesAndUrls.partnerImageTwo,
                    alt: "Logo des zweiten Partners"
                  },
                  on: {
                    click: function click($event) {
                      return _vm.redirectToPartner2()
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              {
                attrs: {
                  id: "coopCenter"
                }
              },
              [_vm._v(" & ")]
            ),
            _c(
              "div",
              {
                staticClass: "coopBox"
              },
              [
                _c("v-img", {
                  staticStyle: {
                    "margin-left": "22px",
                    "margin-top": "17px"
                  },
                  attrs: {
                    "max-width": "60px",
                    "max-height": "70px",
                    src: _vm.cooperationpartnersImagesAndUrls.partnerImageOne,
                    "lazy-src":
                      _vm.cooperationpartnersImagesAndUrls.partnerImageOne,
                    alt: "Logo des ersten Partners"
                  },
                  on: {
                    click: function click($event) {
                      return _vm.redirectToPartner1()
                    }
                  }
                })
              ],
              1
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }