var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _c(
        "v-container",
        [
          _vm.isLoading
            ? _c("v-skeleton-loader", {
                attrs: {
                  type: "list-item-two-line"
                }
              })
            : _c("operation-detail", {
                attrs: {
                  hideDetailButton: true,
                  operation: _vm.operation
                }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }