var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-btn",
        {
          attrs: {
            text: "",
            outlined: ""
          },
          on: {
            click: function click($event) {
              _vm.isDialogActive = true
            }
          }
        },
        [
          _vm._v(
            " " + _vm._s(_vm.$t("sign.SignDocumentCreateDialog.create")) + " "
          ),
          _c("v-icon", [_vm._v("mdi-add")])
        ],
        1
      ),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            isDialogActive: _vm.isDialogActive,
            fullscreen: _vm.$vuetify.breakpoint.xsOnly,
            rightDisabled: !_vm.isValid,
            title: _vm.$t("sign.SignDocumentCreateDialog.title")
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isDialogActive = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isDialogActive = $event
            },
            rightClick: _vm.save,
            leftClick: function leftClick($event) {
              _vm.isDialogActive = false
            },
            close: function close($event) {
              _vm.isDialogActive = false
            }
          }
        },
        [
          _c("sign-document-form", {
            ref: "signDocumentForm",
            attrs: {
              hideButton: true,
              isLoading: _vm.isLoading
            },
            on: {
              save: _vm.save,
              updateValid: function updateValid($e) {
                return (_vm.isValid = $e)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }