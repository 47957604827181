import DocphantLogin from "@/views/docphant/dashboard/DocphantLogin.vue";
import MachinePage from "@/views/docphant/MachinePage.vue";
import MachineSelection from "@/views/docphant/MachineSelection.vue";
import ManufacturerMachineSelection from "@/views/docphant/ManufacturerMachineSelection.vue";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { routes as docphantPortalRoutes } from "./docphant.portal.router";
import { getLoginRoutes } from "./login.router";
import Unauthorized from "@/views/docphant/dashboard/DocphantUnauthorized.vue";
import { permissionGuard } from "@/auth/permissionAuthGuard";
import NoRoles from "@/views/NoRoles.vue";

Vue.use(VueRouter);

export const routes: Array<RouteConfig> = [
  {
    path: "/manufacturer/:manufacturerId/machine/:machineId",
    name: "MachinePage",
    component: MachinePage
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: Unauthorized
  },
  {
    path: "/no-roles",
    name: "NoRoles",
    component: NoRoles
  },
  {
    path: "/manufacturer/:manufacturerId",
    name: "ManufacturerMachineSelectionPage",
    component: ManufacturerMachineSelection
  },
  {
    path: "*",
    name: "MachineSelectionPage",
    component: MachineSelection
  }
];

docphantPortalRoutes.forEach(route => {
  route.path = "/portal" + route.path;
  routes.push(route);
});

getLoginRoutes(DocphantLogin).forEach((route: RouteConfig) => {
  route.path = "/login" + route.path;
  routes.push(route);
});

const router = new VueRouter({
  routes,
  base: "/"
});

router.beforeEach(permissionGuard);

export default router;
