/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * The type of editor used to create a template
 */
export enum TemplateEditorEnum {
  /**
   * The tiptap editor
   */
  TIPTAP = "tiptap",

  /**
   * Default, e.g. when the template is importet from another editor
   */
  DEFAULT = "default"
}
