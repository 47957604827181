
























import CustomerContactCard from "@/components/partner/CustomerContactCard.vue";
import UserAdministrationDetailPermissionsAddDialog from "@/components/partner/UserAdministrationDetailPermissionsAddDialog.vue";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { AuthRolesEnum } from "@/store/enum/authRolesEnum";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import { IAdminUser } from "@/models/user.entity";

@Component({
  components: {
    CustomerContactCard,
    UserAdministrationDetailPermissionsAddDialog,
    Card
  }
})
export default class UserAdministrationDetailRoles extends DarkModeHighlightMixin {
  @Prop({ default: true })
  disabled = true;

  @Prop()
  selectedUser!: IAdminUser;

  editRoles: AuthRolesEnum[] = [];
  authRoles = Object.keys(AuthRolesEnum).map(k => AuthRolesEnum[k as any]);

  loading = false;

  get isLoading() {
    return this.loading;
  }

  get roles() {
    return this.selectedUser?.roles;
  }

  edit() {
    this.disabled = false;
    this.editRoles = [];
    this.editRoles.push(...(this.selectedUser?.roles ?? []));
  }

  close() {
    this.editRoles = [];
    this.disabled = true;
  }

  async save() {
    this.loading = true;
    this.selectedUser.roles = this.editRoles;
    await this.selectedUser.updateRoles();

    this.loading = false;
    this.close();
  }
}
