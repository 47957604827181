var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "span",
    [
      _c(
        "tooltip",
        {
          attrs: {
            text: _vm.$t("partner.PartnerFaqCreateDialog.title")
          }
        },
        [
          _c(
            "v-btn",
            {
              attrs: {
                icon: ""
              },
              on: {
                click: _vm.initialize
              }
            },
            [_c("v-icon", [_vm._v("mdi-plus")])],
            1
          )
        ],
        1
      ),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            isDialogActive: _vm.isDialogActive,
            title: "create",
            rightText: "create",
            rightLoading: _vm.isLoading,
            rightDisabled: _vm.isLoading || !_vm.isValid,
            leftDisabled: _vm.isLoading
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isDialogActive = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isDialogActive = $event
            },
            rightClick: _vm.create
          }
        },
        [
          _c(
            "v-form",
            {
              model: {
                value: _vm.isValid,
                callback: function callback($$v) {
                  _vm.isValid = $$v
                },
                expression: "isValid"
              }
            },
            [
              _c("v-text-field", {
                attrs: {
                  outlined: "",
                  rules: _vm.required,
                  label: "Q"
                },
                model: {
                  value: _vm.createDto.question,
                  callback: function callback($$v) {
                    _vm.$set(_vm.createDto, "question", $$v)
                  },
                  expression: "createDto.question"
                }
              }),
              _c("v-textarea", {
                attrs: {
                  outlined: "",
                  rules: _vm.required,
                  label: "A"
                },
                model: {
                  value: _vm.createDto.answer,
                  callback: function callback($$v) {
                    _vm.$set(_vm.createDto, "answer", $$v)
                  },
                  expression: "createDto.answer"
                }
              }),
              _c("v-checkbox", {
                attrs: {
                  outlined: "",
                  label: "Active"
                },
                model: {
                  value: _vm.createDto.isActive,
                  callback: function callback($$v) {
                    _vm.$set(_vm.createDto, "isActive", $$v)
                  },
                  expression: "createDto.isActive"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }