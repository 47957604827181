































import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import { PartnerReportInitializeDialogPagesEnum as PagesEnum } from "./PartnerReportInitializeCardMixin.vue";
import PartnerReportInitializeCardMixin from "./PartnerReportInitializeCardMixin.vue";
import Card from "../utility/Card.vue";

@Component({
  components: { Card }
})
export default class PartnerReportInitializeDialogSuccess extends mixins(PartnerReportInitializeCardMixin) {
  @Prop()
  step!: PagesEnum;
}
