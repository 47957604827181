












import Card from "@/components/utility/Card.vue";
import CsvImportDialog from "@/components/utility/CsvImportDialog.vue";
import { Component, Vue } from "vue-property-decorator";
import { ITableWrapperHeader } from "../utility/TableWrapper.vue";
import { IInitReport } from "@/lib/utility/createReportUrlFactory";

@Component({
  components: { Card, CsvImportDialog }
})
export default class PartnerReportBatchImportDialog extends Vue {
  items: IInitReport[] = [];

  headers: (ITableWrapperHeader & { value: keyof IInitReport })[] = [
    {
      text: "firstName",
      align: "center",
      value: "firstName",
      forceEditField: true
    },
    {
      text: "lastName",
      align: "center",
      value: "lastName",
      forceEditField: true
    },
    {
      text: "email",
      align: "center",
      value: "email",
      forceEditField: true
    },
    {
      text: "phone",
      align: "center",
      value: "phone",
      forceEditField: true
    },

    {
      text: "numberPlate",
      align: "center",
      value: "numberPlate",
      forceEditField: true
    },
    {
      text: "zip",
      align: "center",
      value: "zip",
      forceEditField: true
    },
    {
      text: "city",
      align: "center",
      value: "city",
      forceEditField: true
    },
    {
      text: "street",
      align: "center",
      value: "street",
      forceEditField: true
    },
    {
      text: "externalId",
      align: "center",
      value: "externalId",
      forceEditField: true
    },
    {
      text: "reportType",
      align: "center",
      value: "reportType",
      forceEditField: true
    }
  ];

  get partnerId() {
    return this.$route.params.partnerId;
  }

  changeItems(items: IInitReport[]) {
    this.items = [];
    for (const row of items) {
      const createdInitReportEntry: IInitReport = {
        email: row.email,
        firstName: row.firstName,
        lastName: row.lastName,
        phone: row.phone,
        zip: row.zip,
        street: row.street,
        city: row.city,
        numberPlate: row.numberPlate,
        externalId: row.externalId,
        reportType: row.reportType
      };

      this.$log.debug("changeItems", createdInitReportEntry);
      this.items.push(createdInitReportEntry);
    }
  }

  async create(items: IInitReport) {
    this.$emit("import", items);
  }

  refresh() {
    this.$emit("refresh");
  }
}
