


























import TemplateList from "@/components/template/TemplateList.vue";
import TheLayoutPortalSplit from "@/layouts/TheLayoutPortalSplit.vue";
import { Component, Vue } from "vue-property-decorator";
import { AdminTemplateModule } from "@/store/modules/admin-template.store";
import { PartnerTemplateModule } from "@/store/modules/partner-template.store";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import TemplateDetail from "@/components/template/TemplateDetail.vue";
import {
  MrfiktivPartnerTemplateViewModelGen,
  MrfiktivAdminTemplateViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { handleError } from "@/lib/utility/handleError";
import { LanguageCodeEnum } from "@/lib/enum/language-code.enum";

@Component({
  components: {
    TheLayoutPortalSplit,
    TemplateList,
    TemplateDetail
  }
})
export default class TemplateListView extends PartnerFallbackMixin {
  loadingSelectedTemplate = false;
  loadingAllTemplates = true;
  partnerId = "";

  async mounted() {
    if (this.$route.params.partnerId) {
      this.partnerId = this.$route.params.partnerId;
      await this.trySetByRouteOrDefault();
    }

    await this.refreshList();
  }

  async loadAllTemplates() {
    if (this.partnerId) {
      PartnerTemplateModule.clearPartnerTemplates();
      await PartnerTemplateModule.getAllTemplates(this.partnerId);
    } else {
      AdminTemplateModule.clearAdminTemplates();
      await AdminTemplateModule.getAllTemplates();
    }
  }

  async loadTemplate(item: MrfiktivPartnerTemplateViewModelGen | MrfiktivAdminTemplateViewModelGen) {
    try {
      if (this.partnerId) {
        PartnerTemplateModule.clearPartnerTemplate();

        await PartnerTemplateModule.getTemplateByKey({
          partnerId: this.partnerId,
          key: item.key,
          language: item.meta.language as LanguageCodeEnum
        });

        if (!PartnerTemplateModule.partnerTemplate) {
          Vue.$toast.error("Das ausgewählte AdminTemplate konnte nicht gefunden werden.");
        }
      } else {
        AdminTemplateModule.clearAdminTemplate();

        await AdminTemplateModule.getTemplateByKey({ key: item.key, language: item.meta.language as LanguageCodeEnum });

        if (!AdminTemplateModule.adminTemplate) {
          Vue.$toast.error("Das ausgewählte AdminTemplate konnte nicht gefunden werden.");
        }
      }
    } catch (e) {
      this.$log.error(e);
      this.$toast.error((e as any).message);
    }
  }

  goToTemplateDetail(item: MrfiktivPartnerTemplateViewModelGen | MrfiktivAdminTemplateViewModelGen) {
    if (this.partnerId) {
      this.$router.push({
        name: "TemplateDetailPartnerView",
        params: { key: item.key, partnerId: this.partnerId },
        query: { language: item.meta.language }
      });
    } else {
      this.$router.push({
        name: "TemplateDetailView",
        params: { key: item.key },
        query: { language: item.meta.language }
      });
    }
  }

  setDetailLoading(loading: boolean) {
    this.loadingSelectedTemplate = loading;
  }

  async refreshList() {
    try {
      this.loadingAllTemplates = true;
      await this.loadAllTemplates();
    } catch (error) {
      handleError(error);
    } finally {
      this.loadingAllTemplates = false;
    }
  }

  async set(item: MrfiktivPartnerTemplateViewModelGen | MrfiktivAdminTemplateViewModelGen) {
    this.loadingSelectedTemplate = true;
    if (["xs", "sm"].includes(this.$vuetify.breakpoint.name)) {
      this.goToTemplateDetail(item);
    } else {
      await this.loadTemplate(item);
    }
    this.loadingSelectedTemplate = false;
  }
}
