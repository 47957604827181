



























import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { PartnerModule } from "@/store/modules/partner";
import { Component } from "vue-property-decorator";
import ConfirmActionDialog from "../../components/utility/ConfirmActionDialog.vue";
import ReportActivityBoxDocumentComponent, {
  ReportActivityBoxDocumentComponentSteps
} from "./ReportActivityBoxDocumentComponent.vue";

@Component({
  components: { ConfirmActionDialog, ReportActivityBoxDocumentComponent }
})
export default class ReportActivityUploadDialog extends DarkModeHighlightMixin {
  get reportActivityUploadDialog() {
    return this.$refs.reportActivityUploadDialog as ReportActivityBoxDocumentComponent;
  }

  dialog = false;

  valid = false;

  step = ReportActivityBoxDocumentComponentSteps.CHOICE;

  readonly STEPS = ReportActivityBoxDocumentComponentSteps;

  get rightButtonText() {
    switch (this.step) {
      case ReportActivityBoxDocumentComponentSteps.CHOICE: {
        return undefined;
      }
      case ReportActivityBoxDocumentComponentSteps.CHOOSE_EXISTING: {
        return this.$t("confirm");
      }
      case ReportActivityBoxDocumentComponentSteps.UPLOAD_NEW: {
        return this.$t("save");
      }
      case ReportActivityBoxDocumentComponentSteps.SUCCESS: {
        return this.$t("timeLine.ReportActivityBoxDocumentComponent.continue");
      }
      default: {
        return undefined;
      }
    }
  }

  get rightDisabled() {
    switch (this.step) {
      case ReportActivityBoxDocumentComponentSteps.CHOICE: {
        return false;
      }
      case ReportActivityBoxDocumentComponentSteps.CHOOSE_EXISTING: {
        return !(this.$refs.reportActivityUploadDialog as ReportActivityBoxDocumentComponent)?.selectedItems.length;
      }
      case ReportActivityBoxDocumentComponentSteps.UPLOAD_NEW: {
        return (this.$refs.reportActivityUploadDialog as ReportActivityBoxDocumentComponent)?.rightDisabled ?? true;
      }
      case ReportActivityBoxDocumentComponentSteps.SUCCESS: {
        return false;
      }
      default: {
        return false;
      }
    }
  }

  get onPrevious() {
    return () => {
      const onPrev = (this.$refs.reportActivityUploadDialog as ReportActivityBoxDocumentComponent)?.onPrevious;
      if (onPrev) {
        return onPrev();
      }
      return this.closeDialog();
    };
  }

  get leftText() {
    switch (this.step) {
      case ReportActivityBoxDocumentComponentSteps.CHOICE: {
        return this.$t("close");
      }
      case ReportActivityBoxDocumentComponentSteps.CHOOSE_EXISTING: {
        return this.$t("back");
      }
      case ReportActivityBoxDocumentComponentSteps.UPLOAD_NEW: {
        return this.$t("back");
      }
      case ReportActivityBoxDocumentComponentSteps.SUCCESS: {
        return this.$t("close");
      }
      default: {
        return "";
      }
    }
  }

  get onNext() {
    return () => {
      const onNext = (this.$refs.reportActivityUploadDialog as ReportActivityBoxDocumentComponent)?.onNext;
      if (onNext) {
        onNext();
      }
    };
  }

  get isLoading() {
    return (this.$refs.reportActivityUploadDialog as ReportActivityBoxDocumentComponent)?.isLoading;
  }

  closeDialog() {
    this.dialog = false;
  }

  show() {
    this.dialog = true;
  }

  close() {
    this.dialog = false;
    (this.$refs.reportActivityUploadDialog as ReportActivityBoxDocumentComponent)?.reset();
  }

  get partner() {
    return PartnerModule.partner;
  }

  get report() {
    return PartnerModule.report;
  }
}
