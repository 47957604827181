var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("v-simple-table", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function fn() {
          return [
            _c(
              "tbody",
              _vm._l(_vm.files, function(file) {
                return _c(
                  "tr",
                  {
                    key: file.id
                  },
                  [
                    _c(
                      "td",
                      {
                        staticClass: "pl-0"
                      },
                      [_c("strong", [_vm._v(_vm._s(file.originalName))])]
                    ),
                    _c("td", [_vm._v("·" + _vm._s(_vm.fileExtension(file)))]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm._f("simpleDoubleDigitDate")(
                            new Date(file.timestamp.created).toISOString()
                          )
                        )
                      )
                    ]),
                    !_vm.isFileInRefs(file)
                      ? _c(
                          "td",
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  icon: ""
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      color: "grey lighten-1"
                                    },
                                    on: {
                                      click: function click($event) {
                                        return _vm.removeFile(file)
                                      }
                                    }
                                  },
                                  [_vm._v("mdi-delete")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              }),
              0
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }