/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivOperationControllerFindAllParamsGen,
  MrfiktivOperationViewModelGen,
  MrfiktivPageViewModelGen,
  MrfiktivPartnerOperationViewModelGen
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Operation<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags operation
   * @name OperationControllerFindAll
   * @summary Get all operations
   * @request GET:/operation
   * @secure
   * @response `200` `((MrfiktivPageViewModelGen & { data?: (MrfiktivOperationViewModelGen)[] }) | MrfiktivOperationViewModelGen)`
   * @response `403` `void` Forbidden.
   */
  operationControllerFindAll = (query: MrfiktivOperationControllerFindAllParamsGen, params: RequestParams = {}) =>
    this.http.request<
      (MrfiktivPageViewModelGen & { data?: MrfiktivOperationViewModelGen[] }) | MrfiktivOperationViewModelGen,
      void
    >({
      path: `/operation`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags operation
   * @name OperationControllerFindOne
   * @summary Get operation by id
   * @request GET:/operation/{id}
   * @secure
   * @response `200` `MrfiktivOperationViewModelGen` The found operations
   * @response `403` `void` Forbidden.
   */
  operationControllerFindOne = (id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivOperationViewModelGen, void>({
      path: `/operation/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags operation
   * @name OperationControllerCancel
   * @summary (ADMIN) Cancel operation.
   * @request PUT:/operation/{id}/cancel
   * @secure
   * @response `200` `MrfiktivPartnerOperationViewModelGen` The cancelled operation
   * @response `400` `void` Bad Request on invalid input.
   * @response `403` `void` Forbidden.
   */
  operationControllerCancel = (id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivPartnerOperationViewModelGen, void>({
      path: `/operation/${id}/cancel`,
      method: "PUT",
      secure: true,
      format: "json",
      ...params
    });
}
