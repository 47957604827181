/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */
import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import partnerLinkerServiceService from "@/services/mrfiktiv/services/partnerLinkerServiceService";
import {
  MrfiktivCreatePartnerLinkDtoGen,
  MrfiktivPartnerLinkControllerGetParamsGen,
  MrfiktivPartnerLinkViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, Module, getModule } from "vuex-module-decorators";
import { PaginatedBaseStore } from "../paginated-base.store";

export class PartnerLinkerDataAccessLayer extends AbstractLocalDataAccessLayer<MrfiktivPartnerLinkViewModelGen> {
  getIdentifier(entity: MrfiktivPartnerLinkViewModelGen): string {
    return entity.id;
  }
}

export class PartnerLinkerPageDataProvider extends AbstractPageDataProvider<
  MrfiktivPartnerLinkViewModelGen,
  MrfiktivPartnerLinkControllerGetParamsGen
> {
  itemsPerPage = 25;

  async getPage(
    query: MrfiktivPartnerLinkControllerGetParamsGen
  ): Promise<IPageViewModel<MrfiktivPartnerLinkViewModelGen>> {
    const res = await partnerLinkerServiceService.getAll(query);

    return res as IPageViewModel<MrfiktivPartnerLinkViewModelGen>;
  }
}

/**
 * Example store for testing
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "partner-linker",
  store
})
export class PartnerLinkerStore extends PaginatedBaseStore<
  MrfiktivPartnerLinkViewModelGen,
  MrfiktivPartnerLinkControllerGetParamsGen
> {
  _data = new PartnerLinkerDataAccessLayer();
  _pageProvider = new PartnerLinkerPageDataProvider();

  _pager = new PageDataHandler(this._data, this._pageProvider);

  @Action
  async create(data: { partnerId: string; data: MrfiktivCreatePartnerLinkDtoGen }) {
    const created = await partnerLinkerServiceService.create(data.partnerId, data.data);
    this._data.set(created);
  }

  @Action
  async delete(data: { partnerId: string; id: string }) {
    const created = await partnerLinkerServiceService.delete(data.partnerId, data.id);
    this._data.delete(created);
  }
}

export const PartnerLinkerModule = getModule(PartnerLinkerStore);
