import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { IPartnerMessage, PartnerMessage } from "@/models/partner-message.entity";

export const PartnerMessageDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<IPartnerMessage> {
  getIdentifier(entity: IPartnerMessage): string {
    return entity.id;
  }

  mapEntity(entity: IPartnerMessage): IPartnerMessage {
    return new PartnerMessage(entity);
  }
})();
