












































import { simpleDate } from "@/lib/utility/date-helper";
import { Component, Prop } from "vue-property-decorator";
import FilterCard from "@/components/filter/FilterCard.vue";
import TheLayoutPortalList from "../../layouts/TheLayoutPortalList.vue";
import { IFilterListElement } from "@/lib/interfaces/filter/filterListElement.interface";
import { mixins } from "vue-class-component";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { AdminUserPaginationModule } from "@/store/modules/admin-user-pagination.store";
import UserAdministrationDetailCreateDialog from "@/components/partner/UserAdministrationDetailCreateDialog.vue";
import { IAdminUser } from "@/models/user.entity";
import PaginationListVueMixin from "@/mixins/PaginationListViewMixin.vue";
import { handleError } from "@/lib/utility/handleError";

@Component({
  components: {
    FilterCard,
    UserAdministrationDetailCreateDialog,
    TheLayoutPortalList
  }
})
export default class UserAdministrationListPaginated extends mixins(PaginationListVueMixin, DarkModeHighlightMixin) {
  @Prop()
  loadingUser!: boolean;

  @Prop()
  value!: IAdminUser;

  isLoading = true;

  isLoadingMore = false;

  isLoadingDetail = false;

  statusFilter: IFilterListElement[] = [];

  get paginationList() {
    return this.getModule().paginationList;
  }

  getModule() {
    return AdminUserPaginationModule;
  }

  async mounted() {
    try {
      this.isLoading = true;
      await this.refresh();
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoading = false;
    }

    const userId = this.$route.query["userId"] as string;
    let item: IAdminUser;

    if (userId) {
      item = this.getModule().maps.id.get(userId)[0];

      //item not loaded
      if (!item) {
        item = await this.getModule().getSelectedUser(userId);
      }
    } else {
      item = this.getModule().paginationList[0];
    }

    this.$emit("input", item ?? {});
  }

  async set(item: any) {
    this.$emit("update:loadingUser", true);
    if (this.$vuetify.breakpoint.smAndDown) {
      this.$router.push({ name: "UserAdministrationDetail", params: { userId: item?._id } });
    } else {
      await this.loadDetail(item);
    }
    this.$emit("update:loadingUser", false);
  }

  async loadDetail(user: IAdminUser) {
    this.isLoadingDetail = true;
    user = await user.fetch();
    this.$emit("input", user);
    this.isLoadingDetail = false;
  }

  async refresh() {
    this.isLoading = true;
    await AdminUserPaginationModule.fetchFirstPage({});
    this.isLoading = false;
  }

  async loadAllFromLastId() {
    if (this.paginationList.length === 0) {
      await AdminUserPaginationModule.fetchFirstPage({});
    } else {
      await AdminUserPaginationModule.fetchNextPage({});
    }
  }

  isActive(item: any) {
    return item?._id === this.value?._id ? "v-item--active v-list-item--active" : "";
  }
  date(item: any): string {
    return simpleDate(item?.timestamps?.created);
  }
}
