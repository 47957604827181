

























import CooperationPartners from "@/components/docphant/CooperationPartners.vue";
import MachineAttachmentListCard from "@/components/docphant/dashboard/MachineAttachmentListCard.vue";
import MachineListCard from "@/components/docphant/dashboard/MachineListCard.vue";
import ManufacturerDetailCard from "@/components/docphant/dashboard/ManufacturerDetailCard.vue";
import ManufacturerListCard from "@/components/docphant/dashboard/ManufacturerListCard.vue";
import TopToolbarLogo from "@/components/navigation/TopToolbarLogo.vue";
import PartnerFooter from "@/components/partner/PartnerFooter.vue";
import { DocphantModule } from "@/store/modules/docphant";
import { MachineModule } from "@/store/modules/machine";
import { MachineAttachmentModule } from "@/store/modules/machine-attachment";
import { ManufacturerModule } from "@/store/modules/manufacturer";
import { Component, Vue } from "vue-property-decorator";
import DocphantFooter from "@/components/docphant/DocphantFooter.vue";
import TheLayoutDocphant from "@/layouts/TheLayoutDocphant.vue";

@Component({
  components: {
    TheLayoutDocphant,
    PartnerFooter,
    TopToolbarLogo,
    ManufacturerListCard,
    ManufacturerDetailCard,
    MachineListCard,
    CooperationPartners,
    MachineAttachmentListCard,
    DocphantFooter
  }
})
export default class ManufacturerDetailPage extends Vue {
  /**
   * gets machine from store
   */
  get manufacturer() {
    return ManufacturerModule.manufacturer;
  }

  get machines() {
    return MachineModule.machineList;
  }

  get machineAttachments() {
    return MachineAttachmentModule.machineAttachmentList;
  }

  async mounted() {
    const favIcon: any = document.querySelector("link[rel~='icon']");
    favIcon.href = this.partner.settings.favIconUrl;

    this.adjustMachineCardMinHeight();
    const manufacturerId = this.$route.params.manufacturerId;
    try {
      await ManufacturerModule.getManufacturer(manufacturerId);
      await MachineModule.getAll(manufacturerId);
      await MachineAttachmentModule.getAll(manufacturerId);
    } catch {
      Vue.$toast.error("Keine Hersteller gefunden");
      this.$router.push({ name: "DocphantErrorPage" });
    }
  }
  created() {
    window.addEventListener("resize", this.adjustMachineCardMinHeight);
  }

  destroyed() {
    window.removeEventListener("resize", this.adjustMachineCardMinHeight);
  }

  adjustMachineCardMinHeight() {
    const container = document.getElementById("cardContainer");
    const footer = document.getElementById("footer");
    const header = document.getElementById("header");

    if (container && footer && header) {
      const top = header.clientHeight + 20;
      const bottom = footer.clientHeight;
      container.style.marginTop = `${top}px`;
      container.style.marginBottom = "22px";
      container.style.minHeight = `calc( 100vh - ${top}px - ${bottom}px - 22px )`;
    }
  }

  /*
   * information on cooperation partner content
   */
  get coop() {
    return DocphantModule.cooperatingPartners;
  }
  get partner() {
    return DocphantModule.ibLeimkuehler;
  }
}
