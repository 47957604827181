var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      attrs: {
        flat: ""
      }
    },
    [
      _c(
        "v-subheader",
        {
          staticClass: "mt-4"
        },
        [
          _vm._v(
            " " +
              _vm._s(_vm.$t("components.utility.LabelComponent.header")) +
              " "
          ),
          _c("v-spacer"),
          _c(
            "v-menu",
            {
              attrs: {
                "close-on-content-click": false,
                "nudge-width": 200
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function fn(_ref) {
                    var on = _ref.on,
                      attrs = _ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              attrs: {
                                text: "",
                                "x-small": "",
                                color: "info",
                                loading: _vm.loading,
                                disabled: _vm.disabled
                              },
                              on: {
                                click: _vm.addEnterEvent
                              }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("components.utility.LabelComponent.new")
                              ) +
                              " "
                          )
                        ]
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.menu,
                callback: function callback($$v) {
                  _vm.menu = $$v
                },
                expression: "menu"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-form",
                    {
                      staticClass: "pa-2",
                      model: {
                        value: _vm.valid,
                        callback: function callback($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          rules: _vm.tagRule
                        },
                        model: {
                          value: _vm.tagToAd,
                          callback: function callback($$v) {
                            _vm.tagToAd = $$v
                          },
                          expression: "tagToAd"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    {
                      staticClass: "mt-n4"
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "info",
                            outlined: "",
                            small: ""
                          },
                          on: {
                            click: _vm.closeMenu
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("views.fleet.EventCreateDialog.close")
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "info",
                            elevation: "0",
                            disabled: !_vm.valid,
                            small: ""
                          },
                          on: {
                            click: _vm.addTag
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("views.fleet.EventCreateDialog.save")
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      !_vm.labels.length
        ? _c("v-card-text", [
            _vm._v(
              " " +
                _vm._s(_vm.$t("components.utility.LabelComponent.none")) +
                " "
            )
          ])
        : _vm._e(),
      _c(
        "v-chip-group",
        {
          staticClass: "mx-2",
          attrs: {
            column: ""
          }
        },
        _vm._l(_vm.labels, function(tag) {
          return _c(
            "v-chip",
            {
              key: tag,
              attrs: {
                small: "",
                disabled: _vm.loading,
                close: "",
                "close-icon": "mdi-close"
              },
              on: {
                "click:close": function clickClose($event) {
                  return _vm.removeTag(tag)
                }
              }
            },
            [_vm._v(" " + _vm._s(tag) + " ")]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }