/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { PartnerEntity } from "@/models/partnerEntity";
import { IThg } from "@/models/thg.entity";
import billingBatchService from "@/services/thg/services/billingBatchService";
import {
  ThgBillingBatchAutoCreationDtoGen,
  ThgBillingBatchControllerCreateBillingDocumentParamsGen,
  ThgBillingBatchCreateDtoGen,
  ThgBillingListDtoGen,
  ThgBillingViewmodelGen,
  ThgNamedUrlViewModelGen,
  ThgOperationIdViewModelGen,
  ThgThgMeterReadingViewModelGen,
  ThgUserViewmodelGen
} from "@/services/thg/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({
  dynamic: true,
  namespaced: true,
  name: "billingBatch",
  store
})
export class BillingBatchStore extends VuexModule {
  private _billing: ThgBillingViewmodelGen | undefined;
  private _billedBeings: (ThgUserViewmodelGen | PartnerEntity)[] = [];
  private _billedDocuments: IThg[] = [];
  private _createdBillings: (ThgBillingViewmodelGen & { billedBeingId: string })[] = [];

  /**
   * A created billing
   */
  get billing(): ThgBillingViewmodelGen | undefined {
    return this._billing;
  }

  /**
   * The human beings or companies that are billed
   */
  get billedBeings(): (ThgUserViewmodelGen | PartnerEntity)[] | undefined {
    return this._billedBeings;
  }

  /**
   * The billed documents
   */
  get billedDocuments(): (IThg | ThgThgMeterReadingViewModelGen)[] | undefined {
    return this._billedDocuments;
  }

  /**
   * A created billing
   */
  get createdBillings(): (ThgBillingViewmodelGen & { billedBeingId: string })[] {
    return this._createdBillings;
  }

  @Mutation
  private mutateBilling(billing: ThgBillingViewmodelGen): void {
    this._billing = billing;
  }

  @Mutation
  private mutateBilledBeings(billedBeings: (ThgUserViewmodelGen | PartnerEntity)[]): void {
    this._billedBeings.splice(0, this._billedBeings.length, ...billedBeings);
  }
  @Mutation
  private mutateBilledDocuments(billedDocuments: IThg[] | undefined): void {
    this._billedDocuments.splice(0, this._billedDocuments.length, ...(billedDocuments || []));
  }

  @Mutation
  private mutateCreatedBillings(data: { id: string; billing: ThgBillingViewmodelGen }): void {
    const existing = this._createdBillings.findIndex(billing => billing.billedBeingId === data.id);
    if (existing >= 0) {
      this._createdBillings.splice(existing, 1, { billedBeingId: data.id, ...data.billing });
    }
    this._createdBillings.push({ billedBeingId: data.id, ...data.billing });
  }

  @Mutation
  private mutateClearCreatedBillings(): void {
    this._createdBillings.splice(0);
  }

  @Action
  setBilling(billing: ThgBillingViewmodelGen) {
    this.context.commit("mutateBilling", billing);
  }

  @Action
  addBillingToCreatedBillings(data: { id: string; billing: ThgBillingViewmodelGen }) {
    this.context.commit("mutateCreatedBillings", data);
  }

  @Action
  async createBilling(data: {
    query: ThgBillingBatchControllerCreateBillingDocumentParamsGen;
    data: ThgBillingBatchCreateDtoGen;
  }): Promise<ThgBillingViewmodelGen> {
    const billing = await billingBatchService.createBatchBilling(data.query, data.data);

    this.setBilling(billing);

    return billing;
  }

  @Action
  async createBillingAsync(data: ThgBillingBatchCreateDtoGen): Promise<string> {
    const operationId = (await billingBatchService.dispatchBatchBillingAsync(data)).operationId;

    return operationId;
  }

  /**
   * Sets the configuration for the billing process
   *
   * @param config
   */
  @Action
  setForBilling(config: {
    billedBeings: (ThgUserViewmodelGen | PartnerEntity)[];
    billedDocuments: IThg[] | ThgThgMeterReadingViewModelGen[];
  }) {
    this.context.commit("mutateBilledBeings", config.billedBeings);
    this.context.commit("mutateBilledDocuments", config.billedDocuments);
    this.context.commit("mutateClearCreatedBillings");
  }

  /**
   * creates operation that generates CSV files for billings
   * @see getBillingCsv
   *
   * @param data
   * @returns
   */
  @Action
  async dispatchBillingCsv(data: ThgBillingListDtoGen): Promise<string> {
    const operation = await billingBatchService.dispatchBillingCsv(data);

    return operation.operationId;
  }

  /**
   * get csv files that are generated in operation with operationId
   *
   * @param operationId
   * @returns
   */
  @Action
  async getBillingCsv(operationId: string): Promise<ThgNamedUrlViewModelGen[]> {
    const csvs = await billingBatchService.getBillingCSVs(operationId);

    return csvs;
  }

  @Action
  async dispatchAutoBill(data: ThgBillingBatchAutoCreationDtoGen): Promise<ThgOperationIdViewModelGen> {
    return billingBatchService.dispatchAutoBill(data);
  }

  private _selectedPartnerId = "";
  get selectedPartnerId() {
    return this._selectedPartnerId;
  }
  @Mutation
  private _mutateSelectedPartnerId(selectedPartnerId: string) {
    this._selectedPartnerId = selectedPartnerId;
  }
  @Action
  setSelectedPartnerId(selectedPartnerId: string) {
    this.context.commit("_mutateSelectedPartnerId", selectedPartnerId);
  }
}

export const BillingBatchModule = getModule(BillingBatchStore);
