





















































































































import { IMachineDto } from "@/lib/interfaces/Docphant/machine.dto.interface";
import { requiredRule } from "@/lib/rules/requiredRule";
import { DimensionUnitEnum } from "@/store/enum/docphant/dimension.unit.enum";
import { MachineEnum } from "@/store/enum/docphant/machine.enum";
import { MachineStatusEnum } from "@/store/enum/docphant/machine.status.enum";
import { WeigthUnitEnum } from "@/store/enum/docphant/weight.unit.enum";
import { IMachineCreateStoreDto } from "@/store/interface/machine.create.store.interface";
import { MachineModule } from "@/store/modules/machine";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class MachineCreateDialog extends Vue {
  machine: IMachineDto = {
    machineType: MachineEnum.MACHINE,
    machineName: "",
    variant: "",
    description: "",
    weight: {
      unit: WeigthUnitEnum.KG,
      amount: 0
    },
    dimension: {
      unit: DimensionUnitEnum.MM,
      height: 0,
      width: 0,
      depth: 0
    },
    status: MachineStatusEnum.ACTIVATED
  };

  machineTypes = Object.keys(MachineEnum).map(k => MachineEnum[k as any]);
  weightUnits = Object.keys(WeigthUnitEnum).map(k => WeigthUnitEnum[k as any]);
  dimensionUnits = Object.keys(DimensionUnitEnum).map(k => DimensionUnitEnum[k as any]);
  machineStati = Object.keys(MachineStatusEnum).map(k => MachineStatusEnum[k as any]);

  get rules() {
    return [requiredRule()];
  }

  dialog = false;

  closeUpdate() {
    this.dialog = false;
  }

  async createMachine() {
    const machineCreateStoreDto: IMachineCreateStoreDto = {
      manufacturerId: this.$route.params.manufacturerId,
      dto: this.machine
    };
    await MachineModule.create(machineCreateStoreDto);
    this.dialog = false;
  }
}
