



















import PartnerFaqDetailCard from "@/components/partner/PartnerFaqDetailCard.vue";
import PartnerFaqListCard from "@/components/partner/PartnerFaqListCard.vue";
import TheLayoutPortalSplit from "@/layouts/TheLayoutPortalSplit.vue";
import { handleError } from "@/lib/utility/handleError";
import PaginationListVueMixin from "@/mixins/PaginationListViewMixin.vue";
import { MrfiktivFaqViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ThgFaqViewModelGen } from "@/services/thg/v1/data-contracts";
import { FaqModule } from "@/store/modules/faq.store";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortalSplit,
    PartnerFaqList,
    PartnerFaqListCard,
    PartnerFaqDetailCard
  }
})
export default class PartnerFaqList extends mixins(PaginationListVueMixin) {
  isLoadingDetail = false;

  get partnerId() {
    return this.$route.params.partnerId;
  }

  get faq() {
    return this.getModule().faq;
  }

  getModule() {
    return FaqModule;
  }

  onUpdate(faq: ThgFaqViewModelGen) {
    this.getModule().replaceInList(faq);
  }

  async onCreate(faq: ThgFaqViewModelGen) {
    this.getModule().prependToList(faq);
    await this.set(faq);
  }

  async onDelete(faq: ThgFaqViewModelGen) {
    const next = await this.getModule().getNextItemInList(faq);
    this.getModule().removeInList(faq);
    if (next && next.id !== faq.id) {
      await this.set(next);
    } else {
      this.getModule().resetFaq();
    }
  }

  async mounted() {
    await this.refresh({ partnerId: this.partnerId });

    const item = this.getModule().paginationList[0];
    if (item) {
      await this.loadDetail(item);
    }
  }

  async set(item: any) {
    if (this.$vuetify.breakpoint.smAndDown) {
      await this.$router.push({ name: "PartnerFaqDetail", params: { faqId: item.id, partnerId: this.partnerId } });
    } else {
      await this.loadDetail(item);
    }
  }

  async loadDetail(faq: ThgFaqViewModelGen | MrfiktivFaqViewModelGen) {
    try {
      this.isLoadingDetail = true;
      await FaqModule.get({ faqId: faq.id, partnerId: this.partnerId });
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoadingDetail = false;
    }
  }
}
