var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      attrs: {
        flat: ""
      }
    },
    [
      _c(
        "v-card-title",
        {
          staticClass: "title pt-4 mb-n7"
        },
        [
          _c("h4", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "components.partner.PartnerCreateorUpdateReportSettings.ciColor"
                  )
                ) +
                " "
            )
          ])
        ]
      ),
      _c(
        "v-card",
        {
          staticClass: "my-2",
          attrs: {
            flat: "",
            "max-width": "700"
          }
        },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "components.partner.PartnerCreateorUpdateReportSettings.ciColorValue1"
                            )
                          )
                        )
                      ]),
                      _c("v-color-picker", {
                        attrs: {
                          "dot-size": "25",
                          "hide-mode-switch": "",
                          "hide-sliders": "",
                          mode: "hexa",
                          "show-swatches": "",
                          "swatches-max-height": "100",
                          disabled: _vm.disabled
                        },
                        model: {
                          value: _vm.reportSettings.firstColor,
                          callback: function callback($$v) {
                            _vm.$set(_vm.reportSettings, "firstColor", $$v)
                          },
                          expression: "reportSettings.firstColor"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "components.partner.PartnerCreateorUpdateReportSettings.ciColorValue2"
                            )
                          )
                        )
                      ]),
                      _c("v-color-picker", {
                        attrs: {
                          "dot-size": "25",
                          "hide-mode-switch": "",
                          "hide-sliders": "",
                          mode: "hexa",
                          "show-swatches": "",
                          "swatches-max-height": "100",
                          disabled: _vm.disabled
                        },
                        model: {
                          value: _vm.reportSettings.secondColor,
                          callback: function callback($$v) {
                            _vm.$set(_vm.reportSettings, "secondColor", $$v)
                          },
                          expression: "reportSettings.secondColor"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-title",
            {
              staticClass: "title pt-4 mb-n7"
            },
            [
              _c("h4", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "components.partner.PartnerCreateorUpdateReportSettings.screens"
                    )
                  )
                )
              ])
            ]
          ),
          _c(
            "v-card-text",
            {
              staticClass: "mt-8"
            },
            [
              !_vm.disabled
                ? _c(
                    "div",
                    {
                      staticClass: "mb-4"
                    },
                    [
                      _vm.reportContext
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "ma-2",
                              attrs: {
                                small: "",
                                dense: "",
                                outlined: ""
                              },
                              on: {
                                click: function click($event) {
                                  return _vm.defaulReportScreenOrder()
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.partner.PartnerCreateorUpdateReportSettings.standardOrder"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.ghgContext
                        ? _c(
                            "div",
                            [
                              _c("v-subheader", [_vm._v("DE")]),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ma-2",
                                  attrs: {
                                    small: "",
                                    dense: "",
                                    outlined: ""
                                  },
                                  on: {
                                    click: function click($event) {
                                      return _vm.defaulThgScreenOrder()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "components.partner.PartnerCreateorUpdateReportSettings.thgStandardOrder"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ma-2",
                                  attrs: {
                                    small: "",
                                    dense: "",
                                    outlined: ""
                                  },
                                  on: {
                                    click: function click($event) {
                                      return _vm.defaulThgScreenOrderCustomerAccount()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "components.partner.PartnerCreateorUpdateReportSettings.thgStandardOrderWithCustomerAccount"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ma-2",
                                  attrs: {
                                    small: "",
                                    dense: "",
                                    outlined: ""
                                  },
                                  on: {
                                    click: function click($event) {
                                      return _vm.defaulThgScreenOrderOnlyCustomerAccount()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "components.partner.PartnerCreateorUpdateReportSettings.thgStandardOrderOnlyWithCustomerAccount"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c("v-subheader", [_vm._v("AT")]),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ma-2",
                                  attrs: {
                                    small: "",
                                    dense: "",
                                    outlined: ""
                                  },
                                  on: {
                                    click: function click($event) {
                                      return _vm.defaulThgATScreenOrder()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "components.partner.PartnerCreateorUpdateReportSettings.thgStandardOrder"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ma-2",
                                  attrs: {
                                    small: "",
                                    dense: "",
                                    outlined: ""
                                  },
                                  on: {
                                    click: function click($event) {
                                      return _vm.defaulThgATScreenOrderCustomerAccount()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "components.partner.PartnerCreateorUpdateReportSettings.thgStandardOrderWithCustomerAccount"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ma-2",
                                  attrs: {
                                    small: "",
                                    dense: "",
                                    outlined: ""
                                  },
                                  on: {
                                    click: function click($event) {
                                      return _vm.defaulThgATScreenOrderOnlyCustomerAccount()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "components.partner.PartnerCreateorUpdateReportSettings.thgStandardOrderOnlyWithCustomerAccount"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-autocomplete", {
                attrs: {
                  items: _vm.screenTypes,
                  filled: "",
                  chips: "",
                  multiple: "",
                  disabled: _vm.disabled
                },
                scopedSlots: _vm._u(
                  [
                    !_vm.disabled
                      ? {
                          key: "selection",
                          fn: function fn(data) {
                            return [
                              _c(
                                "draggable",
                                {
                                  attrs: {
                                    id: data.index,
                                    list: _vm.reportSettings.screenOrder,
                                    animation: true,
                                    group: "group",
                                    "ghost-class": "ghost",
                                    sort: true,
                                    move: _vm.move
                                  },
                                  on: {
                                    change: _vm.handleScreenOrderChange
                                  }
                                },
                                [
                                  _c(
                                    "v-chip",
                                    {
                                      key: data.index,
                                      attrs: {
                                        draggable: ""
                                      },
                                      on: {
                                        mousedown: function mousedown($event) {
                                          $event.stopPropagation()
                                        },
                                        click: function click($event) {
                                          $event.stopPropagation()
                                        }
                                      },
                                      model: {
                                        value:
                                          _vm.reportSettings.screenOrder[
                                            data.index
                                          ],
                                        callback: function callback($$v) {
                                          _vm.$set(
                                            _vm.reportSettings.screenOrder,
                                            data.index,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "reportSettings.screenOrder[data.index]"
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(data.item) + " ")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      : null
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.reportSettings.screenOrder,
                  callback: function callback($$v) {
                    _vm.$set(_vm.reportSettings, "screenOrder", $$v)
                  },
                  expression: "reportSettings.screenOrder"
                }
              }),
              !_vm.disabled
                ? _c("select-and-order-list-dialog", {
                    staticClass: "mt-4",
                    attrs: {
                      possibleItems: _vm.screenTypes,
                      selectedList: _vm.reportSettings.screenOrder
                    },
                    on: {
                      updateOrder: _vm.updateScreenOrder
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-card-title",
            {
              staticClass: "title pt-4 "
            },
            [
              _c("h4", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "components.partner.PartnerCreateorUpdateReportSettings.welcome"
                    )
                  )
                )
              ])
            ]
          ),
          _c(
            "v-card-text",
            [
              _c("v-text-field", {
                attrs: {
                  type: "text",
                  label: _vm.$t(
                    "components.partner.PartnerCreateorUpdateReportSettings.headerTitleLabel"
                  ),
                  hint: _vm.$t(
                    "components.partner.PartnerCreateorUpdateReportSettings.headerTitleHint"
                  ),
                  disabled: _vm.disabled,
                  outlined: ""
                },
                model: {
                  value: _vm.reportSettings.headerTitle,
                  callback: function callback($$v) {
                    _vm.$set(_vm.reportSettings, "headerTitle", $$v)
                  },
                  expression: "reportSettings.headerTitle"
                }
              }),
              _c("v-text-field", {
                attrs: {
                  type: "text",
                  label: _vm.$t(
                    "components.partner.PartnerCreateorUpdateReportSettings.headerBtnLabel"
                  ),
                  hint: _vm.$t(
                    "components.partner.PartnerCreateorUpdateReportSettings.headerBtnHint"
                  ),
                  disabled: _vm.disabled,
                  outlined: ""
                },
                model: {
                  value: _vm.reportSettings.headerBtn,
                  callback: function callback($$v) {
                    _vm.$set(_vm.reportSettings, "headerBtn", $$v)
                  },
                  expression: "reportSettings.headerBtn"
                }
              }),
              _c("v-text-field", {
                attrs: {
                  type: "text",
                  label: _vm.$t(
                    "components.partner.PartnerCreateorUpdateReportSettings.welcomeTitleLabel"
                  ),
                  hint: _vm.$t(
                    "components.partner.PartnerCreateorUpdateReportSettings.welcomeTitleHint"
                  ),
                  disabled: _vm.disabled,
                  outlined: ""
                },
                model: {
                  value: _vm.reportSettings.welcomeTitle,
                  callback: function callback($$v) {
                    _vm.$set(_vm.reportSettings, "welcomeTitle", $$v)
                  },
                  expression: "reportSettings.welcomeTitle"
                }
              }),
              _vm._l(this.reportSettings.welcomeText, function(item, index) {
                return _c(
                  "div",
                  {
                    key: index
                  },
                  [
                    _c(
                      "v-card-text",
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.partner.PartnerCreateorUpdateReportSettings.welcomeTextLabel",
                                {
                                  number: index + 1
                                }
                              )
                            ) +
                            " "
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "mx-2",
                            attrs: {
                              elevation: "0",
                              small: "",
                              color: "error",
                              "data-test-remove-welcome-text": "",
                              disabled: _vm.disabled
                            },
                            on: {
                              click: function click($event) {
                                return _vm.removeWelcomeText(index)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "components.partner.PartnerCreateorUpdateReportSettings.removeWelcomeText"
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c("v-text-field", {
                      attrs: {
                        outlined: "",
                        required: "",
                        rules: _vm.rules,
                        label: _vm.$t("icon") + " *",
                        "data-test-witness-first-name": "",
                        "append-outer-icon": item.icon
                      },
                      model: {
                        value: item.icon,
                        callback: function callback($$v) {
                          _vm.$set(item, "icon", $$v)
                        },
                        expression: "item.icon"
                      }
                    }),
                    _c("v-text-field", {
                      attrs: {
                        outlined: "",
                        required: "",
                        rules: _vm.rules,
                        label: _vm.$t("text") + " *",
                        "data-test-witness-last-name": ""
                      },
                      model: {
                        value: item.text,
                        callback: function callback($$v) {
                          _vm.$set(item, "text", $$v)
                        },
                        expression: "item.text"
                      }
                    })
                  ],
                  1
                )
              }),
              _c(
                "v-btn",
                {
                  staticClass: "mb-4",
                  attrs: {
                    elevation: "0",
                    color: "success",
                    "data-test-add-welcome-text": "",
                    disabled: _vm.disabled
                  },
                  on: {
                    click: _vm.addWelcomeText
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "components.partner.PartnerCreateorUpdateReportSettings.addWelcomeText"
                        )
                      ) +
                      " "
                  )
                ]
              ),
              _c("v-text-field", {
                attrs: {
                  type: "text",
                  label: _vm.$t(
                    "components.partner.PartnerCreateorUpdateReportSettings.welcomeBtnLabel"
                  ),
                  hint: _vm.$t(
                    "components.partner.PartnerCreateorUpdateReportSettings.welcomeBtnHint"
                  ),
                  disabled: _vm.disabled,
                  outlined: ""
                },
                model: {
                  value: _vm.reportSettings.welcomeBtn,
                  callback: function callback($$v) {
                    _vm.$set(_vm.reportSettings, "welcomeBtn", $$v)
                  },
                  expression: "reportSettings.welcomeBtn"
                }
              })
            ],
            2
          ),
          _c(
            "v-card-title",
            {
              staticClass: "title pt-4 "
            },
            [
              _c("h4", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "components.partner.PartnerCreateorUpdateReportSettings.reportImages"
                    )
                  )
                )
              ])
            ]
          ),
          _c(
            "v-card-text",
            {},
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "10"
                      }
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          type: "text",
                          label: _vm.$t(
                            "components.partner.PartnerCreateorUpdateReportSettings.logoLabel"
                          ),
                          hint: _vm.$t(
                            "components.partner.PartnerCreateorUpdateReportSettings.logoHint"
                          ),
                          disabled: _vm.disabled,
                          outlined: ""
                        },
                        model: {
                          value: _vm.reportSettings.logoUrl,
                          callback: function callback($$v) {
                            _vm.$set(_vm.reportSettings, "logoUrl", $$v)
                          },
                          expression: "reportSettings.logoUrl"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "2"
                      }
                    },
                    [
                      !_vm.disabled
                        ? _c("public-image-upload-dialog", {
                            attrs: {
                              prefix: "logo",
                              folder: _vm.folderName
                            },
                            on: {
                              onUpload: _vm.handleLogo
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "10"
                      }
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          type: "text",
                          label: _vm.$t(
                            "components.partner.PartnerCreateorUpdateReportSettings.headerLabel"
                          ),
                          hint: _vm.$t(
                            "components.partner.PartnerCreateorUpdateReportSettings.headerHint"
                          ),
                          disabled: _vm.disabled,
                          outlined: "",
                          required: ""
                        },
                        model: {
                          value: _vm.reportSettings.headerImageUrl,
                          callback: function callback($$v) {
                            _vm.$set(_vm.reportSettings, "headerImageUrl", $$v)
                          },
                          expression: "reportSettings.headerImageUrl"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "2"
                      }
                    },
                    [
                      !_vm.disabled
                        ? _c("public-image-upload-dialog", {
                            attrs: {
                              prefix: "header",
                              folder: _vm.folderName
                            },
                            on: {
                              onUpload: _vm.handleHeader
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "10"
                      }
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          type: "text",
                          label: _vm.$t(
                            "components.partner.PartnerCreateorUpdateReportSettings.faviconLabel"
                          ),
                          hint: _vm.$t(
                            "components.partner.PartnerCreateorUpdateReportSettings.faviconHint"
                          ),
                          disabled: _vm.disabled,
                          outlined: ""
                        },
                        model: {
                          value: _vm.reportSettings.favIconUrl,
                          callback: function callback($$v) {
                            _vm.$set(_vm.reportSettings, "favIconUrl", $$v)
                          },
                          expression: "reportSettings.favIconUrl"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "2"
                      }
                    },
                    [
                      !_vm.disabled
                        ? _c("public-image-upload-dialog", {
                            attrs: {
                              prefix: "favicon",
                              folder: _vm.folderName
                            },
                            on: {
                              onUpload: _vm.handleFavIcon
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "10"
                      }
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          type: "text",
                          label: _vm.$t(
                            "components.partner.PartnerCreateorUpdateReportSettings.welcomeVideoLabel"
                          ),
                          hint: _vm.$t(
                            "components.partner.PartnerCreateorUpdateReportSettings.welcomeVideoHint"
                          ),
                          disabled: _vm.disabled,
                          outlined: ""
                        },
                        model: {
                          value: _vm.reportSettings.welcomeVideoUrl,
                          callback: function callback($$v) {
                            _vm.$set(_vm.reportSettings, "welcomeVideoUrl", $$v)
                          },
                          expression: "reportSettings.welcomeVideoUrl"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-col", {
                    attrs: {
                      cols: "2"
                    }
                  }),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "10"
                      }
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          type: "text",
                          label: _vm.$t(
                            "components.partner.PartnerCreateorUpdateReportSettings.welcomeImageLabel"
                          ),
                          hint: _vm.$t(
                            "components.partner.PartnerCreateorUpdateReportSettings.welcomeImageHint"
                          ),
                          disabled: _vm.disabled,
                          outlined: ""
                        },
                        model: {
                          value: _vm.reportSettings.welcomeImageUrl,
                          callback: function callback($$v) {
                            _vm.$set(_vm.reportSettings, "welcomeImageUrl", $$v)
                          },
                          expression: "reportSettings.welcomeImageUrl"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "2"
                      }
                    },
                    [
                      !_vm.disabled
                        ? _c("public-image-upload-dialog", {
                            attrs: {
                              prefix: "welcome",
                              folder: _vm.folderName
                            },
                            on: {
                              onUpload: _vm.handleWelcome
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("PartnerDetailImage", {
                    staticClass: "pl-4 pb-8",
                    attrs: {
                      favIconUrl: _vm.reportSettings.favIconUrl,
                      headerImageUrl: _vm.reportSettings.headerImageUrl,
                      logoUrl: _vm.reportSettings.logoUrl
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }