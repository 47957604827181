









import { Component, Vue } from "vue-property-decorator";
import TopToolbarLogo from "@/components/navigation/TopToolbarLogo.vue";
import DocphantFooter from "@/components/docphant/DocphantFooter.vue";
import { DocphantModule } from "@/store/modules/docphant";
import DocphantErrorCard from "@/components/docphant/dashboard/DocphantErrorCard.vue";
import TheLayoutDocphant from "@/layouts/TheLayoutDocphant.vue";

@Component({
  components: {
    TheLayoutDocphant,
    TopToolbarLogo,
    DocphantFooter,
    DocphantErrorCard
  }
})
export default class DocphantErrorPage extends Vue {
  mounted() {
    const favIcon: any = document.querySelector("link[rel~='icon']");
    favIcon.href = this.partner.settings.favIconUrl;
  }

  errorMessage = "Ein Fehler ist aufgetreten.";

  /*
   * information on cooperation partner content
   */
  get coop() {
    return DocphantModule.cooperatingPartners;
  }
  get partner() {
    return DocphantModule.ibLeimkuehler;
  }
}
