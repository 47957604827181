






































import { Component, Vue, Prop } from "vue-property-decorator";
import { IManufacturer } from "@/lib/interfaces/Docphant/manufacturer.schema.interface";

@Component({
  components: {}
})
export default class ManufacturerListCard extends Vue {
  @Prop({})
  manufacturerList!: IManufacturer[];
  search = "";
  headers = [
    {
      text: "Name",
      align: "start",
      value: "name"
    },
    {
      text: "Straße",
      value: "address.street"
    },
    {
      text: "Plz",
      value: "address.zip"
    },
    {
      text: "Stadt",
      value: "address.city"
    }
  ];

  handleClickedRow(value: any) {
    this.$router.push({ name: "ManufacturerDetailPage", params: { manufacturerId: value.id } });
  }

  newManufactuer() {
    this.$router.push({ name: "ManufacturerCreationPage" });
  }
}
