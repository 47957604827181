




















import { handleError } from "@/lib/utility/handleError";
import { ThgFaqViewModelGen } from "@/services/thg/v1/data-contracts";
import { FaqModule } from "@/store/modules/faq.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import Tooltip from "../utility/tooltip.vue";

@Component({ components: { Tooltip } })
export default class PartnerFaqToggleActive extends Vue {
  @Prop()
  faq!: ThgFaqViewModelGen;

  isLoading = false;

  async update() {
    try {
      this.isLoading = true;
      const updated = await FaqModule.update({
        faqId: this.faq.id,
        partnerId: this.faq.partnerId,
        data: { isActive: !this.faq.isActive }
      });
      this.$emit("update", updated);
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
