import { IMachineViewmodelWithManufacturerAndAttachment } from "@/lib/interfaces/Docphant/machine.viewmodel.interface";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import MachineService from "../../services/mrfiktiv/services/machineService";
import { DimensionUnitEnum } from "../enum/docphant/dimension.unit.enum";
import { MachineEnum } from "../enum/docphant/machine.enum";
import { MachineStatusEnum } from "../enum/docphant/machine.status.enum";
import { WeigthUnitEnum } from "../enum/docphant/weight.unit.enum";
import { IDocphantMachineSelectors } from "@/lib/interfaces/Docphant/docphantMachineSelectors.interface";
import { CountryCodeEnum } from "../../lib/enum/country-code.enum";
/**
 * Manages the machine document
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "docphant",
  store
})
class Docphant extends VuexModule {
  cooperatingPartners = {
    partnerImageOne: "https://www.mmmint.ai/partner/digitalmanual/cooperation_logo_ib-leimkuehler.png",
    partnerUrlOne: "https://www.ib-leimkuehler.de/",
    partnerImageTwo: "https://www.mmmint.ai/partner/digitalmanual/cooperation_logo_mmmint-ai.png",
    partnerUrlTwo: "https://www.mmmint.ai/"
  };
  ibLeimkuehler = {
    id: "6165599ae275aa3108a916f1",
    address: {
      state: "Niedersachsen",
      street: "Obere Findelstätte 7",
      zip: "49124",
      city: "Georgsmarienhütte",
      geo: { lat: 52.20252, lng: 8.0317 }
    },
    companyName: "Ingenieurbüro Leimkühler",
    companyUsername: "ingenieurbueroLeimkuehler",
    contact: { email: "jan.leimkuehler@ib-leimkuehler.de", phone: "05401/1593294" },
    settings: {
      logoUrl: "https://www.mmmint.ai/partner/digitalmanual/docphant_logo.svg",
      favIconUrl: "https://www.mmmint.ai/partner/digitalmanual/favicon-docphant.png",
      formTitle: "Docphant",
      color: "#004973",
      contact: { email: "jan.leimkuehler@ib-leimkuehler.de", phone: "05401/1593294" },
      openingHours: {
        monday: "16:15 – 20:15",
        tuesday: "16:15 – 20:15",
        wednesday: "16:15 – 20:15",
        thursday: "16:15 – 20:15",
        friday: "14:00 – 20:15",
        saturday: "08:00 – 14:00 Uhr",
        sunday: "geschlossen"
      },
      websites: [{ text: "Homepage", link: "https://www.ib-leimkuehler.de/" }]
    }
  };
  machine: IMachineViewmodelWithManufacturerAndAttachment = getDefaultMachineDocument();
  loading = true;
  /**
   * Updates the stored machine
   * @param machine
   */
  @Mutation
  setMachine(machine: IMachineViewmodelWithManufacturerAndAttachment) {
    this.machine = machine;
    this.loading = false;
  }

  /**
   * Resets the stored machine to initial value
   * @param machine
   */
  @Mutation
  resetMachine() {
    this.machine = getDefaultMachineDocument();
    this.loading = true;
  }

  /**
   * Gets a machine from the API and sets it to the local store
   * @param manufacturerId the id of the manufacturer that the machine belongs to
   * @param machineId the id of the machine
   */
  @Action
  async getMachine(parameters: IDocphantMachineSelectors) {
    this.resetMachine();
    const machine = await MachineService.getMachine(parameters.manufacturerId, parameters.machineId);
    this.context.commit("setMachine", machine);
  }
}
/**
 * Get initial machine document
 * @returns empty machine document
 */
function getDefaultMachineDocument(): IMachineViewmodelWithManufacturerAndAttachment {
  return {
    manufacturer: {
      id: "",
      name: "N/A",
      address: {
        street: "N/A",
        city: "N/A",
        state: "N/A",
        zip: "N/A",
        countryCode: CountryCodeEnum.germany
      },
      contact: {
        email: "N/A",
        phone: "N/A"
      },
      logoUrl: "N/A",
      timestamps: {
        created: new Date(0, 0, 0, 0, 0, 0, 0)
      }
    },
    machineAttachment: [],
    id: "N/A",
    machineType: MachineEnum.UNKNOWN,
    machineName: "N/A",
    variante: "N/A",
    description: "N/A",
    weight: {
      unit: WeigthUnitEnum.NA,
      amount: 0
    },
    dimension: {
      unit: DimensionUnitEnum.NA,
      height: 0,
      width: 0,
      depth: 0
    },
    status: MachineStatusEnum.UNKNOWN,
    timestamps: {
      created: new Date(0, 0, 0, 0, 0, 0, 0)
    }
  };
}
export const DocphantModule = getModule(Docphant);
