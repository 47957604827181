export const getDisplayNames = (displayNameArray: any[]) => {
  const ret: string[] = [];
  displayNameArray.forEach(el => {
    ret.push(el.displayName);
  });
  return ret;
};

export const getDisplayNameFromEnumValue = (displayNameArray: any[], enumValue: string) => {
  let foundStatus = "Unbekannt";

  displayNameArray.forEach(el => {
    if (el.enumValues.includes(enumValue)) {
      foundStatus = el.displayName;
    }
  });
  return foundStatus;
};
