















import { FleetAggregationModule } from "@/store/modules/fleet-aggregation.store";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { IRefSuggestion } from "@/store/modules/fleet-aggregation.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import RefsSelect from "../utility/RefsSelect.vue";
import { handleError } from "@/lib/utility/handleError";

@Component({
  components: { RefsSelect },
  filters: {}
})
export default class TicketRefsSelect extends Vue {
  @Prop()
  private value!: MrfiktivReferenceGen[];

  @Prop()
  partnerId!: string;

  @Prop()
  isConfirmable!: boolean;

  get categories(): BackendResourceEnum[] {
    return [BackendResourceEnum.VEHICLE, BackendResourceEnum.EVENT, BackendResourceEnum.REPORT];
  }

  /**
   * if this is set to true the list with suggestions and page items will always be shown, not just the items that are already selected.
   * e.g. used in create ticket stepper
   */
  @Prop({ default: false })
  listMode!: boolean;

  @Prop({ default: false })
  hideEditButton!: boolean;

  isLoading = false;

  get selected(): MrfiktivReferenceGen[] {
    return this.value;
  }

  set selected(v: MrfiktivReferenceGen[]) {
    this.$emit("input", v);
  }

  get suggested(): IRefSuggestion[] {
    return FleetAggregationModule.referenceSuggestions;
  }

  async beforeMount() {
    await this.updateSuggestions();
  }

  async onSelectedChange() {
    this.$emit("change", this.selected);
    await this.updateSuggestions();
  }

  confirm(item: MrfiktivReferenceGen) {
    this.$emit("confirm", item);
  }

  async updateSuggestions() {
    this.isLoading = true;
    try {
      await FleetAggregationModule.updateSuggestions({
        selected: this.selected,
        month: new Date().getMonth(),
        year: new Date().getFullYear()
      });
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
