import { render, staticRenderFns } from "./PermissionTable.vue?vue&type=template&id=62e84816&"
import script from "./PermissionTable.vue?vue&type=script&lang=ts&"
export * from "./PermissionTable.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VCheckbox,VChip,VIcon})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('62e84816')) {
      api.createRecord('62e84816', component.options)
    } else {
      api.reload('62e84816', component.options)
    }
    module.hot.accept("./PermissionTable.vue?vue&type=template&id=62e84816&", function () {
      api.rerender('62e84816', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/portal/PermissionTable.vue"
export default component.exports