var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "timeline-card",
        {
          attrs: {
            title: _vm.$t("objects.booking.customerInformation")
          }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "pa-2",
              attrs: {
                flat: ""
              }
            },
            [
              _vm.customerName
                ? _c(
                    "div",
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            small: ""
                          }
                        },
                        [_vm._v("mdi-account-outline")]
                      ),
                      _c("span", [_vm._v(_vm._s(_vm.customerName))]),
                      _vm._v(" "),
                      _c("br")
                    ],
                    1
                  )
                : _vm._e(),
              _vm.email
                ? _c(
                    "div",
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            small: ""
                          }
                        },
                        [_vm._v("mdi-email-outline")]
                      ),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "mailto:".concat(_vm.email)
                          }
                        },
                        [_vm._v(_vm._s(_vm.email))]
                      ),
                      _vm._v(" "),
                      _c("br")
                    ],
                    1
                  )
                : _vm._e(),
              _vm.phone
                ? _c(
                    "div",
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            small: ""
                          }
                        },
                        [_vm._v("mdi-phone-outline")]
                      ),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "tel:".concat(_vm.phone)
                          }
                        },
                        [_vm._v(_vm._s(_vm.phone))]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        ],
        1
      ),
      _vm.numberPlate
        ? _c(
            "timeline-card",
            {
              attrs: {
                title: _vm.$t("objects.vehicle.numberplate")
              }
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "pa-2",
                  attrs: {
                    flat: ""
                  }
                },
                [_c("span", [_vm._v(_vm._s(_vm.numberPlate))])]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }