
















import MHeader, { IAction, IBreadcrumb } from "@/components/utility/mmmint/MHeader.vue";
import StatisticsCardRow from "@/components/utility/StatisticsCardRow.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { PartnerModule } from "@/store/modules/partner";
import { Component } from "vue-property-decorator";
import DocumentTable from "./DocumentTable.vue";
import SignRequestTable from "./SignRequestTable.vue";
import { SignRequestModule } from "@/store/modules/sign-request.store";

@Component({
  components: {
    TheLayoutPortal,
    StatisticsCardRow,
    DocumentTable,
    SignRequestTable,
    MHeader
  }
})
export default class SignRequestTableView extends PartnerFallbackMixin {
  loading = false;

  get actions(): IAction[] {
    const actions: IAction[] = [];
    actions.push({
      text: $t("sign.DocumentTableView.goToDigitalSignature"),
      key: "goToDocumentsTable",
      exec: () => new GoToHelper(this.$router).goToDocumentOverview(undefined, true)
    });

    return actions;
  }

  get breadCrumbs(): IBreadcrumb[] {
    const breadCrumbs: IBreadcrumb[] = [];
    return breadCrumbs;
  }

  get chips() {
    const chips: IAction[] = [];

    chips.push({
      text: `${SignRequestModule.totalItems} ${$t("sign.DigitalSignatureHomeView.showAllSignatures")}`,
      key: "numberOfSignatures"
    });

    return chips;
  }

  get partner() {
    return PartnerModule.partner;
  }

  async mounted() {
    try {
      this.loading = true;
      await this.trySetByRouteOrDefault();
    } catch (e) {
      handleError(e);
    } finally {
      this.loading = false;
    }
  }
}
