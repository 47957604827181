




































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";
import { ThgBaseChartItemListViewmodelGen } from "@/services/thg/v1/data-contracts";

@Component({
  components: {}
})
export default class AnalyticsDataTableDialog extends DarkModeHighlightMixin {
  @Prop()
  data!: ThgBaseChartItemListViewmodelGen;

  search = "";

  get headers() {
    return [
      { text: "Variable", value: "name" },
      { text: "Value", value: "value" }
    ];
  }

  dialog = false;

  close() {
    this.dialog = false;
  }
}
