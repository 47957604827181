var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      attrs: {
        elevation: "0"
      }
    },
    [
      _c(
        "v-card-text",
        {
          staticClass: "px-0"
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("components.thg.thgDialogYearSelection.subtitle", {
                  numberplate: _vm.numberplate
                })
              ) +
              " "
          )
        ]
      ),
      _c(
        "v-card-text",
        {
          staticClass: "px-0"
        },
        _vm._l(_vm.possibleYears, function(year, index) {
          var _vm$getPrice

          return _c(
            "v-sheet",
            {
              key: index,
              staticClass: "mt-4 mb-4",
              attrs: {
                color: _vm.getIsSelected(year) ? "success" : "light-grey",
                outlined: "",
                flat: "",
                rounded: ""
              }
            },
            [
              _c(
                "v-card",
                {
                  attrs: {
                    elevation: "0"
                  }
                },
                [
                  _c(
                    "v-row",
                    {
                      attrs: {
                        "no-gutters": ""
                      }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "1"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "ma-auto"
                            },
                            [
                              _c("v-checkbox", {
                                staticClass: "ml-5",
                                attrs: {
                                  "input-value": "true",
                                  justify: "center",
                                  color: "success"
                                },
                                on: {
                                  change: function change($event) {
                                    return _vm.toggleYear(year)
                                  }
                                },
                                model: {
                                  value: _vm.selection[index],
                                  callback: function callback($$v) {
                                    _vm.$set(_vm.selection, index, $$v)
                                  },
                                  expression: "selection[index]"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "11"
                          }
                        },
                        [
                          _c(
                            "v-card-title",
                            {
                              staticClass: "mb-n1 text-h5 font-weight-black"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.thg.thgDialogYearSelection.sheet.title",
                                      {
                                        year: year
                                      }
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "v-card-text",
                            {
                              staticClass: "mb-n4"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.thg.thgDialogYearSelection.sheet.description",
                                      {
                                        year: year
                                      }
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          (_vm$getPrice = _vm.getPrice(year)) !== null &&
                          _vm$getPrice !== void 0 &&
                          _vm$getPrice.isPrice
                            ? _c("v-card-title", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(_vm.getPrice(year).prefix) +
                                        " " +
                                        _vm.getPrice(year).totalAmountAsString
                                    ) +
                                    " "
                                )
                              ])
                            : _c("v-card-text", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.thg.thgDialogYearSelection.sheet.altPriceText",
                                      {
                                        year: year
                                      }
                                    )
                                  ) + " "
                                )
                              ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }