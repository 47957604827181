




















import PortalDetailBanking from "@/components/partner/PortalDetailBanking.vue";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";
import PartnerBillingInformationDataCard from "@/components/partner/PartnerBillingInformationDataCard.vue";

@Component({
  components: {
    PortalDetailBanking,
    PartnerBillingInformationDataCard
  }
})
export default class PartnerDetailBillingInformationCard extends Vue {
  virtualScrollerHeight = 0;
  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;
  get partner() {
    return PartnerModule.partner;
  }

  get partnerId() {
    return PartnerModule.partner._id || PartnerModule.partner.id;
  }
}
