import VueRouter from "vue-router";
import { BaseGoToHelper } from "./goToHelper";
import { $t } from "./t";

export enum CompanyRouteNames {
  COMPANY_TABLE = "COMPANY_TABLE",
  COMPANY_DETAIL = "COMPANY_DETAIL",
  COMPANY_DETAIL_FORM = "COMPANY_DETAIL_FORM",
  COMPANY_GROUP_DETAIL = "COMPANY_GROUP_DETAIL",
  COMPANY_GROUP_TABLE = "COMPANY_GROUP_TABLE",
  COMPANY_GROUP_CUSTOM_VIEW = "COMPANY_GROUP_CUSTOM_VIEW"
}

type Tab = { newTab?: boolean };
type Partner = { partnerId: string };
type Company = { companyId: string };
type CompanyGroup = { companyGroupId: string };
type View = { viewId: string };
type Query = { query?: Record<string, string> };

export class CompanyGoToHelper extends BaseGoToHelper {
  static get breadCrumbs() {
    const locations = CompanyGoToHelper.locations;

    return {
      CompanyTable: (partnerId: string) => {
        return {
          text: $t("company.companies").toString(),
          exact: true,
          to: locations.companyTable({ partnerId })
        };
      },
      CompanyDetail: (partnerId: string, companyId: string) => {
        return {
          text: $t("company.company").toString(),
          exact: true,
          to: locations.companyDetail({ partnerId, companyId })
        };
      },
      CompanyDetailForm: (partnerId: string, companyId: string) => {
        return {
          text: $t("common.verbs.edit").toString(),
          exact: true,
          to: locations.companyDetailForm({ partnerId, companyId })
        };
      },
      CompanyGroupTable: (partnerId: string) => {
        return {
          text: $t("company.companyGroups").toString(),
          exact: true,
          to: locations.companyGroupTable({ partnerId })
        };
      },
      CompanyGroupCustomView: (partnerId: string, companyGroupId: string, viewId?: string) => {
        return {
          text: $t("company.companyGroup").toString(),
          exact: true,
          to: locations.companyGroupCustomView({ partnerId, companyGroupId, viewId: viewId ?? "0" })
        };
      },
      CompanyGroupDetail: (partnerId: string, companyGroupId: string) => {
        return {
          text: $t("common.verbs.edit").toString(),
          exact: true,
          to: locations.companyGroupDetail({ partnerId, companyGroupId })
        };
      }
    };
  }

  constructor(protected readonly router: VueRouter) {
    super(router);
  }

  static locations = {
    companyTable: (d: Partner & Query) => {
      return {
        name: CompanyRouteNames.COMPANY_TABLE,
        params: { partnerId: d.partnerId },
        query: d.query
      };
    },
    companyDetail: (d: Partner & Company & Query) => {
      return {
        name: CompanyRouteNames.COMPANY_DETAIL,
        params: { partnerId: d.partnerId, companyId: d.companyId },
        query: d.query
      };
    },
    companyDetailForm: (d: Partner & Company & Query) => {
      return {
        name: CompanyRouteNames.COMPANY_DETAIL_FORM,
        params: { partnerId: d.partnerId, companyId: d.companyId },
        query: d.query
      };
    },
    companyGroupDetail: (d: Partner & CompanyGroup & Query) => {
      return {
        name: CompanyRouteNames.COMPANY_GROUP_DETAIL,
        params: { partnerId: d.partnerId, companyGroupId: d.companyGroupId },
        query: d.query
      };
    },
    companyGroupTable: (d: Partner & Query) => {
      return {
        name: CompanyRouteNames.COMPANY_GROUP_TABLE,
        params: { partnerId: d.partnerId },
        query: d.query
      };
    },
    companyGroupCustomView: (d: Partner & CompanyGroup & View & Query) => {
      return {
        name: CompanyRouteNames.COMPANY_GROUP_CUSTOM_VIEW,
        params: { partnerId: d.partnerId, companyGroupId: d.companyGroupId, viewId: d.viewId },
        query: d.query
      };
    }
  };

  goToCompanyTable(d: Tab & Partner) {
    this.go(CompanyGoToHelper.locations.companyTable(d), d.newTab);
  }

  goToCompanyDetail(d: Tab & Partner & Company) {
    this.go(CompanyGoToHelper.locations.companyDetail(d), d.newTab);
  }

  goToCompanyDetailForm(d: Tab & Partner & Company) {
    this.go(CompanyGoToHelper.locations.companyDetailForm(d), d.newTab);
  }

  goToCompanyGroupTable(d: Tab & Partner) {
    this.go(CompanyGoToHelper.locations.companyGroupTable(d), d.newTab);
  }

  goToCompanyGroupDetail(d: Tab & Partner & CompanyGroup) {
    this.go(CompanyGoToHelper.locations.companyGroupDetail(d), d.newTab);
  }

  goToCompanyGroupCustomView(d: Tab & Partner & CompanyGroup & View) {
    this.go(CompanyGoToHelper.locations.companyGroupCustomView(d), d.newTab);
  }
}
