



















































import TheLayoutPortalList from "@/layouts/TheLayoutPortalList.vue";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PaginationListMixin from "@/mixins/PaginationListMixin.vue";
import { ThgFaqViewModelGen } from "@/services/thg/v1/data-contracts";
import { FaqModule } from "@/store/modules/faq.store";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import Tooltip from "../utility/tooltip.vue";
import PartnerFaqCreateDialog from "./PartnerFaqCreateDialog.vue";

@Component({
  components: {
    TheLayoutPortalList,
    PartnerFaqCreateDialog,
    Tooltip
  }
})
export default class PartnerFaqListCard extends mixins(DarkModeHighlightMixin, PaginationListMixin) {
  get url() {
    const baseUrl =
      PartnerModule.partner.settings?.defaultUrl ||
      PartnerModule.partner.settings?.urls[PartnerModule.partner.settings?.urls.length - 1];

    if (!baseUrl) {
      return "";
    }

    return `https://${baseUrl}/#/faq`;
  }

  getModule() {
    return FaqModule;
  }

  isSelectedFaq(id: string) {
    return FaqModule.faq?.id === id ? "v-item--active v-list-item--active" : "";
  }

  onCreate(faq: ThgFaqViewModelGen) {
    this.$emit("create", faq);
  }
}
