var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _vm.isDetailMetaExistingLoading ||
      (_vm.metaDataExisting && _vm.metaDataNew)
        ? _c(
            "v-card-actions",
            [
              _vm.isDetailMetaExistingLoading
                ? _c("div", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "components.partner.PartnerReportDetail.SendToKsrDialog.searchingExportRequests"
                          )
                        ) +
                        " "
                    )
                  ])
                : _vm.metaDataExisting && _vm.metaDataNew
                ? _c("div", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "components.partner.PartnerReportDetail.SendToKsrDialog.searchingExportRequestsFound"
                          )
                        ) +
                        " "
                    )
                  ])
                : _vm._e(),
              _c("v-spacer")
            ],
            1
          )
        : _vm._e(),
      _vm.isDetailMetaExistingLoading
        ? _c("v-progress-linear", {
            attrs: {
              indeterminate: ""
            }
          })
        : _vm._e(),
      _vm.metaDataNew
        ? _c(
            "div",
            [
              _c(
                "card",
                {
                  staticClass: "mt-1 mb-1",
                  attrs: {
                    hideTitle: true,
                    margin: 0
                  }
                },
                [
                  _c(
                    "tooltip",
                    {
                      attrs: {
                        text: "Klicken, um neuen Auftrag anzulegen"
                      }
                    },
                    [
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function click($event) {
                              return _vm.confirm(_vm.metaDataNew)
                            }
                          }
                        },
                        [
                          _c(
                            "tooltip",
                            {
                              attrs: {
                                top: "",
                                text: _vm.$t(
                                  "components.partner.PartnerReportDetail.SendToKsrDialog.tooltipRequestNew"
                                )
                              }
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "icon"
                                    },
                                    [_vm._v(" mdi-file-question-outline ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-subtitle", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.partner.PartnerReportDetail.SendToKsrDialog.titleRequestNew"
                                      )
                                    ) +
                                    " "
                                )
                              ]),
                              _c("v-list-item-subtitle", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.partner.PartnerReportDetail.SendToKsrDialog.userId"
                                      )
                                    ) +
                                    ": " +
                                    _vm._s(
                                      _vm.getCustomerIdentityToken(
                                        _vm.metaDataNew.meta
                                      )
                                    ) +
                                    " "
                                )
                              ]),
                              _c("v-list-item-subtitle", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.partner.PartnerReportDetail.SendToKsrDialog.numberplate"
                                      )
                                    ) +
                                    ": " +
                                    _vm._s(
                                      _vm.getNumberPlate(_vm.metaDataNew.meta)
                                    ) +
                                    " "
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "tooltip",
                            {
                              attrs: {
                                text: _vm.$t(
                                  "components.partner.PartnerReportDetail.SendToKsrDialog.delete"
                                )
                              }
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                {
                                  on: {
                                    click: function click($event) {
                                      $event.preventDefault()
                                      return _vm.openDeleteDialog(
                                        _vm.ExportReportMetaTypeEnum.KSR_NEW
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "icon"
                                    },
                                    [_vm._v(" mdi-trash-can-outline ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.isDetailMetaExistingLoading && _vm.metaDataExisting
        ? _c(
            "div",
            [
              _c(
                "card",
                {
                  staticClass: "mt-1 mb-1",
                  attrs: {
                    hideTitle: true,
                    margin: 0
                  }
                },
                [
                  _c(
                    "tooltip",
                    {
                      attrs: {
                        text: "Klicken, um Auftrag zu übernehmen"
                      }
                    },
                    [
                      _vm.metaDataExisting && !_vm.isDetailMetaExistingLoading
                        ? _c(
                            "v-list-item",
                            {
                              on: {
                                click: function click($event) {
                                  return _vm.confirm(_vm.metaDataExisting)
                                }
                              }
                            },
                            [
                              _c(
                                "tooltip",
                                {
                                  attrs: {
                                    text: _vm.$t(
                                      "components.partner.PartnerReportDetail.SendToKsrDialog.tooltipRequestExisting"
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "icon"
                                        },
                                        [_vm._v(" mdi-file-question-outline ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                {
                                  staticClass: "pl-3"
                                },
                                [
                                  _c(
                                    "v-list-item-subtitle",
                                    {
                                      staticClass: "ml-n3"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "components.partner.PartnerReportDetail.SendToKsrDialog.titleRequestExisting"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  !_vm.suggestedDetailNotLoaded &&
                                  _vm.jobDetailMetaExisting
                                    ? _c(
                                        "partner-report-detail-send-to-ksr-dialog-ksr-detail",
                                        {
                                          attrs: {
                                            job: _vm.jobDetailMetaExisting
                                          }
                                        }
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "Can",
                                {
                                  attrs: {
                                    I: _vm.ActionEnum.DELETE,
                                    a: _vm.ResourceEnum.REPORT
                                  }
                                },
                                [
                                  _c(
                                    "tooltip",
                                    {
                                      attrs: {
                                        text: _vm.$t(
                                          "components.partner.PartnerReportDetail.SendToKsrDialog.deleteDialogConfirm"
                                        )
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        {
                                          on: {
                                            click: function click($event) {
                                              return _vm.openDeleteDialog(
                                                _vm.ExportReportMetaTypeEnum
                                                  .KSR_EXISTING
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "icon"
                                            },
                                            [_vm._v(" mdi-trash-can-outline ")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("confirm-action-dialog", {
        attrs: {
          isDialogActive: _vm.isDeleteDialogActive,
          title: _vm.$t(
            "components.partner.PartnerReportDetail.SendToKsrDialog.deleteDialogTitle"
          ),
          leftText: _vm.$t(
            "components.partner.PartnerReportDetail.SendToKsrDialog.back"
          ),
          rightText: _vm.$t(
            "components.partner.PartnerReportDetail.SendToKsrDialog.deleteDialogConfirm"
          )
        },
        on: {
          "update:isDialogActive": function updateIsDialogActive($event) {
            _vm.isDeleteDialogActive = $event
          },
          "update:is-dialog-active": function updateIsDialogActive($event) {
            _vm.isDeleteDialogActive = $event
          },
          leftClick: _vm.cancelDeleteDialog,
          rightClick: _vm.confirmDelete
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }