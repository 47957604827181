

























































import ContactCustomerDialog from "@/components/utility/ContactCustomerDialog.vue";
import { CreateReportAsPartnerDto } from "@/lib/dto/create-report-as-partner-dto";
import { handleError } from "@/lib/utility/handleError";
import { PartnerModule } from "@/store/modules/partner";
import QrcodeVue from "qrcode.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import CreateReportForm from "../fleet/CreateReportForm.vue";
import Card from "../utility/Card.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import TableWrapper from "../utility/TableWrapper.vue";
import PartnerReportBatchImportDialog from "./PartnerReportBatchImportDialog.vue";
import PartnerReportInitializeCard from "./PartnerReportInitializeCard.vue";
import PartnerReportInitializeCardMixin, {
  PartnerReportInitializeDialogPagesEnum
} from "./PartnerReportInitializeCardMixin.vue";

@Component({
  components: {
    ContactCustomerDialog,
    PartnerReportInitializeCard,
    QrcodeVue,
    Card,
    TableWrapper,
    PartnerReportBatchImportDialog,
    CreateReportForm,
    ConfirmActionDialog
  }
})
export default class PartnerReportInitializeDialogEmpty extends mixins(PartnerReportInitializeCardMixin) {
  @Prop()
  step!: PartnerReportInitializeDialogPagesEnum;

  loading = false;

  @Prop()
  reportDto!: CreateReportAsPartnerDto;

  isValid = true;

  isConfirmDialogActive = false;

  forceUpdateKey = 0;

  get partnerId() {
    return this.$route.params.partnerId ?? PartnerModule.partner.id;
  }

  reset() {
    this.$emit("reset");

    this.reportDto = new CreateReportAsPartnerDto();

    this.forceUpdateKey++;
    this.isConfirmDialogActive = false;
  }

  async save() {
    try {
      this.loading = true;
      const partnerId = this.partnerId;

      this.$log.debug(this.reportDto);

      const created = await this.reportDto.create(partnerId);
      this.$emit("created", created);

      this.setNextStep(this.PagesEnum.EMPTY_SUCCESS);
      PartnerModule.setReport(created);

      this.$toast.success("👍");
    } catch (e) {
      handleError(e);
    } finally {
      this.loading = false;
    }
  }
}
