import {
  IMachineViewmodel,
  IMachineViewmodelWithManufacturerAndAttachment
} from "@/lib/interfaces/Docphant/machine.viewmodel.interface";
import Vue from "vue";
import { MrfiktivHttpClientProvider as Api } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { IMachineDto } from "@/lib/interfaces/Docphant/machine.dto.interface";

/**
 * Communicates with docphant backend
 */
class MachineService {
  ROUTE = "docphant";
  /**
   * Gets machine document from docphant backend
   * @param manufacturerId the id of the manufacturer that produces the machine
   * @param machineId the id of the machine
   * @returns a machine document
   */
  async getMachine(manufacturerId: string, machineId: string): Promise<IMachineViewmodelWithManufacturerAndAttachment> {
    const api = new Api().instance();
    let c: any;
    try {
      const response = await api.get(`/${this.ROUTE}/manufacturer/${manufacturerId}/machine/${machineId}/`);
      c = response.data;
    } catch (error) {
      Vue.$log.error(error);
      throw error;
    }

    return c;
  }

  /**
   * Deletes a machine
   * @param manufacturerId the id of the manufacturer that produces the machine
   * @param machineId the id of the machine
   * @returns a machine document
   */
  async delete(manufacturerId: string, machineId: string): Promise<boolean> {
    const api = new Api().instance();
    try {
      await api.delete(`/${this.ROUTE}/manufacturer/${manufacturerId}/machine/${machineId}/`);
      return true;
    } catch (error) {
      Vue.$log.error(error);
      return false;
    }
  }

  async addMachineAttachment(
    manufacturerId: string,
    machineId: string,
    attachmentId: string
  ): Promise<IMachineViewmodel> {
    const api = new Api().instance();
    let c: any;

    const body = {
      machineAttachmentId: attachmentId
    };

    try {
      const response = await api.post(
        `/${this.ROUTE}/manufacturer/${manufacturerId}/machine/${machineId}/documentation/add`,
        body
      );
      c = response.data;
    } catch (error) {
      Vue.$log.error(error);
      throw error;
    }

    return c;
  }

  async removeMachineAttachment(
    manufacturerId: string,
    machineId: string,
    attachmentId: string
  ): Promise<IMachineViewmodel> {
    const api = new Api().instance();
    let c: any;

    const body = {
      machineAttachmentId: attachmentId
    };

    try {
      const response = await api.post(
        `/${this.ROUTE}/manufacturer/${manufacturerId}/machine/${machineId}/documentation/remove`,
        body
      );
      c = response.data;
    } catch (error) {
      Vue.$log.error(error);
      throw error;
    }

    return c;
  }

  async create(manufacturerId: string, createMachineDto: IMachineDto): Promise<IMachineViewmodel> {
    const api = new Api().instance();
    let c: any;
    try {
      const response = await api.post(`/${this.ROUTE}/manufacturer/${manufacturerId}/machine/`, createMachineDto);
      c = response.data;
    } catch (error) {
      Vue.$log.error(error);
      throw error;
    }

    return c;
  }

  async getAll(manufacturerId: string): Promise<IMachineViewmodel[]> {
    const api = new Api().instance();
    let c: any;
    try {
      const response = await api.get(`/${this.ROUTE}/manufacturer/${manufacturerId}/machine/`);
      c = response.data;
    } catch (error) {
      Vue.$log.error(error);
      throw error;
    }

    return c;
  }
}

export default new MachineService();
/**
 * Example machine document
 */
/*
const MACHINE_MOCK: IMachineViewmodelWithManufacturerAndAttachment = {
  id: "61db1fb51d8699bd9e3f6db7",
  manufacturer: {
    name: "Maschinenhersteller XYZ",
    address: { state: "Niedersachsen", street: "Auf der Halle 25a", zip: "49124", city: "Georgsmarienhütte" },
    contact: { email: "info@mmmint.ai", phone: "+4917672884125" },
    logoUrl: "https://www.mrfiktiv.com/images/logo.png",
    timestamps: { created: new Date(1, 1, 1, 1, 1, 1, 1) }
  },
  machineType: MachineEnum.MACHINE,
  machineName: "Umkantmaschine",
  variante: "Variante 0.5",
  description: "Maschine zum Umkanten von Stahl.",
  weight: { unit: WeigthUnitEnum.KG, amount: 99 },
  dimension: { unit: DimensionUnitEnum.KM, height: 99, width: 99, depth: 99 },
  status: MachineStatusEnum.CORRECTED,
  machineAttachment: [
    {
      id: "fa6d3c1fdd448d6151d97eb1",
      name: "Betriebsanleitung ",
      descripton: "Betriebsanleitung xyz",
      attachmentType: MachineAttachmentTypeEnum.PIECE_LIST,
      status: MachineAttachmentStatusEnum.INPROCESS,
      attachmentCode: "AAAAAA",
      editor: "Dax Dusterdadd",
      language: LanguageEnum.DE,
      file: {
        folderName: "documentation",
        timestamp: { created: "2021-09-27T14:47:26.111Z" },
        type: "documentation",
        name: "ecc97d57a72a32692bffd6be.pdf",
        metaData: { originalName: "MDO Maschinendokumentation online.pdf", size: 2312995 },
        url:
          "https://devapimrfiktiv.blob.core.windows.net/documentation/ecc97d57a72a32692bffd6be.pdf?sv=2020-02-10&st=2021-09-29T16%3A37%3A02Z&se=2021-10-06T16%3A37%3A02Z&sr=b&sp=r&sig=eUcO1HWo0S3jOGNp%2BgY6ojCH4rLUpRDmINGrls6tHvg%3D"
      },
      manufacturer: "6151d7b1fd4c351d8f217b3c",
      machines: ["6151d8699bd9db1fbe3f6db7"],
      timestamps: { created: new Date(12, 12, 12, 12, 12, 12, 12) }
    },
    {
      id: "6153c1fdd448d1d97efa6db1",
      name: "Vertriebsanleitung ",
      descripton: "Vertriebsanleitung xyz",
      attachmentType: MachineAttachmentTypeEnum.REGULATIONS,
      status: MachineAttachmentStatusEnum.CORRECTED,
      attachmentCode: "BA_8123977_VENTILATOR ",
      editor: "Nax Nusternann",
      language: LanguageEnum.EN,
      file: {
        folderName: "documentation",
        timestamp: {
          created: "2021-09-27T14:47:26.111Z"
        },
        type: "documentation",
        name: "ecc97d57a72a32692bffd6be.pdf",
        metaData: {
          originalName: "MDO Maschinendokumentation online.pdf",
          size: 2312995
        },
        url: "https://devapimrfiktiv.blob.core.windows.net/documentation/ecc97d57a72a32692bffd6be.pdf"
      },
      manufacturer: "6151d7b1fd4c351d8f217b3c",
      machines: ["6151d8699bd9db1fbe3f6db7"],
      timestamps: {
        created: new Date(6, 6, 6, 6, 6, 6, 6),
        lastModified: new Date()
      }
    },
    {
      id: "613a0f96cc08135afece3edf",
      name: "Getriebsanleitung ",
      descripton: "Getriebsanleitung xyz",
      attachmentType: MachineAttachmentTypeEnum.OTHER,
      status: MachineAttachmentStatusEnum.ACTIVATED,
      attachmentCode: "BBBBBB ",
      editor: "Max Mustermamm",
      language: LanguageEnum.ZH,
      file: {
        folderName: "documentation",
        timestamp: {
          created: "2021-10-04T13:17:01.140Z"
        },
        type: "documentation",
        name: "553bf88c8aadc15c7b115855.pdf",
        metaData: {
          originalName: "ecc97d57a72a32692bffd6be (1).pdf",
          size: 2312995
        },
        url: "https://devapimrfiktiv.blob.core.windows.net/documentation/553bf88c8aadc15c7b115855.pdf"
      },
      manufacturer: "6151d7b1fd4c351d8f217b3c",
      machines: [],
      timestamps: {
        created: new Date(6, 6, 6, 6, 6, 6, 6)
      }
    }
  ],
  timestamps: {
    created: new Date(6, 6, 6, 6, 6, 6, 6)
  }
};
*/
