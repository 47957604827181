/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

export enum LanguageEnum {
  AF = "af",
  AK = "ak",
  SQ = "sq",
  AM = "am",
  AR = "ar",
  HY = "hy",
  AS = "as",
  AZ = "az",
  BM = "bm",
  BN = "bn",
  EU = "eu",
  BE = "be",
  BS = "bs",
  BR = "br",
  BG = "bg",
  MY = "my",
  CA = "ca",
  CE = "ce",
  ZH = "zh",
  ZH_HANS = "zh_Hans",
  ZH_HANT = "zh_Hant",
  CU = "cu",
  KW = "kw",
  CO = "co",
  HR = "hr",
  CS = "cs",
  DA = "da",
  NL = "nl",
  NL_BE = "nl_BE",
  DZ = "dz",
  EN = "en",
  EN_AU = "en_AU",
  EN_CA = "en_CA",
  EN_GB = "en_GB",
  EN_US = "en_US",
  EO = "eo",
  ET = "et",
  EE = "ee",
  FO = "fo",
  FI = "fi",
  FF = "ff",
  GL = "gl",
  LG = "lg",
  KA = "ka",
  DE = "de",
  DE_AT = "de_AT",
  DE_CH = "de_CH",
  EL = "el",
  GU = "gu",
  HT = "ht",
  HA = "ha",
  HE = "he",
  HI = "hi",
  HU = "hu",
  IS = "is",
  IG = "ig",
  ID = "id",
  IA = "ia",
  GA = "ga",
  IT = "it",
  JA = "ja",
  JV = "jv",
  KL = "kl",
  KN = "kn",
  KS = "ks",
  KK = "kk",
  KM = "km",
  KI = "ki",
  RW = "rw",
  KO = "ko",
  KU = "ku",
  KY = "ky",
  LO = "lo",
  LA = "la",
  LV = "lv",
  LN = "ln",
  LT = "lt",
  LU = "lu",
  LB = "lb",
  MK = "mk",
  MG = "mg",
  MS = "ms",
  ML = "ml",
  MT = "mt",
  GV = "gv",
  MI = "mi",
  MR = "mr",
  MN = "mn",
  NE = "ne",
  ND = "nd",
  SE = "se",
  NB = "nb",
  NN = "nn",
  NY = "ny",
  OR = "or",
  OM = "om",
  OS = "os",
  PS = "ps",
  FA = "fa",
  FA_AF = "fa_AF",
  PL = "pl",
  PT = "pt",
  PT_BR = "pt_BR",
  PT_PT = "pt_PT",
  PA = "pa",
  QU = "qu",
  RO = "ro",
  RO_MD = "ro_MD",
  RM = "rm",
  RN = "rn",
  RU = "ru",
  SM = "sm",
  SG = "sg",
  SA = "sa",
  GD = "gd",
  SR = "sr",
  SN = "sn",
  II = "ii",
  SD = "sd",
  SI = "si",
  SK = "sk",
  SL = "sl",
  SO = "so",
  ST = "st",
  SU = "su",
  SW = "sw",
  SW_CD = "sw_CD",
  SV = "sv",
  TG = "tg",
  TA = "ta",
  TT = "tt",
  TE = "te",
  TH = "th",
  BO = "bo",
  TI = "ti",
  TO = "to",
  TR = "tr",
  TK = "tk",
  UK = "uk",
  UR = "ur",
  UG = "ug",
  UZ = "uz",
  VI = "vi",
  VO = "vo",
  CY = "cy",
  FY = "fy",
  WO = "wo",
  XH = "xh",
  YI = "yi",
  YO = "yo",
  ZU = "zu"
}
export const LanugageDisplayNames = [
  { displayName: "Chinesisch", enumValues: ["zu"] },
  { displayName: "Deutsch", enumValues: ["de"] },
  { displayName: "Englisch", enumValues: ["en"] },
  { displayName: "Französisch", enumValues: ["fr"] },
  { displayName: "Russisch", enumValues: ["ru"] },
  { displayName: "Hindi", enumValues: ["hi"] },
  { displayName: "Italienisch", enumValues: ["it"] },
  { displayName: "Koreanisch", enumValues: ["ko"] },
  { displayName: "Spanisch", enumValues: ["es"] },
  {
    displayName: "Sonstige",
    enumValues: [
      "af",
      "ak",
      "sq",
      "am",
      "ar",
      "hy",
      "as",
      "az",
      "bm",
      "bn",
      "eu",
      "be",
      "bs",
      "br",
      "bg",
      "my",
      "ca",
      "ce",
      "zh",
      "zh_Hans",
      "zh_Hant",
      "cu",
      "kw",
      "co",
      "hr",
      "cs",
      "da",
      "nl",
      "nl_BE",
      "dz",
      "eo",
      "et",
      "ee",
      "fo",
      "fi",
      "fr",
      "fr_CA",
      "fr_CH",
      "ff",
      "gl",
      "lg",
      "ka",
      "el",
      "gu",
      "ht",
      "ha",
      "he",
      "hu",
      "is",
      "ig",
      "id",
      "ia",
      "ga",
      "ja",
      "jv",
      "kl",
      "kn",
      "ks",
      "kk",
      "km",
      "ki",
      "rw",
      "ku",
      "ky",
      "lo",
      "la",
      "lv",
      "ln",
      "lt",
      "lu",
      "lb",
      "mk",
      "mg",
      "ms",
      "ml",
      "mt",
      "gv",
      "mi",
      "mr",
      "mn",
      "ne",
      "nd",
      "se",
      "nb",
      "nn",
      "ny",
      "or",
      "om",
      "os",
      "ps",
      "fa",
      "fa_AF",
      "pl",
      "pt",
      "pt_BR",
      "pt_PT",
      "pa",
      "qu",
      "ro",
      "ro_MD",
      "rm",
      "rn",
      "sm",
      "sg",
      "sa",
      "gd",
      "sr",
      "sn",
      "ii",
      "sd",
      "si",
      "sk",
      "sl",
      "so",
      "st",
      "es",
      "es_ES",
      "es_MX",
      "su",
      "sw",
      "sw_CD",
      "sv",
      "tg",
      "ta",
      "tt",
      "te",
      "th",
      "bo",
      "ti",
      "to",
      "tr",
      "tk",
      "uk",
      "ur",
      "ug",
      "uz",
      "vi",
      "vo",
      "cy",
      "fy",
      "wo",
      "xh",
      "yi",
      "yo"
    ]
  }
];
