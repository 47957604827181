import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { Company } from "../v1/Company";
import {
  MrfiktivCompanyControllerGetParamsGen,
  MrfiktivCreateCompanyDtoGen,
  MrfiktivUpdateCompanyDtoGen
} from "../v1/data-contracts";

/**
 * Communicate with company API
 */
class CompanyService {
  mrfiktivProxy: Company;

  /**
   * Construct service
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider) {
    this.mrfiktivProxy = new Company(mrfiktivHttpClientProvider.client());
  }

  /**
   * Create an company
   */
  async create(partnerId: string, data: MrfiktivCreateCompanyDtoGen) {
    const company = (await this.mrfiktivProxy.companyControllerCreate(partnerId, data)).data;

    return company;
  }

  /**
   * Get all company
   */
  async get(data: MrfiktivCompanyControllerGetParamsGen) {
    const companies = (await this.mrfiktivProxy.companyControllerGet(data)).data;

    return companies;
  }

  /**
   * Get a company
   */
  async getOne(partnerId: string, companyId: string) {
    const company = (await this.mrfiktivProxy.companyControllerGetOne(partnerId, companyId)).data;

    return company;
  }

  /**
   * Update a company
   */
  async update(partnerId: string, companyId: string, data: MrfiktivUpdateCompanyDtoGen) {
    const company = (await this.mrfiktivProxy.companyControllerUpdate(partnerId, companyId, data)).data;

    return company;
  }

  /**
   * Delete a company
   */
  async delete(partnerId: string, companyId: string) {
    const company = (await this.mrfiktivProxy.companyControllerDelete(partnerId, companyId)).data;

    return company;
  }
}

/**
 * Communicate with company API
 */
export default new CompanyService(new MrfiktivHttpClientProvider());
