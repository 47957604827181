var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "Can",
        {
          attrs: {
            I: _vm.ActionEnum.CREATE,
            a: _vm.ResourceEnum.TEMPLATE
          }
        },
        [
          _c(
            "v-tooltip",
            {
              attrs: {
                top: "",
                "nudge-right": ""
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function fn(_ref) {
                    var on = _ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            attrs: {
                              icon: ""
                            },
                            on: {
                              click: function click($event) {
                                _vm.createDialog = true
                              }
                            }
                          },
                          on
                        ),
                        [_c("v-icon", [_vm._v("mdi-plus")])],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("components.template.detail.create.create"))
                )
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            isDialogActive: _vm.createDialog,
            fullscreen: true,
            title: _vm.$t("components.template.detail.create.title"),
            rightLoading: _vm.loading,
            rightDisabled: !_vm.isValid,
            supressKeyboardActions: true
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.createDialog = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.createDialog = $event
            },
            leftClick: function leftClick($event) {
              _vm.createDialog = false
            },
            rightClick: _vm.create
          }
        },
        [
          _c(
            "v-form",
            {
              model: {
                value: _vm.isValid,
                callback: function callback($$v) {
                  _vm.isValid = $$v
                },
                expression: "isValid"
              }
            },
            [
              _c("template-detail-meta-fields", {
                attrs: {
                  partnerId: _vm.partnerId,
                  meta: _vm.templateMetaDto,
                  isPublic: _vm.isPublic
                },
                on: {
                  "update:meta": function updateMeta($event) {
                    _vm.templateMetaDto = $event
                  },
                  "update:isPublic": function updateIsPublic($event) {
                    _vm.isPublic = $event
                  },
                  "update:is-public": function updateIsPublic($event) {
                    _vm.isPublic = $event
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }