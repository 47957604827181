























































































































import ContactCustomerDialog from "@/components/utility/ContactCustomerDialog.vue";
import { CreateReportUrlFactory, IInitReport } from "@/lib/utility/createReportUrlFactory";
import { PartnerModule } from "@/store/modules/partner";
import QrcodeVue from "qrcode.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import TableWrapper from "../utility/TableWrapper.vue";
import PartnerReportBatchImportDialog from "./PartnerReportBatchImportDialog.vue";
import PartnerReportInitializeCard from "./PartnerReportInitializeCard.vue";
import PartnerReportInitializeCardMixin, {
  PartnerReportInitializeDialogPagesEnum
} from "./PartnerReportInitializeCardMixin.vue";

@Component({
  components: {
    ContactCustomerDialog,
    PartnerReportInitializeCard,
    QrcodeVue,
    Card,
    TableWrapper,
    PartnerReportBatchImportDialog
  }
})
export default class PartnerReportInitializeDialogBatch extends mixins(PartnerReportInitializeCardMixin) {
  @Prop()
  step!: PartnerReportInitializeDialogPagesEnum;

  loading = false;

  initReports: IInitReport[] = [];
  urls: { url: string }[] = [];

  baseReportUrl = "";
  screen = "";

  get headers() {
    return [{ value: "url" }];
  }

  /**
   * Get the base report URl of the Partner from the Partnermodulke
   */
  get baseReportUrls() {
    return PartnerModule.partner.settings?.urls;
  }

  /**
   * Get the base report URl of the Partner from the Partnermodulke
   */
  get screens() {
    return PartnerModule.partner.settings?.reportSettings.screenOrder;
  }

  mounted() {
    const defaultUrl = PartnerModule.partner.settings?.defaultUrl;
    if (defaultUrl) {
      this.baseReportUrl = defaultUrl;
    } else if (this.baseReportUrls?.length) {
      this.baseReportUrl = this.baseReportUrls[0];
    }
  }

  setInitReports(items: IInitReport[]) {
    this.loading = true;
    this.initReports = items;
    this.loading = false;
  }

  /**
   * Creates the url to the report and appends the specified query params
   */
  createUrlWithQuery(initReport: IInitReport) {
    const createReportUrlFactory = new CreateReportUrlFactory(initReport);
    return createReportUrlFactory.url(this.baseReportUrl);
  }

  createUrls() {
    this.urls = [];
    for (const item of this.initReports) {
      this.urls.push({ url: this.createUrlWithQuery(item) });
    }
  }
}
