


























import { Component, Prop, Vue } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";

@Component({ components: { ConfirmActionDialog } })
export default class ConfirmDeleteDialog extends Vue {
  @Prop()
  value!: boolean;

  @Prop()
  isLoading!: boolean;

  @Prop()
  title?: string;

  get isDeleteDialogActive() {
    return this.value;
  }

  set isDeleteDialogActive(isDialogActive: boolean) {
    this.$emit("input", isDialogActive);
  }
}
