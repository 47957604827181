var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("the-layout-portal-list", {
    attrs: {
      title: "FAQ",
      filterList: _vm.filterList,
      loading: _vm.loading,
      isLoadingMore: _vm.isLoadingMore,
      getModule: _vm.getModule
    },
    on: {
      loadMore: _vm.loadMore,
      refresh: _vm.refresh
    },
    scopedSlots: _vm._u([
      {
        key: "options",
        fn: function fn() {
          return [
            _c(
              "tooltip",
              {
                attrs: {
                  text: _vm.$t("partner.PartnerFaqListCard.open")
                }
              },
              [
                _c(
                  "small",
                  [
                    _vm.url
                      ? _c(
                          "v-btn",
                          {
                            attrs: {
                              href: _vm.url,
                              target: "_blank",
                              icon: ""
                            }
                          },
                          [_c("v-icon", [_vm._v(" mdi-open-in-app ")])],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            ),
            _c("partner-faq-create-dialog", {
              on: {
                create: _vm.onCreate
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "listItem",
        fn: function fn(_ref) {
          var listItem = _ref.listItem
          return [
            listItem
              ? _c(
                  "v-list-item",
                  {
                    key: listItem.id,
                    class: _vm.isSelectedFaq(listItem.id),
                    attrs: {
                      disabled: _vm.isLoadingDetail,
                      "two-line": ""
                    },
                    on: {
                      click: function click($event) {
                        return _vm.set(listItem)
                      }
                    }
                  },
                  [
                    _c(
                      "v-list-item-content",
                      [
                        _c("v-list-item-title", [
                          _vm._v(_vm._s(listItem.question))
                        ]),
                        _c("v-list-item-subtitle", [
                          _vm._v(_vm._s(listItem.answer))
                        ])
                      ],
                      1
                    ),
                    _c(
                      "v-list-item-action",
                      [
                        _c(
                          "v-list-item-action-text",
                          [
                            listItem.isActive
                              ? _c(
                                  "tooltip",
                                  {
                                    attrs: {
                                      text: _vm.$t(
                                        "partner.PartnerFaqToggleActive.active"
                                      )
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          color: "success"
                                        }
                                      },
                                      [_vm._v("mdi-circle-outline")]
                                    )
                                  ],
                                  1
                                )
                              : _c(
                                  "tooltip",
                                  {
                                    attrs: {
                                      text: _vm.$t(
                                        "partner.PartnerFaqToggleActive.disabled"
                                      )
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          color: "warning"
                                        }
                                      },
                                      [_vm._v("mdi-circle-outline")]
                                    )
                                  ],
                                  1
                                )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }