/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */
import { IReport } from "@/models/report.entity";
import { ConfigModule } from "@/store/modules/config";

export class CreatePortalUrlFactory {
  url: string;

  constructor() {
    this.url = ConfigModule.portalUrl;
  }

  /**
   * Creates the url to the signature and appends the specified query params
   */
  buildForReport(report: IReport): string {
    return this.url + `/partners/${report.partnerId}/report/${report.id}`;
  }
}
