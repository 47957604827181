
















import { Vue, Component, Prop } from "vue-property-decorator";
@Component({})
export default class AnalyticsKpiColumn extends Vue {
  @Prop()
  icon!: string;

  @Prop()
  color!: string;

  @Prop()
  title!: string;

  @Prop()
  data!: string;
}
