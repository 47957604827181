






















































import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import FilterCard from "@/components/filter/FilterCard.vue";
import { IFilterListElement } from "@/lib/interfaces/filter/filterListElement.interface";
import PartnerCreateDialog from "@/components/partner/PartnerCreateDialog.vue";
import { getDateFilterList } from "@/lib/utility/filter";
import { PartnerEntity } from "@/models/partnerEntity";
import eventBus from "@/lib/eventBus";

@Component({
  components: {
    FilterCard,
    PartnerCreateDialog
  }
})
export default class PartnerListCard extends Vue {
  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;

  gridLoading = true;
  search = "";
  error = "";

  selected = this.partner;

  get partner() {
    return PartnerModule.partner;
  }

  get partners() {
    return PartnerModule.partners;
  }

  isLoadingPartner(status: boolean) {
    this.$emit("isLoadingPartner", status);
  }

  date(item: PartnerEntity) {
    return new Date(item.timestamps?.created).toLocaleDateString("de-DE");
  }

  async setPartner(item: any) {
    this.isLoadingPartner(true);
    try {
      await PartnerModule.getPartnerById(item._id);
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.isLoadingPartner(false);
    }

    if (["xs", "sm"].includes(this.$vuetify.breakpoint.name)) {
      this.$router.push({ name: "PartnerDetailView", params: { partnerId: item._id } });
    }

    eventBus.$emit("partnerLoaded");
  }

  filterList: IFilterListElement[] = getDateFilterList("timestamps");

  items = PartnerModule.partners.slice();

  get filteredItems() {
    return this.items;
  }
}
