








import PartnerDetailCard from "@/components/partner/PartnerDetailCard.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    LayoutSimple,
    PartnerDetailCard
  }
})
export default class PartnerDetailView extends Vue {
  isLoading = false;
  async mounted() {
    if (this.$route.params.partnerId) {
      this.isLoading = true;
      try {
        await PartnerModule.getPartnerById(this.$route.params.partnerId);
      } catch (error) {
        this.$log.error(error);
      } finally {
        this.isLoading = false;
      }
    }
  }

  get partner() {
    return PartnerModule.partner;
  }
}
