var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          attrs: {
            flat: "",
            "max-width": "700"
          }
        },
        [
          _c(
            "v-card-text",
            [
              _c("PartnerCreateRequirementsList", {
                attrs: {
                  requirementList: _vm.requirementList
                },
                on: {
                  validate: _vm.validate
                }
              }),
              !_vm.isUpdatePartner
                ? _c(
                    "v-btn",
                    {
                      staticClass: "my-4",
                      attrs: {
                        loading: _vm.isLoading,
                        large: "",
                        color: "success",
                        disabled: !_vm.isValidCreate
                      },
                      on: {
                        click: _vm.createPartner
                      }
                    },
                    [_vm._v(" Neuen Partner anlegen ")]
                  )
                : _c(
                    "v-btn",
                    {
                      staticClass: "my-4",
                      attrs: {
                        loading: _vm.isLoading,
                        large: "",
                        color: "success",
                        disabled: !_vm.isValidUpdate
                      },
                      on: {
                        click: _vm.updatePartner
                      }
                    },
                    [_vm._v(" Änderungen speichern ")]
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }