/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */
export enum MachineStatusEnum {
  INPROCESS = "inprocess",
  CORRECTED = "corrected",
  ACTIVATED = "activated",
  DISABLED = "disabled",
  UNKNOWN = "unknown"
}

export const MachineStatusDisplayNames = [
  { displayName: "Unter Bearbeitung", enumValues: ["inprocess"] },
  { displayName: "Korrigiert", enumValues: ["corrected"] },
  { displayName: "Aktiviert", enumValues: ["activated"] },
  { displayName: "Deaktiviert", enumValues: ["disabled"] },
  { displayName: "Unbekannt", enumValues: ["unknown"] }
];
