

































































import { deepCopy } from "@/lib/utility/deep-copy";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class SelectAndOrderListDialog extends DarkModeHighlightMixin {
  dialog = false;
  valid = false;

  @Prop()
  selectedList!: string[];

  @Prop()
  possibleItems!: string[];

  tempSelectedList: string[] = [];

  initialize() {
    this.tempSelectedList = deepCopy(this.selectedList);
  }

  get listSelected() {
    const selectedIndizes = [];

    for (const item of this.tempSelectedList) {
      const index = this.possibleItems.indexOf(item);
      selectedIndizes.push(index);
    }

    return selectedIndizes as any;
  }

  set listSelected(updatedList) {
    const list = [];
    for (const index of updatedList) {
      list.push(this.possibleItems[index]);
    }
    this.tempSelectedList = list;
  }

  up(index: number) {
    const newValue = [...this.tempSelectedList];
    newValue[index] = this.tempSelectedList[index - 1];
    newValue[index - 1] = this.tempSelectedList[index];
    this.tempSelectedList = newValue;
  }

  down(index: number) {
    const newValue = [...this.tempSelectedList];
    newValue[index] = this.tempSelectedList[index + 1];
    newValue[index + 1] = this.tempSelectedList[index];
    this.$emit("input", newValue);
    this.tempSelectedList = newValue;
  }

  remove(index: number) {
    const newValue = [...this.tempSelectedList.slice(0, index), ...this.tempSelectedList.slice(index + 1)];
    this.tempSelectedList = newValue;
    this.$emit("input", newValue);
  }

  closeDialog() {
    this.dialog = false;
  }

  updateList() {
    this.$emit("updateOrder", this.tempSelectedList);
    this.dialog = false;
  }
}
