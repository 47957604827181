


















































import Card from "@/components/utility/Card.vue";
import TableWrapper, { ITableWrapperHeader } from "@/components/utility/TableWrapper.vue";
import { SimpleCsvParser } from "@/lib/utility/simple-csv.parser";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: { Card, TableWrapper }
})
export default class CsvImportDialog extends Vue {
  @Prop()
  headers!: ITableWrapperHeader[];

  @Prop({ default: "" })
  color?: string;

  get header() {
    const header: ITableWrapperHeader[] = [];
    header.push({
      text: "",
      value: "controls"
    });

    header.push(...this.headers);

    return header;
  }

  @Prop()
  create!: Function;

  @Prop()
  title!: string;

  @Prop({ default: false })
  icon!: boolean;

  @Prop({ default: false })
  block?: boolean;

  isValid = false;
  createDialog = false;
  loading = false;

  files: File[] = [];
  items: any[] = [];

  selected = [];

  removeItem(removeItem: any) {
    const index = this.items.findIndex(i => i === removeItem);

    this.items.splice(index, 1);
  }

  downloadExample() {
    this.loading = true;
    let csvContent = "data:text/csv;charset=utf-8,";

    this.headers.forEach((colum, index) => {
      let delimiter = ";";
      if (index == this.headers.length - 1) {
        delimiter = "";
      }
      csvContent += colum.value + delimiter;
    });

    csvContent += "\r\n";

    const encodedUri = encodeURI(csvContent);
    window.open(encodedUri);

    this.loading = false;
  }

  change(file: File) {
    if (!file) {
      this.items = [];
      this.$log.warn("clear");
      return;
    }

    if (file.type && !file.type.startsWith("text/csv")) {
      this.$log.error("File is not an csv.", file.type, file);
      return;
    }

    this.loading = true;

    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        const csv = reader.result as string;

        try {
          const parser = new SimpleCsvParser(csv);
          this.items = parser.toArray();

          this.$emit("change", this.items);
        } catch (error) {
          this.$log.error(error);
          this.$toast.error((error as any).message);
        } finally {
          this.loading = false;
        }
      },
      false
    );

    reader.readAsText(file);
  }

  async confirmCreation() {
    try {
      this.loading = true;

      await this.create(this.items);

      this.$emit("refresh");
      this.createDialog = false;
    } catch (error) {
      this.$log.error(error);
      this.$toast.error((error as any).message);
    } finally {
      this.loading = false;
    }
  }

  get itemsPreview() {
    return this.items.slice(0, 5);
  }
}
