import { Operation } from "../v1/Operation";
import { PartnerOperation } from "../v1/PartnerOperation";
import {
  MrfiktivOperationControllerFindAllParamsGen,
  MrfiktivOperationViewModelGen,
  MrfiktivPartnerOperationViewModelGen,
  MrfiktivPageViewModelGen,
  MrfiktivOperationPartnerControllerFindAllByPartnerIdParamsGen
} from "../v1/data-contracts";
import { MrfiktivHttpClientProvider } from "./../mrfiktiv-http-client.provider";

/**
 * Service for operations.
 */
export class OperationService {
  /**
   * The http client
   */
  private clientProvider: MrfiktivHttpClientProvider;

  /**
   * Creates the operation service
   *
   * @param clientProvider the client provider
   */
  constructor(clientProvider: MrfiktivHttpClientProvider) {
    this.clientProvider = clientProvider;
  }

  /**
   * Gets all operations
   *
   **/
  async getOperations(
    query: MrfiktivOperationControllerFindAllParamsGen = {}
  ): Promise<
    | MrfiktivOperationViewModelGen
    | (MrfiktivPageViewModelGen & {
        data?: MrfiktivOperationViewModelGen[] | undefined;
      })
  > {
    const proxy = new Operation(this.clientProvider.client());
    const res = await proxy.operationControllerFindAll(query);

    return res.data;
  }

  /**
   * Gets all operation by partner
   *
   * @param partnerId The partner id
   **/
  async getOperationsByPartner(
    query: MrfiktivOperationPartnerControllerFindAllByPartnerIdParamsGen
  ): Promise<
    | MrfiktivOperationViewModelGen
    | (MrfiktivPageViewModelGen & {
        data?: MrfiktivOperationViewModelGen[] | undefined;
      })
  > {
    const proxy = new PartnerOperation(this.clientProvider.client());

    const res = await proxy.operationPartnerControllerFindAllByPartnerId(query);

    return res.data;
  }

  /**
   * Gets an operation by id
   *
   * @param id The operation id
   **/
  async getOperation(id: string): Promise<MrfiktivOperationViewModelGen> {
    const proxy = new Operation(this.clientProvider.client());
    const res = await proxy.operationControllerFindOne(id);

    return res.data;
  }

  /**
   * Gets an operation by partner
   *
   * @param partnerId The partner id
   * @param operationId The operation id
   **/
  async getOperationByPartner(partnerId: string, operationId: string): Promise<MrfiktivPartnerOperationViewModelGen> {
    const proxy = new PartnerOperation(this.clientProvider.client());
    const res = await proxy.operationPartnerControllerFindOneByPartnerId(partnerId, operationId);

    return res.data;
  }

  /**
   * Updates Status of partner operation to canceled and sets cancellation flag
   *
   * @param partnerId
   * @param operationId
   * @returns
   */
  async cancelOperationByPartnerId(
    partnerId: string,
    operationId: string
  ): Promise<MrfiktivPartnerOperationViewModelGen> {
    const proxy = new PartnerOperation(this.clientProvider.client());
    const res = await proxy.operationPartnerControllerCancelByPartnerId(partnerId, operationId);

    return res.data;
  }

  /**
   * Updates Status of operation to canceled and sets cancellation flag
   *
   * @param operationId
   * @returns
   */
  async cancelOperation(operationId: string): Promise<MrfiktivPartnerOperationViewModelGen> {
    const proxy = new Operation(this.clientProvider.client());
    const res = await proxy.operationControllerCancel(operationId);

    return res.data;
  }

  /**
   * Updates Status of operation to canceled and sets cancellation flag
   *
   * @param operationId
   * @returns
   */
  async restartOperation(partnerId: string, operationId: string): Promise<MrfiktivPartnerOperationViewModelGen> {
    const proxy = new PartnerOperation(this.clientProvider.client());
    const res = await proxy.operationPartnerControllerRestartAnOperation(partnerId, operationId);

    return res.data;
  }
}

export default new OperationService(new MrfiktivHttpClientProvider());
