var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-docphant",
    [
      _c(
        "v-container",
        {
          attrs: {
            id: "cardContainer"
          }
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-container",
                {
                  staticClass: ".content",
                  attrs: {
                    fluid: ""
                  }
                },
                [
                  _c("ManufacturerListCard", {
                    attrs: {
                      manufacturerList: _vm.manufacturerList
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("DocphantFooter", {
        attrs: {
          id: "footer",
          partner: _vm.partner
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }