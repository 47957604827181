


















import PartnerDetailAttributeWithToolTip from "@/components/partner/PartnerDetailAttributeWithToolTip.vue";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { MrfiktivWitnessGen } from "@/services/mrfiktiv/v1/data-contracts";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {
    PartnerDetailAttributeWithToolTip
  }
})
export default class PartnerReportDetailInfoWitnessCard extends DarkModeHighlightMixin {
  @Prop()
  witness!: MrfiktivWitnessGen[];

  search = "";

  get headers() {
    return [
      { text: this.$t("components.partner.PartnerReportDetailInfoWitnessCard.firstName"), value: "firstName" },
      { text: this.$t("components.partner.PartnerReportDetailInfoWitnessCard.lastName"), value: "lastName" },
      { text: this.$t("components.partner.PartnerReportDetailInfoWitnessCard.phone"), value: "contact.phone" },
      { text: this.$t("components.partner.PartnerReportDetailInfoWitnessCard.mail"), value: "contact.email" }
    ];
  }
}
