















import { Component, Vue } from "vue-property-decorator";

import LayoutLogo from "@/layouts/LayoutLogo.vue";

@Component({
  components: {
    LayoutLogo
  }
})
export default class Loader extends Vue {}
