import { emailRule, emailRuleOptional, phoneRule, phoneRuleOptional } from "./contactRule";
import { requiredRule } from "./requiredRule";

export enum RulesEnum {
  EMAIL_RULE = "EMAIL_RULE",
  EMAIL_RULE_OPTIONAL = "EMAIL_RULE_OPTIONAL",
  PHONE_RULE = "PHONE_RULE",
  PHONE_RULE_OPTIONAL = "PHONE_RULE_OPTIONAL",
  REQUIRED_RULE = "REQUIRED_RULE"
}

export const RulesMap = new Map([
  [RulesEnum.EMAIL_RULE, emailRule],
  [RulesEnum.EMAIL_RULE_OPTIONAL, emailRuleOptional],
  [RulesEnum.PHONE_RULE, phoneRule],
  [RulesEnum.PHONE_RULE_OPTIONAL, phoneRuleOptional],
  [RulesEnum.REQUIRED_RULE, requiredRule]
]);
