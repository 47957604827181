var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          attrs: {
            flat: "",
            "max-width": "4000"
          }
        },
        [
          _c(
            "v-card-title",
            {
              staticClass: "title pt-4 mb-n7"
            },
            [_c("h4", [_vm._v("CI-Farbe")])]
          ),
          _c(
            "v-card-text",
            {
              staticClass: "mt-8"
            },
            [
              _c(
                "v-icon",
                {
                  staticClass: "mr-2",
                  attrs: {
                    small: "",
                    color: _vm.partner.settings.reportSettings.firstColor
                  }
                },
                [_vm._v("mdi-invert-colors")]
              ),
              _vm._v(
                " " + _vm._s(_vm.partner.settings.reportSettings.firstColor)
              ),
              _c("br"),
              _c(
                "v-icon",
                {
                  staticClass: "mr-2",
                  attrs: {
                    small: "",
                    color: _vm.partner.settings.reportSettings.secondColor
                  }
                },
                [_vm._v("mdi-invert-colors")]
              ),
              _vm._v(
                " " +
                  _vm._s(_vm.partner.settings.reportSettings.secondColor) +
                  " "
              )
            ],
            1
          ),
          _c(
            "v-card-title",
            {
              staticClass: "title pt-4 mb-n7"
            },
            [_c("h4", [_vm._v("Anzeige")])]
          ),
          _c(
            "v-card-text",
            {
              staticClass: "mt-8"
            },
            [
              _c("PartnerDetailAttributeWithToolTip", {
                attrs: {
                  tooltip: "Anzeigereihenfolge der Bildschirme",
                  displayName: _vm.partner.settings.reportSettings.screenOrder,
                  icon: "mdi-format-title"
                }
              }),
              _c("PartnerDetailAttributeWithToolTip", {
                attrs: {
                  tooltip: "Anzeigereihenfolge der Bildschirme",
                  displayName: _vm.partner.settings.reportSettings.screens,
                  icon: "mdi-format-title"
                }
              })
            ],
            1
          ),
          _c(
            "v-card-title",
            {
              staticClass: "title pt-4 mb-n7"
            },
            [_c("h4", [_vm._v("Fotos")])]
          ),
          _c(
            "v-card-text",
            {
              staticClass: "mt-8"
            },
            [
              _c("PartnerDetailImage", {
                attrs: {
                  favIconUrl: _vm.partner.settings.reportSettings.favIconUrl,
                  headerImageUrl:
                    _vm.partner.settings.reportSettings.headerImageUrl,
                  logoUrl: _vm.partner.settings.reportSettings.logoUrl
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }