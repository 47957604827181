


































import { IMachineAttachmentViewmodel } from "@/lib/interfaces/Docphant/machine.attachment.viewmodel";
import { IManufacturer } from "@/lib/interfaces/Docphant/manufacturer.schema.interface";
import { Component, Prop, Vue } from "vue-property-decorator";
import MachineAttachmentCreateDialog from "@/components/docphant/dashboard/MachineAttachmentCreateDialog.vue";

@Component({
  components: {
    MachineAttachmentCreateDialog
  }
})
export default class MachineAttachmentListCard extends Vue {
  @Prop({})
  machineAttachmentList: IMachineAttachmentViewmodel[] = [] as IMachineAttachmentViewmodel[];

  @Prop({})
  manufacturer: any = {} as IManufacturer;

  search = "";
  headers = [
    {
      text: "Name",
      align: "start",
      value: "name"
    },
    {
      text: "Typ",
      value: "attachmentType"
    },
    {
      text: "Sprache",
      value: "language"
    }
  ];
}
