

























import { Component, Prop, Vue } from "vue-property-decorator";
import FormHelpCard from "@/components/form/FormHelpCard.vue";

@Component({
  components: {
    FormHelpCard
  }
})
export default class FormHelpTimeline extends Vue {
  @Prop({ default: "" })
  toolbarSrc!: string;

  @Prop({ default: "#2c2f3b" })
  color!: string;

  items = [
    {
      number: "1",
      icon: "fit_screen",
      image: "https://www.mmmint.ai/images/app/contact/fahrzeugschein.png",
      imagePlaceHolder: "https://www.mmmint.ai/images/app/contact/fahrzeugschein-placeholder.png",
      title: "Fahrzeugscheinfoto",
      subtitle:
        "Machen Sie ein Foto vom Fahrzeugschein. Mithilfe des Fahrzeugscheins haben wir alle nötigen Informationen, um Ihnen das beste Angebot zu erstellen."
    },
    {
      number: "2",
      icon: "camera_enhance",
      image: "https://www.mmmint.ai/images/app/contact/schadenfotos.jpeg",
      imagePlaceHolder: "https://www.mmmint.ai/images/app/contact/schadenfotos-placeholder.png",
      title: "Schadenfotos",
      subtitle:
        "Machen Sie 1 bis 2 Fotos vom Schaden. Der Schaden sollte gut erkennbar sein, damit wir Ihnen ein Angebot zukommen lassen können."
    },
    {
      number: "3",
      icon: "fact_check",
      image: "https://www.mmmint.ai/images/app/contact/kontaktformular.jpeg",
      imagePlaceHolder: "https://www.mmmint.ai/images/app/contact/kontaktformular-placeholder.png",
      title: "Kontaktformular",
      subtitle:
        "Füllen Sie das Kontaktforumaler aus, laden Sie die Bilder hoch und erhalten Sie in kürzester Zeit Ihr individuelles Angebot per E-Mail."
    }
  ];
}
