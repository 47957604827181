































































































import { IMachineAttachmentDto } from "@/lib/interfaces/Docphant/machienAttachments.interface.ts";
import { requiredRule } from "@/lib/rules/requiredRule";
import { LanguageEnum } from "@/store/enum/docphant/language.enum";
import { MachineAttachmentStatusEnum } from "@/store/enum/docphant/machine.attachment.status.enum";
import { MachineAttachmentTypeEnum } from "@/store/enum/docphant/machine.attachment.type.enum";
import { IMachineAttachmentCreateStoreDto } from "@/store/interface/machine-attachment.create.store.interace";
import { MachineAttachmentModule } from "@/store/modules/machine-attachment";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class MachineAttachmentCreateDialog extends Vue {
  manufacturer: any = {};
  dialog = false;
  machineAttachmentTypeEnum = Object.keys(MachineAttachmentTypeEnum).map(k => MachineAttachmentTypeEnum[k as any]);
  machineAttachmentStatusEnum = Object.keys(MachineAttachmentStatusEnum).map(
    k => MachineAttachmentStatusEnum[k as any]
  );
  languageEnum = Object.keys(LanguageEnum).map(k => MachineAttachmentStatusEnum[k as any]);
  machineAttachment: any = {
    name: "",
    descripton: "",
    attachmentType: MachineAttachmentTypeEnum.OTHER,
    status: MachineAttachmentStatusEnum.ACTIVATED,
    attachmentCode: "",
    editor: "",
    language: LanguageEnum.DE,
    file: ""
  } as IMachineAttachmentDto;

  get rules() {
    return [requiredRule()];
  }

  closeUpdate() {
    this.dialog = false;
  }

  async createMachine() {
    const machineAttachmentCreateStoreDto: IMachineAttachmentCreateStoreDto = {
      manufacturerId: this.$route.params.manufacturerId,
      dto: this.machineAttachment
    };
    await MachineAttachmentModule.create(machineAttachmentCreateStoreDto);
    this.dialog = false;
  }
}
