var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.loading || _vm.isMounting
    ? _c("v-skeleton-loader", {
        attrs: {
          type: "article@1"
        }
      })
    : _vm.value
    ? _c(
        "v-card",
        {
          attrs: {
            flat: "",
            loading: _vm.loading || _vm.isMounting
          }
        },
        [
          _c("m-header", {
            attrs: {
              breadCrumbs: _vm.breadCrumbs,
              actions: _vm.actions,
              chips: _vm.chips,
              title: _vm.value.content.subject
            },
            on: {
              actionClicked: function actionClicked(action) {
                return action.exec()
              }
            }
          }),
          _c("v-divider"),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    sm: "9"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "pb-3 pt-2 ms-4"
                    },
                    [
                      _c(
                        "v-avatar",
                        {
                          staticClass: "white--text",
                          attrs: {
                            color: "primary"
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.getAvatar(_vm.fromMail)) + " "
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-inline-flex flex-column justify-center ms-3",
                          staticStyle: {
                            "vertical-align": "middle"
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "text--primary text-truncate font-weight-semibold"
                            },
                            [_vm._v(" " + _vm._s(_vm.fromMail) + " ")]
                          ),
                          _c(
                            "small",
                            {
                              staticClass: "text--disabled"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.PartnerMessageDetail.to",
                                      {
                                        mail: _vm.value.to
                                      }
                                    )
                                  )
                              )
                            ]
                          ),
                          _c(
                            "small",
                            {
                              staticClass: "text--disabled"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.PartnerMessageDetail.from",
                                      {
                                        mail: _vm.value.from
                                      }
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _vm.isMobile
                            ? _c(
                                "div",
                                {
                                  staticClass: "d-flex align-center"
                                },
                                [
                                  _c(
                                    "small",
                                    {
                                      staticClass: "text--disabled"
                                    },
                                    [_vm._v(" " + _vm._s(_vm.date) + " ")]
                                  ),
                                  _c("v-spacer"),
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "close-on-content-click": false,
                                        bottom: ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function fn(_ref) {
                                              var on = _ref.on
                                              return [
                                                _c(
                                                  "div",
                                                  _vm._g({}, on),
                                                  [
                                                    !_vm.isMarkUnreadLoading
                                                      ? _c("viewed-preview", {
                                                          key:
                                                            "viewed-preview-value" +
                                                            _vm.value.viewed
                                                              .length,
                                                          attrs: {
                                                            small: true,
                                                            value:
                                                              _vm.value.viewed
                                                          }
                                                        })
                                                      : _c("v-btn", {
                                                          attrs: {
                                                            icon: "",
                                                            loading: ""
                                                          }
                                                        })
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        2556441895
                                      ),
                                      model: {
                                        value: _vm.unMarkMenu,
                                        callback: function callback($$v) {
                                          _vm.unMarkMenu = $$v
                                        },
                                        expression: "unMarkMenu"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        [
                                          _c(
                                            "v-list-item",
                                            {
                                              attrs: {
                                                loading: _vm.isMarkUnreadLoading
                                              },
                                              on: {
                                                click: _vm.markUnread
                                              }
                                            },
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "components.assignees.markUnread"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]),
                                              _c(
                                                "v-list-item-icon",
                                                [
                                                  _vm.isMarkUnreadLoading
                                                    ? _c(
                                                        "v-progress-circular",
                                                        {
                                                          attrs: {
                                                            size: "20",
                                                            color: "primary",
                                                            indeterminate: ""
                                                          }
                                                        }
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                ]
              ),
              !_vm.isMobile
                ? _c(
                    "v-col",
                    {
                      attrs: {
                        align: "right",
                        cols: "12",
                        sm: "3"
                      }
                    },
                    [
                      _c("v-card-text", [_vm._v(_vm._s(_vm.date))]),
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function fn(_ref2) {
                                  var on = _ref2.on
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "mt-n4 mr-4"
                                      },
                                      [
                                        _c(
                                          "span",
                                          _vm._g({}, on),
                                          [
                                            !_vm.isMarkUnreadLoading
                                              ? _c("viewed-preview", {
                                                  key:
                                                    "viewed-preview-value" +
                                                    _vm.value.viewed.length,
                                                  attrs: {
                                                    value: _vm.value.viewed
                                                  }
                                                })
                                              : _c("v-btn", {
                                                  attrs: {
                                                    icon: "",
                                                    loading: ""
                                                  }
                                                })
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3490532941
                          ),
                          model: {
                            value: _vm.unMarkMenu,
                            callback: function callback($$v) {
                              _vm.unMarkMenu = $$v
                            },
                            expression: "unMarkMenu"
                          }
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-list-item",
                                {
                                  attrs: {
                                    loading: _vm.isMarkUnreadLoading
                                  },
                                  on: {
                                    click: _vm.markUnread
                                  }
                                },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "components.assignees.markUnread"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _vm.isMarkUnreadLoading
                                        ? _c("v-progress-circular", {
                                            attrs: {
                                              size: "20",
                                              color: "primary",
                                              indeterminate: ""
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.value.attachments.length
                ? _c(
                    "v-col",
                    [
                      _c("partner-message-detail-attachment-row", {
                        staticClass: "mx-3 mt-n8 mb-1",
                        on: {
                          onCostCreated: _vm.onCostCreated,
                          onTicketCreated: _vm.onTicketCreated
                        },
                        model: {
                          value: _vm.value,
                          callback: function callback($$v) {
                            _vm.value = $$v
                          },
                          expression: "value"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "m-detail-view-grid",
            {
              scopedSlots: _vm._u([
                {
                  key: "details",
                  fn: function fn() {
                    return [
                      _c(
                        "v-row",
                        {
                          staticClass: "mx-2"
                        },
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12"
                              }
                            },
                            [
                              _c("select-assignees", {
                                attrs: {
                                  css: "width: 100%; margin-right: 9px",
                                  amount: 10,
                                  outlined: true,
                                  disabled: !_vm.can(
                                    _vm.ActionEnum.UPDATE,
                                    _vm.ResourceEnum.MESSAGE
                                  )
                                },
                                on: {
                                  input: _vm.onAssigneesUpdate,
                                  added: _vm.onAssigneesAdded,
                                  removed: _vm.onAssigneesRemoved
                                },
                                model: {
                                  value: _vm.value.assignees,
                                  callback: function callback($$v) {
                                    _vm.$set(_vm.value, "assignees", $$v)
                                  },
                                  expression: "value.assignees"
                                }
                              }),
                              _c(
                                "refs-select",
                                {
                                  attrs: {
                                    partnerId: _vm.value.partnerId,
                                    loading: _vm.isLoadingRefs,
                                    suggested: [],
                                    categories: [
                                      _vm.ResourceEnum.VEHICLE,
                                      _vm.ResourceEnum.EVENT,
                                      _vm.ResourceEnum.TICKET,
                                      _vm.ResourceEnum.DOCUMENT,
                                      _vm.ResourceEnum.SIGN,
                                      _vm.ResourceEnum.MESSAGE,
                                      _vm.ResourceEnum.COST
                                    ],
                                    listMode: false,
                                    isConfirmable: false,
                                    hideEditButton: false,
                                    hideSuggestions: true
                                  },
                                  on: {
                                    confirm: _vm.updateNonReportRefs,
                                    change: _vm.updateNonReportRefs,
                                    input: _vm.updateNonReportRefs,
                                    openDetail: _vm.openSideCard
                                  },
                                  model: {
                                    value: _vm.editNonReportReferences,
                                    callback: function callback($$v) {
                                      _vm.editNonReportReferences = $$v
                                    },
                                    expression: "editNonReportReferences"
                                  }
                                },
                                [
                                  _c("partner-message-detail-report-refs", {
                                    staticClass: "mb-n1",
                                    model: {
                                      value: _vm.value,
                                      callback: function callback($$v) {
                                        _vm.value = $$v
                                      },
                                      expression: "value"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("template-editor", {
                attrs: {
                  value: _vm.value.content.body,
                  readOnly: true,
                  outlined: false
                }
              })
            ],
            1
          ),
          _c("cost-create-dialog", {
            key: "cost-create-dialog",
            ref: "costCreateDialog",
            attrs: {
              isDialogActive: _vm.isCostCreateDialogActive,
              hideButton: true,
              partnerId: _vm.value.partnerId,
              refs: [
                {
                  refType: _vm.ResourceEnum.MESSAGE,
                  refId: _vm.value.id
                }
              ]
            },
            on: {
              "update:isDialogActive": function updateIsDialogActive($event) {
                _vm.isCostCreateDialogActive = $event
              },
              "update:is-dialog-active": function updateIsDialogActive($event) {
                _vm.isCostCreateDialogActive = $event
              },
              created: _vm.onCostCreated
            }
          }),
          _c("ticket-create-dialog", {
            key: "ticket-create-dialog-attachment-card",
            ref: "ticketCreateDialog",
            attrs: {
              isDialogActive: _vm.isTicketCreateDialogActive,
              hideButton: true,
              partnerIdProp: _vm.value.partnerId,
              refs: _vm.ticketCreateRefs
            },
            on: {
              "update:isDialogActive": function updateIsDialogActive($event) {
                _vm.isTicketCreateDialogActive = $event
              },
              "update:is-dialog-active": function updateIsDialogActive($event) {
                _vm.isTicketCreateDialogActive = $event
              },
              created: _vm.onTicketCreated
            }
          }),
          _c("confirm-action-dialog", {
            attrs: {
              isDialogActive: _vm.isArchiveDialogOpen,
              absolute: true,
              title: _vm.$t("common.verbs.archive") + "?",
              rightLoading: _vm.isArchiveLoading
            },
            on: {
              "update:isDialogActive": function updateIsDialogActive($event) {
                _vm.isArchiveDialogOpen = $event
              },
              "update:is-dialog-active": function updateIsDialogActive($event) {
                _vm.isArchiveDialogOpen = $event
              },
              leftClick: _vm.abortArchive,
              rightClick: _vm.archive
            }
          }),
          _c(
            "confirm-action-dialog",
            {
              attrs: {
                isDialogActive: _vm.isMoveDialogOpen,
                absolute: true,
                title: _vm.$t("common.verbs.move") + "?",
                loading: _vm.isMoveLoading,
                hideRight: true
              },
              on: {
                "update:isDialogActive": function updateIsDialogActive($event) {
                  _vm.isMoveDialogOpen = $event
                },
                "update:is-dialog-active": function updateIsDialogActive(
                  $event
                ) {
                  _vm.isMoveDialogOpen = $event
                },
                leftClick: function leftClick($event) {
                  _vm.isMoveDialogOpen = false
                },
                close: function close($event) {
                  _vm.isMoveDialogOpen = false
                }
              }
            },
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item-group",
                    {
                      attrs: {
                        value: _vm.value.folder
                      }
                    },
                    _vm._l(_vm.folderOptions, function(f) {
                      return _c(
                        "v-list-item",
                        {
                          key: "folderOptions" + f,
                          attrs: {
                            value: f
                          },
                          on: {
                            click: function click($event) {
                              return _vm.setNewFolder(f)
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-title",
                            [
                              _c("v-icon", [
                                _vm._v(_vm._s(_vm.messageFolderIconMap.get(f)))
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("MessageFolderEnum.".concat(f))
                                  ) +
                                  " "
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.canCreateMessage
            ? _c("template-dialog", {
                ref: "templateDialog",
                attrs: {
                  hideButton: true,
                  partnerId: _vm.value.partnerId,
                  to: [_vm.value.from],
                  from: [_vm.partner],
                  refs: [
                    {
                      refType: _vm.ResourceEnum.MESSAGE,
                      refId: _vm.value.id
                    }
                  ]
                }
              })
            : _vm._e(),
          _c("partner-message-detail-attach-dialog", {
            ref: "attachDialog",
            attrs: {
              value: _vm.value
            }
          }),
          _vm.selectedCost
            ? _c("cost-side-card", {
                attrs: {
                  value: _vm.selectedCost
                },
                on: {
                  close: function close() {
                    return (_vm.selectedCost = null)
                  }
                }
              })
            : _vm._e(),
          _vm.selectedTicket
            ? _c("ticket-side-card", {
                attrs: {
                  value: _vm.selectedTicket
                },
                on: {
                  close: function close() {
                    return (_vm.selectedTicket = null)
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    : _c("latest-entries-card-empty")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }