






import { IMenuListItem } from "@/lib/interfaces/menu-list-item.interface";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { FeatureModule } from "@/store/modules/feature.store";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import TheNavigationLayout from "./TheNavigationLayout.vue";

@Component({
  components: {
    TheNavigationLayout
  }
})
export default class ThePartnerSettingList extends mixins(PermissionMixin) {
  get title() {
    return String(this.$t("navigation.ThePartnerList.setting.title"));
  }

  get partnerId() {
    return PartnerModule.partner._id;
  }

  get items() {
    const items: IMenuListItem[] = [
      {
        icon: "mdi-chart-line",
        text: "navigation.ThePartnerList.analytics",
        link: `/partners/${this.partnerId}/analytics`,
        permission: {
          resource: ResourceEnum.ANALYTICS,
          action: ActionEnum.READ,
          field: this.partnerId
        },
        isEnabled: FeatureModule.isPartnerAnalyticsEnabled
      },
      {
        icon: "mdi-email-edit-outline",
        text: "navigation.ThePartnerList.setting.templates",
        link: `/partner/${this.partnerId}/template`,
        isEnabled: FeatureModule.isTemplateEnabled,
        permission: {
          resource: ResourceEnum.TEMPLATE,
          action: ActionEnum.READ,
          field: this.partnerId
        }
      },
      {
        icon: "mdi-comment-question-outline",
        text: "navigation.ThePartnerList.setting.faq",
        link: `/partners/${this.partnerId}/faq`,
        isEnabled: true,
        permission: {
          resource: ResourceEnum.FAQ,
          action: ActionEnum.READ,
          field: this.partnerId
        }
      },
      {
        icon: "mdi-share-all-outline",
        text: "navigation.ThePartnerList.setting.sharing",
        link: `/partners/${this.partnerId}/shared`,
        isEnabled: true,
        permission: {
          resource: ResourceEnum.SHARED_CONTENT,
          action: ActionEnum.READ,
          field: this.partnerId
        }
      },
      {
        icon: "mdi-account-outline",
        text: "nav.PartnerUserList.title",
        link: `/partners/${this.partnerId}/user`,
        isEnabled: true,
        permission: {
          resource: ResourceEnum.USER,
          action: ActionEnum.READ,
          field: this.partnerId
        }
      },
      {
        icon: "mdi-dots-hexagon",
        text: "nav.COMPANY_GROUP_TABLE.title",
        link: `/partner/${this.partnerId}/company-group`,
        isEnabled: true,
        permission: {
          resource: ResourceEnum.COMPANY_GROUP,
          action: ActionEnum.READ,
          field: this.partnerId
        }
      }
    ];

    return items.filter(i => i.isEnabled);
  }
}
