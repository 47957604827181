var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _c("LoginCard", {
        attrs: {
          onLogin: _vm.onLoginFunction,
          logo: _vm.logo,
          title: _vm.title,
          text: _vm.text,
          isDescriptionCardActive: true
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }