var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            persistent: "",
            width: "800px"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function fn(_ref) {
                var menu = _ref.on,
                  attrs = _ref.attrs
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: {
                        bottom: ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function fn(_ref2) {
                              var tooltip = _ref2.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          icon: ""
                                        },
                                        on: {
                                          click: _vm.initialize
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    Object.assign({}, tooltip, menu)
                                  ),
                                  [_c("v-icon", [_vm._v("mdi-plus")])],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "components.partner.PartnerCreateDialog.title"
                            )
                          )
                        )
                      ])
                    ]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function callback($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "title pt-4",
                  class: _vm.color
                },
                [
                  _c(
                    "h4",
                    {
                      staticClass: "text-truncate"
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("components.partner.PartnerCreateDialog.title")
                        )
                      )
                    ]
                  ),
                  _c("v-spacer"),
                  _c("partner-create-from-template-dialog", {
                    on: {
                      getTemplate: _vm.setTemplate
                    }
                  }),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: ""
                      },
                      on: {
                        click: _vm.closeDialog
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-form",
                {
                  ref: "form",
                  model: {
                    value: _vm.isValid,
                    callback: function callback($$v) {
                      _vm.isValid = $$v
                    },
                    expression: "isValid"
                  }
                },
                [
                  _c("PartnerCreateOrUpdateGeneralInfos", {
                    attrs: {
                      showUsername: true,
                      partnerDto: _vm.partnerDto
                    }
                  }),
                  _c("PartnerCreateOrUpdateLandingpageSettigns", {
                    attrs: {
                      settings: _vm.partnerDto.settings
                    }
                  }),
                  _c("PartnerCreateOrUpdateReportSettings", {
                    attrs: {
                      reportSettings: _vm.partnerDto.settings.reportSettings
                    }
                  }),
                  _c("PartnerCreateOrUpdateLicense", {
                    attrs: {
                      settings: _vm.partnerDto.settings
                    }
                  }),
                  _c("PartnerCreateOrUpdateSubmitCard", {
                    attrs: {
                      partnerDto: _vm.partnerDto
                    },
                    on: {
                      closeDialog: function closeDialog($event) {
                        return _vm.closeDialog()
                      },
                      validate: _vm.validate
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }