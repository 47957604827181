


























































import { ibanRule } from "@/lib/rules/ibanRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgBankingDtoGen, ThgBankingViewModelGen } from "@/services/thg/v1/data-contracts";
import { BankingModule } from "@/store/modules/banking.store";
import { electronicFormatIBAN } from "ibantools";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";

@Component({
  components: { Card }
})
export default class PortalBankingDialog extends DarkModeHighlightMixin {
  dialog = false;
  valid = false;

  @Prop()
  userId!: string;

  @Prop()
  partnerId!: string;

  banking: ThgBankingViewModelGen = {
    id: "",
    userId: "",
    partnerId: "",
    name: "",
    iban: "",
    bank: ""
  };

  get ibanRules() {
    return [ibanRule(), requiredRule()];
  }

  get requiredRules() {
    return [requiredRule()];
  }

  editLoading = false;

  openDialog() {
    this.banking = { ...(BankingModule.banking as ThgBankingViewModelGen) };
    if (this.banking.iban.includes("*")) {
      this.banking.iban = "";
    }
    this.dialog = true;
  }

  close() {
    this.dialog = false;
  }

  async edit() {
    try {
      const iban = electronicFormatIBAN(this.banking.iban) || "";
      this.editLoading = true;
      const dto: ThgBankingDtoGen = {
        name: this.banking.name,
        iban: iban,
        bank: this.banking.bank
      };

      this.$emit("bankingChanged", dto);
    } catch (e) {
      this.$log.error(e);
      this.$toast.error((e as any).message);
    } finally {
      this.editLoading = false;
      this.dialog = false;
    }
  }
}
