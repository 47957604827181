var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            persistent: "",
            width: "800px"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function fn(_ref) {
                var on = _ref.on,
                  attrs = _ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            icon: "",
                            disabled: _vm.partners.length <= 0
                          },
                          on: {
                            click: _vm.initialize
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-pencil")])],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function callback($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "title pt-4",
                  class: _vm.color
                },
                [
                  _c(
                    "h4",
                    {
                      staticClass: "text-truncate"
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "components.partner.PartnerBatchUpdateDialog.title"
                          )
                        )
                      )
                    ]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: ""
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            color: "grey lighten-1"
                          },
                          on: {
                            click: _vm.closeDialog
                          }
                        },
                        [_vm._v("mdi-close")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-card-subtitle", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "components.partner.PartnerBatchUpdateDialog.subtitle",
                        {
                          count: _vm.partners.length,
                          processingTimeInSeconds: Math.round(
                            (_vm.partners.length *
                              (_vm.averageUpdateInMs + _vm.sleepInMs)) /
                              60
                          )
                        }
                      )
                    ) +
                    " "
                )
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      model: {
                        value: _vm.isValid,
                        callback: function callback($$v) {
                          _vm.isValid = $$v
                        },
                        expression: "isValid"
                      }
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "px-0",
                        attrs: {
                          rules: [_vm.requiredRule],
                          label: _vm.$t(
                            "components.partner.PartnerBatchUpdateDialog.cardTitle"
                          ),
                          hint: _vm.$t(
                            "components.partner.PartnerBatchUpdateDialog.cardTitleHint"
                          ),
                          type: "number",
                          required: "",
                          outlined: "",
                          "data-test-title": ""
                        },
                        model: {
                          value: _vm.cardTitle,
                          callback: function callback($$v) {
                            _vm.cardTitle = _vm._n($$v)
                          },
                          expression: "cardTitle"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          items: [false, true],
                          label: _vm.$t(
                            "components.partner.PartnerBatchUpdateDialog.cardSubtitle"
                          ),
                          hint: _vm.$t(
                            "components.partner.PartnerBatchUpdateDialog.cardSubtitleHint"
                          ),
                          outlined: "",
                          required: "",
                          "data-test-subtitle": ""
                        },
                        model: {
                          value: _vm.cardSubtitle,
                          callback: function callback($$v) {
                            _vm.cardSubtitle = $$v
                          },
                          expression: "cardSubtitle"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-card-text", [_vm._v(" " + _vm._s(_vm.companyNames) + " ")]),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: !_vm.isValid,
                        loading: _vm.isLoading
                      },
                      on: {
                        click: _vm.save
                      }
                    },
                    [_vm._v("Save")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }