






















































import { requiredRule } from "@/lib/rules/requiredRule";
import { deepCopy } from "@/lib/utility/deep-copy";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";

@Component({
  components: { Card }
})
export default class LabelComponent extends DarkModeHighlightMixin {
  @Prop({ default: [] })
  labels!: string[];

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: false })
  loading!: boolean;

  private readonly UPDATE = "update";

  menu = false;
  valid = false;

  tagToAd = "";

  /**
   * make sure that interval is gone
   */
  beforeDestroy() {
    document.removeEventListener("keyup", this.handleKeyDown);
  }

  addEnterEvent() {
    document.addEventListener("keyup", this.handleKeyDown);
  }

  /**
   * default prefill
   */
  handleKeyDown(event: any) {
    if (this.menu === true && event.key === "Enter") {
      this.addTag();
    }
  }

  get tagRule() {
    const rules = [];

    rules.push(requiredRule());

    return rules;
  }

  /**
   * Closes the menu and rests tagToAd
   */
  closeMenu() {
    this.menu = false;
    this.tagToAd = "";
    document.removeEventListener("keyup", this.handleKeyDown);
  }

  /**
   * Emit the updated labels
   */
  emitUpdate(labels: string[]) {
    this.$emit(this.UPDATE, labels);
  }

  addTag() {
    const updatedLabels = deepCopy(this.labels);
    updatedLabels.push(this.tagToAd);
    this.emitUpdate(updatedLabels);
    this.closeMenu();
  }

  /**
   *
   * @param tag
   */
  async removeTag(tag: string) {
    let updatedLabels = deepCopy(this.labels);
    updatedLabels = this.labels.filter((e: string) => e !== tag);
    this.emitUpdate(updatedLabels);
  }
}
