var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-simple",
    {
      attrs: {
        title: _vm.partner.companyName
      }
    },
    [
      _c("partner-detail-card", {
        attrs: {
          isLoading: _vm.isLoading
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }