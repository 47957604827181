





































import { requiredRule } from "@/lib/rules/requiredRule";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";

@Component({
  components: { ConfirmActionDialog }
})
export default class DeleteDialog extends DarkModeHighlightMixin {
  /**
   * The event emitted by the dialog on delete Action
   */
  public EMIT_EVENT_DELETE = "delete";

  /**
   * The event emitted by the dialog on cancel Action
   */
  public EMIT_EVENT_CANCEL = "cancel";

  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: false })
  small!: boolean;

  @Prop({ default: "components.dialog.DeleteDialog.title" })
  title!: string;

  @Prop({ default: "components.dialog.DeleteDialog.reason" })
  text!: string;

  @Prop({ default: true })
  isBlock?: boolean;

  dialog = false;
  reason = "";

  get isDisabeld(): boolean {
    return !this.reason;
  }

  /**
   * Emit that delete can be continued
   */
  emitDelete() {
    this.$emit(this.EMIT_EVENT_DELETE, this.reason);
    this.close();
  }

  /**
   * Emit that delete is aborted
   */
  emitCancel() {
    this.$emit(this.EMIT_EVENT_CANCEL, true);
    this.close();
  }

  close() {
    this.dialog = false;
  }

  /**
   * Rules for required data
   */
  get requiredRules() {
    const rules = [];

    rules.push(requiredRule());

    return rules;
  }
}
