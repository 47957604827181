


















import { Component } from "vue-property-decorator";
import DocumentTemplateDetailSave from "./DocumentTemplateDetailSave.vue";

@Component({
  components: {}
})
export default class DocumentTemplateDetailSaveMobile extends DocumentTemplateDetailSave {}
