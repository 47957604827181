














































import { Component, Prop } from "vue-property-decorator";
import CsvExportMixin from "@/mixins/CsvExportMixin.vue";
import { IFullOptions } from "json-2-csv";
import Card from "../utility/Card.vue";

@Component({
  components: { Card }
})
export default class AnalyticsDataTableTimeSeriesDialog extends CsvExportMixin {
  @Prop({ default: [] })
  data!: { name: string; data: number[][] }[];

  search = "";
  dialog = false;
  isLoading = false;
  selected: { name: string; data: number[][] }[] = [];

  get headers() {
    return [{ text: "Data", value: "name" }];
  }

  options: IFullOptions = {
    delimiter: {
      wrap: '"', // Double Quote (") character
      field: ";", // Comma field delimiter
      eol: "\n" // Newline delimiter
    },
    prependHeader: true,
    sortHeader: false,
    excelBOM: true,
    trimFieldValues: true,
    unwindArrays: true
  };

  exportData() {
    this.isLoading = true;
    let exportDataRow: { timestamp: number; value: number }[] = [];
    const exportData = {};

    if (this.selected.length) {
      exportDataRow = [];
      for (const timeSeriesDataPoint of this.selected[0].data) {
        exportDataRow.push({ timestamp: timeSeriesDataPoint[0], value: timeSeriesDataPoint[1] });
      }
      exportData[this.selected[0].name] = exportDataRow;
    }

    this.download(exportData, this.options);

    this.dialog = false;
    this.isLoading = false;
  }

  close() {
    this.dialog = false;
  }
}
