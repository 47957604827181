


















import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class ManufacturerCard extends Vue {
  @Prop({})
  manufacturer: any;
}
