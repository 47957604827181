















































































import MachineCard from "@/components/docphant/MachineCard.vue";
import { IMachineAttachmentViewmodel } from "@/lib/interfaces/Docphant/machine.attachment.viewmodel";
import { IMachineViewmodel } from "@/lib/interfaces/Docphant/machine.viewmodel.interface";
import { IManufacturer } from "@/lib/interfaces/Docphant/manufacturer.schema.interface";
import QrcodeVue from "qrcode.vue";
import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import MachineAttachmentAddDialog from "./MachineAttachmentAddDialog.vue";
import MachineAttachmentRemoveDialog from "./MachineAttachmentRemoveDialog.vue";
import MachineCreateDialog from "./MachineCreateDialog.vue";
import MachineDeleteDialog from "./MachineDeleteDialog.vue";
import MachineInfoDialog from "./MachineInfoDialog.vue";
import MachineQrCodeCard from "./MachineQrCodeCard.vue";

@Component({
  components: {
    MachineCreateDialog,
    MachineCard,
    QrcodeVue,
    MachineQrCodeCard,
    MachineAttachmentRemoveDialog,
    MachineAttachmentAddDialog,
    MachineInfoDialog,
    MachineDeleteDialog
  }
})
export default class MachineListCard extends Vue {
  @Prop({})
  machineList!: IMachineViewmodel[];

  @Prop({})
  machineAttachmentList!: IMachineAttachmentViewmodel[];

  @Prop({})
  manufacturer!: IManufacturer;

  @Ref("machineAttachmentRemoveDialogComponent")
  readonly machineAttachmentRemoveDialogComponent!: MachineAttachmentRemoveDialog;

  @Ref("machineAttachmentAddDialogComponent")
  readonly machineAttachmentAddDialogComponent!: MachineAttachmentAddDialog;

  @Ref("machineInfoDialogComponent")
  readonly machineInfoDialogComponent!: MachineInfoDialog;

  @Ref("machineDeleteDialogComoponent")
  readonly machineDeleteDialogComoponent!: MachineDeleteDialog;

  assignableMachineAttachment: IMachineAttachmentViewmodel[] = [];

  machineDeleteDialog = false;

  search = "";
  headers = [
    {
      text: "Name",
      align: "start",
      value: "machineName"
    },
    {
      text: "Variante",
      value: "variante"
    },
    {
      text: "Typ",
      value: "machineType"
    },
    {
      text: "Actions",
      value: "actions",
      align: "right",
      sortable: false
    }
  ];

  /**
   *
   * Possible Actions for Machines
   */
  openMachineAttachmentRemoveDialog(machine: IMachineViewmodel) {
    this.machineAttachmentRemoveDialogComponent.openDialog(machine);
  }

  openMachineAttachmentAddDialog(machine: IMachineViewmodel) {
    this.machineAttachmentAddDialogComponent.openDialog(machine);
  }

  openMachineInfoDialog(machine: IMachineViewmodel) {
    this.machineInfoDialogComponent.openDialog(machine);
  }

  openMachineDeleteDialog(machine: IMachineViewmodel) {
    this.machineDeleteDialogComoponent.openDialog(machine);
  }

  goToMachineView(machine: IMachineViewmodel) {
    this.$router.push({ name: "MachinePage", params: { manufacturerId: this.manufacturer.id, machineId: machine.id } });
  }
}
