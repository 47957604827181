





































import { getFlagEmojiByLanguage } from "@/lib/CountryCodeHelper";
import { LanguageCodeEnum } from "@/lib/enum/language-code.enum";
import { IDialog } from "@/lib/interfaces/dialog.interface";
import { handleError } from "@/lib/utility/handleError";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import {
  MrfiktivAdminTemplateViewModelGen,
  MrfiktivCreateAdminTemplateDtoGen,
  MrfiktivPartnerTemplateViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import adminTemplateService from "@/services/shared/adminTemplateService";
import partnerTemplateService from "@/services/shared/partnerTemplateService";
import { ThgAdminTemplateViewModelGen, ThgPartnerTemplateViewModelGen } from "@/services/thg/v1/data-contracts";
import { AdminTemplateModule } from "@/store/modules/admin-template.store";
import { ConfigModule } from "@/store/modules/config";
import { PartnerTemplateModule } from "@/store/modules/partner-template.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";

@Component({
  components: {
    ConfirmActionDialog
  },
  filters: {
    getFlagEmojiByLanguage
  }
})
export default class TemplateDetailLanguageDialog extends mixins(PermissionMixin, DarkModeHighlightMixin)
  implements IDialog {
  readonly availableLanguages = ConfigModule.availableLanguages;

  @Prop()
  template!: MrfiktivAdminTemplateViewModelGen | MrfiktivPartnerTemplateViewModelGen;

  @Prop()
  partnerId?: string;

  loading = false;

  dialog = false;

  loadingCopy = false;

  get templateKey() {
    return this.template.key;
  }

  get language() {
    return this.template.meta.language;
  }

  templates: Map<
    LanguageCodeEnum,
    MrfiktivAdminTemplateViewModelGen | MrfiktivPartnerTemplateViewModelGen | null
  > = new Map();

  open(): void {
    this.dialog = true;
    this.getTemplates();
  }

  close(): void {
    this.dialog = false;
  }

  async getTemplates() {
    this.loading = true;
    this.templates.clear();

    const templatesAsync = [];
    for (const language of this.availableLanguages) {
      const templateAsync = this.getTemplateForLanguage(language)
        .then(template => this.templates.set(language, template ?? null))
        .catch(this.$log.error);

      templatesAsync.push(templateAsync);
    }

    await Promise.all(templatesAsync);

    this.loading = false;
  }

  async getTemplateForLanguage(language: LanguageCodeEnum) {
    const key = this.templateKey;

    let template: ThgAdminTemplateViewModelGen | ThgPartnerTemplateViewModelGen | undefined;
    if (this.partnerId) {
      template = PartnerTemplateModule.maps.id.get(`${this.templateKey}.${language}`)[0];
      if (!template) template = await partnerTemplateService.getByKey(this.partnerId, key, language);
    } else {
      template = AdminTemplateModule.maps.id.get(`${this.templateKey}.${language}`)[0];
      if (!template) template = await adminTemplateService.getByKey(key, language);
    }

    if (template?.meta.language !== language) {
      template = undefined;
    }

    return template;
  }

  async copyTemplateForLanguage(language: LanguageCodeEnum) {
    this.loadingCopy = true;
    try {
      let created:
        | MrfiktivAdminTemplateViewModelGen
        | MrfiktivPartnerTemplateViewModelGen
        | ThgAdminTemplateViewModelGen
        | ThgPartnerTemplateViewModelGen
        | undefined;

      if (this.partnerId) {
        const data = {
          partnerId: this.partnerId,
          data: {
            content: {
              body: this.template.content.body,
              subject: this.template.content.subject
            },
            meta: {
              ...this.template.meta,
              title: this.template.key,
              language
            }
          }
        };
        created = await PartnerTemplateModule.create(data);
      } else {
        const data: MrfiktivCreateAdminTemplateDtoGen = {
          content: {
            body: this.template.content.body,
            subject: this.template.content.subject
          },
          meta: {
            ...this.template.meta,
            title: this.template.key,
            language
          },
          isPublic: (this.template as MrfiktivAdminTemplateViewModelGen).isPublic
        };
        created = await AdminTemplateModule.create(data);
      }

      this.templates.set(language, created ?? null);
    } catch (e) {
      handleError(e);
    }
    this.loadingCopy = false;
  }

  goToTemplateDetail(language: LanguageCodeEnum) {
    if (language === this.template.meta.language) {
      this.close();
      return;
    }

    if (this.partnerId && this.template) {
      this.$router.push({
        name: "TemplateDetailPartnerView",
        params: { key: this.templateKey, partnerId: this.partnerId },
        query: { language: language }
      });
    } else if (this.template) {
      this.$router.push({
        name: "TemplateDetailView",
        params: { key: this.templateKey },
        query: { language: language }
      });
    }
  }
}
