var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "weightedFont"
    },
    [
      _c(
        "v-card-title",
        {
          staticClass: "title pt-4 mb-n7"
        },
        [_c("h3", [_vm._v("Dokumente")])]
      ),
      _c(
        "v-card-title",
        {
          staticClass: "pt-0 mb-n5"
        },
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: "10"
              }
            },
            [
              _c("v-text-field", {
                attrs: {
                  "append-icon": "mdi-magnify",
                  label: "Suchen",
                  "single-line": "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.search,
                  callback: function callback($$v) {
                    _vm.search = $$v
                  },
                  expression: "search"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "2",
                align: "center"
              }
            },
            [
              _vm.isFiltersSet
                ? _c(
                    "v-icon",
                    {
                      staticClass: "mt-4",
                      on: {
                        click: _vm.toggleFilter
                      }
                    },
                    [_vm._v(" mdi-filter-outline ")]
                  )
                : _c(
                    "v-icon",
                    {
                      staticClass: "mt-4",
                      on: {
                        click: _vm.toggleFilter
                      }
                    },
                    [_vm._v(" mdi-filter-off-outline ")]
                  )
            ],
            1
          )
        ],
        1
      ),
      _vm.isFiltersSet
        ? _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        col: "12"
                      }
                    },
                    [
                      _c(
                        "v-chip-group",
                        {
                          attrs: {
                            "active-class": "primary--text",
                            column: ""
                          }
                        },
                        [
                          _vm._l(_vm.filterItems, function(item) {
                            return _c(
                              "v-chip",
                              {
                                key: item.element,
                                staticClass: "chipElement",
                                attrs: {
                                  close: ""
                                },
                                on: {
                                  "click:close": function clickClose($event) {
                                    return _vm.removeFilterItem(
                                      item.element,
                                      item.category
                                    )
                                  }
                                }
                              },
                              [
                                item.category !== "startDate" &&
                                item.category !== "endDate"
                                  ? _c("div", [
                                      _vm._v(" " + _vm._s(item.element) + " ")
                                    ])
                                  : _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.chipDateDisplayHelper(
                                              item.element
                                            )
                                          ) +
                                          " "
                                      )
                                    ])
                              ]
                            )
                          }),
                          _c(
                            "v-chip",
                            {
                              staticClass: "closeChipElement",
                              on: {
                                click: _vm.clearFilter
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "closeChipElementText"
                                },
                                [_vm._v(" mdi-close-circle-outline ")]
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.filteredAttachments,
          search: _vm.search,
          "custom-filter": _vm.searchMethod,
          "hide-default-footer": true,
          "mobile-breakpoint": 200,
          "custom-sort": _vm.customSort
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function fn(_ref) {
              var items = _ref.items
              return _vm._l(items, function(item) {
                return _c(
                  "tbody",
                  {
                    key: item.name
                  },
                  [
                    _c(
                      "tr",
                      {
                        staticClass: "tableRow",
                        on: {
                          click: function click($event) {
                            return _vm.toggleExpand(item.id)
                          }
                        }
                      },
                      [
                        _c(
                          "td",
                          {
                            staticClass: "timestampCol"
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  new Date(
                                    item.timestamps.created
                                  ).toLocaleDateString("de-DE")
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getDisplayNameFromEnumValue(
                                  _vm.MachineAttachmentDisplayNames,
                                  item.attachmentType
                                )
                              ) +
                              " "
                          )
                        ]),
                        _c(
                          "td",
                          {
                            staticClass: "nameCol"
                          },
                          [_vm._v(" " + _vm._s(item.name) + " ")]
                        )
                      ]
                    ),
                    _c(
                      "tr",
                      {
                        staticClass: "expandCard",
                        staticStyle: {
                          display: "none"
                        },
                        attrs: {
                          id: item.id
                        },
                        on: {
                          click: function click($event) {
                            return _vm.toggleExpand(item.id)
                          }
                        }
                      },
                      [
                        _c(
                          "td",
                          {
                            attrs: {
                              colspan: "3"
                            }
                          },
                          [
                            _c(
                              "v-row",
                              {
                                staticClass: "firstExpansionRow"
                              },
                              [
                                _c(
                                  "v-col",
                                  {
                                    attrs: {
                                      cols: "3",
                                      xs: "4",
                                      sm: "4"
                                    }
                                  },
                                  [_c("nobr", [_vm._v("Beschreibung")])],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    attrs: {
                                      cols: "9",
                                      xs: "8",
                                      sm: "8"
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(item.descripton) + " ")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              {
                                staticClass: "expansionRow"
                              },
                              [
                                _c(
                                  "v-col",
                                  {
                                    attrs: {
                                      cols: "3",
                                      xs: "4",
                                      sm: "4"
                                    }
                                  },
                                  [_c("nobr", [_vm._v("Editor")])],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    attrs: {
                                      cols: "9",
                                      xs: "8",
                                      sm: "8"
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(item.editor) + " ")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              {
                                staticClass: "expansionRow"
                              },
                              [
                                _c(
                                  "v-col",
                                  {
                                    attrs: {
                                      cols: "3",
                                      xs: "4",
                                      sm: "4"
                                    }
                                  },
                                  [_c("nobr", [_vm._v("Sprache")])],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    attrs: {
                                      cols: "9",
                                      xs: "8",
                                      sm: "8"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getDisplayNameFromEnumValue(
                                            _vm.LanugageDisplayNames,
                                            item.language
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              {
                                staticStyle: {
                                  "margin-bottom": "10px"
                                }
                              },
                              [
                                _c(
                                  "v-col",
                                  {
                                    attrs: {
                                      cols: "12",
                                      align: "center"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "submitButton",
                                        attrs: {
                                          rounded: "",
                                          title: item.file.name
                                        },
                                        on: {
                                          click: function click($event) {
                                            return _vm.open(item.file.url)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              left: ""
                                            }
                                          },
                                          [_vm._v(" mdi-send ")]
                                        ),
                                        _vm._v(" Download ")
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c("v-divider")
                          ],
                          1
                        )
                      ]
                    )
                  ]
                )
              })
            }
          }
        ])
      }),
      _c(
        "div",
        {
          staticStyle: {
            height: "0px"
          }
        },
        [
          _vm.filterInactive
            ? _c("FilterCard", {
                attrs: {
                  toggleFilter: _vm.toggleFilter,
                  setFilters: _vm.setFilters,
                  filters: _vm.filters
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }