import { IMachineAttachmentDto } from "@/lib/interfaces/Docphant/machienAttachments.interface.ts";
import { IMachineAttachmentViewmodel } from "@/lib/interfaces/Docphant/machine.attachment.viewmodel";
import { Utils } from "@/lib/utility/utils";
import { MrfiktivHttpClientProvider as Api } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import Vue from "vue";

/**
 * Communicates with docphant backend
 */
class MachineAttachmentService {
  ROUTE = "docphant";

  async create(
    manufacturerId: string,
    createMachineAttachmentDto: IMachineAttachmentDto
  ): Promise<IMachineAttachmentViewmodel> {
    const api = new Api().instance();
    let c: any;

    const config = {
      headers: {
        "content-type": "multipart/form-data"
      }
    };

    const formData = new FormData();
    Utils.buildFormData(formData, createMachineAttachmentDto);

    try {
      const response = await api.post(
        `/${this.ROUTE}/manufacturer/${manufacturerId}/machine-attachment/`,
        formData,
        config
      );
      c = response.data;
    } catch (error) {
      Vue.$log.error(error);
      throw error;
    }

    return c;
  }

  async getAll(manufacturerId: string): Promise<IMachineAttachmentViewmodel[]> {
    const api = new Api().instance();
    let c: any;
    try {
      const response = await api.get(`/${this.ROUTE}/manufacturer/${manufacturerId}/machine-attachment/`);
      c = response.data;
    } catch (error) {
      Vue.$log.error(error);
      throw error;
    }

    return c;
  }
}

export default new MachineAttachmentService();
