var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            width: "600px"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function fn(_ref) {
                var on = _ref.on,
                  attrs = _ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            text: ""
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "components.partner.PartnerCreateFromTemplateDialog.btnTxt"
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function callback($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "title pt-4",
                  class: _vm.color
                },
                [
                  _c(
                    "h4",
                    {
                      staticClass: "text-truncate"
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "components.partner.PartnerCreateFromTemplateDialog.title"
                          )
                        )
                      )
                    ]
                  )
                ]
              ),
              _c("v-divider"),
              _c(
                "v-form",
                {
                  ref: "form",
                  model: {
                    value: _vm.valid,
                    callback: function callback($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c("v-autocomplete", {
                    staticClass: "ma-4",
                    attrs: {
                      rules: _vm.requiredRules,
                      items: _vm.partners,
                      "item-text": "companyName",
                      label: "Partner",
                      outlined: "",
                      "return-object": ""
                    },
                    model: {
                      value: _vm.partnerTemplate,
                      callback: function callback($$v) {
                        _vm.partnerTemplate = $$v
                      },
                      expression: "partnerTemplate"
                    }
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-4",
                      attrs: {
                        disabled: !_vm.valid
                      },
                      on: {
                        click: _vm.useTemplate
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "components.partner.PartnerCreateFromTemplateDialog.submitBtnTxt"
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }