


























































































import CustomerContactCard from "@/components/partner/CustomerContactCard.vue";
import PortalBankingDialog from "@/components/partner/PortalBankingDialog.vue";
import UserAdministrationResendInvitationDialog from "@/components/partner/UserAdministrationResendInvitationDialog.vue";
import { AppContextEnum } from "@/lib/enum/appContext.enum";
import { requiredRule } from "@/lib/rules/requiredRule";
import { handleError } from "@/lib/utility/handleError";
import { UpdateUserDtoFactory } from "@/lib/utility/updateUserDtoFactory";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IPageFilterElement, PageFilterElement } from "@/models/page-filter-element.entity";
import { IAdminUser } from "@/models/user.entity";
import { ThgStatusEnum } from "@/store/enum/thg/thg-status.enum";
import { ConfigModule } from "@/store/modules/config";
import { ThgPaginationModule } from "@/store/modules/thg-pagination.store";
import { Component, Prop } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import Card from "../utility/Card.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";

@Component({
  components: {
    CustomerContactCard,
    PortalBankingDialog,
    LatestEntriesCardEmpty,
    Card,
    UserAdministrationResendInvitationDialog,
    ConfirmActionDialog
  }
})
export default class UserAdminsitrationDangerZoneCard extends DarkModeHighlightMixin {
  @Prop()
  partnerId?: string;

  @Prop()
  selectedUser!: IAdminUser;

  /**
   * Confirm action dialogs
   */
  isUnsubscribeDialogActive = false;
  isLoadingUnsubscribe = false;
  isDeleteDialogActive = false;

  /**
   * Loading if a user is deleted
   */
  isLoadingDeletion = false;

  /**
   * deletion is valid if user name and user id matches (asked in confirm action dialog)
   */
  isDeletionValid = false;

  /**
   * value for validation that userId matches selected user id
   */
  userIdToDelete = "";

  /**
   * value for validation that unser name matches selected user name
   */
  userNameToDelete = "";

  /**
   * required rule
   */
  get requiredRule() {
    return [requiredRule()];
  }

  /**
   * Set user.isMarketingoptin to false
   */
  async unsubscribe() {
    if (!this.selectedUser) return;

    try {
      this.isLoadingUnsubscribe = true;
      const updateUserDto = new UpdateUserDtoFactory().createFromIUser(this.selectedUser);
      await this.selectedUser?.updateFromDto({
        ...updateUserDto,
        isMarketingOptIn: false
      });
      this.$toast.success("Nutzer wurde vom Newsletter abgemeldet.");
    } catch (error) {
      this.$toast.error("Error unsubcribing user.");
    }
    this.isUnsubscribeDialogActive = false;
    this.isLoadingUnsubscribe = false;
  }

  /**
   * returns true if the user has given marketing optin
   */
  get isMarketingOptin(): boolean {
    return !!this.selectedUser?.isMarketingOptIn;
  }

  /**
   * checks if userId and username matches
   */
  get deletionParamsValid(): boolean {
    return (
      this.isDeletionValid &&
      this.userIdToDelete === this.selectedUser?._id &&
      this.userNameToDelete === this.selectedUser?.userName
    );
  }

  /**
   * Open delete Dialog (and fetch ghg if app context is ghg)
   */
  async openDeleteDialog() {
    this.isDeleteDialogActive = true;
    if (this.ghgContext) {
      await this.fetchThg();
    }
  }

  /**
   * Deletes a user
   */
  async deleteUser() {
    this.isLoadingDeletion = true;
    await this.selectedUser?.delete();
    this.isDeleteDialogActive = false;
    this.isLoadingDeletion = false;
    this.$toast.success("Nutzer wurde erfolgreich gelöscht.");
    this.$router.push({ name: "UserAdministration" });
  }

  /**
   * -----------------------------------------------
   * GHG-Specific stuff below
   * Only relevant if the app context is ghg Context
   * -----------------------------------------------
   */

  /**
   * if true thg of the users are fetched
   */
  isLoadingThg = false;

  /**
   * Is true if the app context is ghg portal
   */
  get ghgContext() {
    return ConfigModule.appContext === AppContextEnum.THG_PORTAL;
  }

  /**
   * return filtered ghg of the user
   */
  get thgs() {
    return ThgPaginationModule.paginationList;
  }

  /**
   * headers for thg table
   */
  get headers() {
    return [
      {
        text: "ID",
        value: "id"
      },
      {
        text: "Status",
        value: "status"
      }
    ];
  }

  /**
   * Check if user has a ghg quota in a success state
   */
  get isDeletionAllowed(): boolean {
    let isAllowed = true;
    for (const item of this.thgs) {
      if (
        [
          ThgStatusEnum.CREATED,
          ThgStatusEnum.CONFIRMED,
          ThgStatusEnum.REGISTRATION_IMAGES_REQUESTED,
          ThgStatusEnum.REQUEST_REGISTRATION_IMAGES,
          ThgStatusEnum.SUBMITTED,
          ThgStatusEnum.WAIT_FOR_AGENCY,
          ThgStatusEnum.CONFIRMED_BY_AGENCY,
          ThgStatusEnum.PAYED
        ].includes(item.status as ThgStatusEnum)
      ) {
        isAllowed = false;
      }
    }
    return isAllowed;
  }

  /**
   * Fetch thg to check if deletion is allowed in ghg context
   * if user has ghg that we had processed sucesfully, deletion of the user is not allowed due to regulatory
   */
  async fetchThg() {
    const filter: IPageFilterElement[] = [
      new PageFilterElement({
        key: "userId",
        operation: "$eq",
        value: this.selectedUser.id
      })
    ];
    ThgPaginationModule.setFilter(filter);
    try {
      this.isLoadingThg = true;
      await ThgPaginationModule.fetchAll({});
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoadingThg = false;
    }
  }
}
