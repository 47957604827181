var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      attrs: {
        flat: "",
        id: "filterCardFilter"
      }
    },
    [
      _c("v-divider"),
      _c(
        "v-card-title",
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: "10",
                align: "center"
              }
            },
            [
              _c("v-text-field", {
                ref: "searchInput",
                staticClass: "ml-n6",
                attrs: {
                  "append-icon": "mdi-magnify",
                  label: _vm.$t("components.filter.label"),
                  "hide-details": "",
                  outlined: "",
                  rounded: "",
                  dense: ""
                },
                on: {
                  input: _vm.debounceFilter
                },
                model: {
                  value: _vm._search,
                  callback: function callback($$v) {
                    _vm._search = $$v
                  },
                  expression: "_search"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "2",
                align: "center"
              }
            },
            [
              _vm.isFilterSelectionOpen
                ? _c(
                    "v-icon",
                    {
                      ref: "filterToggleOn",
                      on: {
                        click: _vm.toggleFilter
                      }
                    },
                    [_vm._v(" mdi-filter-outline ")]
                  )
                : _c(
                    "v-icon",
                    {
                      ref: "filterToggleOff",
                      on: {
                        click: _vm.toggleFilter
                      }
                    },
                    [_vm._v(" mdi-filter-off-outline ")]
                  )
            ],
            1
          ),
          _vm.isFilterListNotEmpty
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12"
                      }
                    },
                    [
                      _c(
                        "v-chip-group",
                        {
                          attrs: {
                            "active-class": "primary--text",
                            column: ""
                          }
                        },
                        [
                          _vm._l(_vm.filterList, function(filter, index) {
                            return _c(
                              "div",
                              {
                                key: index
                              },
                              _vm._l(filter.value, function(
                                filterValue,
                                valueIndex
                              ) {
                                return _c(
                                  "v-chip",
                                  {
                                    key: valueIndex,
                                    ref: "selectionChip",
                                    refInFor: true,
                                    style: _vm.chipStyle,
                                    attrs: {
                                      color: "primary",
                                      close: ""
                                    },
                                    on: {
                                      "click:close": function clickClose(
                                        $event
                                      ) {
                                        return _vm.removeFromFilterList(
                                          filterValue,
                                          index,
                                          valueIndex
                                        )
                                      }
                                    }
                                  },
                                  [
                                    filter.type === "date"
                                      ? _c("div", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.convertDashedToDotted(
                                                  filterValue
                                                )
                                              ) +
                                              " "
                                          )
                                        ])
                                      : _c("div", [
                                          _vm._v(
                                            " " + _vm._s(filterValue) + " "
                                          )
                                        ])
                                  ]
                                )
                              }),
                              1
                            )
                          }),
                          _c(
                            "v-chip",
                            {
                              ref: "clearChip",
                              style: _vm.closeChipStyle,
                              attrs: {
                                color: _vm.closeChipColor
                              },
                              on: {
                                click: _vm.clearFilterList
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "closeChipElementText"
                                },
                                [_vm._v(" mdi-close-circle-outline ")]
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.isFilterSelectionOpen
        ? _c("filter-selection", {
            attrs: {
              filterList: _vm.filterList
            }
          })
        : _vm._e(),
      _c("v-divider")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }