var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("confirm-action-dialog", {
    attrs: {
      isDialogActive: _vm.dialog,
      title: _vm.$t("components.template.detail.delete.title"),
      leftText: _vm.$t("components.template.detail.delete.abort"),
      rightText: _vm.$t("components.template.detail.delete.delete"),
      rightColor: "error"
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.dialog = $event
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.dialog = $event
      },
      close: function close($event) {
        _vm.dialog = false
      },
      leftClick: function leftClick($event) {
        _vm.dialog = false
      },
      rightClick: _vm.deleteTemplate
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }