var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "800",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
        transition: "dialog-bottom-transition"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function fn(_ref) {
            var on = _ref.on,
              attrs = _ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        icon: ""
                      }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c(
                    "v-icon",
                    {
                      attrs: {
                        color: "grey lighten-1"
                      }
                    },
                    [_vm._v("mdi-database")]
                  )
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function callback($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        {
          attrs: {
            flat: ""
          }
        },
        [
          _c(
            "v-card-title",
            {
              staticClass: "title pt-4",
              class: _vm.color
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t("components.analytics.AnalyticsDataTableDialog.data")
                  )
                )
              ]),
              _c("v-spacer"),
              _c("v-text-field", {
                attrs: {
                  dense: "",
                  "append-icon": "mdi-magnify",
                  label: _vm.$t(
                    "components.analytics.AnalyticsDataTableDialog.search"
                  ),
                  "single-line": "",
                  "hide-details": "",
                  outlined: ""
                },
                model: {
                  value: _vm.search,
                  callback: function callback($$v) {
                    _vm.search = $$v
                  },
                  expression: "search"
                }
              }),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    icon: ""
                  }
                },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: {
                        color: "grey lighten-1"
                      },
                      on: {
                        click: _vm.close
                      }
                    },
                    [_vm._v("mdi-close")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.data,
              search: _vm.search,
              "items-per-page": 5
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }