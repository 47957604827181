import { render, staticRenderFns } from "./MachineQrCodeCard.vue?vue&type=template&id=135da73c&scoped=true&"
import script from "./MachineQrCodeCard.vue?vue&type=script&lang=ts&"
export * from "./MachineQrCodeCard.vue?vue&type=script&lang=ts&"
import style0 from "./MachineQrCodeCard.vue?vue&type=style&index=0&id=135da73c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "135da73c",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
installComponents(component, {VCard,VCardText,VCombobox})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('135da73c')) {
      api.createRecord('135da73c', component.options)
    } else {
      api.reload('135da73c', component.options)
    }
    module.hot.accept("./MachineQrCodeCard.vue?vue&type=template&id=135da73c&scoped=true&", function () {
      api.rerender('135da73c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/docphant/dashboard/MachineQrCodeCard.vue"
export default component.exports