import { permissionGuard } from "@/auth/permissionAuthGuard";
import DocphantErrorPage from "@/views/docphant/dashboard/DocphantErrorPage.vue";
import ManufacturerCreationPage from "@/views/docphant/dashboard/ManufacturerCreationPage.vue";
import ManuFacturerDetailPage from "@/views/docphant/dashboard/ManufacturerDetailPage.vue";
import ManufacturerListPage from "@/views/docphant/dashboard/ManufacturerListPage.vue";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

export const routes: Array<RouteConfig> = [
  {
    path: "/manufacturer",
    name: "ManufacturerListPage",
    component: ManufacturerListPage
  },
  {
    path: "/manufacturer/create",
    name: "ManufacturerCreationPage",
    component: ManufacturerCreationPage
  },
  {
    path: "/manufacturer/:manufacturerId",
    name: "ManufacturerDetailPage",
    component: ManuFacturerDetailPage
  },
  {
    path: "/error",
    name: "DocphantErrorPage",
    component: DocphantErrorPage
  },
  {
    path: "/",
    name: "ManufacturerListPage",
    component: ManufacturerListPage
  }
];

const router = new VueRouter({
  routes,
  base: "/"
});

router.beforeEach(permissionGuard);

export default router;
