var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("ticket-form", {
        attrs: {
          valid: _vm.isTicketFormValidLocal,
          titleSuggestions: _vm.titleSuggestions,
          hideTitle: _vm.hideTitle,
          hideBody: _vm.hideBody,
          hideAssignees: _vm.hideAssignees,
          hideDue: _vm.hideDue,
          hideTags: _vm.hideTags,
          hideProject: _vm.hideProject
        },
        on: {
          "update:valid": function updateValid($event) {
            _vm.isTicketFormValidLocal = $event
          }
        },
        model: {
          value: _vm.ticket,
          callback: function callback($$v) {
            _vm.ticket = $$v
          },
          expression: "ticket"
        }
      }),
      !_vm.hideProject &&
      _vm.ticket &&
      _vm.ticket.projectId &&
      _vm.selectedProject
        ? _c("custom-field-list-form", {
            key: "customFieldListFormKey" + _vm.ticket.projectId,
            attrs: {
              valid: _vm.isTicketCustomFieldsValidLocal,
              customFieldConfig:
                _vm.selectedProject.configuration.customFieldConfig
            },
            on: {
              "update:valid": function updateValid($event) {
                _vm.isTicketCustomFieldsValidLocal = $event
              }
            },
            model: {
              value: _vm.ticket.values,
              callback: function callback($$v) {
                _vm.$set(_vm.ticket, "values", $$v)
              },
              expression: "ticket.values"
            }
          })
        : _vm._e(),
      !_vm.hideReferences && _vm.ticket
        ? _c("ticket-refs-select", {
            attrs: {
              partnerId: _vm.partnerId
            },
            model: {
              value: _vm.ticket.refs,
              callback: function callback($$v) {
                _vm.$set(_vm.ticket, "refs", $$v)
              },
              expression: "ticket.refs"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }