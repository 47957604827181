


























































































import PartnerBillingInformationDataDialog from "@/components/partner/PartnerBillingInformationDataDialog.vue";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgBillingInformationViewmodelGen, ThgCreateBillingInformationDtoGen } from "@/services/thg/v1/data-contracts";
import { BillingInformationModule } from "@/store/modules/billing-information.store";
import { Component, Prop } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import Card from "../utility/Card.vue";

@Component({
  components: {
    PartnerBillingInformationDataDialog,
    LatestEntriesCardEmpty,
    Card
  }
})
export default class PartnerBillingInformationDataCard extends DarkModeHighlightMixin {
  @Prop({ default: undefined })
  partnerId!: string;

  @Prop({ default: false })
  hasPermissions!: string;

  isLoading = false;

  get isBillingInformation(): boolean {
    return BillingInformationModule.isBillingInformation;
  }

  get billingInformation(): ThgBillingInformationViewmodelGen {
    return BillingInformationModule.partnerBillingInformation;
  }

  async mounted() {
    if (this.hasPermissions) {
      await this.loadBillingInformation();
    } else {
      this.isLoading = false;
    }
  }

  async loadBillingInformation() {
    if (!this.partnerId) {
      return;
    }
    try {
      this.isLoading = true;
      await BillingInformationModule.getPartnerBillingInformation(this.partnerId);
    } catch (e) {
      this.$log.error(e);
    } finally {
      this.isLoading = false;
    }
  }

  async saveBillingDataForPartner(dto: ThgCreateBillingInformationDtoGen) {
    if (!this.partnerId) {
      return;
    }
    try {
      this.isLoading = true;
      await BillingInformationModule.createOrUpdateBillingInformation({
        partnerId: this.partnerId,
        createOrUpdateDto: dto
      });
    } catch (e) {
      this.$log.error(e);
    } finally {
      this.isLoading = false;
    }
  }
}
