



























































































































































































import { requiredRule } from "@/lib/rules/requiredRule";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { MrfiktivPermissionDtoGen as PermissionDto } from "@/services/mrfiktiv/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IAdminUser } from "@/models/user.entity";

@Component({
  components: {}
})
export default class UserAdministrationDetailPermissionsAddDialog extends DarkModeHighlightMixin {
  @Prop()
  selectedUser!: IAdminUser;

  step = 1;
  isLoading = false;

  newPermission: PermissionDto = {
    type: null as any,
    id: "",
    action: [ActionEnum.READ]
  };

  standardPermissions = {
    id: "",
    form: true,
    report: true,
    partner: true,
    settings: true,
    operations: true,
    analytics: true,
    admin: false,
    template: false,
    message: false,
    booking: false,
    share: false,
    vehicle: false,
    document: false,
    sign: false,
    partnerBanking: false,
    billingInformation: false,
    billing: false,
    customer_data: false
  };

  selectedRessource: any;

  actions = Object.keys(ActionEnum).map(k => ActionEnum[k as any]);
  resource = Object.keys(BackendResourceEnum).map(k => BackendResourceEnum[k as any]);

  get loading() {
    return this.isLoading;
  }

  get rules() {
    return [requiredRule()];
  }

  get isDisabled() {
    return !(
      this.newPermission.id &&
      this.newPermission.type &&
      this.newPermission.action &&
      this.newPermission.action.length !== 0
    );
  }

  dialog = false;

  get partners() {
    return PartnerModule.partners;
  }

  resetStandardPermissions() {
    this.standardPermissions = {
      id: "",
      form: true,
      report: true,
      partner: true,
      settings: true,
      operations: true,
      analytics: true,
      admin: false,
      template: false,
      message: false,
      booking: false,
      share: false,
      vehicle: false,
      document: false,
      sign: false,
      partnerBanking: false,
      billingInformation: false,
      billing: false,
      customer_data: false
    };
  }

  resetDialog() {
    this.newPermission = {
      type: null as any,
      id: "",
      action: [ActionEnum.READ]
    };
    this.resetStandardPermissions();

    this.step = 1;
  }

  closeUpdate() {
    this.resetDialog();
    this.dialog = false;
  }

  async addPermissions() {
    //await MachineModule.create(machineCreateStoreDto);
    this.selectedUser?.permission?.push(this.newPermission);

    await this.updatePermissions();
  }

  setReportStandardPermissions() {
    this.step = 2;
    this.standardPermissions = {
      id: "",
      form: true,
      report: true,
      partner: true,
      settings: false,
      operations: true,
      analytics: true,
      admin: false,
      template: false,
      message: true,
      booking: false,
      share: true,
      vehicle: true,
      document: false,
      sign: false,
      partnerBanking: false,
      billingInformation: false,
      billing: false,
      customer_data: false
    };
  }

  setThgStandardPermissions() {
    this.step = 2;
    this.standardPermissions = {
      id: "",
      form: false,
      report: false,
      partner: true,
      settings: false,
      operations: false,
      analytics: true,
      admin: false,
      template: false,
      message: false,
      booking: false,
      share: false,
      vehicle: false,
      document: false,
      sign: false,
      partnerBanking: true,
      billingInformation: true,
      billing: true,
      customer_data: true
    };
  }

  async addPermissionsStandard() {
    //await MachineModule.create(machineCreateStoreDto);
    const newPermission: PermissionDto[] = [];

    if (this.standardPermissions.form) {
      newPermission.push({
        type: BackendResourceEnum.FORM,
        id: this.standardPermissions.id,
        action: [ActionEnum.MANAGE]
      });
    }

    if (this.standardPermissions.report) {
      newPermission.push({
        type: BackendResourceEnum.REPORT,
        id: this.standardPermissions.id,
        action: [ActionEnum.MANAGE]
      });
    }

    if (this.standardPermissions.partner) {
      newPermission.push({
        type: BackendResourceEnum.PARTNER,
        id: this.standardPermissions.id,
        action: [ActionEnum.READ]
      });
    }

    if (this.standardPermissions.settings) {
      newPermission.push({
        type: BackendResourceEnum.SETTING_PARTNER_INTERNAL,
        id: this.standardPermissions.id,
        action: [ActionEnum.MANAGE]
      });
    }

    if (this.standardPermissions.operations) {
      newPermission.push({
        type: BackendResourceEnum.OPERATION,
        id: this.standardPermissions.id,
        action: [ActionEnum.READ]
      });
    }

    if (this.standardPermissions.analytics) {
      newPermission.push({
        type: BackendResourceEnum.ANALYTICS,
        id: this.standardPermissions.id,
        action: [ActionEnum.READ]
      });
    }

    if (this.standardPermissions.admin) {
      newPermission.push({
        type: BackendResourceEnum.SETTING_PARTNER_INTERNAL,
        id: this.standardPermissions.id,
        action: [ActionEnum.MANAGE]
      });

      newPermission.push({
        type: BackendResourceEnum.PARTNER,
        id: this.standardPermissions.id,
        action: [ActionEnum.MANAGE]
      });
    }

    if (this.standardPermissions.template) {
      newPermission.push({
        type: BackendResourceEnum.TEMPLATE,
        id: this.standardPermissions.id,
        action: [ActionEnum.MANAGE]
      });
    }

    if (this.standardPermissions.message) {
      newPermission.push({
        type: BackendResourceEnum.MESSAGE,
        id: this.standardPermissions.id,
        action: [ActionEnum.MANAGE]
      });
    }

    if (this.standardPermissions.share) {
      newPermission.push({
        type: BackendResourceEnum.SHARED_CONTENT,
        id: this.standardPermissions.id,
        action: [ActionEnum.MANAGE]
      });
    }

    if (this.standardPermissions.booking) {
      newPermission.push({
        type: BackendResourceEnum.BOOKING,
        id: this.standardPermissions.id,
        action: [ActionEnum.MANAGE]
      });
    }

    if (this.standardPermissions.vehicle) {
      newPermission.push({
        type: BackendResourceEnum.VEHICLE,
        id: this.standardPermissions.id,
        action: [ActionEnum.MANAGE]
      });
    }

    if (this.standardPermissions.document) {
      newPermission.push({
        type: BackendResourceEnum.DOCUMENT,
        id: this.standardPermissions.id,
        action: [ActionEnum.MANAGE]
      });
    }

    if (this.standardPermissions.sign) {
      newPermission.push({
        type: BackendResourceEnum.SIGN,
        id: this.standardPermissions.id,
        action: [ActionEnum.MANAGE]
      });
    }

    if (this.standardPermissions.partnerBanking) {
      newPermission.push({
        type: BackendResourceEnum.PARTNER_BANKING as any,
        id: this.standardPermissions.id,
        action: [ActionEnum.UPDATE, ActionEnum.READ]
      });
    }

    if (this.standardPermissions.billingInformation) {
      newPermission.push({
        type: BackendResourceEnum.BILLING_INFORMATION as any,
        id: this.standardPermissions.id,
        action: [ActionEnum.UPDATE, ActionEnum.READ]
      });
    }

    if (this.standardPermissions.billing) {
      newPermission.push({
        type: BackendResourceEnum.BILLING as any,
        id: this.standardPermissions.id,
        action: [ActionEnum.READ]
      });
    }

    if (this.standardPermissions.customer_data) {
      newPermission.push({
        type: BackendResourceEnum.CUSTOMER_DATA,
        id: this.standardPermissions.id,
        action: [ActionEnum.READ]
      });
    }

    if (!this.selectedUser?.permission) {
      if (!this.selectedUser) return;
      this.selectedUser.permission = newPermission;
    } else {
      this.selectedUser?.permission.push(...newPermission);
    }

    await this.updatePermissions();
  }

  private async updatePermissions() {
    this.isLoading = true;
    if (!this.selectedUser) return;
    const updatedUser = await this.selectedUser.addPermissionsByUser();

    if (!updatedUser) {
      Vue.$toast.error("Berechtigungen konnte nicht bearbeitet werden.");
    } else {
      Vue.$toast.success("Berechtigungen hinzugefügt.");
    }
    this.resetDialog();
    this.dialog = false;
    this.isLoading = false;
  }
}
