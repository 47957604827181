












import { Component, Vue, Prop } from "vue-property-decorator";
import ManufacturerDeleteDialog from "@/components/docphant/dashboard/ManufacturerDeleteDialog.vue";
import ManufacturerUpdateDialog from "@/components/docphant/dashboard/ManufacturerUpdateDialog.vue";

@Component({
  components: {
    ManufacturerDeleteDialog,
    ManufacturerUpdateDialog
  }
})
export default class DocphantErrorCard extends Vue {
  @Prop({})
  errorMessage!: string;
}
