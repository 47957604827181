import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { PartnerLinkerService } from "../v1/PartnerLinkerService";
import {
  MrfiktivCreatePartnerLinkDtoGen,
  MrfiktivPartnerLinkControllerGetParamsGen,
  MrfiktivUpdatePartnerLinkDtoGen
} from "../v1/data-contracts";

/**
 * The service to communicate with the partner backend.
 */
class PartnerLinkerServiceService {
  proxy: PartnerLinkerService;

  /**
   * Construct service
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider) {
    this.proxy = new PartnerLinkerService(mrfiktivHttpClientProvider.client());
  }

  /**
   * Create
   */
  async create(partnerId: string, data: MrfiktivCreatePartnerLinkDtoGen) {
    return (await this.proxy.partnerLinkControllerCreate(partnerId, data)).data;
  }

  /**
   * Get all
   */
  async getAll(data: MrfiktivPartnerLinkControllerGetParamsGen) {
    return (await this.proxy.partnerLinkControllerGet(data)).data;
  }

  /**
   * Get
   */
  async getOne(partnerId: string, id: string) {
    return (await this.proxy.partnerLinkControllerGetOne(partnerId, id)).data;
  }

  /**
   * Update
   */
  async update(partnerId: string, id: string, data: MrfiktivUpdatePartnerLinkDtoGen) {
    return (await this.proxy.partnerLinkControllerUpdate(partnerId, id, data)).data;
  }

  /**
   * Delete
   */
  async delete(partnerId: string, id: string) {
    return (await this.proxy.partnerLinkControllerDelete(partnerId, id)).data;
  }
}

/**
 * Communicate with ticket API
 */
export default new PartnerLinkerServiceService(new MrfiktivHttpClientProvider());
