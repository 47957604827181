
















import UserAdministrationDetail from "@/components/partner/UserAdministrationDetail.vue";
import TheLayoutPortalSplit from "@/layouts/TheLayoutPortalSplit.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { Component } from "vue-property-decorator";
import UserAdministrationListPaginated from "@/components/partner/UserAdministrationListPaginated.vue";
import { IAdminUser } from "@/models/user.entity";
import { AdminUserPaginationModule } from "@/store/modules/admin-user-pagination.store";

@Component({
  components: {
    TheLayoutPortalSplit,
    UserAdministrationListPaginated,
    UserAdministrationDetail
  }
})
export default class UserAdministration extends PartnerFallbackMixin {
  loadingSelectedUser = false;

  selectedUser: IAdminUser | null = null;

  get users() {
    return AdminUserPaginationModule.paginationList;
  }

  /**
   * Returns information on if the user and all the data that is associated to it but saved seperately outside of the user doc is loaded
   */
  get _loadingSelectedUserOrBanking() {
    return this.loadingSelectedUser || this.bankingLoading;
  }
  set _loadingSelectedUserOrBanking(loadingSelectedUser: boolean) {
    this.loadingSelectedUser = loadingSelectedUser;
  }

  /**
   * Information of banking is loaded (banking is loaded seperately from user)
   * if we do not await this we may have inconsistencies after switching back and forth between user documents in the list quickly
   * when banking loading is true, the elements in the list of the user-administration-list are disabled, so you can not switch users until everything is done loading
   */
  bankingLoading = false;
  setBankingLoading(bankingLoading: boolean) {
    this.bankingLoading = bankingLoading;
  }

  setLoadingSelectedUser(isLoading: any) {
    this.loadingSelectedUser = isLoading;
  }
}
