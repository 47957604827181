





















import { handleError } from "@/lib/utility/handleError";
import { ThgFaqViewModelGen, ThgUpdateFaqDtoGen } from "@/services/thg/v1/data-contracts";
import { FaqModule } from "@/store/modules/faq.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";

@Component({ components: { ConfirmActionDialog } })
export default class PartnerFaqEditDialog extends Vue {
  isDialogActive = false;
  isLoading = false;

  @Prop()
  faq!: ThgFaqViewModelGen;

  dto: ThgUpdateFaqDtoGen = {
    answer: "",
    question: ""
  };

  init() {
    this.dto.answer = this.faq.answer;
    this.dto.question = this.faq.question;
    this.isDialogActive = true;
  }

  async update() {
    try {
      this.isLoading = true;
      const updated = await FaqModule.update({ faqId: this.faq.id, partnerId: this.faq.partnerId, data: this.dto });
      this.$emit("update", updated);
    } catch (e) {
      handleError(e);
    } finally {
      this.isDialogActive = false;
      this.isLoading = false;
    }
  }
}
