
































import PartnerCreateRequirementsList from "@/components/partner/PartnerCreateRequirementsList.vue";
import PartnerDetailImage from "@/components/partner/PartnerDetailImage.vue";
import { handleError } from "@/lib/utility/handleError";
import { Type } from "@/lib/utility/type";
import { PartnerUpdateEntity } from "@/models/partnerUpdateEntity";
import { MrfiktivCreatePartnerDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    PartnerDetailImage,
    PartnerCreateRequirementsList
  }
})
export default class PartnerCreateOrUpdateSubmitCard extends Vue {
  @Prop()
  partnerDto!: MrfiktivCreatePartnerDtoGen | PartnerUpdateEntity | any;

  @Prop({ default: false })
  isUpdatePartner!: boolean;

  isLoading = false;

  async createPartner() {
    this.isLoading = true;

    try {
      const createdPartner = await PartnerModule.createPartner(this.partnerDto as MrfiktivCreatePartnerDtoGen);
      Vue.$toast.success("Partner wurde erfolgreich angelegt.");
      this.$emit("closeDialog", true);
      this.$router.push({ name: "PartnerDetailView", params: { partnerId: createdPartner._id } });
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoading = false;
    }
  }

  async updatePartner() {
    this.isLoading = true;

    try {
      const updatePartner = await PartnerModule.updatePartner({
        id: PartnerModule.partner._id,
        partnerUpdateEntity: this.partnerDto as PartnerUpdateEntity
      });
      Vue.$toast.success("Partner erfolgreich geändert.");
      this.$emit("closeDialog", true);

      this.$router.push({ name: "PartnerDetailView", params: { partnerId: updatePartner._id } });
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoading = false;
    }
  }

  get isValidCreate() {
    return (
      this.isValidGeneralInformations() &&
      this.isValidCompanyUserName() &&
      this.isValidLandingpageSettings() &&
      this.isValidDomains()
    );
  }

  get isValidUpdate() {
    return this.isValidGeneralInformations() && this.isValidLandingpageSettings() && this.isValidDomains();
  }

  get requirementList() {
    const reqs = [
      {
        test: this.isValidGeneralInformations(),
        text: "Generelle Informationen."
      },
      {
        test: this.isValidLandingpageSettings(),
        text: "Landingpage Einstellungen."
      },
      {
        test: this.isValidDomains(),
        text: "Domains mit DNS."
      }
    ];
    return reqs;
  }

  isValidDomains() {
    return this.partnerDto.settings.urls.length > 0;
  }

  isValidGeneralInformations() {
    return (
      !Type.isBlankString(this.partnerDto.companyName) &&
      !Type.isBlankString(this.partnerDto.contact.email) &&
      !Type.isBlankString(this.partnerDto.address.street) &&
      !Type.isBlankString(this.partnerDto.address.zip) &&
      !Type.isBlankString(this.partnerDto.address.city) &&
      !(this.partnerDto.address.geo.lat === 0) &&
      !(this.partnerDto.address.geo.lng === 0)
    );
  }

  isValidCompanyUserName() {
    return !Type.isBlankString(this.partnerDto.companyUsername);
  }

  isValidLandingpageSettings() {
    return !Type.isBlankString(this.partnerDto.settings.contact.email);
  }

  validate() {
    this.$emit("validate");
  }
}
