import { render, staticRenderFns } from "./DocphantUnauthorized.vue?vue&type=template&id=15c75834&scoped=true&"
import script from "./DocphantUnauthorized.vue?vue&type=script&lang=ts&"
export * from "./DocphantUnauthorized.vue?vue&type=script&lang=ts&"
import style0 from "./DocphantUnauthorized.vue?vue&type=style&index=0&id=15c75834&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15c75834",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('15c75834')) {
      api.createRecord('15c75834', component.options)
    } else {
      api.reload('15c75834', component.options)
    }
    module.hot.accept("./DocphantUnauthorized.vue?vue&type=template&id=15c75834&scoped=true&", function () {
      api.rerender('15c75834', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/docphant/dashboard/DocphantUnauthorized.vue"
export default component.exports