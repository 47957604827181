var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "card",
    {
      attrs: {
        outlined: false,
        hideDivider: true,
        title: _vm.faq ? "FAQ" : "",
        subtitle: _vm.faq ? _vm.timestamp : "",
        loading: _vm.isDetailLoading
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function fn() {
            return [
              !_vm.$route.params.faqId
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        to: "/partners/"
                          .concat(_vm.faq.partnerId, "/faq/")
                          .concat(_vm.faq.id),
                        icon: ""
                      }
                    },
                    [_c("v-icon", [_vm._v(" mdi-open-in-new ")])],
                    1
                  )
                : _vm._e(),
              _vm.faq
                ? _c("partner-faq-toggle-active", {
                    staticClass: "mr-5",
                    attrs: {
                      faq: _vm.faq
                    },
                    on: {
                      update: _vm.onUpdate
                    }
                  })
                : _vm._e(),
              _vm.faq
                ? _c("partner-faq-edit-dialog", {
                    attrs: {
                      faq: _vm.faq
                    },
                    on: {
                      update: _vm.onUpdate
                    }
                  })
                : _vm._e(),
              _vm.faq
                ? _c("confirm-delete-dialog", {
                    attrs: {
                      isLoading: _vm.isDeleteLoading
                    },
                    on: {
                      delete: _vm.deleteFaq
                    },
                    model: {
                      value: _vm.isDeleteDialog,
                      callback: function callback($$v) {
                        _vm.isDeleteDialog = $$v
                      },
                      expression: "isDeleteDialog"
                    }
                  })
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.isDetailLoading
        ? _c(
            "div",
            [
              _c("v-skeleton-loader", {
                attrs: {
                  type: "list-item-two-line,list-item-two-line"
                }
              })
            ],
            1
          )
        : _vm.faq
        ? _c(
            "div",
            [
              _c("v-text-field", {
                attrs: {
                  readonly: "",
                  outlined: "",
                  value: _vm.faq.question,
                  label: "Q:"
                }
              }),
              _c("v-textarea", {
                attrs: {
                  readonly: "",
                  outlined: "",
                  value: _vm.faq.answer,
                  label: "A:"
                }
              })
            ],
            1
          )
        : _c(
            "div",
            [
              _c("latest-entries-card-empty", {
                staticClass: "ma-10"
              })
            ],
            1
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }