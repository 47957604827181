var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-row",
    {
      staticClass: "justify-center align-center"
    },
    [
      _c(
        "v-toolbar",
        {
          staticClass: "mb-2 d-md-none",
          attrs: {
            dark: "",
            prominent: "",
            src: _vm.toolbarSrc
          },
          scopedSlots: _vm._u([
            {
              key: "img",
              fn: function fn(_ref) {
                var props = _ref.props
                return [
                  _c(
                    "v-img",
                    _vm._b(
                      {
                        attrs: {
                          gradient:
                            "to top right, rgba(27, 33, 36, .8), rgba(134, 134, 134, 1.0)"
                        }
                      },
                      "v-img",
                      props,
                      false
                    )
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "v-toolbar-title",
            {
              staticClass: "text-wrap",
              attrs: {
                color: "black"
              }
            },
            [_vm._v("Jetzt schnell zum kostengünstigen Angebot")]
          ),
          _c("v-spacer")
        ],
        1
      ),
      _c(
        "v-timeline",
        {
          staticClass: "d-none d-sm-block",
          attrs: {
            dense: ""
          }
        },
        _vm._l(_vm.items, function(item, i) {
          return _c(
            "v-timeline-item",
            {
              key: i,
              staticClass: "white--text mb-12",
              attrs: {
                "fill-dot": "",
                color: _vm.color
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "icon",
                    fn: function fn() {
                      return [_c("span", [_vm._v(_vm._s(item.number))])]
                    },
                    proxy: true
                  }
                ],
                null,
                true
              )
            },
            [
              _c("FormHelpCard", {
                attrs: {
                  item: item
                }
              })
            ],
            1
          )
        }),
        1
      ),
      _vm._l(_vm.items, function(item, i) {
        return _c(
          "v-container",
          {
            key: i,
            staticClass: "d-sm-none"
          },
          [
            _c("FormHelpCard", {
              attrs: {
                item: item,
                number: i + 1
              }
            })
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }