var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-simple",
    {
      attrs: {
        title: "Deine Nachricht wurde erfolgreich abgeschickt"
      }
    },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            {
              attrs: {
                align: "center",
                justify: "center"
              }
            },
            [
              _c("img", {
                staticClass: "overlay",
                attrs: {
                  src: require("@/assets/partner_contact_success.svg")
                }
              })
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }