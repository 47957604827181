





















































import { Component, Vue, Prop } from "vue-property-decorator";
import { IBaseMachineViewmodel } from "@/lib/interfaces/Docphant/machine.viewmodel.interface";
import { MachineStatusDisplayNames } from "@/store/enum/docphant/machine.status.enum";
import { MachineDisplayNames } from "@/store/enum/docphant/machine.enum";
import { getDisplayNameFromEnumValue } from "@/lib/EnumDisplayNameHelpers";
@Component({
  components: {}
})
export default class MachineCard extends Vue {
  @Prop({})
  machine!: IBaseMachineViewmodel;

  get status() {
    return getDisplayNameFromEnumValue(MachineStatusDisplayNames, this.machine.status);
  }
  get type() {
    return getDisplayNameFromEnumValue(MachineDisplayNames, this.machine.machineType);
  }

  constructor() {
    super();
  }
}
