



















































































import { IManufacturerDto } from "@/lib/interfaces/Docphant/manufacturer.dto.interface";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { ManufacturerModule } from "@/store/modules/manufacturer";
import { Component, Vue } from "vue-property-decorator";
import { requiredRule } from "@/lib/rules/requiredRule";

@Component({
  components: {}
})
export default class ManufacturerCreationCard extends Vue {
  manufacturer: IManufacturerDto = {
    name: "",
    address: {
      street: "",
      city: "",
      zip: "",
      state: "",
      countryCode: CountryCodeEnum.germany
    },
    contact: {
      email: "",
      phone: ""
    },
    logoUrl: ""
  };
  get rules() {
    return [requiredRule()];
  }

  async createManufacturer() {
    await ManufacturerModule.createManufacturer(this.manufacturer);
    const manufacturer = ManufacturerModule.getCurrentManufacturer as any;
    this.$router.push({ name: "ManufacturerDetailPage", params: { manufacturerId: manufacturer.id } });
  }
}
