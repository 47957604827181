















































import CustomerContactCard from "@/components/partner/CustomerContactCard.vue";
import UserAdministrationDetailPermissionsAddDialog from "@/components/partner/UserAdministrationDetailPermissionsAddDialog.vue";
import UserAdministrationDetailPermissionsRemoveDialog from "@/components/partner/UserAdministrationDetailPermissionsRemoveDialog.vue";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { MrfiktivPermissionDtoGen as PermissionDto } from "@/services/mrfiktiv/v1/data-contracts";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import UserPermissionAddDialog from "./UserPermissionAddDialog.vue";
import { IAdminUser } from "@/models/user.entity";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";

@Component({
  components: {
    CustomerContactCard,
    UserAdministrationDetailPermissionsAddDialog,
    UserPermissionAddDialog,
    UserAdministrationDetailPermissionsRemoveDialog,
    Card
  }
})
export default class UserAdministrationDetailPermissions extends DarkModeHighlightMixin {
  @Prop({ default: false })
  loading!: boolean;

  @Prop()
  selectedUser!: IAdminUser;

  @Prop()
  permissions!: PermissionDto[];

  selectedItem = 0;

  get ActionEnum() {
    return ActionEnum;
  }

  get ResourceEnum() {
    return ResourceEnum;
  }

  removePermission(permission: PermissionDto) {
    const index = this.permissions.indexOf(permission);
    if (index > -1) {
      this.permissions.splice(index, 1);
    }
    this.selectedUser?.removePermission([permission]);
  }

  getRessourceName(permission: PermissionDto): string {
    const partner = PartnerModule.partnersMap.get(permission.id);

    if (partner) {
      return partner?.companyUsername;
    }

    return permission.id;
  }
}
