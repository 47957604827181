







































































import { CronJobEnum } from "@/lib/enum/CronJob.enum";
import { OperationStatusEnum } from "@/lib/enum/OperationStatus.enum";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IOperation } from "@/models/operation.entity";
import { ThgNamedUrlViewModelGen, ThgOperationViewModelGen } from "@/services/thg/v1/data-contracts";
import { BillingBatchModule } from "@/store/modules/billing-batch.store";
import { BillingModule } from "@/store/modules/billing.store";
import { Component, Prop } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import Card from "../utility/Card.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import MHeader, { IAction, IBreadcrumb } from "../utility/mmmint/MHeader.vue";
import { $t } from "@/lib/utility/t";
import Tooltip from "../utility/tooltip.vue";
import RefsSelected from "../utility/RefsSelected.vue";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import Debug from "@/components/utility/Debug.vue";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";

@Component({ components: { LatestEntriesCardEmpty, Card, ConfirmActionDialog, MHeader, Tooltip, RefsSelected, Debug } })
export default class OperationDetail extends DarkModeHighlightMixin {
  @Prop()
  operation!: IOperation;

  @Prop({ default: false })
  hideDetailButton!: boolean;

  @Prop({ default: false })
  hideBreadCrumbs!: boolean;

  readonly GO_TO_DETAIL = "detail";
  readonly PAUSE = "pause";
  readonly REFRESH = "refresh";

  /**
   * List of operations where something can be downloaded
   */
  readonly downloadableOperations = [CronJobEnum.BILLING_THG_CSV, CronJobEnum.CREDITOR_CSV];

  /**
   * List of states in which operation is done
   */
  readonly operationFinalStates = [
    OperationStatusEnum.SUCCEEDED,
    OperationStatusEnum.FAILED,
    OperationStatusEnum.PARTIAL_COMPLETED,
    OperationStatusEnum.CANCELLED
  ];

  /**
   * is operation loading
   */
  operationLoading = false;

  /**
   * Is a url for a download loading
   */
  downloadUrlLoading = true;

  /**
   * The url to download with
   */
  downloadUrl = "";

  /**
   * The Timeout that refreshes the operation periodically
   */
  periodicRefreshTimeout: any = undefined;

  /**
   * The time in milliseconds when the operation will be refreshed again
   */
  nextRefresh = Date.now();

  /**
   * The time between refreshes (is increased in every refresh iteration)
   */
  timeout = 0;

  /**
   * The amount of time that is left until the next refresh. Calculated as difference bettween nextRefresh and current time
   */
  timeLeft = 0;

  /**
   * The interval in which the timeLeft is refreshed
   */
  timeLeftInterval: any = undefined;

  /**
   * If this flag is failed, it will not be attempted to load in following refreshs
   */
  loadingFailed = false;

  /**
   * Opening status of dialog to cancel operation
   */
  isCancelDialogActive = false;

  /**
   * Status of cancellation call
   */
  isCancellationLoading = false;

  /**
   * Request to get results for BILLING_THG_ALL_CSV operation
   */
  loadingThgAllCsvResults = false;

  /**
   * results of BILLING_THG_ALL_CSV operation
   */
  multiUrls: ThgNamedUrlViewModelGen[] = [];

  get breadCrumbs(): IBreadcrumb[] | undefined {
    if (this.hideBreadCrumbs) {
      return undefined;
    }

    const breadCrumbs: IBreadcrumb[] = [];
    if (this.operation.partnerId) {
      breadCrumbs.push({
        text: $t("components.operation.OperationTable.title"),
        to: { name: "PartnerOperations", params: { partnerId: this.operation.partnerId } },
        exact: true
      });
    } else {
      breadCrumbs.push({
        text: $t("components.operation.OperationTable.title"),
        to: { name: "ThgOperations" },
        exact: true
      });
    }
    breadCrumbs.push({
      text: this.operation.type,
      to: { name: "OperationPartnerView" },
      exact: true
    });

    return breadCrumbs;
  }

  get subtitle() {
    if (this.operation.creatorId && this.getUserNameForId(this.operation.creatorId)) {
      return $t("createdOnBy", {
        date: simpleDoubleDigitDate(this.operation.timestamp.created),
        name: this.getUserNameForId(this.operation.creatorId)
      });
    }

    return $t("createdOn", { date: simpleDoubleDigitDate(this.operation.timestamp.created) });
  }

  getUserNameForId(id: string) {
    const user = PartnerUserModule.maps.id.get(id)[0];

    let name = "";
    if (user) {
      name = `${user.firstName} ${user.lastName}`;
    }

    return name;
  }

  get parentOperation() {
    return this.operation.refs.find(ref => ref.refType === BackendResourceEnum.OPERATION)?.refId;
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    if (this.isOperationDownloadable) {
      actions.push({
        text: $t("download"),
        key: "download",
        exec: this.openCsv
      });
    }
    if (this.getShowCsvResults(this.operation)) {
      if (!this.multiUrls.length) {
        actions.push({
          text: $t("download"),
          key: "download",
          exec: this.loadThgAllCsvResults
        });
      } else {
        for (const url of this.multiUrls) {
          actions.push({
            text: `${$t("download")} ${url.name}`,
            key: "download" + url.name,
            exec: () => this.openCsvByLink(url.url)
          });
        }
      }
    }

    if (this.operation.type === CronJobEnum.WEBHOOK) {
      actions.push({
        text: $t("common.verbs.restart"),
        key: "restart",
        exec: () =>
          this.operation
            .restartPartnerOperation()
            .then((operation: IOperation) => {
              this.$toast.success(`👍 ${$t("clickToOpen")}`, {
                onClick: () => {
                  new GoToHelper(this.$router).goToOperationDetail(operation.id, operation.partnerId, false);
                }
              });
            })
            .catch(handleError)
      });
    }

    if (!this.hideDetailButton) {
      actions.push({
        text: $t("project.ticket.actions.openInNewTab"),
        key: this.GO_TO_DETAIL
      });
    }

    if (!this.isOperationFinished) {
      actions.push({
        text: $t("common.verbs.refresh"),
        key: this.REFRESH
      });
    }

    if (!this.isOperationFinished && !this.isCancellationRequested) {
      actions.push({
        text: String(this.$t("common.verbs.cancel")),
        key: this.PAUSE
      });
    }

    return actions;
  }

  get chips(): IAction[] {
    const chips: IAction[] = [];

    if (this.operation.status) {
      if (!this.isOperationFinished && this.isCancellationRequested) {
        chips.push({
          text: this.operation.status + " (" + $t("components.operation.OperationDetail.cancellationRequested") + ")",
          key: this.operation.status,
          color: "primary"
        });
      } else if (this.operation.status === OperationStatusEnum.FAILED) {
        chips.push({
          text: this.operation.status,
          key: this.operation.status,
          color: "error"
        });
      } else if (this.operation.status === OperationStatusEnum.CANCELLED) {
        chips.push({
          text: this.operation.status,
          key: this.operation.status,
          color: "grey"
        });
      } else if (this.operation.status === OperationStatusEnum.SUCCEEDED) {
        chips.push({
          text: this.operation.status,
          key: this.operation.status,
          color: "success"
        });
      } else {
        chips.push({
          text: this.operation.status,
          key: this.operation.status,
          color: "success"
        });
      }
    }

    const parentOperation = this.parentOperation;
    if (parentOperation) {
      chips.push({
        text: `${$t("common.nouns.attempt")} #${this.operation.operationRefs.length + 1}`,
        key: parentOperation,
        color: "primary",
        exec: () => new GoToHelper(this.$router).goToOperationDetail(parentOperation, this.operation.partnerId, true)
      });
    }

    return chips;
  }

  get isCancellationRequested() {
    return this.operation?.isCanceled;
  }

  /**
   * Checks if the operation has something to be downloaded
   */
  get isOperationDownloadable() {
    return (
      this.downloadableOperations.includes((this.operation?.type as CronJobEnum) || "") &&
      this.operation?.status === OperationStatusEnum.SUCCEEDED
    );
  }

  /**
   * Check if the operation is finished
   */
  get isOperationFinished() {
    return this.operationFinalStates.includes((this.operation?.status || "") as OperationStatusEnum);
  }

  processAction(action: IAction) {
    if (action.exec) {
      action.exec();
      return;
    }
    if (action.key === this.GO_TO_DETAIL) {
      new GoToHelper(this.$router).goToOperationDetail(this.operation.id, this.operation.partnerId, true);
    } else if (action.key === this.PAUSE) {
      this.isCancelDialogActive = true;
    } else if (action.key === this.REFRESH) {
      this.startAll();
    }
  }

  /**
   * Determines if CSV Operations are shown which is the case if the operation is succeeded and of type BILLING_THG_ALL_CSV
   * @param operation
   */
  getShowCsvResults(operation: ThgOperationViewModelGen) {
    return (
      operation &&
      operation.type === CronJobEnum.BILLING_THG_ALL_CSV &&
      operation.status === OperationStatusEnum.SUCCEEDED
    );
  }

  /**
   * Checks if a value is an object
   */
  isObject(value: any) {
    return value && typeof value === "object" && value.constructor === Object;
  }

  /**
   * Kicks off the whole loading and refresh process
   */
  async mounted() {
    await this.startAll();
  }

  /**
   * Loads the operation, resets timers, starts periodic executions
   */
  async startAll() {
    // await this.loadOperation();
    await this.loadDownloadUrl();
    this.timeout = 0;
    this.nextRefresh = Date.now();
    this.reStartTimeLeftInterval();
    await this.setNextPeriodicRefresh();
  }

  /**
   * Cancels operation
   */
  async cancel() {
    this.isCancellationLoading = true;
    await this.operation.cancel();
    this.isCancellationLoading = false;
    this.isCancelDialogActive = false;
  }

  /**
   * Restarts the interval that calculates the timeLeft for the v-progress component
   */
  reStartTimeLeftInterval() {
    clearInterval(this.timeLeftInterval);

    this.timeLeftInterval = setInterval(() => {
      this.timeLeft = Math.floor((this.nextRefresh - Date.now()) / 1000);

      if (this.timeLeft < -2) {
        clearInterval(this.timeLeftInterval);
      }
    }, 1000);
  }

  /**
   * Sets a timeout after which the operation is loaded
   */
  async setNextPeriodicRefresh() {
    if (this.isOperationFinished || this.loadingFailed) {
      this.timeLeft = 0;
      return;
    }

    this.timeout += 5000;
    const currentMilliseconds = Date.now();
    this.nextRefresh = currentMilliseconds + this.timeout;

    this.periodicRefreshTimeout = setTimeout(async () => {
      if (this.$route.params.operationId) {
        await this.loadOperation();
        await this.loadDownloadUrl();
        this.setNextPeriodicRefresh();
        this.reStartTimeLeftInterval();
      }
    }, this.nextRefresh - currentMilliseconds);
  }

  /**
   * loads the operation (as a normal/ partner operation) from the backend using the route parameters
   */
  async loadOperation() {
    this.operationLoading = true;
    await this.operation.fetch();
    this.operationLoading = false;
  }

  /**
   * Loads the download url for the operation
   */
  async loadDownloadUrl() {
    if (this.isOperationDownloadable && this.operation) {
      try {
        this.downloadUrlLoading = true;
        const url = (await BillingModule.getCsvAccess(this.operation.id)).url;
        this.downloadUrl = url;
      } catch (e) {
        this.$log.error(e);
        this.$toast((e as any).message);
      } finally {
        this.downloadUrlLoading = false;
      }
    }
  }

  /**
   * Opens the download url in a new tab, which starts the download
   */
  async openCsv() {
    window.open(this.downloadUrl, "_blank");
  }

  /**
   * Opens the passed download url in a new tab, which starts the download
   */
  async openCsvByLink(downloadUrl: string) {
    window.open(downloadUrl, "_blank");
  }

  /**
   * Request to get results for BILLING_THG_ALL_CSV operation
   */
  async loadThgAllCsvResults() {
    try {
      this.loadingThgAllCsvResults = true;
      this.multiUrls.splice(0, this.multiUrls.length, ...(await BillingBatchModule.getBillingCsv(this.operation.id)));
    } catch (e) {
      handleError(e);
    } finally {
      this.loadingThgAllCsvResults = false;
    }
  }
}
