































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IAdminUser } from "@/models/user.entity";
import { MrfiktivPermissionDtoGen as PermissionDto } from "@/services/mrfiktiv/v1/data-contracts";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class UserAdministrationDetailPermissionsRemoveDialog extends DarkModeHighlightMixin {
  @Prop()
  permissions!: PermissionDto[];

  @Prop()
  permissionToDelete!: PermissionDto;

  dialog = false;

  @Prop({ default: false })
  isLoading = false;

  @Prop()
  selectedUser!: IAdminUser;

  close() {
    this.dialog = false;
  }

  get loading() {
    return this.isLoading;
  }

  async removePermission(permission: PermissionDto) {
    this.isLoading = true;

    const index = this.permissions.indexOf(permission);
    if (index > -1) {
      this.permissions.splice(index, 1);
    }
    await this.selectedUser?.removePermission([permission]);

    this.dialog = false;
    this.isLoading = false;
  }
}
