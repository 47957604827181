var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-simple",
    [
      _c(
        "table-wrapper",
        {
          attrs: {
            title: _vm.$t("components.partner.PermissionTable.title"),
            controlElements: _vm.controlElements,
            headers: _vm.headers,
            allItems: _vm.permissions,
            loading: _vm.isLoadingAll || _vm.isLoading
          },
          scopedSlots: _vm._u([
            {
              key: "item.action",
              fn: function fn(_ref) {
                var item = _ref.item
                return _vm._l(item.action, function(a, i) {
                  return _c(
                    "v-chip",
                    {
                      key: i,
                      staticClass: "ma-2",
                      attrs: {
                        label: ""
                      }
                    },
                    [_vm._v(" " + _vm._s(a) + " ")]
                  )
                })
              }
            },
            {
              key: "item.type",
              fn: function fn(_ref2) {
                var item = _ref2.item
                return [
                  _c(
                    "tooltip",
                    {
                      attrs: {
                        text: item.type
                      }
                    },
                    [
                      _c(
                        "v-chip",
                        {
                          staticClass: "ma-2",
                          attrs: {
                            label: ""
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("BackendResourceEnum.".concat(item.type))
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "item.id",
              fn: function fn(_ref3) {
                var _vm$getPartnerById

                var item = _ref3.item
                return [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "PartnerDetailView",
                          params: {
                            partnerId: item.id
                          }
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            ((_vm$getPartnerById = _vm.getPartnerById(
                              item.id
                            )) === null || _vm$getPartnerById === void 0
                              ? void 0
                              : _vm$getPartnerById.companyUsername) || item.id
                          ) +
                          " "
                      )
                    ]
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "template",
            {
              slot: "headerActions"
            },
            [_vm._v(" " + _vm._s(_vm.selectedUser.userName) + " ")]
          ),
          _c(
            "template",
            {
              slot: "bodyActions"
            },
            [
              !_vm.isConfirm
                ? _c(
                    "tooltip",
                    {
                      attrs: {
                        text: "Achtung, Bestätigung nicht erforderlich!"
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "ma-2",
                          attrs: {
                            "x-large": "",
                            color: "warning"
                          }
                        },
                        [_vm._v("mdi-alert")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-checkbox", {
                staticClass: "mr-2",
                attrs: {
                  label: "Bestätigung"
                },
                model: {
                  value: _vm.isConfirm,
                  callback: function callback($$v) {
                    _vm.isConfirm = $$v
                  },
                  expression: "isConfirm"
                }
              }),
              _c("user-administration-detail-permissions-add-dialog", {
                attrs: {
                  selectedUser: _vm.selectedUser
                }
              }),
              _c("user-permission-add-dialog", {
                attrs: {
                  selectedUser: _vm.selectedUser
                }
              })
            ],
            1
          )
        ],
        2
      ),
      _c("confirm-action-dialog", {
        attrs: {
          isDialogActive: _vm.isDeleteDialogActive,
          title: _vm.$t("sign.DocumentTable.confirmDeleteTitle"),
          subtitle: _vm.$t("sign.DocumentTable.confirmDeleteSubtitle"),
          leftLoading: _vm.isDeleteDialogLoading,
          rightLoading: _vm.isDeleteDialogLoading
        },
        on: {
          "update:isDialogActive": function updateIsDialogActive($event) {
            _vm.isDeleteDialogActive = $event
          },
          "update:is-dialog-active": function updateIsDialogActive($event) {
            _vm.isDeleteDialogActive = $event
          },
          leftClick: function leftClick($event) {
            _vm.isDeleteDialogActive = false
          },
          rightClick: _vm.deleteDocument
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }