import { render, staticRenderFns } from "./PartnerDetailCard.vue?vue&type=template&id=3b7cc93c&scoped=true&"
import script from "./PartnerDetailCard.vue?vue&type=script&lang=ts&"
export * from "./PartnerDetailCard.vue?vue&type=script&lang=ts&"
import style0 from "./PartnerDetailCard.vue?vue&type=style&index=0&id=3b7cc93c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b7cc93c",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCard,VCardSubtitle,VCardTitle,VDivider,VSkeletonLoader,VTab,VTabItem,VTabs,VTabsItems})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Resize from 'vuetify/lib/directives/resize'
installDirectives(component, {Resize})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3b7cc93c')) {
      api.createRecord('3b7cc93c', component.options)
    } else {
      api.reload('3b7cc93c', component.options)
    }
    module.hot.accept("./PartnerDetailCard.vue?vue&type=template&id=3b7cc93c&scoped=true&", function () {
      api.rerender('3b7cc93c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/partner/PartnerDetailCard.vue"
export default component.exports