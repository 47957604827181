




















import { requiredRule } from "@/lib/rules/requiredRule";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { SignCreateSignRequestDtoGen } from "@/services/sign/v1/data-contracts";
import { ISignDocument } from "@/models/sign-document.entity";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop, Ref } from "vue-property-decorator";
import SignRequestForm from "./SignRequestForm.vue";
import { handleError } from "@/lib/utility/handleError";
import { SignRequest } from "@/models/sign-request.entity";

@Component({
  components: { SignRequestForm }
})
export default class SignRequestCreateDialog extends DarkModeHighlightMixin {
  @Ref("signRequestForm")
  signRequestForm!: SignRequestForm;

  @Prop({ default: false })
  dialog = false;

  @Prop({ default: [] })
  documents!: ISignDocument[];

  isValid = false;
  isLoading = false;

  closeDialog() {
    this.dialog = false;
  }

  get requiredRule() {
    return requiredRule();
  }

  get partner() {
    return PartnerModule.partner;
  }

  async save(document: SignCreateSignRequestDtoGen) {
    this.isLoading = true;

    this.$log.debug(document);

    try {
      const request = await new SignRequest({
        partnerId: this.partner._id,
        ...document
      }).create();

      this.$emit("save", request);
      this.$toast.success("👍");
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoading = false;
    }
  }
}
