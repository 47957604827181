var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "confirm-action-dialog",
    {
      attrs: {
        fullscreen: true,
        isDialogActive: _vm.dialog,
        title: _vm.$t("timeLine.ReportActivityUploadDialog.title"),
        supressKeyboardActions: true,
        hideRight: _vm.step === _vm.STEPS.CHOICE,
        rightText: _vm.rightButtonText,
        leftText: _vm.leftText,
        leftLoading: _vm.isLoading,
        rightLoading: _vm.isLoading,
        rightDisabled: _vm.rightDisabled
      },
      on: {
        "update:isDialogActive": function updateIsDialogActive($event) {
          _vm.dialog = $event
        },
        "update:is-dialog-active": function updateIsDialogActive($event) {
          _vm.dialog = $event
        },
        leftClick: function leftClick($event) {
          return _vm.onPrevious()
        },
        rightClick: function rightClick($event) {
          return _vm.onNext()
        }
      }
    },
    [
      _c("report-activity-box-document-component", {
        ref: "reportActivityUploadDialog",
        attrs: {
          hideButton: true,
          actionButton: false,
          partnerId: _vm.partner.id,
          report: _vm.report
        },
        on: {
          updateValid: function updateValid(v) {
            return (_vm.valid = v)
          },
          updateStep: function updateStep(v) {
            return (_vm.step = v)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }