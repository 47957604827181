var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        fullscreen: ""
      },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                attrs = _ref.attrs
              return [
                _vm._t(
                  "activator",
                  function() {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              attrs: {
                                elevation: "0",
                                outlined: _vm.outlined,
                                small: _vm.small,
                                color: "info"
                              },
                              on: {
                                click: function click($event) {
                                  return _vm.reset()
                                }
                              }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_vm._v(" " + _vm._s(_vm.$t(_vm.btnText)) + " ")]
                      )
                    ]
                  },
                  null,
                  {
                    on: on,
                    attrs: attrs,
                    reset: _vm.reset
                  }
                )
              ]
            }
          }
        ],
        null,
        true
      ),
      model: {
        value: _vm.dialog,
        callback: function callback($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-stepper",
            {
              attrs: {
                "alt-labels": "",
                flat: ""
              },
              model: {
                value: _vm.activeWindow,
                callback: function callback($$v) {
                  _vm.activeWindow = $$v
                },
                expression: "activeWindow"
              }
            },
            [
              _c(
                "v-stepper-header",
                [
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        complete: _vm.activeWindow > 1,
                        step: "1"
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("partner.excelimport.file")) + " "
                      )
                    ]
                  ),
                  _c("v-divider"),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        complete: _vm.activeWindow > 2,
                        step: "2"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("partner.excelimport.configuration")) +
                          " "
                      )
                    ]
                  ),
                  _c("v-divider"),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        complete: _vm.activeWindow > 3,
                        step: "3"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("partner.excelimport.preview")) +
                          " "
                      )
                    ]
                  ),
                  _c("v-divider"),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        complete: _vm.activeWindow > 4,
                        step: "4"
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("partner.excelimport.import")) + " "
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-stepper-items",
                [
                  _c(
                    "v-stepper-content",
                    {
                      attrs: {
                        step: "1"
                      }
                    },
                    [
                      _c(
                        "v-card-title",
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("partner.excelimport.selectFile")) +
                              " "
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                text: ""
                              },
                              on: {
                                click: _vm.downloadHeaderFile
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "partner.excelimport.downloadHeaderFile"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c("v-file-input", {
                            ref: "doc",
                            attrs: {
                              accept: ".xlsx",
                              label: "File input"
                            },
                            on: {
                              change: function change($event) {
                                return _vm.readFile()
                              }
                            },
                            model: {
                              value: _vm.inputFile,
                              callback: function callback($$v) {
                                _vm.inputFile = $$v
                              },
                              expression: "inputFile"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                text: "",
                                color: "info"
                              },
                              on: {
                                click: function click($event) {
                                  return _vm.closeAndReset()
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("abort")))]
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                disabled: !_vm.isFileLoaded,
                                elevation: "0",
                                color: "info"
                              },
                              on: {
                                click: function click($event) {
                                  return _vm.importFileAsExcel()
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("partner.excelimport.continue")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-content",
                    {
                      attrs: {
                        step: "2"
                      }
                    },
                    [
                      _c("v-card-title", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("partner.excelimport.selectColumns")
                            ) +
                            " "
                        )
                      ]),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-form",
                            {
                              model: {
                                value: _vm.isColumnMapValid,
                                callback: function callback($$v) {
                                  _vm.isColumnMapValid = $$v
                                },
                                expression: "isColumnMapValid"
                              }
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "4"
                                      }
                                    },
                                    [
                                      _vm.isAllNonRequiredSelected
                                        ? _c("v-checkbox", {
                                            attrs: {
                                              "input-value": true,
                                              label: _vm.$t(
                                                "timeLine.PartnerReportDetailImageTimeLineElement.unselectAll"
                                              ),
                                              disabled: !_vm.isNonRequiredColumns
                                            },
                                            on: {
                                              click: _vm.unselectAllNonRequired
                                            }
                                          })
                                        : _c("v-checkbox", {
                                            attrs: {
                                              "input-value": false,
                                              label: _vm.$t(
                                                "timeLine.PartnerReportDetailImageTimeLineElement.selectAll"
                                              ),
                                              disabled: !_vm.isNonRequiredColumns
                                            },
                                            on: {
                                              click: _vm.selectAllNonRequired
                                            }
                                          })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._l(_vm.importConfig, function(config, idx) {
                                return _c(
                                  "div",
                                  {
                                    key: idx
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "4"
                                            }
                                          },
                                          [
                                            _c("v-checkbox", {
                                              attrs: {
                                                label: config.label,
                                                disabled: config.required
                                              },
                                              model: {
                                                value: config.import,
                                                callback: function callback(
                                                  $$v
                                                ) {
                                                  _vm.$set(
                                                    config,
                                                    "import",
                                                    $$v
                                                  )
                                                },
                                                expression: "config.import"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "4"
                                            }
                                          },
                                          [
                                            config.import &&
                                            _vm.keys &&
                                            _vm.keys.length
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c("v-autocomplete", {
                                                      attrs: {
                                                        label: config.label,
                                                        hint: config.hint,
                                                        "persistent-hint": "",
                                                        outlined: "",
                                                        items: _vm.keys,
                                                        rules: _vm.requiredRule
                                                      },
                                                      model: {
                                                        value:
                                                          config.originColumnNameInExcelFile,
                                                        callback: function callback(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            config,
                                                            "originColumnNameInExcelFile",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "config.originColumnNameInExcelFile"
                                                      }
                                                    }),
                                                    config.duplicateHandling
                                                      ? _c("v-autocomplete", {
                                                          attrs: {
                                                            label:
                                                              "Umgang mit doppelten Werten",
                                                            outlined: "",
                                                            items:
                                                              _vm.duplicateHandling,
                                                            rules:
                                                              _vm.requiredRule
                                                          },
                                                          model: {
                                                            value:
                                                              config
                                                                .duplicateHandling
                                                                .method,
                                                            callback: function callback(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                config.duplicateHandling,
                                                                "method",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "config.duplicateHandling.method"
                                                          }
                                                        })
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              : _c("div", [
                                                  _vm._v(
                                                    _vm._s(config.label) +
                                                      ": " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "partner.excelimport.notImport"
                                                        )
                                                      )
                                                  )
                                                ])
                                          ]
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "4"
                                            }
                                          },
                                          [
                                            config.originColumnNameInExcelFile &&
                                            config.import
                                              ? _c(
                                                  "div",
                                                  _vm._l(
                                                    _vm.dataPreview(config),
                                                    function(entry, idx) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: idx
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(entry) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    idx + 1 != _vm.importConfig.length
                                      ? _c("v-divider", {
                                          staticClass: "mb-6 mt-6"
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                disabled: !_vm.isFileLoaded,
                                outlined: "",
                                color: "info"
                              },
                              on: {
                                click: function click($event) {
                                  return _vm.reset()
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("partner.excelimport.back")) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-2",
                              attrs: {
                                text: "",
                                color: "info"
                              },
                              on: {
                                click: function click($event) {
                                  return _vm.closeAndReset()
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("abort")) + " ")]
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                disabled: !_vm.isColumnMapValid,
                                elevation: "0",
                                color: "info"
                              },
                              on: {
                                click: function click($event) {
                                  return _vm.createDto()
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("partner.excelimport.continue")) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-content",
                    {
                      attrs: {
                        step: "3"
                      }
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c("v-card-title", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("partner.excelimport.readyForImport", {
                                    value: _vm.importResult.length
                                  })
                                ) +
                                " "
                            )
                          ]),
                          _c(
                            "v-sheet",
                            {
                              staticClass: "ma-4",
                              attrs: {
                                outlined: "",
                                rounded: "2"
                              }
                            },
                            [
                              _c("v-data-table", {
                                attrs: {
                                  "item-key": "name",
                                  headers: _vm.importResultPrevHeaders,
                                  items: _vm.importResult,
                                  "items-per-page": 5
                                }
                              })
                            ],
                            1
                          ),
                          _vm.validationErrors.length
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-alert",
                                    {
                                      staticClass: "ma-4",
                                      attrs: {
                                        value: true,
                                        type: "error",
                                        border: "left"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "partner.excelimport.validationError",
                                              {
                                                value:
                                                  _vm.validationErrors.length
                                              }
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-sheet",
                                    {
                                      staticClass: "ma-4",
                                      attrs: {
                                        outlined: "",
                                        rounded: "2"
                                      }
                                    },
                                    [
                                      _c("v-data-table", {
                                        attrs: {
                                          "item-key": "name",
                                          headers: _vm.importResultPrevHeaders,
                                          items: _vm.validationErrors,
                                          "items-per-page": 5
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-card-actions",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    disabled: !_vm.isFileLoaded,
                                    outlined: "",
                                    color: "info"
                                  },
                                  on: {
                                    click: function click($event) {
                                      _vm.activeWindow = 2
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("partner.excelimport.back"))
                                  )
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    text: "",
                                    color: "info"
                                  },
                                  on: {
                                    click: function click($event) {
                                      return _vm.closeAndReset()
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("abort")))]
                              ),
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    disabled: _vm.importDisabled,
                                    elevation: "0",
                                    color: "info"
                                  },
                                  on: {
                                    click: function click($event) {
                                      return _vm.emitDto()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("partner.excelimport.start")
                                      )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-content",
                    {
                      attrs: {
                        step: "4"
                      }
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _vm._t("default", null, {
                            data: _vm.importResult,
                            keys: _vm.importResultPrevHeaders,
                            dialog: _vm.dialog
                          }),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    disabled: _vm.loading,
                                    elevation: "0",
                                    color: "info"
                                  },
                                  on: {
                                    click: function click($event) {
                                      return _vm.finish()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("partner.excelimport.close")
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }