var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-app",
    {
      staticClass: "machineSelection"
    },
    [
      _c("TopToolbarLogo", {
        attrs: {
          src: _vm.manufacturer.logoUrl,
          elevation: 1
        }
      }),
      _c("MachineSelectionCard", {
        staticClass: "content",
        attrs: {
          manufacturerId: _vm.manufacturer.id,
          hideManufacturerInput: true
        }
      }),
      _c("DocphantFooter", {
        attrs: {
          manufacturer: _vm.manufacturer
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }