import { render, staticRenderFns } from "./UserAdminsitrationDangerZoneCard.vue?vue&type=template&id=2a901ef3&"
import script from "./UserAdminsitrationDangerZoneCard.vue?vue&type=script&lang=ts&"
export * from "./UserAdminsitrationDangerZoneCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCardText,VDataTable,VDivider,VForm,VSkeletonLoader,VSubheader,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2a901ef3')) {
      api.createRecord('2a901ef3', component.options)
    } else {
      api.reload('2a901ef3', component.options)
    }
    module.hot.accept("./UserAdminsitrationDangerZoneCard.vue?vue&type=template&id=2a901ef3&", function () {
      api.rerender('2a901ef3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/partner/UserAdminsitrationDangerZoneCard.vue"
export default component.exports