








import LayoutSimple from "@/layouts/LayoutSimple.vue";
import PartnerFaqDetailCard from "@/components/partner/PartnerFaqDetailCard.vue";
import { Component, Vue } from "vue-property-decorator";
import { FaqModule } from "@/store/modules/faq.store";
import { handleError } from "@/lib/utility/handleError";
import { ThgFaqViewModelGen } from "@/services/thg/v1/data-contracts";

@Component({
  components: {
    LayoutSimple,
    PartnerFaqDetailCard
  }
})
export default class PartnerFaqDetail extends Vue {
  isLoadingDetail = false;
  get faqId() {
    return this.$route.params.faqId;
  }
  get partnerId() {
    return this.$route.params.partnerId;
  }

  faq: ThgFaqViewModelGen | null = null;

  async mounted() {
    try {
      this.isLoadingDetail = true;
      const faq = await FaqModule.get({ faqId: this.faqId, partnerId: this.partnerId });

      if (faq) {
        this.faq = faq;
      }
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoadingDetail = false;
    }
  }
}
