var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticStyle: {
        height: "100%"
      }
    },
    [
      _vm.isLoading
        ? _c(
            "v-container",
            [
              _c("v-skeleton-loader", {
                attrs: {
                  type:
                    "article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
                }
              })
            ],
            1
          )
        : _vm.currentPage
        ? _c(
            "v-card",
            {
              staticStyle: {
                height: "100%"
              },
              attrs: {
                elevation: 0
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "max-height": "80px: overflow:hidden"
                  }
                },
                [
                  _c(
                    "v-card-text",
                    {
                      staticClass: "mt-n1",
                      staticStyle: {
                        position: "absolute"
                      }
                    },
                    [
                      _c(
                        "a",
                        {
                          on: {
                            click: _vm.goToDocumentOverview
                          }
                        },
                        [
                          _c("v-icon", [_vm._v(" mdi-menu-left ")]),
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("sign.DocumentDetail.back")) +
                                " "
                            )
                          ])
                        ],
                        1
                      ),
                      _vm.hasRefs
                        ? _c(
                            "a",
                            {
                              on: {
                                click: _vm.goToReference
                              }
                            },
                            [
                              _c("v-icon", [_vm._v(" mdi-menu-left ")]),
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "timeLine.GoToReferenceButton.tooltip"
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "ma-0 ml-1",
                  attrs: {
                    align: "center"
                  }
                },
                [
                  !_vm.isMobile
                    ? _c(
                        "tooltip",
                        {
                          attrs: {
                            text: _vm.document.isTemplate
                              ? _vm.$t("sign.DocumentDetail.template")
                              : _vm.$t("sign.DocumentDetail.file")
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "ml-4 mr-n4 mb-n3 mt-2",
                              attrs: {
                                "x-large": ""
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.signature
                                      ? "mdi-file-sign"
                                      : _vm.document.isTemplate
                                      ? "mdi-form-textarea"
                                      : "mdi-file-document-outline"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    {
                      staticClass: "mt-2 mb-n2"
                    },
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass: "text-truncate"
                        },
                        [
                          _c(
                            "span",
                            {
                              class: _vm.isMobile ? "title" : ""
                            },
                            [_vm._v(" " + _vm._s(_vm.document.title) + " ")]
                          ),
                          _c("v-spacer"),
                          _vm.signatureRequest && !_vm.isMobile
                            ? _c("select-assignees", {
                                staticClass: "mb-n3 mt-3",
                                attrs: {
                                  disabled: !_vm.canEditSignRequest
                                },
                                on: {
                                  input: _vm.onAssigneesUpdate,
                                  added: _vm.onAssigneesAdded,
                                  removed: _vm.onAssigneesRemoved
                                },
                                model: {
                                  value: _vm.signatureRequest.assignees,
                                  callback: function callback($$v) {
                                    _vm.$set(
                                      _vm.signatureRequest,
                                      "assignees",
                                      $$v
                                    )
                                  },
                                  expression: "signatureRequest.assignees"
                                }
                              })
                            : _vm._e(),
                          !_vm.isMobile
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "tooltip",
                                    {
                                      attrs: {
                                        text: _vm.$t(
                                          "sign.DocumentDetail.detail"
                                        )
                                      }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            icon: ""
                                          },
                                          on: {
                                            click: function click() {
                                              return _vm.toggleSideBar(
                                                _vm
                                                  .DocumentDetailSideBarElements
                                                  .DETAIL
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color:
                                                  _vm
                                                    .DocumentDetailSideBarElements
                                                    .DETAIL ===
                                                  _vm.sideBarElement
                                                    ? "info"
                                                    : ""
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " mdi-information-variant "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "tooltip",
                                    {
                                      attrs: {
                                        text: _vm.$t(
                                          "sign.DocumentDetail.activity"
                                        )
                                      }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            icon: "",
                                            loading: _vm.isLoadingActivities
                                          },
                                          on: {
                                            click: function click() {
                                              return _vm.toggleSideBar(
                                                _vm
                                                  .DocumentDetailSideBarElements
                                                  .ACTIVITY
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color:
                                                  _vm
                                                    .DocumentDetailSideBarElements
                                                    .ACTIVITY ===
                                                  _vm.sideBarElement
                                                    ? "info"
                                                    : ""
                                              }
                                            },
                                            [_vm._v(" mdi-comment-outline ")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.$can(
                                    _vm.ActionEnum.CREATE,
                                    _vm.ResourceEnum.COST
                                  )
                                    ? _c(
                                        "tooltip",
                                        {
                                          attrs: {
                                            text: _vm.$t(
                                              "sign.DocumentDetail.cost"
                                            )
                                          }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                icon: ""
                                              },
                                              on: {
                                                click: function click($event) {
                                                  _vm.sideBarElement =
                                                    _vm.DocumentDetailSideBarElements.COST
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color:
                                                      _vm
                                                        .DocumentDetailSideBarElements
                                                        .COST ===
                                                      _vm.sideBarElement
                                                        ? "info"
                                                        : ""
                                                  }
                                                },
                                                [_vm._v(" mdi-cash-multiple ")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.$can(
                                    _vm.ActionEnum.READ,
                                    _vm.ResourceEnum.SIGN
                                  )
                                    ? _c(
                                        "span",
                                        [
                                          _c(
                                            "tooltip",
                                            {
                                              attrs: {
                                                text: _vm.$t(
                                                  "sign.DocumentDetail.requestSignature"
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    disabled: ""
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      " mdi-signature-freehand "
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : !(_vm.signature || _vm.signatureRequest)
                                    ? _c(
                                        "span",
                                        [
                                          _c(
                                            "tooltip",
                                            {
                                              attrs: {
                                                text: _vm.$t(
                                                  "sign.DocumentDetail.requestSignature"
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    icon: ""
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.initializeSignatureRequestDialog
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      " mdi-signature-freehand "
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _c(
                                        "tooltip",
                                        {
                                          attrs: {
                                            text: _vm.$t(
                                              "sign.DocumentDetail.signature"
                                            )
                                          }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                icon: ""
                                              },
                                              on: {
                                                click: function click() {
                                                  return _vm.toggleSideBar(
                                                    _vm
                                                      .DocumentDetailSideBarElements
                                                      .SIGNATURE
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color:
                                                      _vm
                                                        .DocumentDetailSideBarElements
                                                        .SIGNATURE ===
                                                      _vm.sideBarElement
                                                        ? "info"
                                                        : ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " mdi-signature-freehand "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                  _c(
                                    "tooltip",
                                    {
                                      attrs: {
                                        text: _vm.$t("sign.DocumentDetail.edit")
                                      }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            icon: ""
                                          },
                                          on: {
                                            click: function click() {
                                              return _vm.toggleSideBar(
                                                _vm
                                                  .DocumentDetailSideBarElements
                                                  .EDIT
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color:
                                                  _vm
                                                    .DocumentDetailSideBarElements
                                                    .EDIT === _vm.sideBarElement
                                                    ? "info"
                                                    : ""
                                              }
                                            },
                                            [_vm._v(" mdi-playlist-edit ")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "span",
                                    [
                                      _c("v-icon", [
                                        _vm._v(" mdi-minus mdi-rotate-90 ")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "tooltip",
                                    {
                                      attrs: {
                                        text: _vm.$t(
                                          "sign.DocumentDetail.download"
                                        )
                                      }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            icon: ""
                                          },
                                          on: {
                                            click: function click() {
                                              return _vm.toggleSideBar(
                                                _vm
                                                  .DocumentDetailSideBarElements
                                                  .DOWNLOAD
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color:
                                                  _vm
                                                    .DocumentDetailSideBarElements
                                                    .DOWNLOAD ===
                                                  _vm.sideBarElement
                                                    ? "info"
                                                    : ""
                                              }
                                            },
                                            [_vm._v(" mdi-download-outline ")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "tooltip",
                                    {
                                      attrs: {
                                        text: _vm.$t(
                                          "components.template.detail.delete.delete"
                                        )
                                      }
                                    },
                                    [
                                      _c(
                                        "document-template-detail-delete-dialog",
                                        {
                                          ref: "deleteDialog",
                                          attrs: {
                                            disabled: !_vm.canDeleteSignRequest
                                          },
                                          on: {
                                            startDelete: function startDelete(
                                              $event
                                            ) {
                                              _vm.sideBarElement =
                                                _vm.DocumentDetailSideBarElements.CLOSED
                                            },
                                            delete: _vm.onDelete
                                          }
                                        }
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _c(
                                "context-menu",
                                {
                                  attrs: {
                                    closeOnContentClick: true
                                  }
                                },
                                [
                                  _c(
                                    "v-list",
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function click() {
                                              return _vm.toggleSideBar(
                                                _vm
                                                  .DocumentDetailSideBarElements
                                                  .DETAIL
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  " mdi-information-variant "
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _c("v-list-item-content", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "sign.DocumentDetail.detail"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function click() {
                                              return _vm.toggleSideBar(
                                                _vm
                                                  .DocumentDetailSideBarElements
                                                  .ACTIVITY
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v(" mdi-comment-outline ")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c("v-list-item-content", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "sign.DocumentDetail.activity"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm.$can(
                                        _vm.ActionEnum.CREATE,
                                        _vm.ResourceEnum.COST
                                      )
                                        ? _c(
                                            "v-list-item",
                                            {
                                              on: {
                                                click: function click($event) {
                                                  _vm.sideBarElement =
                                                    _vm.DocumentDetailSideBarElements.COST
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-list-item-icon",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      " mdi-cash-multiple "
                                                    )
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c("v-list-item-content", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "sign.DocumentDetail.cost"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      !_vm.$can(
                                        _vm.ActionEnum.READ,
                                        _vm.ResourceEnum.SIGN
                                      )
                                        ? _c(
                                            "v-list-item",
                                            {
                                              attrs: {
                                                disabled: ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-list-item-icon",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      " mdi-signature-freehand "
                                                    )
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c("v-list-item-content", [
                                                _vm._v(
                                                  " Disabled " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "sign.DocumentDetail.signature"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        : !(
                                            _vm.signature ||
                                            _vm.signatureRequest
                                          )
                                        ? _c(
                                            "v-list-item",
                                            {
                                              on: {
                                                click:
                                                  _vm.initializeSignatureRequestDialog
                                              }
                                            },
                                            [
                                              _c(
                                                "v-list-item-icon",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      " mdi-signature-freehand "
                                                    )
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c("v-list-item-content", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "sign.DocumentDetail.signature"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        : _c(
                                            "v-list-item",
                                            {
                                              on: {
                                                click: function click() {
                                                  return _vm.toggleSideBar(
                                                    _vm
                                                      .DocumentDetailSideBarElements
                                                      .SIGNATURE
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-list-item-icon",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      " mdi-signature-freehand "
                                                    )
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c("v-list-item-content", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "sign.DocumentDetail.signature"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function click() {
                                              return _vm.toggleSideBar(
                                                _vm
                                                  .DocumentDetailSideBarElements
                                                  .EDIT
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v(" mdi-playlist-edit ")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c("v-list-item-content", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "sign.DocumentDetail.edit"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function click() {
                                              return _vm.toggleSideBar(
                                                _vm
                                                  .DocumentDetailSideBarElements
                                                  .DOWNLOAD
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v(" mdi-download-outline ")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c("v-list-item-content", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "sign.DocumentDetail.download"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        {
                                          attrs: {
                                            disabled: !_vm.canDeleteSignRequest
                                          },
                                          on: {
                                            click: _vm.deleteDocument
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v(" mdi-trash-can ")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c("v-list-item-content", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.template.detail.delete.delete"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "v-card__subtitle pt-n4",
                          staticStyle: {
                            "margin-left": "1px",
                            "margin-right": "1px"
                          }
                        },
                        [
                          _vm.signatureRequest && _vm.isMobile
                            ? _c("assignees", {
                                staticClass: "mt-n2 mb-2",
                                attrs: {
                                  disabled: !_vm.canEditSignRequest
                                },
                                on: {
                                  input: _vm.onAssigneesUpdate,
                                  added: _vm.onAssigneesAdded,
                                  removed: _vm.onAssigneesRemoved
                                },
                                model: {
                                  value: _vm.signatureRequest.assignees,
                                  callback: function callback($$v) {
                                    _vm.$set(
                                      _vm.signatureRequest,
                                      "assignees",
                                      $$v
                                    )
                                  },
                                  expression: "signatureRequest.assignees"
                                }
                              })
                            : _vm._e(),
                          _vm.signatureRequest && _vm.isMobile
                            ? _c("v-spacer")
                            : _vm._e(),
                          !_vm.isMobile
                            ? _c(
                                "span",
                                {
                                  staticStyle: {
                                    "text-overflow": "ellipsis",
                                    overflow: "hidden",
                                    "white-space": "nowrap",
                                    "max-width": "200px"
                                  }
                                },
                                [
                                  _c(
                                    "tooltip",
                                    {
                                      attrs: {
                                        text: _vm.document.name
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.document.name) + " "
                                      )
                                    ]
                                  ),
                                  _vm._v("  |   ")
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.isMobile
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("sign.DocumentDetail.created")
                                    ) +
                                    ": " +
                                    _vm._s(
                                      _vm._f("simpleDoubleDigitDate")(
                                        _vm.document.timestamp.created
                                      )
                                    ) +
                                    "  |   "
                                )
                              ])
                            : _vm._e(),
                          !_vm.isMobile
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("sign.DocumentDetail.changed")
                                    ) +
                                    ": " +
                                    _vm._s(_vm.lastChanged) +
                                    "  |   "
                                )
                              ])
                            : _vm._e(),
                          _vm.document.tags &&
                          _vm.document.tags.length &&
                          !_vm.isMobile
                            ? _c(
                                "span",
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("sign.DocumentDetail.tags")
                                      ) +
                                      ": "
                                  ),
                                  _vm._l(
                                    _vm.document.tags.slice(0, 3),
                                    function(tag, index) {
                                      return _c(
                                        "v-chip",
                                        {
                                          key: "tag" + tag,
                                          class:
                                            index !==
                                            _vm.document.tags.length - 1
                                              ? "mr-1"
                                              : "",
                                          staticStyle: {
                                            "margin-top": "-2px"
                                          },
                                          attrs: {
                                            small: ""
                                          }
                                        },
                                        [_vm._v(" " + _vm._s(tag) + " ")]
                                      )
                                    }
                                  ),
                                  _vm.document.tags.length > 3
                                    ? _c("span", [_vm._v("...")])
                                    : _vm._e(),
                                  _vm._v("  |   ")
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm.document.isSigned &&
                          !_vm.signatureRequest &&
                          !_vm.isMobile
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "v-chip",
                                    {
                                      staticClass: "ml-1",
                                      staticStyle: {
                                        "margin-top": "-2px"
                                      },
                                      attrs: {
                                        small: ""
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("sign.DocumentDetail.signed")
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _vm._v("  |   ")
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.signatureRequest && _vm.signatureRequest
                            ? _c("report-document-card-status-menu", {
                                attrs: {
                                  signRequest: _vm.signatureRequest
                                }
                              })
                            : _vm._e(),
                          _c("v-spacer"),
                          !_vm.isMobile
                            ? _c("document-template-detail-save", {
                                key: "detailSave" + _vm.isLoadingSave,
                                attrs: {
                                  document: _vm.document,
                                  loading: _vm.isLoadingSave
                                },
                                on: {
                                  save: _vm.save
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c("document-detail-card-editor", {
                ref: "detailCardEditor",
                staticStyle: {
                  height: "100%"
                },
                attrs: {
                  isSignature: _vm.signature,
                  isLoading: _vm.isLoading,
                  isLoadingSave: _vm.isLoadingSave,
                  isLoadingDelete: _vm.isLoadingDelete,
                  hideTokenFields: _vm.document.isSigned,
                  sideBarElement: _vm.sideBarElement,
                  currentPageNumber: _vm.currentPageNumber,
                  pages: _vm.pages,
                  editValues: _vm.canEditSignRequest
                },
                on: {
                  closeSideBar: _vm.closeSideBar,
                  setCurrentPage: _vm.setCurrentPage,
                  save: _vm.save,
                  onDelete: _vm.onDelete
                }
              }),
              _vm.isMobile &&
              _vm.sideBarElement === _vm.DocumentDetailSideBarElements.CLOSED
                ? _c("document-template-detail-token-list-options-mobile", {
                    staticStyle: {
                      position: "relative",
                      top: "-100px"
                    },
                    attrs: {
                      getDetailCardEditor: function getDetailCardEditor() {
                        return _vm.detailCardEditor
                      },
                      disabled: !_vm.canEditSignRequest
                    },
                    on: {
                      save: _vm.saveTokens
                    }
                  })
                : _vm._e(),
              _vm.isMobile
                ? _c("document-template-detail-save-mobile", {
                    key: "detailSave" + _vm.isLoadingSave,
                    staticStyle: {
                      position: "absolute",
                      bottom: "-65px",
                      right: "2px"
                    },
                    attrs: {
                      document: _vm.document,
                      loading: _vm.isLoadingSave
                    },
                    on: {
                      save: _vm.save
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      !_vm.isLoading
        ? _c(
            "div",
            [
              _vm.sideBarElement === _vm.DocumentDetailSideBarElements.EDIT
                ? _c("document-template-detail-token-list-side-card", {
                    attrs: {
                      currentPageNumber: _vm.currentPageNumber,
                      disabled: !_vm.canEditSignRequest,
                      page: _vm.pages[_vm.currentPageNumber]
                    },
                    on: {
                      setCurrentPage: _vm.setCurrentPage,
                      close: _vm.closeSideBar
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "addToken",
                          fn: function fn() {
                            return [
                              _c(
                                "document-template-detail-token-list-options",
                                {
                                  attrs: {
                                    loading: _vm.isLoadingSave,
                                    document: _vm.document,
                                    getDetailCardEditor: function getDetailCardEditor() {
                                      return _vm.detailCardEditor
                                    },
                                    disabled: !_vm.canEditSignRequest
                                  },
                                  on: {
                                    save: _vm.saveTokens
                                  }
                                }
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2004951149
                    )
                  })
                : _vm._e(),
              _vm.sideBarElement === _vm.DocumentDetailSideBarElements.DETAIL
                ? _c("document-template-detail-information", {
                    attrs: {
                      document: _vm.document
                    },
                    on: {
                      close: _vm.closeSideBar,
                      save: _vm.save
                    }
                  })
                : _vm._e(),
              _vm.sideBarElement === _vm.DocumentDetailSideBarElements.DOWNLOAD
                ? _c("document-template-detail-download", {
                    attrs: {
                      document: _vm.document
                    },
                    on: {
                      close: _vm.closeSideBar
                    }
                  })
                : _vm._e(),
              _vm.sideBarElement === _vm.DocumentDetailSideBarElements.COST
                ? _c("document-template-detail-cost", {
                    attrs: {
                      document: _vm.document
                    },
                    on: {
                      close: _vm.closeSideBar
                    }
                  })
                : _vm._e(),
              _vm.sideBarElement === _vm.DocumentDetailSideBarElements.SIGNATURE
                ? _c("document-template-detail-signature", {
                    attrs: {
                      document: _vm.document,
                      report: _vm.report,
                      signature: _vm.signature,
                      signatureRequest: _vm.signatureRequest,
                      loading: _vm.isLoadingSignature,
                      disabled: !_vm.canEditSignRequest
                    },
                    on: {
                      save: _vm.saveSignatureRequest,
                      close: _vm.closeSideBar
                    }
                  })
                : _vm._e(),
              _vm.sideBarElement === _vm.DocumentDetailSideBarElements.ACTIVITY
                ? _c(
                    "side-card",
                    {
                      attrs: {
                        title: _vm.$t("sign.DocumentDetail.activity"),
                        width: _vm.sideCardWidth,
                        loading: _vm.isLoadingActivities,
                        height: _vm.sideCardHeight,
                        fullscreen: _vm.isMobile
                      },
                      on: {
                        close: _vm.closeSideBar
                      }
                    },
                    [
                      _c(
                        "v-timeline",
                        {
                          staticClass: "mr-2 ml-2 pb-4",
                          attrs: {
                            dense: "",
                            clipped: ""
                          }
                        },
                        [
                          _vm.activityLog && _vm.activityLog.length
                            ? _c("activity-time-line")
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-card",
                        {
                          attrs: {
                            outlined: "",
                            flat: ""
                          }
                        },
                        [
                          _c(
                            "v-tabs",
                            {
                              attrs: {
                                "align-with-title": ""
                              },
                              model: {
                                value: _vm.tab,
                                callback: function callback($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab"
                              }
                            },
                            [
                              _c("v-tabs-slider"),
                              _c("v-tab", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "timeLine.PartnerReportDetailInfoTimeLineCard.actionCard.comment"
                                      )
                                    ) +
                                    " "
                                )
                              ]),
                              _vm.canCreateMessage && _vm.mail
                                ? _c("v-tab", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "timeLine.PartnerReportDetailInfoTimeLineCard.actionCard.email"
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-tabs-items",
                            {
                              model: {
                                value: _vm.tab,
                                callback: function callback($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab"
                              }
                            },
                            [
                              _c(
                                "v-tab-item",
                                {
                                  key: "0"
                                },
                                [
                                  _c("report-activity-box-comment-component", {
                                    attrs: {
                                      source: _vm.source,
                                      isEditorMobile: true,
                                      partnerId: _vm.partnerId
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.canCreateMessage && _vm.mail
                                ? _c(
                                    "v-tab-item",
                                    {
                                      key: "1"
                                    },
                                    [
                                      _c("report-activity-box-mail-component", {
                                        attrs: {
                                          source: _vm.source,
                                          isEditorMobile: true,
                                          partner: _vm.partner,
                                          mail: _vm.mail
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.$can(_vm.ActionEnum.READ, _vm.ResourceEnum.SIGN)
        ? _c(
            "div",
            {
              attrs: {
                hidden: ""
              }
            },
            [
              _c("report-document-sign-request-dialog", {
                ref: "signRequestDialog",
                on: {
                  close: _vm.onCloseSignRequestDialog
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.isMobile
        ? _c("document-template-detail-delete-dialog", {
            ref: "deleteDialog",
            staticClass: "ml-1",
            attrs: {
              hidden: "",
              text: _vm.$t("sign.DocumentDetail.delete")
            },
            on: {
              startDelete: function startDelete($event) {
                _vm.sideBarElement = _vm.DocumentDetailSideBarElements.CLOSED
              },
              delete: _vm.onDelete
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }