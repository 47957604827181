

















































import Tooltip from "@/components/utility/tooltip.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { RefTypeMap } from "@/store/modules/refs.store";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { MrfiktivReportViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { IVehicle } from "@/models/vehicle.entity";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { ITicket } from "@/models/ticket.entity";
import ProjectGoToMixin from "@/views/project/mixins/ProjectGoToMixin.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import RefsSelect from "../utility/RefsSelect.vue";
import TicketCreateForm from "./TicketCreateForm.vue";
import TicketRefsSelect from "./TicketRefsSelect.vue";
import { IEventUIDto } from "@/lib/dto/event/event-ui.dto";
import { IReport } from "@/models/report.entity";

type ElementType = IVehicle | IEventUIDto | IReport | ITicket;

@Component({
  components: { Tooltip, ConfirmActionDialog, TicketCreateForm, RefsSelect, TicketRefsSelect },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class TicketCreateCard extends mixins(PermissionMixin, ProjectGoToMixin) {
  @Prop({ default: "" })
  projectId!: string;

  @Prop()
  partnerId!: string;

  @Prop()
  ticket!: ITicket;

  isTicketFormValid = false;

  isTicketCustomFieldsValid = true;

  get titleSuggestions() {
    const suggestions: string[] = [];
    if (!this.ticket.refs?.length) {
      return suggestions;
    }
    for (const ref of this.ticket.refs) {
      const module = RefTypeMap.get(ref.refType as BackendResourceEnum)?.module;
      let element: ElementType | undefined;
      if (module?.maps?.id) {
        element = module?.maps?.id.get(ref.refId)?.[0];
      }
      if (!element) {
        element = module?.entities?.find((el: ElementType) => el.id === ref.refId);
      }
      if (element) {
        suggestions.push(this.getTitleSuggestion(ref.refType as BackendResourceEnum, element));
      }
    }
    return suggestions;
  }

  get isReferencesValid() {
    return !this.ticket.refs?.find(r => !r.refId || !r.refType);
  }

  getTitleSuggestion(refType: BackendResourceEnum, element: ElementType) {
    const prefix = this.$t(`BackendResourceEnum.${refType}`);
    switch (refType) {
      case BackendResourceEnum.VEHICLE: {
        const v = element as IVehicle;
        return `${this.$t("common.nouns.vehicle")}: ${v.displayName ?? v.numberplate}`;
      }
      case BackendResourceEnum.EVENT: {
        const e = element as IEventUIDto;
        return `${this.$t("common.nouns.appointment")}: ${e.summary}`;
      }
      case BackendResourceEnum.REPORT: {
        const r = element as MrfiktivReportViewModelGen;
        return `${prefix}: ${r.numberplate}`;
      }
      case BackendResourceEnum.TICKET: {
        const t = element as ITicket;
        return `${prefix}: #${t.number} ${t.title}`;
      }
      default: {
        return "";
      }
    }
  }
}
