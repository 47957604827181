



























import PartnerCreateOrUpdateGeneralInfos from "@/components/partner/PartnerCreateOrUpdateGeneralInfos.vue";
import PartnerCreateOrUpdateLandingpageSettigns from "@/components/partner/PartnerCreateOrUpdateLandingpageSettigns.vue";
import PartnerCreateOrUpdateLicense from "@/components/partner/PartnerCreateOrUpdateLicense.vue";
import PartnerCreateOrUpdateReportSettings from "@/components/partner/PartnerCreateOrUpdateReportSettings.vue";
import PartnerCreateOrUpdateSubmitCard from "@/components/partner/PartnerCreateOrUpdateSubmitCard.vue";
import PartnerDetailImage from "@/components/partner/PartnerDetailImage.vue";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { PartnerModule } from "@/store/modules/partner";
import { Component } from "vue-property-decorator";
import { CreatePartnerDto } from "@/store/models/createPartnerDto";

@Component({
  components: {
    PartnerDetailImage,
    PartnerCreateOrUpdateGeneralInfos,
    PartnerCreateOrUpdateLandingpageSettigns,
    PartnerCreateOrUpdateReportSettings,
    PartnerCreateOrUpdateLicense,
    PartnerCreateOrUpdateSubmitCard
  }
})
export default class PartnerUpdateDialog extends DarkModeHighlightMixin {
  dialog = false;

  partnerDto = new CreatePartnerDto(PartnerModule.partner);

  get reportSettings() {
    return this.partnerDto.settings?.reportSettings;
  }
  closeDialog() {
    this.dialog = false;
  }
}
