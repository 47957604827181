











































import { requiredRule } from "@/lib/rules/requiredRule";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class PartnerSelectionForm extends Vue {
  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: "61e9880bb4c41b6269ef5649" })
  partnerId!: string;

  internalPartnerId: string = this.partnerId;

  isValid = false;

  get requiredRule() {
    return [requiredRule()];
  }

  get partners() {
    return PartnerModule.partners;
  }

  change() {
    this.$emit("change");
    this.$emit("save", this.internalPartnerId);
  }
}
