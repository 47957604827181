var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "a",
    {
      attrs: {
        to: {
          path: "/"
        }
      }
    },
    [
      _c("img", {
        attrs: {
          alt: "MrFiktiv logo",
          src: require("../../assets/logo.png")
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }