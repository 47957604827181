var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          staticClass: "mx-4",
          attrs: {
            "max-width": "1200",
            flat: "",
            outlined: ""
          }
        },
        [
          _c(
            "v-card-title",
            {
              staticClass: "title pt-4 mb-n7"
            },
            [
              _c("h3", [_vm._v("Maschinen")]),
              _c(
                "v-card",
                {
                  attrs: {
                    flat: ""
                  }
                },
                [_c("v-card-actions", [_c("MachineCreateDialog")], 1)],
                1
              )
            ],
            1
          ),
          _c(
            "v-card",
            {
              staticClass: "my-2",
              attrs: {
                flat: "",
                "max-width": "250"
              }
            },
            [
              _c(
                "v-card-text",
                [
                  _c("v-text-field", {
                    attrs: {
                      "append-icon": "mdi-magnify",
                      label: "Search",
                      "single-line": "",
                      "hide-details": ""
                    },
                    model: {
                      value: _vm.search,
                      callback: function callback($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              headers: _vm.headers,
              search: _vm.search,
              items: _vm.machineList,
              "loading-text": "Maschinen werden geladen..."
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "items",
                  fn: function fn(props) {
                    return [
                      _c("td", [_vm._v(_vm._s(props.item.name))]),
                      _c(
                        "td",
                        {
                          staticClass: "text-xs-right"
                        },
                        [_vm._v(_vm._s(props.item.machineName))]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "text-xs-right"
                        },
                        [_vm._v(_vm._s(props.item.variante))]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "text-xs-right"
                        },
                        [_vm._v(_vm._s(props.item.machineType))]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "text-xs-right"
                        },
                        [_vm._v(_vm._s(props.item.machineType))]
                      )
                    ]
                  }
                },
                {
                  key: "no-data",
                  fn: function fn() {
                    return [_c("p", [_vm._v("Keine Daten")])]
                  },
                  proxy: true
                },
                {
                  key: "item.actions",
                  fn: function fn(_ref) {
                    var item = _ref.item
                    return [
                      _c(
                        "td",
                        {
                          staticClass: "justify-end layout px-0"
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              on: {
                                click: function click($event) {
                                  return _vm.openMachineAttachmentAddDialog(
                                    item
                                  )
                                }
                              }
                            },
                            [_vm._v(" mdi-text-box-plus-outline ")]
                          ),
                          _c(
                            "v-icon",
                            {
                              on: {
                                click: function click($event) {
                                  return _vm.openMachineAttachmentRemoveDialog(
                                    item
                                  )
                                }
                              }
                            },
                            [_vm._v(" mdi-text-box-minus-outline ")]
                          ),
                          _c(
                            "v-icon",
                            {
                              on: {
                                click: function click($event) {
                                  return _vm.openMachineInfoDialog(item)
                                }
                              }
                            },
                            [_vm._v(" mdi-information-outline ")]
                          ),
                          _c(
                            "v-icon",
                            {
                              on: {
                                click: function click($event) {
                                  return _vm.goToMachineView(item)
                                }
                              }
                            },
                            [_vm._v(" mdi-arrow-up-bold-box-outline ")]
                          ),
                          _c(
                            "v-icon",
                            {
                              on: {
                                click: function click($event) {
                                  return _vm.openMachineDeleteDialog(item)
                                }
                              }
                            },
                            [_vm._v(" mdi-delete ")]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _c("MachineAttachmentRemoveDialog", {
        ref: "machineAttachmentRemoveDialogComponent",
        attrs: {
          manufacturer: _vm.manufacturer,
          machineAttachmentList: _vm.machineAttachmentList
        }
      }),
      _c("MachineAttachmentAddDialog", {
        ref: "machineAttachmentAddDialogComponent",
        attrs: {
          manufacturer: _vm.manufacturer,
          machineAttachmentList: _vm.machineAttachmentList
        }
      }),
      _c("MachineInfoDialog", {
        ref: "machineInfoDialogComponent",
        attrs: {
          manufacturer: _vm.manufacturer
        }
      }),
      _c("MachineDeleteDialog", {
        ref: "machineDeleteDialogComoponent",
        attrs: {
          manufacturer: _vm.manufacturer,
          machineAttachmentList: _vm.machineAttachmentList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }