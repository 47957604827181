





























































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import FilterSelection from "@/components/filter/FilterSelection.vue";
import { IFilterListElement } from "@/lib/interfaces/filter/filterListElement.interface";
import { filterByFilter, filterBySearch, isFilterListEmpty } from "@/lib/utility/filter";
import { convertDashedToDotted } from "@/lib/utility/date-helper";
import { debounce } from "debounce";

@Component({
  components: {
    FilterSelection
  }
})
export default class FilterCard extends Vue {
  /**
   * List of filters that are shown in filter selection
   */
  @Prop({ default: () => [] })
  filterList!: IFilterListElement[];
  /*
   * Content that is supposed to be filtered
   */
  @Prop()
  filterItems!: any[];
  /*
   * Content that is filtered
   */
  @Prop()
  filteredItems!: any[];

  @Prop({ default: "red" })
  closeChipColor?: string;

  @Prop({ default: "white" })
  chipFontColor?: string;

  @Prop({ default: "white" })
  closeChipFontColor?: string;

  @Prop({ default: false })
  emitEvent?: boolean;

  /**
   * Content in search bar
   */
  @Prop()
  search!: string;

  get _search() {
    return this.search;
  }

  set _search(search: string) {
    this.$emit("update:search", search);
  }

  get chipStyle() {
    return `color:${this.chipFontColor}`;
  }

  get closeChipStyle() {
    return `color:${this.closeChipFontColor}`;
  }

  clearFilterList() {
    this.filterList.forEach(el => {
      el.value = [];
    });
  }

  removeFromFilterList(filterValue: string, categoryIndex: number, valueIndex: number) {
    this.filterList[categoryIndex].value?.splice(valueIndex, 1);
  }

  get isFilterListNotEmpty() {
    return !isFilterListEmpty(this.filterList);
  }

  isFilterSelectionOpen = false;

  toggleFilter() {
    this.isFilterSelectionOpen = !this.isFilterSelectionOpen;
  }

  convertDashedToDotted(date: string) {
    return convertDashedToDotted(date);
  }

  /**
   * Filter the filterItems with the filters in the filterList whenever the items or the filters are changed
   */
  @Watch("filterItems", { deep: true })
  @Watch("filterList", { deep: true })
  filter() {
    if (!this.emitEvent) {
      this.filteredItems.splice(0, this.filterItems.length);
      let res = filterByFilter(this.filterItems, this.filterList);
      res = filterBySearch(res, this._search);
      this.filteredItems.push(...res);
    } else {
      let res = filterByFilter(this.filterItems, this.filterList);
      res = filterBySearch(res, this._search);
      this.$emit("onFiltered", res);
    }
  }

  /**
   * How long to wait for changes before search input is updated
   */
  filterDebounceTimeout = 300;

  /**
   * Debounce the filter function, that calls the filter method whenever the search or the filterList stops changing for the specified amount of ms
   */
  debounceFilter = debounce(() => this.filter(), this.filterDebounceTimeout, false);

  /**
   * Filter the filterItems with the filters in the filterList when component just loaded
   */
  mounted() {
    this.filter();
  }
}
