import { render, staticRenderFns } from "./ThgTerms.vue?vue&type=template&id=c8b537ac&scoped=true&"
import script from "./ThgTerms.vue?vue&type=script&lang=ts&"
export * from "./ThgTerms.vue?vue&type=script&lang=ts&"
import style0 from "./ThgTerms.vue?vue&type=style&index=0&id=c8b537ac&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8b537ac",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VAlert,VCard,VCardSubtitle,VCardText,VCardTitle,VContainer,VSheet})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c8b537ac')) {
      api.createRecord('c8b537ac', component.options)
    } else {
      api.reload('c8b537ac', component.options)
    }
    module.hot.accept("./ThgTerms.vue?vue&type=template&id=c8b537ac&scoped=true&", function () {
      api.rerender('c8b537ac', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/thg/ThgTerms.vue"
export default component.exports