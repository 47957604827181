var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-dialog",
    {
      attrs: {
        width: "600px"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function fn(_ref) {
            var on = _ref.on,
              attrs = _ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        text: ""
                      },
                      on: {
                        click: _vm.closeDelete
                      }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(" Löschen ")]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function callback($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c(
              "span",
              {
                staticClass: "title"
              },
              [_vm._v("Wollen Sie den Hersteller wirklich löschen?")]
            )
          ]),
          _c("v-card-text", [
            _vm._v(
              " Dieser Vorgang kann nicht Rückgängig gemacht werden. Bitte stellen Sie sicher, dass alle Maschinen und Dokumente gelöscht sind. "
            )
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    text: ""
                  },
                  on: {
                    click: _vm.closeDelete
                  }
                },
                [_vm._v(" Abbrechen ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "error"
                  },
                  on: {
                    click: _vm.confirmDelete
                  }
                },
                [_vm._v(" Löschen ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }