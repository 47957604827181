







































































































































import CustomerContactCard from "@/components/partner/CustomerContactCard.vue";
import UserAdministrationDetailPermissions from "@/components/partner/UserAdministrationDetailPermissions.vue";
import UserAdministrationDetailRoles from "@/components/partner/UserAdministrationDetailRoles.vue";
import EditProfileDialog from "@/components/profile/EditProfileDialog.vue";
import { getFlagEmoji } from "@/lib/CountryCodeHelper";
import { AppContextEnum } from "@/lib/enum/appContext.enum";
import { ITemplateContext } from "@/lib/interfaces/template/templateContext.interface";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { $t } from "@/lib/utility/t";
import { UpdateUserDtoFactory } from "@/lib/utility/updateUserDtoFactory";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IUser } from "@/models/user.entity";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { BillingModule } from "@/store/modules/billing.store";
import { ChargingStationPaginatedModule } from "@/store/modules/charging-station-paginated.store";
import { ConfigModule } from "@/store/modules/config";
import { MeterReadingPaginatedModule } from "@/store/modules/meter-reading-paginated.store";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop, Ref } from "vue-property-decorator";
import TemplateDialog from "../template/TemplateDialog.vue";
import ActivityCard from "../thg/ActivityCard.vue";
import MHeader, { IAction } from "../utility/mmmint/MHeader.vue";
import PortalDetailBanking from "./PortalDetailBanking.vue";
import UserAdministrationDetailIdentityStatus from "./UserAdministrationDetailIdentityStatus.vue";
import UserAdminsitrationDangerZoneCard from "./UserAdminsitrationDangerZoneCard.vue";
import UserAdminsitrationGhgCard from "./UserAdminsitrationGhgCard.vue";
import ThgUserTable from "../thg/ThgUserTable.vue";
import { ThgPaginationModule } from "@/store/modules/thg-pagination.store";
import { IAdminUser } from "@/models/user.entity";
import { IPageFilterElement, PageFilterElement } from "@/models/page-filter-element.entity";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { ThgVehicleModule } from "@/store/modules/thg-vehicle.store";
import { IdentityStatusEnum } from "@/lib/enum/identity-status.enum";

@Component({
  components: {
    CustomerContactCard,
    UserAdministrationDetailPermissions,
    UserAdministrationDetailRoles,
    PortalDetailBanking,
    EditProfileDialog,
    TemplateDialog,
    UserAdministrationDetailIdentityStatus,
    UserAdminsitrationDangerZoneCard,
    UserAdminsitrationGhgCard,
    MHeader,
    ActivityCard,
    ThgUserTable
  }
})
export default class UserAdministrationDetail extends DarkModeHighlightMixin {
  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: true })
  displayToDetailView!: boolean;

  @Ref("templateDialog")
  templateDialog!: TemplateDialog;

  @Prop()
  selectedUser!: IAdminUser;

  rolesDisabled!: boolean;

  readonly TO_DETAIL_VIEW = "toDetailView";
  readonly TO_CHARGING_STATION = "toChargingStation";
  readonly TO_METER_READING = "toMeterReading";
  readonly TO_BILLING = "toBilling";
  readonly TO_GHG = "toGhg";
  readonly TO_THG_VEHICLES = "goToThgVehicles";

  readonly EDIT = "edit";
  readonly MAIL = "mail";

  readonly colorMap: Map<string, string> = new Map([
    [IdentityStatusEnum.ARCHIVED, ""],
    [IdentityStatusEnum.UNKNOWN, ""],
    [IdentityStatusEnum.CONFIRMED, "success"],
    [IdentityStatusEnum.FORCE_CHANGE_PASSWORD, "warning"],
    [IdentityStatusEnum.RESET_REQUIRED, "warning"],
    [IdentityStatusEnum.UNCONFIRMED, "error"],
    [IdentityStatusEnum.COMPROMISED, "error"]
  ]);

  isEditProfileDialog = false;

  selectedPartnerId = PartnerModule.partner.id ?? "";

  tab = 0;

  get adminTemplateSender() {
    return PartnerModule.partners;
  }

  get adminTemplateReceiver() {
    return [this.selectedUser?.contact.email];
  }

  /**
   * Is true if the app context is ghg portal
   */
  get ghgContext() {
    return ConfigModule.appContext === AppContextEnum.THG_PORTAL;
  }

  get showBanking() {
    return [AppContextEnum.THG_PORTAL].includes(ConfigModule.appContext);
  }

  get userIdentityStatus() {
    return this.selectedUser?.status;
  }

  get fullname() {
    return this.selectedUser?.firstName + " " + this.selectedUser?.lastName;
  }

  get to() {
    return [this.selectedUser?.contact.email];
  }

  get from() {
    return PartnerModule.partners;
  }

  get chips(): IAction[] {
    const chips: IAction[] = [];

    chips.push({
      key: "id",
      icon: "mdi-account",
      text: this.selectedUser.id
    });

    if (this.selectedUser?.status) {
      chips.push({
        key: "status",
        icon: "mdi-account-check",
        color: this.colorMap.get(this.selectedUser.status),
        text: this.selectedUser.status
      });
    }

    if (this.selectedUser.externalId) {
      chips.push({
        key: "externalId",
        icon: "mdi-connection",
        text: this.selectedUser.externalId
      });
    }

    return chips;
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    if (this.displayToDetailView) {
      actions.push({
        key: this.TO_DETAIL_VIEW,
        icon: "mdi-open-in-new",
        text: $t("components.partner.PartnerReportDetail.tooltip.toDetailView")
      });
    }

    actions.push({
      key: this.MAIL,
      icon: "mdi-email-outline",
      text: $t("sign.DocumentTemplateDetailEdit.sendMail")
    });

    actions.push({
      key: this.EDIT,
      icon: "mdi-pencil",
      text: $t("edit")
    });

    if (this.isGhgPortal) {
      actions.push({
        key: this.TO_GHG,
        icon: "mdi-car-multiple",
        text: $t("components.partner.UserAdministrationGhgCard.ghg.action")
      });
      actions.push({
        key: this.TO_THG_VEHICLES,
        icon: "mdi-car-multiple",
        text: $t("components.partner.UserAdministrationGhgCard.ghg.action2")
      });
      actions.push({
        key: this.TO_CHARGING_STATION,
        icon: "mdi-ev-station",
        text: $t("components.partner.UserAdministrationGhgCard.chargingStation.action")
      });
      actions.push({
        key: this.TO_METER_READING,
        icon: "mdi-battery-charging",
        text: $t("components.partner.UserAdministrationGhgCard.chargingStation.action2")
      });
      actions.push({
        key: this.TO_BILLING,
        icon: "mdi-cash-multiple",
        text: $t("components.partner.UserAdministrationGhgCard.billing.action")
      });
    }

    return actions;
  }

  get activitySource(): MrfiktivReferenceGen {
    return {
      refType: BackendResourceEnum.USER,
      refId: this.selectedUser?._id
    };
  }

  get partner() {
    return this.from.find(partner => partner.id === this.selectedPartnerId);
  }

  get isGhgPortal() {
    return ConfigModule.appContext === AppContextEnum.THG_PORTAL;
  }

  getFilter(): IPageFilterElement[] {
    return [
      new PageFilterElement({
        key: "userId",
        operation: "$eq",
        value: this.selectedUser?._id
      })
    ];
  }

  toDetailView() {
    this.$router.push({ name: "UserAdministrationDetail", params: { userId: this.selectedUser?._id } });
  }

  async mounted() {
    this.loadPartners();
  }

  bankingLoading(bankingLoading: boolean) {
    this.$emit("bankingLoading", bankingLoading);
  }

  getFlag(countryCode: CountryCodeEnum) {
    if (!countryCode) return;

    return getFlagEmoji(countryCode);
  }

  processAction(action: IAction) {
    switch (action.key) {
      case this.TO_DETAIL_VIEW: {
        this.toDetailView();
        break;
      }
      case this.EDIT: {
        this.isEditProfileDialog = true;
        break;
      }
      case this.TO_CHARGING_STATION: {
        this.goToChargingStation();
        break;
      }
      case this.TO_METER_READING: {
        this.goToMeterReading();
        break;
      }
      case this.TO_BILLING: {
        this.goToBilling();
        break;
      }
      case this.TO_GHG: {
        this.goToGhg();
        break;
      }
      case this.TO_THG_VEHICLES: {
        this.goToThgVehicles();
        break;
      }
      case this.MAIL: {
        this.templateDialog.show();
        break;
      }
    }
  }

  async loadPartners() {
    if (this.from.length === 0) {
      await PartnerModule.getPartners();
    }
  }

  async loadTemplateContext(): Promise<ITemplateContext> {
    return {
      user: this.selectedUser
    };
  }

  async deleteUser() {
    this.selectedUser?.delete();
  }

  async updateUser(user: IUser) {
    try {
      this.loading = true;
      const updateUserDto = new UpdateUserDtoFactory().createFromIUser(user);
      await this.selectedUser?.updateFromDto(updateUserDto);
    } catch (error) {
      this.$toast.error(this.$t("components.profile.EditProfileDialog.errorMsg"));
    } finally {
      this.loading = false;
    }
  }

  /**
   * Fetch thg to check if deletion is allowed in ghg context
   * if user has ghg that we had processed sucesfully, deletion of the user is not allowed due to regulatory
   */
  public goToGhg(): void {
    ThgPaginationModule.setFilter(this.getFilter());
    const goToHelper = new GoToHelper(this.$router);

    goToHelper.goToThgAdminList();
  }

  /**
   *
   */
  public goToThgVehicles(): void {
    ThgVehicleModule.setFilter(this.getFilter());
    const goToHelper = new GoToHelper(this.$router);

    goToHelper.goToThgVehicles();
  }

  /**
   * Fetch thg to check if deletion is allowed in ghg context
   * if user has ghg that we had processed sucesfully, deletion of the user is not allowed due to regulatory
   */
  public goToChargingStation(): void {
    const goToHelper = new GoToHelper(this.$router);
    ChargingStationPaginatedModule.setFilter(this.getFilter());
    ChargingStationPaginatedModule.setIsLoadAll(true);
    goToHelper.goToChargingStations();
  }

  /**
   * Fetch thg to check if deletion is allowed in ghg context
   * if user has ghg that we had processed sucesfully, deletion of the user is not allowed due to regulatory
   */
  public goToMeterReading(): void {
    const goToHelper = new GoToHelper(this.$router);
    MeterReadingPaginatedModule.setFilter(this.getFilter());
    goToHelper.goToMeterReadings();
  }

  /**
   * Fetch thg to check if deletion is allowed in ghg context
   * if user has ghg that we had processed sucesfully, deletion of the user is not allowed due to regulatory
   */
  public goToBilling(): void {
    const goToHelper = new GoToHelper(this.$router);
    BillingModule.setFilter(this.getFilter());
    BillingModule.setIsLoadAll(true);
    goToHelper.goToBillings();
  }
}
