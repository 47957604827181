var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-form",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "mb-n7",
              attrs: {
                cols: "12",
                md: "6"
              }
            },
            [
              _c("v-text-field", {
                attrs: {
                  type: "text",
                  label: _vm.$t(
                    "components.partner.PartnerReportInitializeCard.firstName"
                  ),
                  outlined: ""
                },
                model: {
                  value: _vm.initReport.firstName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.initReport, "firstName", $$v)
                  },
                  expression: "initReport.firstName"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "mb-n7",
              attrs: {
                cols: "12",
                md: "6"
              }
            },
            [
              _c("v-text-field", {
                attrs: {
                  type: "text",
                  label: _vm.$t(
                    "components.partner.PartnerReportInitializeCard.lastName"
                  ),
                  outlined: ""
                },
                model: {
                  value: _vm.initReport.lastName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.initReport, "lastName", $$v)
                  },
                  expression: "initReport.lastName"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "mb-n7",
              attrs: {
                cols: "12",
                md: "6"
              }
            },
            [
              _c("v-text-field", {
                attrs: {
                  type: "email",
                  label: _vm.$t(
                    "components.partner.PartnerReportInitializeCard.email"
                  ),
                  rules: _vm.emailRules,
                  outlined: ""
                },
                model: {
                  value: _vm.initReport.email,
                  callback: function callback($$v) {
                    _vm.$set(_vm.initReport, "email", $$v)
                  },
                  expression: "initReport.email"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "mb-n7",
              attrs: {
                cols: "12",
                md: "6"
              }
            },
            [
              _c("v-text-field", {
                attrs: {
                  type: "tel",
                  label: _vm.$t(
                    "components.partner.PartnerReportInitializeCard.phone"
                  ),
                  outlined: ""
                },
                model: {
                  value: _vm.initReport.phone,
                  callback: function callback($$v) {
                    _vm.$set(_vm.initReport, "phone", $$v)
                  },
                  expression: "initReport.phone"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "mb-n7",
              attrs: {
                cols: "12"
              }
            },
            [
              _c("v-text-field", {
                attrs: {
                  type: "text",
                  label: _vm.$t(
                    "components.partner.PartnerReportInitializeCard.street"
                  ),
                  outlined: ""
                },
                model: {
                  value: _vm.initReport.street,
                  callback: function callback($$v) {
                    _vm.$set(_vm.initReport, "street", $$v)
                  },
                  expression: "initReport.street"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "mb-n7",
              attrs: {
                cols: "12",
                md: "6"
              }
            },
            [
              _c("v-text-field", {
                attrs: {
                  type: "number",
                  label: _vm.$t(
                    "components.partner.PartnerReportInitializeCard.zip"
                  ),
                  outlined: ""
                },
                model: {
                  value: _vm.initReport.zip,
                  callback: function callback($$v) {
                    _vm.$set(_vm.initReport, "zip", $$v)
                  },
                  expression: "initReport.zip"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "mb-n7",
              attrs: {
                cols: "12",
                md: "6"
              }
            },
            [
              _c("v-text-field", {
                attrs: {
                  type: "text",
                  label: _vm.$t(
                    "components.partner.PartnerReportInitializeCard.city"
                  ),
                  outlined: ""
                },
                model: {
                  value: _vm.initReport.city,
                  callback: function callback($$v) {
                    _vm.$set(_vm.initReport, "city", $$v)
                  },
                  expression: "initReport.city"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "mb-n7",
              attrs: {
                cols: "12"
              }
            },
            [
              _c("v-text-field", {
                attrs: {
                  type: "text",
                  label: _vm.$t(
                    "components.partner.PartnerReportInitializeCard.numberplate"
                  ),
                  rules: _vm.numberPlateRules,
                  outlined: ""
                },
                model: {
                  value: _vm.initReport.numberPlate,
                  callback: function callback($$v) {
                    _vm.$set(_vm.initReport, "numberPlate", $$v)
                  },
                  expression: "initReport.numberPlate"
                }
              })
            ],
            1
          ),
          _c(
            "Can",
            {
              attrs: {
                I: _vm.ActionEnum.READ,
                a: _vm.ResourceEnum.VEHICLE
              }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "mb-n7",
                  attrs: {
                    cols: "12"
                  }
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      type: "text",
                      label: _vm.$t(
                        "components.partner.PartnerReportInitializeCard.vin"
                      ),
                      rules: _vm.vinRuleLength,
                      outlined: ""
                    },
                    model: {
                      value: _vm.initReport.registration_identificationnumber,
                      callback: function callback($$v) {
                        _vm.$set(
                          _vm.initReport,
                          "registration_identificationnumber",
                          $$v
                        )
                      },
                      expression: "initReport.registration_identificationnumber"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "mb-n7",
              attrs: {
                cols: "12"
              }
            },
            [
              _c("v-text-field", {
                attrs: {
                  type: "text",
                  label: _vm.$t(
                    "components.partner.PartnerReportInitializeCard.externalId"
                  ),
                  hint: _vm.$t(
                    "components.partner.PartnerReportInitializeCard.externalIdHint"
                  ),
                  outlined: ""
                },
                model: {
                  value: _vm.initReport.externalId,
                  callback: function callback($$v) {
                    _vm.$set(_vm.initReport, "externalId", $$v)
                  },
                  expression: "initReport.externalId"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "mb-n7",
              attrs: {
                cols: "12"
              }
            },
            [
              _c("v-select", {
                attrs: {
                  label: _vm.$t(
                    "components.partner.PartnerReportInitializeCard.reportType"
                  ),
                  flat: "",
                  outlined: "",
                  items: _vm.reportTypeItems
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function fn(_ref) {
                      var item = _ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.report.reportTypes.".concat(
                                  item
                                )
                              )
                            ) +
                            " "
                        )
                      ]
                    }
                  },
                  {
                    key: "selection",
                    fn: function fn(_ref2) {
                      var item = _ref2.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.report.reportTypes.".concat(
                                  item
                                )
                              )
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.initReport.reportType,
                  callback: function callback($$v) {
                    _vm.$set(_vm.initReport, "reportType", $$v)
                  },
                  expression: "initReport.reportType"
                }
              })
            ],
            1
          ),
          _vm.isKsrSetup
            ? _c(
                "v-col",
                {
                  staticClass: "mb-n7",
                  attrs: {
                    cols: "12"
                  }
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      type: "text",
                      label: _vm.$t(
                        "components.partner.PartnerReportInitializeCard.ksrJobId"
                      ),
                      hint: _vm.$t(
                        "components.partner.PartnerReportInitializeCard.ksrJobIdHint"
                      ),
                      outlined: ""
                    },
                    model: {
                      value: _vm.initReport.jobId,
                      callback: function callback($$v) {
                        _vm.$set(_vm.initReport, "jobId", $$v)
                      },
                      expression: "initReport.jobId"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.isDaSetup
            ? _c(
                "v-col",
                {
                  staticClass: "mb-n7",
                  attrs: {
                    cols: "12"
                  }
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      type: "text",
                      label: _vm.$t(
                        "components.partner.PartnerReportInitializeCard.serviceEventId"
                      ),
                      hint: _vm.$t(
                        "components.partner.PartnerReportInitializeCard.serviceEventIdHint"
                      ),
                      outlined: ""
                    },
                    model: {
                      value: _vm.initReport.serviceEventId,
                      callback: function callback($$v) {
                        _vm.$set(_vm.initReport, "serviceEventId", $$v)
                      },
                      expression: "initReport.serviceEventId"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            {
              staticClass: "mb-n7",
              attrs: {
                cols: "12",
                md: "12"
              }
            },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.baseReportUrls,
                  "item-text": "type",
                  "item-value": "id",
                  label: _vm.$t(
                    "components.partner.PartnerReportInitializeCard.url"
                  ),
                  outlined: "",
                  "return-object": "",
                  "single-line": ""
                },
                model: {
                  value: _vm.baseReportUrl,
                  callback: function callback($$v) {
                    _vm.baseReportUrl = $$v
                  },
                  expression: "baseReportUrl"
                }
              })
            ],
            1
          ),
          _c(
            "small",
            {
              staticClass: "ml-2",
              staticStyle: {
                height: "0"
              },
              attrs: {
                hidden: ""
              }
            },
            [_vm._v(" " + _vm._s(_vm.createUrlWithQuery) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }