var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-simple",
    {
      attrs: {
        title: "User Detail"
      }
    },
    [
      _c(
        "v-container",
        {
          staticClass: "wrapper"
        },
        [
          _vm.loading
            ? _c("v-skeleton-loader", {
                attrs: {
                  type:
                    "article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
                }
              })
            : _vm.selectedUser
            ? _c("user-administration-detail", {
                attrs: {
                  selectedUser: _vm.selectedUser,
                  displayToDetailView: false
                }
              })
            : _c("latest-entries-card-empty")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }