

















































































import { Component, Vue, Prop } from "vue-property-decorator";
import { IFilters } from "@/lib/interfaces/Docphant/filters.interface";
import { MachineStatusDisplayNames } from "@/store/enum/docphant/machine.status.enum";
import { LanugageDisplayNames } from "@/store/enum/docphant/language.enum";
import { MachineAttachmentDisplayNames } from "@/store/enum/docphant/machine.attachment.type.enum";
import { getDisplayNames } from "@/lib/EnumDisplayNameHelpers.ts";

@Component({
  components: {}
})
export default class FilterCard extends Vue {
  status = ["inprocess", "corrected", "activated", "disabled"];

  get machineStatus() {
    return getDisplayNames(MachineStatusDisplayNames);
  }
  get languages() {
    return getDisplayNames(LanugageDisplayNames);
  }
  get machineAttachmentTypes() {
    return getDisplayNames(MachineAttachmentDisplayNames);
  }
  @Prop({})
  toggleFilter!: Function;

  @Prop({})
  filters!: IFilters;

  @Prop({})
  setFilters!: Function;

  removeFilterItem(item: string, category: string) {
    const index = this.filters[category].indexOf(item);
    if (index > -1) {
      this.filters[category].splice(index, 1);
    }
  }

  clickBackground(event: Event) {
    if (event.target === document.getElementById("filterBackground")) {
      this.filter();
    }
  }

  filter() {
    this.setFilters(this.filters);
    this.toggleFilter();
  }
}
