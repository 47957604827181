











































import ContactCustomerDialog from "@/components/utility/ContactCustomerDialog.vue";
import { CreateReportUrlFactory } from "@/lib/utility/createReportUrlFactory";
import QrcodeVue from "qrcode.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import { CreateReportAsPartnerDto } from "@/lib/dto/create-report-as-partner-dto";
import Card from "../utility/Card.vue";
import PartnerReportInitializeCard from "./PartnerReportInitializeCard.vue";
import PartnerReportInitializeCardMixin, {
  PartnerReportInitializeDialogPagesEnum
} from "./PartnerReportInitializeCardMixin.vue";

@Component({
  components: {
    ContactCustomerDialog,
    PartnerReportInitializeCard,
    QrcodeVue,
    Card
  }
})
export default class PartnerReportInitializeDialogMail extends mixins(PartnerReportInitializeCardMixin) {
  @Prop()
  step!: PartnerReportInitializeDialogPagesEnum;

  @Prop()
  reportDto!: CreateReportAsPartnerDto;

  /**
   * The created URL
   */
  url = "";

  /**
   * Factory to create report URL
   */
  initReport = new CreateReportUrlFactory().setFromCreateDialog(this.reportDto);

  /**
   * Copy created Report URL to clipboard
   */
  copyLinkToClipboard() {
    navigator.clipboard.writeText(this.url);
    this.$toast.info(this.$t("components.partner.PartnerReportInitializeCard.linkCopied"));
  }

  confirm() {
    this.$emit("update", this.url);
    this.copyLinkToClipboard();
    this.setNextStep(PartnerReportInitializeDialogPagesEnum.SEND);
  }
}
