


























import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import TimelineCard from "@/components/utility/TimelineItem.vue";

@Component({
  components: { TimelineCard }
})
export default class BookingCustomerInformationTimeLineElement extends mixins(DarkModeHighlightMixin) {
  @Prop()
  firstName!: string;

  @Prop()
  lastName!: string;

  @Prop()
  numberPlate!: string;

  @Prop()
  email!: string;

  @Prop()
  phone!: string;

  get customerName() {
    if (!this.firstName && !this.lastName) {
      return undefined;
    }

    return `${this.firstName} ${this.lastName}`;
  }
}
