
import { ServiceNotImplementedException } from "@/lib/exceptions/http";
import { CancelToken } from "@/lib/utility/cancelToken";
import { handleError } from "@/lib/utility/handleError";
import { BasePagination } from "@/store/modules/base-pagination.store";
import { BackwardsCompatiblePaginatedStore } from "@/store/backwards-compatible-paginated.store";
import { Component, Vue } from "vue-property-decorator";
import { PaginatedBaseStore } from "@/store/paginated-base.store";

/**
 * Offers services to load data for a paginated list
 */
@Component({})
export default class PaginationListVueMixin extends Vue {
  isLoadingMore = false;
  loadingAll = true;

  /**
   * TODO: Type this
   * skipped this because it leads to too many errors to handle quickly
   */
  getModule(): BasePagination<any, any> | BackwardsCompatiblePaginatedStore<any, any> | PaginatedBaseStore<any, any> {
    throw new ServiceNotImplementedException("getModule");
  }

  get items() {
    return this.getModule().paginationList;
  }

  async loadMore(query = {}) {
    try {
      this.isLoadingMore = true;
      await this.loadAllFromLastId(query);
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoadingMore = false;
    }
  }

  async loadAllFromLastId(query = {}) {
    const legacyModule = this.getModule() as BasePagination<any, any>;
    const module = this.getModule() as PaginatedBaseStore<any, any>;
    const isLegacyModule = !!legacyModule?.isLegacy;

    if (isLegacyModule) {
      if (this.items.length === 0) {
        await legacyModule.fetchAllFromBeginning(query);
      } else {
        legacyModule.cancelToken?.requestCancellation();
        const cancelToken = new CancelToken();
        legacyModule.setCancelToken(cancelToken);
        await legacyModule.fetchRest({
          query,
          cancelToken
        });
      }
    } else {
      if (this.items.length === 0) {
        await module.fetchFirstPage(query);
      } else {
        await module.fetchNextPage(query);
      }
    }
  }

  async refresh(query = {}) {
    const legacyModule = this.getModule() as BasePagination<any, any>;
    const module = this.getModule() as PaginatedBaseStore<any, any>;

    const isLegacymodule = !!legacyModule?.isLegacy;

    this.loadingAll = true;
    if (isLegacymodule) {
      legacyModule.emptyList();
      await legacyModule.fetchAllFromBeginning(query);
    } else {
      await module.fetchFirstPage(query);
    }
    this.loadingAll = false;
  }
}
