/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * Creates an avatar of the mail
 * @example `info@mmmint.ai` -> I
 * @example `max.mustermann@mmmint.ai` -> MM
 * @param mail the supposedly mail
 * @returns an avatar of the first letters of the mails local part
 */
export function avatar(mail: string): string {
  if (!mail) {
    return "";
  }

  const localPart = mail.split("@");
  if (!localPart) {
    return mail[0].toUpperCase();
  }

  const bits = localPart[0].split(".");

  if (!bits) {
    return "";
  }

  if (bits.length >= 1 && bits[1]) {
    return `${bits[0][0]}${bits[1][0]}`.toUpperCase();
  }

  return `${bits[0][0]}`.toUpperCase();
}
