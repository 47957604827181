












import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class FormHelpCard extends Vue {
  @Prop()
  item?: IFormHelpCard;

  @Prop({ default: "" })
  number!: number;
}

interface IFormHelpCard {
  title: string;
  image: string;
  subtitle: string;
}
