var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      staticClass: "mx-4 my-4",
      attrs: {
        flat: ""
      }
    },
    [
      _c(
        "v-card-title",
        {
          staticClass: "title"
        },
        [_vm._v(_vm._s(_vm.errorMessage))]
      ),
      _c("v-img", {
        staticClass: "hidden-sm-and-down",
        attrs: {
          src: require("@/assets/undraw/docphant/undraw_qa_engineers_dg-5-p.svg"),
          "max-height": "500",
          "max-width": "500"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }