













import { Component, Vue, Prop } from "vue-property-decorator";
import { DocphantModule } from "@/store/modules/docphant";
import PartnerFooter from "@/components/partner/PartnerFooter.vue";
import CooperationPartners from "@/components/docphant/CooperationPartners.vue";
import { ConfigModule } from "@/store/modules/config";
import { IManufacturer } from "@/lib/interfaces/Docphant/manufacturer.schema.interface";

/**
 * Footer for Docphant pages
 */
@Component({
  components: {
    PartnerFooter,
    CooperationPartners
  }
})
export default class MachinePage extends Vue {
  @Prop({ default: "#ffffff" })
  color?: string;

  @Prop({ default: "#1d1d1c" })
  fontColor?: string;

  @Prop()
  partner?: string;

  @Prop()
  manufacturer!: IManufacturer;

  get getFooterColor() {
    return ConfigModule.darkMode ? "#272727" : this.color;
  }

  get getFontColor() {
    return ConfigModule.darkMode ? "#b3b3b3" : this.fontColor;
  }
  /*
   * information on cooperation partner content
   */
  get coop() {
    return DocphantModule.cooperatingPartners;
  }
  /**
   * gets machine from docphant store
   */
  get machine() {
    return DocphantModule.machine.manufacturer;
  }

  get getInformations() {
    return this.mapManufacturerToPartner(this.manufacturer);
  }

  /**
   * gets manufacturer of machine from store
   */
  mapManufacturerToPartner(manufacturer: IManufacturer) {
    if (!this.partner) {
      const contactInfo = {
        email: manufacturer.contact.email,
        phone: manufacturer.contact.phone
      };
      const addressInfo = {
        state: manufacturer.address.state,
        street: manufacturer.address.street,
        zip: manufacturer.address.zip,
        city: manufacturer.address.city
      };
      const settingsInfo = {
        contact: contactInfo,
        logoUrl: manufacturer.logoUrl,
        displayMap: false
      };
      const partnerInfo = {
        address: addressInfo,
        companyName: manufacturer.name,
        companyUsername: manufacturer.name,
        logo: manufacturer.logoUrl,
        contact: contactInfo,
        settings: settingsInfo
      };
      return partnerInfo;
    } else {
      return this.partner;
    }
  }
}
