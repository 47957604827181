









































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import {
  MrfiktivAdminTemplateViewModelGen,
  MrfiktivPartnerTemplateViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { Component, Prop } from "vue-property-decorator";
import TemplateEditor from "./TemplateEditor.vue";

@Component({
  components: { TemplateEditor }
})
export default class TemplateDetailBodyCard extends DarkModeHighlightMixin {
  @Prop()
  partnerId!: string;

  @Prop()
  template!: MrfiktivAdminTemplateViewModelGen | MrfiktivPartnerTemplateViewModelGen;
}
