var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "confirm-action-dialog",
    {
      attrs: {
        supressKeyboardConfirm: true,
        isDialogActive: _vm.isDialogActive,
        title: _vm.$t(
          "components.partner.PartnerReportDetail.SendToDaDialog.title"
        ),
        hideLeft: _vm.hideLeft,
        hideRight: _vm.hideRight
      },
      on: {
        "update:isDialogActive": function updateIsDialogActive($event) {
          _vm.isDialogActive = $event
        },
        "update:is-dialog-active": function updateIsDialogActive($event) {
          _vm.isDialogActive = $event
        },
        close: _vm.close,
        leftClick: _vm.leftClick,
        rightClick: _vm.rightClick
      }
    },
    [
      _c("partner-report-detail-send-to-da-dialog-stepper", {
        ref: "daStepper",
        attrs: {
          thirdPartySystem: _vm.thirdPartySystem
        },
        on: {
          createServiceEvent: _vm.createServiceEvent,
          createWorkshopTask: _vm.createWorkshopTask,
          close: _vm.close
        },
        model: {
          value: _vm.window,
          callback: function callback($$v) {
            _vm.window = $$v
          },
          expression: "window"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }