var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _c(
        "v-container",
        [
          _c("m-header", {
            attrs: {
              title: _vm.$t("sign.DocumentTable.title"),
              actions: _vm.actions,
              breadCrumbs: _vm.breadCrumbs,
              chips: _vm.chips
            },
            on: {
              actionClicked: function actionClicked($event) {
                return $event.exec()
              }
            }
          }),
          !_vm.partner.id
            ? _c("v-skeleton-loader", {
                attrs: {
                  type: "table"
                }
              })
            : _c("document-table")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }