













import UserAdministrationDetail from "@/components/partner/UserAdministrationDetail.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { AdminUserPaginationModule } from "@/store/modules/admin-user-pagination.store";
import { Component } from "vue-property-decorator";
import Vue from "vue";
import { IAdminUser } from "@/models/user.entity";
import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";

@Component({
  components: {
    LayoutSimple,
    UserAdministrationDetail,
    LatestEntriesCardEmpty
  }
})
export default class UserAdministrationDetailView extends PartnerFallbackMixin {
  loading = false;

  selectedUser: IAdminUser | null = null;

  async created() {
    this.loading = true;
    const selectedUser = await AdminUserPaginationModule.getSelectedUser(this.$route.params.userId);

    if (selectedUser) {
      await selectedUser.getIdentity();
    }
    this.selectedUser = selectedUser;
    this.loading = false;
    if (!selectedUser) {
      Vue.$toast.warning("User konnten nicht geladen.");
      this.$router.push({ name: "UserAdministration" });
    }
  }
}
