import { ThgBillingInformationViewmodelGen, ThgCreateBillingInformationDtoGen } from "@/services/thg/v1/data-contracts";
import { CountryCodeEnum } from "../enum/country-code.enum";

export class BillingInformationCreateDto implements ThgCreateBillingInformationDtoGen {
  firstName = "";
  lastName = "";
  isCompany = true;
  isTaxDeductible = true;
  company = "";
  taxnumber = "";
  contact = {
    email: "",
    phone: ""
  };
  address = {
    street: "",
    city: "",
    zip: "",
    state: "",
    countryCode: `${CountryCodeEnum.germany}`
  };

  constructor(billingInformation?: ThgBillingInformationViewmodelGen) {
    if (billingInformation?.id) {
      this.firstName = billingInformation.firstName || "";
      this.lastName = billingInformation.lastName || "";
      this.isCompany = billingInformation.isCompany;
      this.isTaxDeductible = billingInformation.isTaxDeductible;
      this.company = billingInformation.company || "";
      this.taxnumber = billingInformation.taxnumber || "";
      this.contact.email = billingInformation?.contact?.email || "";
      this.contact.phone = billingInformation?.contact?.phone || "";
      this.address.street = billingInformation?.address?.street || "";
      this.address.city = billingInformation?.address?.city || "";
      this.address.zip = billingInformation?.address?.zip || "";
      this.address.state = billingInformation?.address?.state || "";
      this.address.countryCode = billingInformation?.address?.countryCode || "";
    }
  }
}
