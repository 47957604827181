












































import { IManufacturerDto } from "@/lib/interfaces/Docphant/manufacturer.dto.interface";
import { IManufacturerUpdateStoreDto } from "@/store/interface/manufacturer.update.store.interface";
import { ManufacturerModule } from "@/store/modules/manufacturer";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IManufacturer } from "@/lib/interfaces/Docphant/manufacturer.schema.interface";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";

@Component({
  components: {}
})
export default class ManufacturerUpdateDialog extends Vue {
  @Prop({})
  manufacturer!: IManufacturer;

  manufacturerDto: IManufacturerDto = {
    name: "",
    address: {
      street: "",
      city: "",
      zip: "",
      state: "",
      countryCode: CountryCodeEnum.germany
    },
    contact: {
      email: "",
      phone: ""
    },
    logoUrl: ""
  };

  manufacturerUpdateDialog = false;

  closeUpdate() {
    this.manufacturerUpdateDialog = false;
  }

  async confirmUpdate() {
    const manufacturerUpdateStoreDto: IManufacturerUpdateStoreDto = {
      manufacturerId: this.manufacturer.id,
      dto: this.manufacturerDto
    };
    this.manufacturerUpdateDialog = false;
    await ManufacturerModule.updateManufacturer(manufacturerUpdateStoreDto);
  }

  initializeManufacturerUpdateDto() {
    this.manufacturerDto.name = this.manufacturer.name;
    this.manufacturerDto.logoUrl = this.manufacturer.logoUrl;
    this.manufacturerDto.address.street = this.manufacturer.address.street;
    this.manufacturerDto.address.zip = this.manufacturer.address.zip;
    this.manufacturerDto.address.city = this.manufacturer.address.city;
    this.manufacturerDto.address.state = this.manufacturer.address.state;
    this.manufacturerDto.contact.email = this.manufacturer.contact.email;
    this.manufacturerDto.contact.phone = this.manufacturer.contact.phone;
  }
}
