


















































import FilterCard from "@/components/filter/FilterCard.vue";
import FilterCardPagination from "@/components/filter/FilterCardPagination.vue";
import { PaginationFilterListElement } from "@/lib/filterable";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IPageFilterElement } from "@/models/page-filter-element.entity";
import { Component, Prop } from "vue-property-decorator";
import { AssetEnum, AssetRepository } from "../../lib/AssetRepository";
import { simpleDate } from "../../lib/utility/date-helper";
import {
  MrfiktivAdminTemplateViewModelGen,
  MrfiktivPartnerTemplateViewModelGen
} from "../../services/mrfiktiv/v1/data-contracts";
import { AdminTemplateModule } from "../../store/modules/admin-template.store";
import { PartnerTemplateModule } from "../../store/modules/partner-template.store";
import TemplateDetailCreateDialog from "./TemplateDetailCreateDialog.vue";
import { PredefinedFilterType } from "@/views/event/filter/event.filter";
import { getFlagEmojiByLanguage } from "@/lib/CountryCodeHelper";

@Component({
  components: { FilterCard, FilterCardPagination, TemplateDetailCreateDialog },
  filters: { simpleDate, getFlagEmojiByLanguage }
})
export default class TemplateList extends DarkModeHighlightMixin {
  @Prop()
  partnerId!: string;

  selected: MrfiktivPartnerTemplateViewModelGen | MrfiktivAdminTemplateViewModelGen | null = null;

  get emptyDataSrc() {
    return AssetRepository.getAsset(false, AssetEnum.emptyData);
  }

  get templates(): MrfiktivPartnerTemplateViewModelGen[] | MrfiktivAdminTemplateViewModelGen[] {
    if (this.partnerId) {
      return PartnerTemplateModule.filteredAndSorted;
    } else {
      return AdminTemplateModule.filteredAndSorted;
    }
  }

  get filterList(): PaginationFilterListElement[] {
    return PartnerTemplateModule.filterOptions;
  }

  get predefinedFilter(): PredefinedFilterType[] {
    if (this.partnerId) {
      return PartnerTemplateModule.predefinedFilter;
    } else {
      return [];
    }
  }

  get search() {
    return PartnerTemplateModule.search;
  }

  set search(search: string | undefined) {
    PartnerTemplateModule.setSearch(search);
  }

  get filters() {
    return PartnerTemplateModule.filters;
  }

  set filters(filters: IPageFilterElement[]) {
    PartnerTemplateModule.setFilters(filters);
  }

  refresh() {
    this.$emit("refreshList");
  }

  async set(item: MrfiktivPartnerTemplateViewModelGen | MrfiktivAdminTemplateViewModelGen) {
    this.$emit("setDetail", item);
  }
}
