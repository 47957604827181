import { DigitalSignatureRequestStatusEnum } from "@/lib/enum/digital-signature-request-status.enum";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";
import { IEntity } from "@/lib/utility/data/entity.interface";
import { handleError } from "@/lib/utility/handleError";
import signRequestService from "@/services/sign/services/sign-request.service";
import {
  SignCreateThirdPartySystemDtoGen,
  SignDigitalSignatureRecipientGen,
  SignDigitalSignatureRequestViewModelGen,
  SignDigitalSignatureStatusLogGen,
  SignUpdateSignRequestDtoGen
} from "@/services/sign/v1/data-contracts";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { SignRequestDataAccessLayer } from "@/store/modules/access-layers/sign-request.access-layer";
import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { IReference, Reference } from "./reference.entity";
import { ITimestampDocument, Timestamp, TimestampDocument } from "./timestamp.entity";
import { VehicleReference } from "./vehicle-reference.entity";
import { ActivityLog } from "./activity-log.entity";

class SignRecipientBase implements SignDigitalSignatureRecipientGen {
  /** The first name of the recipient */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "sign.SignRequestTable.firstName"
  })
  firstName?: string;

  /** The last name of the recipient */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "sign.SignRequestTable.lastName"
  })
  lastName?: string;

  /** The company name of the recipient */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "sign.SignRequestTable.companyName"
  })
  companyName?: string;

  /** The  email of the recipient */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "sign.SignRequestTable.email"
  })
  email: string;

  constructor(recipient?: Partial<SignDigitalSignatureRecipientGen>) {
    this.firstName = recipient?.firstName;
    this.lastName = recipient?.lastName;
    this.companyName = recipient?.companyName;
    this.email = recipient?.email || "";
  }
}

type ISignRecipient = SignRecipientBase;
const SignRecipient = Filter.createForClass(SignRecipientBase);

export { ISignRecipient, SignRecipient };

class SignStatusLogBase implements SignDigitalSignatureStatusLogGen {
  action: DigitalSignatureRequestStatusEnum;
  timestamp: string;

  constructor(log?: Partial<SignDigitalSignatureStatusLogGen>) {
    this.action = (log?.action || "") as DigitalSignatureRequestStatusEnum;
    this.timestamp = log?.timestamp || new Date().toISOString();
  }
}

type ISignStatusLog = SignStatusLogBase;
const SignStatusLog = Filter.createForClass(SignStatusLogBase);

export { ISignStatusLog, SignStatusLog };

@IsFilterable
class SignRequestBase
  implements IEntity<ISignRequest, SignUpdateSignRequestDtoGen>, SignDigitalSignatureRequestViewModelGen {
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.id",
    config: {
      itemCallback: () => SignRequestDataAccessLayer.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-sign-request"
    }
  })
  /** The id of the signature request */
  id: string;

  /** The partner id of the signature request */
  partnerId: string;

  /** The recipient of the signature request */
  @FilterConfig({
    type: SignRecipient
  })
  recipient: SignDigitalSignatureRecipientGen;

  /** The document assigned to the sign request */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.document.id"
  })
  documents: string[];

  /** The title of the sign request */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "sign.SignRequestTable.requestTitle",
    width: "350"
  })
  title: string;

  /** The status of the sign request */
  @FilterConfig({
    type: FilterTypes.ENUM,
    config: {
      items: Object.values(DigitalSignatureRequestStatusEnum).map(e => {
        return {
          text: `enums.DigitalSignatureRequestStatusEnum.${e}`,
          value: e
        } as IVSelectItem;
      }),
      itemValue: "value"
    },
    displayName: "sign.SignRequestTable.status",
    width: "85"
  })
  status: DigitalSignatureRequestStatusEnum;

  /** Can request be edited by the partner */
  isEditable?: boolean;

  /** History of status updates */
  @FilterConfig({
    type: SignStatusLog
  })
  logs: ISignStatusLog[];

  /** The states that that the patner can update the request to */
  nextStates?: DigitalSignatureRequestStatusEnum[];

  /** The signature ids of the sign request */
  signatures: string[];

  /**
   * The timestamps of the document
   * @example {"created":"2021-01-01T12:12:12.317Z","lastModified":"2021-01-01T12:12:12.317Z","modified":["2021-01-01T12:12:12.317Z","2021-01-01T12:12:12.000Z"]}
   */
  @FilterConfig({
    type: Timestamp
  })
  timestamp: ITimestampDocument;

  /**
   * The expiration date of the sign request
   * @format date-time
   */
  validBy?: string;

  /** The description of the sign request */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "sign.SignRequestTable.description",
    width: "350"
  })
  description?: string;

  /** The refs of the document */
  @FilterConfig({
    type: VehicleReference
  })
  refs?: IReference[];

  /**
   * Assignees to the report
   * @example []
   */
  @FilterConfig({
    displayName: "objects.ticket.assignees",
    type: FilterTypes.OBJECT_ID,
    width: "120",
    config: {
      itemCallback: () => PartnerUserModule.paginationList,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-user"
    }
  })
  assignees: string[];

  externalId: string;

  /**
   * Used in createion to link the sign request to tps
   */
  thirdPartySystemConnections: SignCreateThirdPartySystemDtoGen[];

  constructor(
    request?: Partial<SignDigitalSignatureRequestViewModelGen> & {
      thirdPartySystemConnections?: SignCreateThirdPartySystemDtoGen[];
    }
  ) {
    this.id = request?.id || "";
    this.partnerId = request?.partnerId || "";
    this.recipient = new SignRecipient(request?.recipient);
    this.documents = request?.documents || [];
    this.title = request?.title || "";
    this.status = (request?.status || "") as DigitalSignatureRequestStatusEnum;
    this.isEditable = request?.isEditable;
    this.logs = request?.logs?.map(log => new SignStatusLog(log)) || [];
    this.nextStates = (request?.nextStates as DigitalSignatureRequestStatusEnum[]) || [];
    this.signatures = request?.signatures || [];
    this.timestamp = new TimestampDocument(request?.timestamp);
    this.validBy = request?.validBy;
    this.description = request?.description || "";
    this.refs = (request?.refs || []).map(ref => new Reference(ref));
    this.assignees = request?.assignees || [];
    this.externalId = request?.externalId ?? "";
    this.thirdPartySystemConnections = request?.thirdPartySystemConnections ?? [];
  }

  map(request?: SignDigitalSignatureRequestViewModelGen) {
    this.id = request?.id || "";
    this.partnerId = request?.partnerId || "";
    this.recipient = new SignRecipient(request?.recipient);
    this.documents = request?.documents || [];
    this.title = request?.title || "";
    this.status = (request?.status || "") as DigitalSignatureRequestStatusEnum;
    this.isEditable = request?.isEditable;
    this.logs.splice(0, this.logs.length, ...(request?.logs || []).map(log => new SignStatusLog(log)));
    this.nextStates = (request?.nextStates as DigitalSignatureRequestStatusEnum[]) || [];
    this.signatures = request?.signatures || [];
    this.timestamp = new TimestampDocument(request?.timestamp);
    this.validBy = request?.validBy;
    this.description = request?.description || "";
    this.refs = (request?.refs || []).map(ref => new Reference(ref));
    this.assignees = request?.assignees || [];
    this.externalId = request?.externalId ?? "";
  }

  async fetch(): Promise<this> {
    const res = await signRequestService.getOneForPartner(this.partnerId, this.id);

    this.map(res);

    SignRequestDataAccessLayer.set(this);

    return this;
  }

  async update() {
    try {
      const res = await signRequestService.updateForPartner(this.partnerId, this.id, {
        assignees: this.assignees,
        description: this.description,
        documents: this.documents,
        recipient: this.recipient,
        refs: this.refs,
        title: this.title,
        validBy: this.validBy
      });

      this.map(res);

      SignRequestDataAccessLayer.set(this);
    } catch (e) {
      handleError(e);
    }

    return this;
  }

  async updatePartial(dto: SignUpdateSignRequestDtoGen) {
    try {
      const res = await signRequestService.updateForPartner(this.partnerId, this.id, dto);

      this.map(res);

      SignRequestDataAccessLayer.set(this);
    } catch (e) {
      handleError(e);
    }

    return this;
  }

  async updateState(status: DigitalSignatureRequestStatusEnum) {
    try {
      const res = await signRequestService.updateState(this.partnerId, this.id, { status });

      this.map(res);

      SignRequestDataAccessLayer.set(this);
    } catch (e) {
      handleError(e);
    }

    return this;
  }

  async delete(): Promise<void> {
    try {
      const res = await signRequestService.removeForPartner(this.partnerId, this.id);

      this.map(res);

      SignRequestDataAccessLayer.delete(this);
    } catch (e) {
      handleError(e);
    }
  }

  async create(): Promise<this> {
    const res = await signRequestService.create(this.partnerId, {
      documents: this.documents,
      recipient: this.recipient,
      title: this.title,
      assignees: this.assignees,
      description: this.description,
      refs: this.refs,
      validBy: this.validBy,
      externalId: this.externalId,
      tps: this.thirdPartySystemConnections
    });

    this.map(res);

    if (this.assignees?.length) {
      this.createAssigneeActivity(ActivityTypeEnum.CREATE_ASSIGNEE, this.assignees);
    }

    SignRequestDataAccessLayer.set(this);

    return this;
  }

  /**
   * Create an activity log for assignee changes
   * @param newAssignees The ids of the new assignees of the ticket
   * @param activityType Whether the assignee was added or removed
   */
  async createAssigneeActivity(
    activityType: ActivityTypeEnum.CREATE_ASSIGNEE | ActivityTypeEnum.DELETE_ASSIGNEE,
    newAssignees?: string[]
  ) {
    if (!newAssignees?.length) return;

    await new ActivityLog().createAssigneeActivity({
      partnerId: this.partnerId,
      source: {
        refType: ResourceEnum.SIGN,
        refId: this.id
      },
      newAssignees,
      activityType
    });
  }
}

type ISignRequest = SignRequestBase;
const SignRequest = Filter.createForClass(SignRequestBase);

export { ISignRequest, SignRequest };
