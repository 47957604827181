




























import { MachineModule } from "@/store/modules/machine";
import { ManufacturerModule } from "@/store/modules/manufacturer";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IManufacturer } from "@/lib/interfaces/Docphant/manufacturer.schema.interface";
import { MachineAttachmentModule } from "@/store/modules/machine-attachment";

@Component({
  components: {}
})
export default class ManufacturerDeleteDialog extends Vue {
  @Prop({})
  manufacturer!: IManufacturer;

  dialog = false;

  get machines() {
    return MachineModule.machineList;
  }

  get machineAttachments() {
    return MachineAttachmentModule.machineAttachmentList;
  }

  closeDelete() {
    this.dialog = false;
  }

  async confirmDelete() {
    this.dialog = false;
    if (this.machines.length > 0) {
      Vue.$toast.error("Sie können den Hersteller erst löschen, wenn alle Maschinen gelöscht sind.");
    } else if (this.machineAttachments.length > 0) {
      Vue.$toast.error("Sie können den Hersteller erst löschen, wenn alle Dokumente gelöscht sind.");
    } else {
      if (!(await ManufacturerModule.deleteManufacturer(this.manufacturer.id))) {
        Vue.$toast.error("Hersteller konnte nicht gelöscht werden.");
      } else {
        Vue.$toast.success("Hersteller wurde erfolgreich gelöscht.");
        return this.$router.push({ name: "ManufacturerListPage" });
      }
    }
  }
}
