






import { IdentityStatusEnum } from "@/lib/enum/identity-status.enum";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";
import { IAdminUser } from "@/models/user.entity";

@Component({})
export default class UserAdministrationDetailIdentityStatus extends DarkModeHighlightMixin {
  @Prop()
  value!: IAdminUser;

  get status() {
    return this.value?.status;
  }

  readonly colorMap: Map<string, string> = new Map([
    [IdentityStatusEnum.ARCHIVED, ""],
    [IdentityStatusEnum.UNKNOWN, ""],
    [IdentityStatusEnum.CONFIRMED, "success"],
    [IdentityStatusEnum.FORCE_CHANGE_PASSWORD, "warning"],
    [IdentityStatusEnum.RESET_REQUIRED, "warning"],
    [IdentityStatusEnum.UNCONFIRMED, "error"],
    [IdentityStatusEnum.COMPROMISED, "error"]
  ]);
}
