




















import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import { IAdminUser } from "@/models/user.entity";
import { IPageFilterElement, PageFilterElement } from "@/models/page-filter-element.entity";

@Component({
  components: {
    Card
  }
})
export default class UserAdminsitrationGhgCard extends DarkModeHighlightMixin {
  @Prop()
  selectedUser!: IAdminUser;

  actions: { title: string; actions: { action: () => void; btnText: string }[]; divider: boolean }[] = [
    {
      title: "components.partner.UserAdministrationGhgCard.ghg.title",
      actions: [
        {
          action: () => this.$emit("goToGhg"),
          btnText: "components.partner.UserAdministrationGhgCard.ghg.action"
        }
      ],
      divider: true
    },
    {
      title: "components.partner.UserAdministrationGhgCard.chargingStation.title",
      actions: [
        {
          action: () => this.$emit("goToChargingStation"),
          btnText: "components.partner.UserAdministrationGhgCard.chargingStation.action"
        },
        {
          action: () => this.$emit("goToMeterReading"),
          btnText: "components.partner.UserAdministrationGhgCard.chargingStation.action2"
        }
      ],
      divider: true
    },
    {
      title: "components.partner.UserAdministrationGhgCard.billing.title",
      actions: [
        {
          action: () => this.$emit("goToBilling"),
          btnText: "components.partner.UserAdministrationGhgCard.billing.action"
        }
      ],
      divider: false
    }
  ];

  filter: IPageFilterElement[] = [
    new PageFilterElement({
      key: "userId",
      operation: "$eq",
      value: this.selectedUser?._id ?? ""
    })
  ];
}
