var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          attrs: {
            flat: "",
            "max-width": "4000"
          }
        },
        [
          _c(
            "v-card-title",
            {
              staticClass: "title pt-4 mb-n7"
            },
            [_c("h4", [_vm._v("CI-Farbe")])]
          ),
          _c(
            "v-card-text",
            {
              staticClass: "mt-8"
            },
            [
              _c(
                "v-icon",
                {
                  staticClass: "mr-2",
                  attrs: {
                    small: "",
                    color: _vm.partner.settings.color
                  }
                },
                [_vm._v("mdi-invert-colors")]
              ),
              _vm._v(" " + _vm._s(_vm.partner.settings.color) + " ")
            ],
            1
          ),
          _c(
            "v-card-title",
            {
              staticClass: "title pt-4 mb-n7"
            },
            [_c("h4", [_vm._v("Karteninhalte")])]
          ),
          _c(
            "v-card-text",
            {
              staticClass: "mt-8"
            },
            [
              _c("PartnerDetailAttributeWithToolTip", {
                attrs: {
                  tooltip: "Formtitle",
                  displayName: _vm.partner.settings.formTitle,
                  icon: "mdi-format-title"
                }
              }),
              _c("PartnerDetailAttributeWithToolTip", {
                attrs: {
                  tooltip: "Titel der Karte",
                  displayName: _vm.partner.settings.cardTitle,
                  icon: "mdi-format-title"
                }
              }),
              _c("PartnerDetailAttributeWithToolTip", {
                attrs: {
                  tooltip: "Untertitel der Karte",
                  displayName: _vm.partner.settings.cardSubtitle,
                  icon: "mdi-format-title"
                }
              }),
              _c("PartnerDetailAttributeWithToolTip", {
                attrs: {
                  tooltip: "Text der Karte",
                  displayName: _vm.partner.settings.cardText,
                  icon: "mdi-format-title"
                }
              })
            ],
            1
          ),
          _c(
            "v-card-title",
            {
              staticClass: "title pt-4 mb-n7"
            },
            [_c("h4", [_vm._v("Fotos")])]
          ),
          _c(
            "v-card-text",
            {
              staticClass: "mt-8"
            },
            [
              _c("PartnerDetailImage", {
                attrs: {
                  favIconUrl: _vm.partner.settings.favIconUrl,
                  headerImageUrl: _vm.partner.settings.headerImageUrl,
                  logoUrl: _vm.partner.settings.logoUrl
                }
              })
            ],
            1
          ),
          _c(
            "v-card-title",
            {
              staticClass: "title pt-4 mb-n7"
            },
            [_c("h4", [_vm._v("Sonstiges")])]
          ),
          _c(
            "v-card-text",
            {
              staticClass: "mt-8"
            },
            [
              _c("PartnerDetailAttributeWithToolTip", {
                attrs: {
                  tooltip: "Anzeige der Location des Partners auf einer Karte",
                  displayName: _vm.partner.settings.displayMap,
                  icon: "mdi-earth"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }