var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-btn",
        {
          attrs: {
            icon: "",
            disabled: _vm.isLoading
          },
          on: {
            click: function click($event) {
              _vm.dialog = true
            }
          }
        },
        [_c("v-icon", [_vm._v("mdi-table-large-plus")])],
        1
      ),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            fullscreen: true,
            isDialogActive: _vm.dialog,
            title: _vm.$t("components.partner.AddPermission.title"),
            subtitle: _vm.$t("components.partner.AddPermission.subtitle"),
            rightLoading: _vm.isLoading
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.dialog = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.dialog = $event
            },
            leftClick: function leftClick($event) {
              _vm.dialog = false
            },
            rightClick: function rightClick($event) {
              return _vm.updatePermissions()
            }
          }
        },
        [
          _c("v-autocomplete", {
            attrs: {
              rules: _vm.rules,
              items: _vm.partners,
              "item-value": "_id",
              "item-text": "companyUsername",
              label: "Partner"
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function fn(data) {
                  return [
                    _c(
                      "v-list-item-content",
                      [
                        _c("v-list-item-title", {
                          domProps: {
                            innerHTML: _vm._s(data.item.companyName)
                          }
                        }),
                        _c("v-list-item-subtitle", [
                          _vm._v(
                            _vm._s(data.item.companyUsername) +
                              " (" +
                              _vm._s(data.item.id) +
                              ")"
                          )
                        ])
                      ],
                      1
                    )
                  ]
                }
              }
            ]),
            model: {
              value: _vm.partnerId,
              callback: function callback($$v) {
                _vm.partnerId = $$v
              },
              expression: "partnerId"
            }
          }),
          _vm.isReportPortalAppContext
            ? _c(
                "div",
                [
                  _vm._v(" " + _vm._s(_vm.$t("common.nouns.presets")) + ": "),
                  _vm._l(_vm.licenses, function(license) {
                    return _c(
                      "v-btn",
                      {
                        key: license + "license",
                        attrs: {
                          disabled: !_vm.partnerId,
                          elevation: 0,
                          text: "",
                          small: ""
                        },
                        on: {
                          click: function click($event) {
                            return _vm.applyLicense(license)
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("common.LicensesEnum.".concat(license))
                            ) +
                            " "
                        )
                      ]
                    )
                  })
                ],
                2
              )
            : _vm._e(),
          _vm.partnerId
            ? _c(
                "v-simple-table",
                {
                  attrs: {
                    "fixed-header": "",
                    height: "100%"
                  }
                },
                [
                  _c("thead", [
                    _c(
                      "tr",
                      [
                        _c(
                          "th",
                          {
                            staticClass: "text-left"
                          },
                          [_vm._v(" Berechtigung ")]
                        ),
                        _c(
                          "th",
                          {
                            staticClass: "text-left"
                          },
                          [_vm._v(" Backend ")]
                        ),
                        _vm._l(_vm.actions, function(action) {
                          return _c(
                            "th",
                            {
                              key: action,
                              staticClass: "text-left pr-8"
                            },
                            [_vm._v(" " + _vm._s(action) + " ")]
                          )
                        })
                      ],
                      2
                    )
                  ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.permissionForResources, function(resource) {
                      return _c(
                        "tr",
                        {
                          key: resource.resource
                        },
                        [
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "BackendResourceEnum.".concat(
                                    resource.resource
                                  )
                                )
                              )
                            )
                          ]),
                          _c("td", [_vm._v(_vm._s(resource.resource))]),
                          _vm._l(_vm.actions, function(action) {
                            return _c(
                              "td",
                              {
                                key: action
                              },
                              [
                                _c("v-checkbox", {
                                  attrs: {
                                    value: action,
                                    disabled: resource.hasAction(action)
                                  },
                                  on: {
                                    click: function click($event) {
                                      return resource.print()
                                    }
                                  },
                                  model: {
                                    value: resource.actions,
                                    callback: function callback($$v) {
                                      _vm.$set(resource, "actions", $$v)
                                    },
                                    expression: "resource.actions"
                                  }
                                })
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    }),
                    0
                  )
                ]
              )
            : _vm._e(),
          _c("debug", {
            attrs: {
              debug: _vm.permissionForResources
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }