import { IMachineAttachmentViewmodel } from "@/lib/interfaces/Docphant/machine.attachment.viewmodel";
import MachineAttachmentService from "@/services/mrfiktiv/services/machineAttachmentService";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { IMachineAttachmentCreateStoreDto } from "@/store/interface/machine-attachment.create.store.interace";

/**
 * Manages the machine document
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "machine-attachment",
  store
})
class MachineAttachment extends VuexModule {
  machineAttachmentList: IMachineAttachmentViewmodel[] = [];

  /**
   * Updates the stored machine
   * @param machine
   */
  @Mutation
  setAll(machineAttachmentList: IMachineAttachmentViewmodel[]) {
    this.machineAttachmentList = machineAttachmentList;
  }

  /**
   * Updates the stored machine
   * @param machine
   */
  @Mutation
  appendOneToList(machineAttachment: IMachineAttachmentViewmodel) {
    this.machineAttachmentList.push(machineAttachment);
  }

  /**
   *
   * Actions follow
   */

  /**
   * Gets a machineAttachmentList from the API and sets it to the local store
   * @param manufacturerId the id of the manufacturer that the machine belongs to
   */
  @Action
  async getAll(manufacturerId: string) {
    const machineAttachmentList = await MachineAttachmentService.getAll(manufacturerId);
    this.context.commit("setAll", machineAttachmentList);
  }

  /**
   * Creates a machine Attachment and update Store
   * @param machineAttachmentStoreDto the machineAttachmentStore Dto consists of manufacturerId and machineAttachmentDto
   */
  @Action
  async create(machineAttachmentDto: IMachineAttachmentCreateStoreDto) {
    const machineAttachment = await MachineAttachmentService.create(
      machineAttachmentDto.manufacturerId,
      machineAttachmentDto.dto
    );
    this.context.commit("appendOneToList", machineAttachment);
  }
}

export const MachineAttachmentModule = getModule(MachineAttachment);
