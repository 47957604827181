import { DetailFormComponentsEnum } from "@/lib/enum/detail-form-components.enum";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { Form, FormConfig, IsFormable } from "@/lib/formable";
import { RulesEnum } from "@/lib/rules/rules.map";
import { MrfiktivPartialContactDocumentGen } from "@/services/mrfiktiv/v1/data-contracts";

@IsFormable
@IsFilterable
class ContactPartialBase implements MrfiktivPartialContactDocumentGen {
  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.contact",
    searchKeywords: ["objects.contact.email", "common.nouns.contact"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.contact.email"
    },
    rules: [RulesEnum.EMAIL_RULE_OPTIONAL]
  })
  @FilterConfig({ type: FilterTypes.STRING, displayName: "objects.contact.email" })
  email?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.contact",
    searchKeywords: ["objects.contact.phone", "common.nouns.contact"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.contact.phone"
    },
    rules: [RulesEnum.PHONE_RULE_OPTIONAL]
  })
  @FilterConfig({ type: FilterTypes.STRING, displayName: "common.nouns.contact" })
  phone?: string;

  constructor(contact?: Partial<MrfiktivPartialContactDocumentGen>) {
    this.email = contact?.email ?? "";
    this.phone = contact?.phone ?? "";
  }
}

type IContactPartial = ContactPartialBase;
const ContactPartial = Form.createForClass(Filter.createForClass(ContactPartialBase));

export { ContactPartial, IContactPartial };
