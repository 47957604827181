
































































import { getFlagEmojiByLanguage } from "@/lib/CountryCodeHelper";
import { LanguageCodeEnum } from "@/lib/enum/language-code.enum";
import { detailedDate } from "@/lib/utility/date-helper";
import { $t } from "@/lib/utility/t";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import {
  MrfiktivAdminTemplateViewModelGen,
  MrfiktivPartnerTemplateViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { AdminTemplateModule } from "@/store/modules/admin-template.store";
import { PartnerTemplateModule } from "@/store/modules/partner-template.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import Card from "../utility/Card.vue";
import MHeader, { IAction } from "../utility/mmmint/MHeader.vue";
import TemplateDetailBodyCard from "./TemplateDetailBodyCard.vue";
import TemplateDetailContentUpdateDialog from "./TemplateDetailContentUpdateDialog.vue";
import TemplateDetailCreateDialog from "./TemplateDetailCreateDialog.vue";
import TemplateDetailDeleteDialog from "./TemplateDetailDeleteDialog.vue";
import TemplateDetailLanguageDialog from "./TemplateDetailLanguageDialog.vue";
import TemplateDetailMetaUpdateDialog from "./TemplateDetailMetaUpdateDialog.vue";

@Component({
  components: {
    MHeader,
    LatestEntriesCardEmpty,
    Card,
    TemplateDetailDeleteDialog,
    TemplateDetailBodyCard,
    TemplateDetailMetaUpdateDialog,
    TemplateDetailContentUpdateDialog,
    TemplateDetailCreateDialog,
    TemplateDetailLanguageDialog
  }
})
export default class TemplateDetail extends mixins(DarkModeHighlightMixin, PermissionMixin) {
  @Prop()
  loading!: boolean;

  @Prop()
  partnerId!: string;

  @Prop()
  displayToDetailView?: boolean;

  get chips() {
    const breadyumm = [];

    breadyumm.push({
      text: this.createdDate,
      color: "info"
    });

    if ((this.template as MrfiktivPartnerTemplateViewModelGen)?.isAdminTemplate) {
      breadyumm.push({
        text: $t("components.template.detail.delete.systemMail"),
        color: "info"
      });
    }

    const language = this.template?.meta.language as LanguageCodeEnum;
    if (language) {
      breadyumm.push({
        text: getFlagEmojiByLanguage(language),
        color: "info"
      });
    }

    for (const cat of this.template?.meta.categories ?? []) {
      breadyumm.push({
        text: cat,
        color: "primary"
      });
    }

    return breadyumm;
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    if (this.can(this.ActionEnum.UPDATE, this.ResourceEnum.TEMPLATE)) {
      actions.push({
        key: "bodyUpdate",
        text: $t("components.template.detail.body.update.title"),
        exec: () => {
          (this.$refs.contentUpdate as TemplateDetailContentUpdateDialog)?.open();
        }
      });
    }

    if (this.can(this.ActionEnum.UPDATE, this.ResourceEnum.TEMPLATE)) {
      actions.push({
        key: "metaUpdate",
        text: $t("components.template.detail.meta.update.title"),
        exec: () => {
          (this.$refs.metaUpdate as TemplateDetailMetaUpdateDialog)?.open();
        }
      });
    }

    if (this.can(this.ActionEnum.UPDATE, this.ResourceEnum.TEMPLATE)) {
      actions.push({
        key: "createForNewLanguage",
        text: $t("components.template.detail.language.title"),
        exec: () => (this.$refs.languageOptions as TemplateDetailLanguageDialog)?.open()
      });
    }

    if (this.displayToDetailView) {
      actions.push({
        key: "metaUpdate",
        text: $t("components.template.detail.toDetail"),
        exec: this.toDetailView
      });
    }

    if (
      this.template &&
      !(this.template as MrfiktivPartnerTemplateViewModelGen).isAdminTemplate &&
      this.can(this.ActionEnum.DELETE, this.ResourceEnum.TEMPLATE)
    ) {
      actions.push({
        key: "bodyUpdate",
        text: $t("delete"),
        exec: () => {
          (this.$refs.deleteDialog as TemplateDetailDeleteDialog)?.open();
        },
        color: "error"
      });
    }

    return actions;
  }

  get template(): MrfiktivAdminTemplateViewModelGen | MrfiktivPartnerTemplateViewModelGen | undefined {
    if (this.partnerId) {
      return PartnerTemplateModule.partnerTemplate;
    } else {
      return AdminTemplateModule.adminTemplate;
    }
  }

  get createdDate() {
    if (!this.template) {
      return "";
    }
    return detailedDate(this.template.timestamp.created);
  }

  toDetailView() {
    if (this.partnerId && this.template) {
      this.$router.push({
        name: "TemplateDetailPartnerView",
        params: { key: this.template.key, partnerId: this.partnerId },
        query: { language: this.template.meta.language }
      });
    } else if (this.template) {
      this.$router.push({
        name: "TemplateDetailView",
        params: { key: this.template.key },
        query: { language: this.template.meta.language }
      });
    }
  }

  refresh() {
    this.$emit("refreshList");
  }
}
