
















import SideCard from "@/components/utility/SideCard.vue";
import OperationDetail from "@/components/operations/OperationDetail.vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import { IOperation } from "@/models/operation.entity";

@Component({
  components: {
    SideCard,
    OperationDetail
  }
})
export default class OperationSideCard extends Vue {
  @Prop()
  operation!: IOperation;

  isLoading = false;

  async mounted() {
    this.isLoading = true;

    await this.operation.fetch();

    this.isLoading = false;
  }
}
