import { render, staticRenderFns } from "./UserAdministrationDetailPermissionsAddDialog.vue?vue&type=template&id=5fc9bbd4&scoped=true&"
import script from "./UserAdministrationDetailPermissionsAddDialog.vue?vue&type=script&lang=ts&"
export * from "./UserAdministrationDetailPermissionsAddDialog.vue?vue&type=script&lang=ts&"
import style0 from "./UserAdministrationDetailPermissionsAddDialog.vue?vue&type=style&index=0&id=5fc9bbd4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fc9bbd4",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';
installComponents(component, {VAutocomplete,VBtn,VCard,VCardActions,VCardText,VCardTitle,VCheckbox,VDialog,VDivider,VIcon,VListItemContent,VListItemSubtitle,VListItemTitle,VSelect,VSpacer,VWindow,VWindowItem})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5fc9bbd4')) {
      api.createRecord('5fc9bbd4', component.options)
    } else {
      api.reload('5fc9bbd4', component.options)
    }
    module.hot.accept("./UserAdministrationDetailPermissionsAddDialog.vue?vue&type=template&id=5fc9bbd4&scoped=true&", function () {
      api.rerender('5fc9bbd4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/partner/UserAdministrationDetailPermissionsAddDialog.vue"
export default component.exports