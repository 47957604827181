import { render, staticRenderFns } from "./DocumentDetailCard.vue?vue&type=template&id=26a96004&scoped=true&"
import script from "./DocumentDetailCard.vue?vue&type=script&lang=ts&"
export * from "./DocumentDetailCard.vue?vue&type=script&lang=ts&"
import style1 from "./DocumentDetailCard.vue?vue&type=style&index=1&id=26a96004&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26a96004",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VChip,VCol,VContainer,VDivider,VIcon,VList,VListItem,VListItemContent,VListItemIcon,VRow,VSkeletonLoader,VSpacer,VTab,VTabItem,VTabs,VTabsItems,VTabsSlider,VTimeline})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('26a96004')) {
      api.createRecord('26a96004', component.options)
    } else {
      api.reload('26a96004', component.options)
    }
    module.hot.accept("./DocumentDetailCard.vue?vue&type=template&id=26a96004&scoped=true&", function () {
      api.rerender('26a96004', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/sign/DocumentDetailCard.vue"
export default component.exports